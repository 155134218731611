import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { CombinedState } from 'redux';

import ErrorDialog from '../../components/ErrorDialog';
import { WithVisibility } from '../../components/WithVisibility';
import type { AppStore } from '../../state-management/store.d';
import { appStyle } from './App.style';
import ProjectHost, {
    aiCenterCallback,
    homePageCallback,
} from './ProjectHost';
import { TenantManagement } from './TenantManagement';

interface AppContentProps {
    isHostAdmin?: boolean;
    portalNavEnabled: boolean;
    failureCode?: null | number | string;
    backendCode: undefined | null | string;
    loggedIn: boolean;
    isErrored: boolean;
}

const AppContent: React.FC<AppContentProps> = ({
    failureCode, backendCode, isHostAdmin, portalNavEnabled, loggedIn, isErrored,
}) => {
    const { t } = useTranslation();
    const classes = appStyle();

    return (<div
        className={`${classes.appContent} ${!portalNavEnabled ? 'isPortalNavDisabled' : ''}`}
        data-testid="app-content">
        {/* Show page content if all good */}
        <WithVisibility visible={loggedIn && !isErrored}>
            <ShowPageContent isHostAdmin={isHostAdmin} />
        </WithVisibility>
        {/* Show error page if errored */}
        <WithVisibility visible={isErrored}>
            <ErrorDialog
                title={t(`tenant_error_title_${failureCode}`, t('tenant_error_title'))}
                subtitle={t(`tenant_error_subtitle_${failureCode}`, t('tenant_error_subtitle'))}
                buttonCallback={homePageCallback}
                backendCode={backendCode}
                buttonText={t('tenant_error_home_button')}
                secondaryButtonCallback={aiCenterCallback}
                secondaryButtonText={t('generic_error_AIF_home_button')}
            />
        </WithVisibility>
    </div>);
};

interface PageContentProps {
    isHostAdmin?: boolean;
}

const ShowPageContent: React.FC<PageContentProps> = ({ isHostAdmin }) => isHostAdmin ? <TenantManagement /> :
    <ProjectHost />;

export default connect((state: CombinedState<AppStore>) => ({
    isHostAdmin: !!state.auth.isHostAdmin,
    portalNavEnabled: !!state.config.paths?.portalNavEnabled,
    failureCode: state.authZ.failureCode,
    backendCode: state.authZ.backendCode,
}))(AppContent);
