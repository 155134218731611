import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React from 'react';

interface FormLayoutProps extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    footer: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submitForm?: (() => Promise<void>) & (() => Promise<any>);
    childrenClass?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },

    scrollableContent: {
        width: '100%',
        flexGrow: 1,
        overflowX: 'hidden',
        overflowY: 'auto',
        alignSelf: 'flex-start',
        height: `calc(100vh - ${2 * 76}px)`,
    },

    formPadding: {
    /* Add padding to form layout */
        padding: '24px',
    },

    tsErrorMessage: { color: theme.palette.semantic.colorErrorText },

    form: {

        '& .formTitle': {
            margin: '0px',
            fontFamily: Tokens.FontFamily.FontNormal,
            fontSize: Tokens.FontFamily.FontHeader4Size,
            lineHeight: Tokens.FontFamily.FontHeader4LineHeight,
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },

        '& .MuiFormHelperText-root.Mui-error': { color: theme.palette.semantic.colorErrorText },

        '& .makeStyles-tsErrorMessage': { color: theme.palette.semantic.colorErrorText },

        '& .tsErrorMessage': { color: theme.palette.semantic.colorErrorText },

        /* This is trick to handle tree select error helper text color */
        '& .MuiTypography-caption': { color: theme.palette.semantic.colorErrorText },

        '& .textFeild': {
            '& .MuiOutlinedInput-root': { height: '40px' },

            '& .MuiOutlinedInput-input': { padding: '12px 12px' },

            /* Set the On focus color for textField */
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.semantic.colorForegroundLink,
                borderWidth: 2,
            },

            '.MuiFormHelperText-root': { color: theme.palette.semantic.colorErrorText },
        },
    },

    buttonFooter: {
        borderTop: 'solid',
        borderTopWidth: '1px',
        borderTopColor: theme.palette.semantic.colorBorder,
        borderColor: theme.palette.semantic.colorForegroundLink,
        flexGrow: 0,
        position: 'relative',
        marginBottom: 'auto',

        '& div': {
            height: '70px',
            lineHeight: '70px',
        },

        '& div.MuiLinearProgress-indeterminate': {
            height: '4px',
            position: 'absolute',
            width: '100%',
            top: 0,
        },
    },
}));

const FormLayout: React.FC<FormLayoutProps> = ({
    children, childrenClass, footer, className, ...props
}) => {
    const classes = useStyles();
    const classNames = `${classes.root} ${className || ''}`;

    return (
        <form
            {...props}
            className={classNames}>
            <div className={classes.scrollableContent}>
                <div className={classes.form + ' ' + (childrenClass ? '' : classes.formPadding)}>
                    {children}
                </div>
            </div>
            <div className={footer ? classes.buttonFooter : ''}>
                {footer}
            </div>
        </form>
    );
};

export default FormLayout;
