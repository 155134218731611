import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import {
    Breadcrumbs,
    IconButton,
    Link,
    Menu,
    MenuItem,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type {
    BlobMetadata,
    EnvironmentVarsDto,
    MLPackageVersionDto,
    ProjectDto,
    RunDto,
} from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import type { AxiosResponse } from 'axios';
import prettyBytes from 'pretty-bytes';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    connect,
    useSelector,
} from 'react-redux';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';
import type { Column } from 'react-table';

import { getDatasetById } from '../../api/client/datasetManagerClient';
import { updateRun } from '../../api/client/pipelineManagerClient';
import { getMlVersionsByPackageId } from '../../api/client/pkgManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import { usePermissions } from '../../api/global/usePermissions';
import type {
    ProjectActions,
    ProjectState,
} from '../../api/global/useProjects';
import { useProjects } from '../../api/global/useProjects';
import {
    PipelineType,
    RunDisplayStatusType,
} from '../../api/types/common';
import type { BreadcrumbProps } from '../../components/Breadcrumbs';
import type { DialogPopupProps } from '../../components/Dialog';
import {
    CustomDialog,
    DialogType,
} from '../../components/Dialog';
import HeroInfoIcon from '../../components/HeroInfoIcon';
import Section from '../../components/Section';
import ServerSideTableGCS
    from '../../components/Table/ServerSideTable/ServerSideTableGCS';
import URLManager from '../../config/URLManager';
import { AZURE_SAS_TOKEN_SUBS_STRING } from '../../constants/BlobStorageConstants';
import { AppPermissions } from '../../enums/Authorization';
import { BaseTableIcons } from '../../enums/BaseTableIcons';
import type FeatureFlagManager from '../../feature-flag/FeatureFlagManager';
import { http } from '../../http';
import { RoutePath } from '../../route/routeMap';
import {
    dataFormatter,
    downloadContentWithSignUrl,
    extractErrorMessage,
    getCronStr,
    getFileOrFolderNameForPath,
} from '../../utils/CommonUtils';
import { dateFormatter } from '../../utils/DateFormatter';
import logger from '../../utils/Logging';
import { PipelineRunLogs } from './PipelineRunLogs';

const usePipelineDataPageStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: Tokens.FontFamily.FontMSize,
        width: '100%',
        fontWeight: 600,
        fontFamily: Tokens.FontFamily.FontNormal,
        overflowY: 'auto',

        '& .breadcrumb': { cursor: 'pointer' },

        '& .pipelineOutputTable': {
            marginBottom: '10px',
            border: `solid 1px ${theme.palette.semantic.colorBorder}`,
            boxShadow: 'none',
        },

        '& .pipelineDetails': {
            padding: '24px',
            height: 'auto',
            border: `solid 1px ${theme.palette.semantic.colorBorder}`,
            color: theme.palette.semantic.colorForeground,
            fontFamily: Tokens.FontFamily.FontNormal,
            fontSize: Tokens.FontFamily.FontMSize,
            lineHeight: Tokens.FontFamily.FontMLineHeight,
            fontWeight: 600,
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'row',

            '& .infoImage': {
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',

                '& .MuiSvgIcon-fontSizeLarge': { fontSize: Tokens.FontFamily.FontHeroSize },
            },

            '& .sectionContent': {
                width: '100%',
                marginLeft: '50px',

                '& .sectionRow': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    lineHeight: Tokens.FontFamily.FontHeader2LineHeight,

                    '& .rowData': {
                        width: '50%',
                        color: theme.palette.semantic.colorForeground,
                        alignItems: 'center',
                    },

                    '& .rowHeader': {
                        width: '30%',
                        display: 'inline-block',
                    },

                    '& .rowValue': {
                        lineHeight: Tokens.FontFamily.FontMLineHeight,
                        fontWeight: 'normal',
                        marginLeft: '10px',

                        '& .MuiSvgIcon-root': { marginBottom: '-5px' },
                    },
                },
            },
        },
    },
}));

interface PipelineDetailsPageProps {
    loadPipelineDataUrl: Function;
    loadPipelineName: Function;
    loadRunName: Function;
    loadDirectoryName: Function;
    currentProject: ProjectDto;
    permissions: AppPermissions[];
}

const PipelineDetailsPage: React.FC<PipelineDetailsPageProps> = (props) => {
    const classes = usePipelineDataPageStyles();
    const { t } = useTranslation();
    const { pipelineRunId } = useParams<{ pipelineRunId: string }>();
    const feedback = useFeedback();
    const history = useHistory();

    const response: RunDto = {};
    const loadPipelineDataUrl = props.loadPipelineDataUrl;
    const loadPipelineName = props.loadPipelineName;
    const loadRunName = props.loadRunName;
    const loadDirectoryName = props.loadDirectoryName;
    const currentProject = props.currentProject;

    const [ pipelineDetailsUrl ] = useState(URLManager.url().apiTrainer + '/runs/' + pipelineRunId);
    const [ pipelineDetailsResponse, setpipelineDetailsResponse ] = useState(response);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ dialogProps, setDialogProps ] = useState<DialogPopupProps | undefined>(undefined);

    const projectName = currentProject.name;
    const [ pipelineType, setPipelineType ] = useState('');
    const [ pipelineDisplayName, setPipelineDisplayName ] = useState('');
    const [ pipelineStatus, setPipelineStatus ] = useState('');
    const [ createdOn, setCreatedOn ] = useState('');
    const [ trainingDatasetName, setTrainingDatasetName ] = useState('');
    const [ evaluationDatasetName, setEvaluationDatasetName ] = useState('');
    const [ inputDirectory, setInputDirectory ] = useState('');
    const [ evaluationDirectory, setEvaluationDirectory ] = useState('');
    const [ parameters, setParameters ] = useState<EnvironmentVarsDto[]>();
    const [ scheduledTime, setScheduledTime ] = useState('');
    const [ generatedTrainingPackageVersion, setGeneratedTrainingPackageVersion ] = useState('-');

    useEffect(() => {
        http.get(pipelineDetailsUrl + '?projectId=' + currentProject?.id).then((res: AxiosResponse) => {
            setpipelineDetailsResponse(res.data.data);

            const outputRootDirectory = 'PipelineRun/' + res.data.data.pipeline.name + '/' + res.data.data.name + '/';
            loadPipelineDataUrl(URLManager.url().apiTrainer + '/datasets/listPipelineArtifacts?directoryName=' + outputRootDirectory + '&projectId=' + currentProject?.id);
            loadPipelineName(res.data.data.pipeline.name);
            loadRunName(res.data.data.name);
            loadDirectoryName(outputRootDirectory);

            setPipelineDisplayName(res.data.data.pipeline.displayName);
            setPipelineType(t(`PIPELINE_${res.data.data.pipelineType}`));
            setPipelineStatus(t(`RUNS_${res.data.data.displayStatus}`));
            setCreatedOn(dateFormatter(res.data.data.createdOn, 'en'));

            if (res.data.data.dataDirectory) {
                if (res.data.data.dataDirectory.startsWith('/')) {
                    setInputDirectory(res.data.data.dataDirectory);
                } else {
                    setInputDirectory('/' + res.data.data.dataDirectory);
                }
            }

            if (res.data.data.evaluationDataDirectory) {
                if (res.data.data.evaluationDataDirectory.startsWith('/')) {
                    setEvaluationDirectory(res.data.data.evaluationDataDirectory);
                } else {
                    setEvaluationDirectory('/' + res.data.data.evaluationDataDirectory);
                }
            }

            setParameters(res.data.data.pipeline.environmentVars);

            if (res.data.data.trainingDatasetId) {
                // eslint-disable-next-line promise/no-nesting
                getDatasetById(res.data.data.trainingDatasetId, currentProject?.id).then(trainingDataset => {
                    if (trainingDataset?.name) {
                        setTrainingDatasetName(trainingDataset.name);
                    }
                    return true;
                })
                    .catch((error: Error) => {
                        logger.error({
                            identifier: 'Pipeline data',
                            message: 'Error getting datasets',
                            error,
                        });
                    });
            }

            if (res.data.data.evaluationDatasetId) {
                // eslint-disable-next-line promise/no-nesting
                getDatasetById(res.data.data.evaluationDatasetId, currentProject?.id).then(evaluationDataset => {
                    if (evaluationDataset?.name) {
                        setEvaluationDatasetName(evaluationDataset.name);
                    }
                    return true;
                })
                    .catch((error: Error) => {
                        logger.error({
                            identifier: 'Pipeline data',
                            message: 'Error getting dataset by ID',
                            error,
                        });
                    });
            }

            if (res.data.data.pipeline.scheduleType === 'TIME_TRIGGER') {
                let scheduled = '';
                if (res.data.data.pipeline.repeat) {
                    if (res.data.data.pipeline?.schedule) {
                        scheduled = getCronStr(res.data.data.pipeline?.schedule);
                    }
                } else if (res.data.data.pipeline?.triggerTime) {
                    scheduled = dateFormatter(parseInt(res.data.data.pipeline?.triggerTime), 'en');
                }
                setScheduledTime(scheduled);
            }
            return true;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        })
            .catch((e: any) => {
                logger.error({
                    identifier: 'Pipeline data',
                    message: 'Exception while fetching pipeline run',
                    error: e,
                });
            });
    }, [ pipelineDetailsUrl ]);

    useEffect(() => {
        if (pipelineDetailsResponse.displayStatus === RunDisplayStatusType.SUCCESSFUL
      && pipelineDetailsResponse.pipelineType !== PipelineType.EvaluateOnly) {
            getMlVersionsByPackageId(pipelineDetailsResponse.pipeline?.sourceId, currentProject?.id, pipelineRunId)
                .then((versions: MLPackageVersionDto[] | undefined) => {
                    if (versions?.length) {
                        const majorversion = versions[0].customVersion ? versions[0].customVersion : versions[0]?.version;
                        setGeneratedTrainingPackageVersion(majorversion + '.' + versions[0]?.trainingVersion);
                    }
                    return true;
                })
                .catch(error => {
                    logger.error({
                        identifier: 'ML package get new trained version',
                        message: 'Error getting trained ML Package version',
                        error,
                    });
                });
        }
    }, [ pipelineDetailsResponse ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlleMoreIconClickEvent = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleContextPopopCloseEvent = (): void => {
        setAnchorEl(null);
    };

    const baseDialogProps = {
        open: true,
        handleClose: (): void => setDialogProps(undefined),
        closeIconButton: true,
        type: DialogType.Custom,
        primaryButtonText: t('dialog_button_confirm_text'),
        secondaryButtonText: t('dialog_button_cancel_text'),
        secondarybuttonCallback: (): void => {
            setDialogProps(undefined);
        },
    };

    const buttonItems = [];

    const addButton = (action: string) => {
        buttonItems.push({
            buttonText: 'pipeline_data_' + action + '_run_button',
            buttonCallback: () => {
                setDialogProps({
                    ...baseDialogProps,
                    title: t('pipeline_run_' + action + '_dialog_title'),
                    infoText: t('pipeline_run_' + action + '_dialog_info_text'),
                    warningText: t('pipeline_run_' + action + '_dialog_warning_text'),
                    confirmationText: t('pipeline_run_' + action + '_dialog_confirmation_text'),
                    primarybuttonCallback: () => {
                        updateRun(pipelineRunId, currentProject?.id, action)
                            .then(() => {
                                setDialogProps(undefined);
                                /* Move to Pipeline list page on success */
                                feedback.enqueueSuccess(t('feedback_pipeline_' + action + '_success'));
                                history.push(generatePath(RoutePath.PIPELINES, { projectName: currentProject?.name }));
                                return true;
                            })
                            .catch((error) => {
                                logger.error({
                                    identifier: 'Pipeline data',
                                    message: 'Error while ' + action + 'ing pipeline run',
                                    error,
                                });
                                setDialogProps(undefined);
                                feedback.enqueueError(extractErrorMessage(error, t('feedback_pipeline_' + action + '_error')));
                            });
                    },
                });
            },
        });
    };

    if ((props.permissions.indexOf(AppPermissions.MLPipeline_Edit) > -1) && (pipelineDetailsResponse.displayStatus === RunDisplayStatusType.RUNNING ||
        pipelineDetailsResponse.displayStatus === RunDisplayStatusType.QUEUED)) {
        addButton('kill');
    }

    if ((props.permissions.indexOf(AppPermissions.MLPipeline_Edit) > -1) && (pipelineDetailsResponse.displayStatus === RunDisplayStatusType.QUEUED_FOR_RESOURCES)) {
        addButton('dequeue');
    }

    if ((props.permissions.indexOf(AppPermissions.MLPipeline_Create) > -1) && (pipelineDetailsResponse.displayStatus === RunDisplayStatusType.SUCCESSFUL ||
        pipelineDetailsResponse.displayStatus === RunDisplayStatusType.KILLED ||
        pipelineDetailsResponse.displayStatus === RunDisplayStatusType.FAILED)) {
        addButton('restart');
    }

    if ((props.permissions.indexOf(AppPermissions.MLPipeline_Edit) > -1) && pipelineDetailsResponse.displayStatus === RunDisplayStatusType.SCHEDULED) {
        buttonItems.push({
            buttonText: 'pipeline_data_edit_button',
            buttonCallback: () => {
                history.push(generatePath(RoutePath.EDIT_PIPELINE, {
                    pipelineId: pipelineDetailsResponse.pipeline?.id,
                    mlpackageName: pipelineDetailsResponse.sourceName,
                    projectName,
                }));
            },
        });
    }

    /* Generate breadcrum for pipeline detail page */
    const pipelineDetailsbreadcrumProps: BreadcrumbProps =
  {
      pageTitle: pipelineDisplayName,
      crumbs: [
          {
              title: t('pipeline_list_title'),
              link: generatePath(RoutePath.PIPELINE, { projectName }),
          },
      ],
  };

    let scheduledTimeLabel = scheduledTime;
    if (pipelineDetailsResponse.pipeline?.triggerTime && pipelineDetailsResponse.pipeline?.recurrence && pipelineDetailsResponse.pipeline.recurrence > 0) {
        scheduledTimeLabel = t('recurrence_display', {
            recurrence: pipelineDetailsResponse.pipeline.recurrence,
            time: dateFormatter(parseInt(pipelineDetailsResponse.pipeline?.triggerTime), 'en', false),
        });
    }

    const getSourcePackageVersion = (): string | undefined => pipelineDetailsResponse.sourceCustomVersion ? pipelineDetailsResponse.sourceCustomVersion : pipelineDetailsResponse.sourceVersion;

    return projectName ? (
        <div className={classes.root}>
            <Section
                breadcrumProps={pipelineDetailsbreadcrumProps}
                buttonItems={buttonItems}>
                <div className="pipelineDetails">
                    <div
                        aria-hidden
                        className="infoImage">
                        <HeroInfoIcon />
                    </div>
                    <div className="sectionContent">
                        <div className="sectionRow">
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('pipeline_data_pipeline_type_label')}
                                </span>
                                <span className="rowValue">
                                    {pipelineType}
                                </span>
                            </div>
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('pipeline_data_pipeline_status_label')}
                                </span>
                                <span className="rowValue">
                                    {pipelineStatus}
                                </span>
                            </div>
                        </div>

                        <div className="sectionRow spaceFromTop">
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('pipeline_data_pipeline_ml_package_label')}
                                </span>
                                <span className="rowValue">
                                    {pipelineDetailsResponse.sourceName}
                                </span>
                            </div>
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('pipeline_data_pipeline_ml_package_version_label')}
                                </span>
                                <span className="rowValue">
                                    {getSourcePackageVersion()}
                                </span>
                            </div>
                        </div>
                        <div className="sectionRow spaceFromTop">
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('pipeline_data_pipeline_ml_package_generated_version_label')}
                                </span>
                                <span className="rowValue">
                                    {generatedTrainingPackageVersion}
                                </span>
                            </div>
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('pipeline_data_pipeline_input_directory_label')}
                                </span>
                                <span className="rowValue">
                                    {trainingDatasetName + inputDirectory}
                                </span>
                            </div>
                        </div>

                        <div className="sectionRow spaceFromTop">
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('pipeline_data_pipeline_evaluation_directory_label')}
                                </span>
                                <span className="rowValue">
                                    {(evaluationDatasetName + evaluationDirectory).length > 0
                                        ? (evaluationDatasetName + evaluationDirectory) : '--'}
                                </span>
                            </div>
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('gpu_label')}
                                </span>
                                <span className="rowValue">
                                    {typeof pipelineDetailsResponse.pipeline?.processor === 'undefined' ? ' ' :
                                        pipelineDetailsResponse.pipeline?.processor === 'GPU' ? <DoneSharpIcon /> : <ClearSharpIcon />}
                                </span>
                            </div>
                        </div>

                        <div className="sectionRow spaceFromTop">
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('pipeline_data_scheduled_time_label')}
                                </span>
                                <span className="rowValue">
                                    {scheduledTimeLabel}
                                </span>
                            </div>
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('pipeline_data_pipeline_parameters_label')}

                                    <IconButton
                                        aria-label={t('a11y_show_pipeline_params')}
                                        disabled={parameters?.length === 0}
                                        onClick={handlleMoreIconClickEvent}
                                        data-testid="uipath-pipeline-parameters"
                                        data-cy="uipath-pipeline-parameters">
                                        <ArrowDropDownIcon fontSize='inherit' />
                                    </IconButton>

                                    <Menu
                                        style={{ marginTop: '40px' }}
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleContextPopopCloseEvent}>
                                        {
                                            parameters?.map((item: EnvironmentVarsDto) => (
                                                <MenuItem
                                                    key={item.key + '_' + item.value}
                                                    disabled>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '300px',
                                                        justifyContent: 'space-between',
                                                        fontWeight: 300,
                                                    }}>
                                                        <span>
                                                            {item.key}
                                                        </span>
                                                        <span>
                                                            {item.value}
                                                        </span>
                                                    </div>
                                                </MenuItem>
                                            ))
                                        }
                                    </Menu>
                                </span>
                            </div>
                        </div>
                        <div className="sectionRow spaceFromTop">
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('pipeline_data_pipeline_failure_reason')}
                                </span>
                                <span className="rowValue">
                                    {(pipelineDetailsResponse?.failureReason?.length ?? 0) > 0
                                        ? (pipelineDetailsResponse.failureReason) : '--'}
                                </span>
                            </div>
                            <div className="rowData">
                                <span className="rowHeader">
                                    {t('common_createdOn_label')}
                                </span>
                                <span className="rowValue">
                                    {createdOn}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            {dialogProps && <CustomDialog {...dialogProps} />}
        </div>
    ) : null;
};

interface PipelineDataProps {
    url: string;
    pipelineName: string;
    runName: string;
    directoryName: string;
    currentProject: ProjectDto | undefined;
    permissions: AppPermissions[];
    isInstanceProfileEnabled: boolean;
    azureStorageFQDNSuffix: string;
}

interface PipelineDataBreadCrumb {
    name: string;
    link: string;
    linkType: 'ROUTER' | 'STATE';
    index: number;
}

const PipelineOutput: React.FC<PipelineDataProps> = (props) => {
    const { t } = useTranslation();
    const dataMapper: Column[] = [
        {
            Header: `${t('common_name_label')}`,
            accessor: 'blobName',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Cell: (data: any): string => getFileOrFolderNameForPath(data),
        },
        {
            Header: `${t('common_type_label')}`,
            accessor: 'blobType',
            Cell: ({ cell: { value } }): string => dataFormatter(value),
        },
        {
            Header: `${t('common_size_label')}`,
            accessor: 'blobSize',
            Cell: ({ cell }): string => {
                const blobInfo: BlobMetadata = cell.row.original;
                if (blobInfo && blobInfo.blobType !== 'DIRECTORY' && typeof cell.value !== 'undefined' && cell.value) {
                    return prettyBytes(cell.value);
                }

                return '--';

            },
        },
    ];

    const [ url, setUrl ] = useState(props.url);
    const feedback = useFeedback();
    const [ pipelineName, setPipelineName ] = useState(props.pipelineName);
    const [ runName, setRunName ] = useState(props.runName);
    const [ directoryName, setDirectoryName ] = useState(props.directoryName);
    const currentProject = props.currentProject;
    const [ itemDeletedFlag, setItemDeletedFlag ] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ activeNode, setActiveNode ] = useState<any>(null);
    const [ open, setOpen ] = React.useState(false);
    const authToken = useSelector((state: any) => state.auth.authToken);

    useEffect(() => {
        setUrl(props.url);
        setPipelineName(props.pipelineName);
        setRunName(props.runName);
        setDirectoryName(props.directoryName);
    }, [ props.url ]);

    const icons = [
        {
            iconType: BaseTableIcons.DOWNLOAD,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (_: any, data: any): void => {
                downloadContentWithSignUrl(URLManager.url().apiTrainer + '/signedURL?blobName=' + data.original.blobName + '&signingMethod=GET&projectId=' + props.currentProject?.id, props.isInstanceProfileEnabled, authToken, props.azureStorageFQDNSuffix);
            },
        },
    ];

    if (props.permissions.indexOf(AppPermissions.MLPipeline_Edit) > -1) {
        icons.push({
            iconType: BaseTableIcons.DELETE,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, data: any) => {
                setOpen(true);
                setActiveNode(data);
            },
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deleteData = (): Promise<boolean | void> | void => {
        if (activeNode?.original) {
            const deleteURL = URLManager.url().apiTrainer + '/datasets/files?blobType=' + activeNode.original.blobType + '&fileName=' + activeNode.original.blobName + '&projectId=' + currentProject?.id;
            return http.delete(deleteURL)
                .then(() => {
                    closeDialog();
                    // toggle the flag to reload table data
                    setItemDeletedFlag(prev => !prev);
                    feedback.enqueueSuccess(t('feedback_delete_success_ouput'));
                    return true;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                })
                .catch((error: any) => {
                    closeDialog();
                    logger.error({
                        identifier: 'Pipeline data',
                        message: 'Error deleting dataset file',
                        error,
                    });
                    // toggle the flag to reload table data
                    setItemDeletedFlag(prev => !prev);
                    feedback.enqueueError(extractErrorMessage(error, t('feedback_delete_error_output')));
                });
        }
    };

    const [ breadCrumbs, setBreadCrumbs ] = useState<PipelineDataBreadCrumb[]>([ {
        name: t('pipeline_data_output_title'),
        link: 'PipelineRun/' + pipelineName + '/' + runName + '/',
        linkType: 'STATE',
        index: 0,
    } ]);

    const setURLViaBreadCrumb = useCallback((dirName: string, index: number) => {
        const outputRootDirectory = 'PipelineRun/' + pipelineName + '/' + runName + '/';

        if (dirName !== outputRootDirectory) {
            setBreadCrumbs(breadCrumbs.slice(0, index + 1));
        }

        setUrl(`${URLManager.url().apiTrainer}/datasets/listPipelineArtifacts?directoryName=${dirName}&projectId=${currentProject?.id}`);
    }, [ breadCrumbs ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const urlBuilder = useCallback((_: any, nextPageToken: string): void => {
        setUrl(`${URLManager.url().apiTrainer}/datasets/listPipelineArtifacts?directoryName=${directoryName}${nextPageToken ? ('&pageToken=' + nextPageToken) : ''}&projectId=${currentProject?.id}`);
    }, [ directoryName ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOutputCellClicked = (rowInfo: any): void => {
        if (rowInfo.original.blobType === 'DIRECTORY') {
            const outputRootDirectory = 'PipelineRun/' + pipelineName + '/' + runName + '/';
            setDirectoryName(rowInfo.original.blobName);
            const newCrumbs: PipelineDataBreadCrumb[] = [
                {
                    name: t('pipeline_data_output_title'),
                    link: outputRootDirectory,
                    linkType: 'STATE',
                    index: 0,
                },
            ];

            const fromStr: string[] = rowInfo.original.blobName.replace(outputRootDirectory, '').split('/');
            let dirName = outputRootDirectory;
            fromStr.forEach((str, index) => {
                dirName += (str + '/');
                newCrumbs.push({
                    name: str,
                    link: dirName,
                    index: index + 1,
                    linkType: 'STATE',
                });
            });
            setBreadCrumbs([ ...newCrumbs ]);
            setUrl(`${URLManager.url().apiTrainer}/datasets/listPipelineArtifacts?directoryName=${rowInfo.original.blobName}&projectId=${currentProject?.id}`);
        }
    };

    const closeDialog = (): void => {
        setOpen(false);
    };

    const deleteClicked = async (): Promise<void> => {
        await deleteData();
    };

    return (
        <div>
            <CustomDialog
                title={t('data_delete_dialog_title_text')}
                open={open}
                handleClose={closeDialog}
                closeIconButton
                infoText={t('data_delete_dialog_info_text')}
                confirmationText={t('data_delete_dialog_confirmation_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={() => deleteClicked()}
                secondarybuttonCallback={closeDialog} />
            <Section
                title={t('pipeline_data_output_title')}
                tooltipText={t('tooltip_pipelines_outputs')}>
                <Breadcrumbs aria-label={t('a11y_breadcrumb')} >
                    {
                        breadCrumbs.map((breadCrumb: PipelineDataBreadCrumb) => (
                            <Link
                                component="span"
                                key={breadCrumb.link + '_' + breadCrumb.index}
                                underline='hover'
                                onClick={() => {
                                    setURLViaBreadCrumb(breadCrumb.link, breadCrumb.index);
                                }}>
                                <h3
                                    className="breadcrumb"
                                    data-testid="pipeline-output-breadcrumb"
                                    data-cy="pipeline-output-breadcrumb">
                                    {breadCrumb.name}
                                </h3>
                            </Link>
                        ))
                    }
                </Breadcrumbs>

                <div className="pipelineOutputTable">
                    <ServerSideTableGCS
                        url={url}
                        urlBuilder={urlBuilder}
                        totalKey="count"
                        dataKey="data.dataList"
                        mapper={dataMapper}
                        icons={icons}
                        itemDeletedFlag={itemDeletedFlag}
                        onTableCellClicked={handleOutputCellClicked}
                        level="pipelines_pagesize"
                    />
                </div>
            </Section>
        </div>
    );
};

interface PipelineDataPageProps {
    isInstanceProfileEnabled: boolean;
    featureManager: FeatureFlagManager;
    azureStorageFQDNSuffix: string;
}

export const PipelineDataPageFC: React.FC<PipelineDataPageProps> = ({
    isInstanceProfileEnabled,
    featureManager,
    azureStorageFQDNSuffix,
}) => {

    const classes = usePipelineDataPageStyles();
    const { projectName } = useParams<{ projectName: string }>();

    const [ pipelineOutputUrl, setPipelineOutputUrl ] = useState('');
    const [ pipelineName, setPipelineName ] = useState('');
    const [ runName, setRunName ] = useState('');
    const [ directoryName, setDirectoryName ] = useState('');

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(currentProject?.id as string);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    return (
        <div className={classes.root}>
            {currentProject ?
                <PipelineDetailsPage
                    loadPipelineDataUrl={setPipelineOutputUrl}
                    currentProject={currentProject}
                    loadPipelineName={setPipelineName}
                    loadRunName={setRunName}
                    permissions={permissions}
                    loadDirectoryName={setDirectoryName} />
                : null}
            {
                pipelineOutputUrl && pipelineName && runName && directoryName && projectName ?
                    <PipelineOutput
                        url={pipelineOutputUrl}
                        permissions={permissions}
                        currentProject={currentProject}
                        pipelineName={pipelineName}
                        runName={runName}
                        directoryName={directoryName}
                        isInstanceProfileEnabled={isInstanceProfileEnabled}
                        azureStorageFQDNSuffix={azureStorageFQDNSuffix} />
                    : null
            }

            <PipelineRunLogs
                currentProject={currentProject}
                isInstanceProfileEnabled={isInstanceProfileEnabled}
                featureManager={featureManager}
                azureStorageFQDNSuffix={azureStorageFQDNSuffix}
            />
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PipelineDataPage = connect((state: any) => ({
    isInstanceProfileEnabled: state.config.paths ? state.config.paths.isInstanceProfileEnabled : undefined,
    azureStorageFQDNSuffix: state.config.paths ? state.config.paths.azureStorageFQDNSuffix : AZURE_SAS_TOKEN_SUBS_STRING,
    featureManager: state.featureManagement.featureManager,
}))(PipelineDataPageFC);

export default PipelineDataPage;
