export default abstract class FeatureFlagManager {

    protected static instance: FeatureFlagManager | null = null;

    static getInstance(): FeatureFlagManager | null {
        return FeatureFlagManager.instance;
    }

    async waitUntilReady(): Promise<void> {
        return;
    }

    abstract isEnabled(flag: string): boolean;
}
