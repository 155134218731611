import React from "react";
import globalHook, { Store } from 'use-global-hook';
import { ProjectDto } from "@uipath/aifabric";
import {getProjectsRBAC} from "../client/projectManagerClient";
import logger from "../../utils/Logging";

export enum UpdateType {
    Create,
    Edit,
    Refresh,
    Delete,
}

export type ProjectState = {
    projects: ProjectDto[],
    currentProject: ProjectDto | undefined,
    currentProjectName: string | undefined,
    currentProjectId: string | undefined,
    status: "INITIAL" | "LOADING" | "SUCCESS" | "FAILED"
}

export type ProjectActions = {
    forceRefresh: () => Promise<void>;
    setCurrent: (projectName: string | undefined, project?: ProjectDto) => void;
    clearProject: () => void;
    updateProjectsList: (project: ProjectDto | undefined, updateType: UpdateType, projects?: ProjectDto[]) => void;
}

const forceRefresh = async (store: Store<ProjectState, ProjectActions>) => {
    store.setState({ ...store.state, status: "LOADING" });
    try {
        const projectsMap =  (await getProjectsRBAC()) || {others: [], owned: []};
        const projects = [...projectsMap.others.map(o => o.projectDto), ...projectsMap.owned.map(o => o.projectDto)]
        if (projects) {
            store.setState({ ...store.state, projects, status: "SUCCESS" })
        } else {
            store.setState({ ...store.state, status: "FAILED" })
        }
    } catch (error) {
        store.setState({ ...store.state, status: "FAILED" })
    }
};

const clearProject = (store: Store<ProjectState, ProjectActions>) => {
    store.setState({
        projects: store.state.projects,
        currentProject: undefined,
        currentProjectName: undefined,
        currentProjectId: undefined,
        status: "SUCCESS"
    });
};

const updateProjectsList = (store: Store<ProjectState, ProjectActions>, newProject: ProjectDto, updateType: UpdateType, refreshedlist?: ProjectDto[]) => {

    if (!newProject && !refreshedlist) {
        return;
    }

    /* Add new project to list */
    if (UpdateType.Create === updateType) {
        const projects: ProjectDto[] = [...store.state.projects];
        projects.push(newProject);
        store.setState({ ...store.state, projects: projects });
    }

    /* Update edited project to list */
    if (UpdateType.Edit === updateType) {
        const projects: ProjectDto[] = [...store.state.projects];
        const index = projects.findIndex(project => project.id == newProject.id);
        if (index !== -1) {
            projects[index] = newProject;
            store.setState({ ...store.state, projects: projects });
        }
    }

    /* Remove project from list */
    if (UpdateType.Delete === updateType) {
        let projects: ProjectDto[] = [...store.state.projects];
        projects = projects.filter(project => project.id !== newProject.id);
        store.setState({ ...store.state, projects: (projects && projects.length > 0) ? projects : [] });
    }

    /* Refresh project list */
    if (UpdateType.Refresh === updateType) {
        store.setState({ ...store.state, projects: refreshedlist ? refreshedlist : [] });
    }
}

const setCurrent = (store: Store<ProjectState, ProjectActions>, projectName: string | undefined, project?: ProjectDto) => {

    /* Handling projectName being null */
    if (projectName === undefined) {
        return;
    }

    /* Handle when project is edited */
    if (project && store.state.projects && store.state.projects.length > 0) {
        store.setState({ ...store.state, currentProject: project, currentProjectId: project.id, currentProjectName: project.name });
        return;
    }

    if (projectName.toLowerCase() !== store.state.currentProject?.name?.toLowerCase()) {
        if (store.state.projects && store.state.projects.length > 0) {
            const currProj = store.state.projects.find(proj => proj.name?.toLowerCase() === projectName.toLowerCase());
            if (currProj && (store.state.currentProjectId !== currProj.id || store.state.currentProjectName !== currProj.name)) {
                store.setState({ ...store.state, currentProject: currProj, currentProjectId: currProj.id, currentProjectName: currProj.name });
            } else {
                logger.error({
                  identifier: "Use projects hook",
                  message: "Failed to find project name"
                });
            }
        } else {
            if (store.state.status === "SUCCESS") {
                return;
            }

            if (store.state.status === "FAILED") {
                logger.error({
                  identifier: "Use projects hook",
                  message: "Failed to make the call to get projects"
                });
                return;
            }

            if (store.state.status !== "LOADING") {
                store.actions.forceRefresh().then(() => {
                    const currProj = store.state.projects.find(proj => proj.name?.toLowerCase() === projectName.toLowerCase());
                    if (currProj && (store.state.currentProjectId !== currProj.id || store.state.currentProjectName !== currProj.name)) {
                        store.setState({ ...store.state, currentProject: currProj, currentProjectId: currProj.id, currentProjectName: currProj.name });
                    } else {
                        // todo: track error
                        logger.error({
                          identifier: "Use projects hook",
                          message: "Failed to find project name"
                        });
                    }
                });
            }
        }
    }
};

const actions = {
    forceRefresh,
    setCurrent,
    clearProject,
    updateProjectsList,
};

const initialProjectState: ProjectState = {
    projects: [],
    currentProject: undefined,
    currentProjectName: undefined,
    currentProjectId: undefined,
    status: "INITIAL"
}

export const useProjects = globalHook<ProjectState, ProjectActions>(
    React,
    initialProjectState,
    actions
);
