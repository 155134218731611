export enum PipelineType {
    FullTraining  = "FULL_TRAINING",
    TrainOnly = "TRAIN_ONLY",
    EvaluateOnly = "EVALUATE_ONLY"
}


export enum ScheduleType {
    ONE_SHOT = "ONE_SHOT",
    TIME_TRIGGER = "TIME_TRIGGER",
    REPEAT_TRIGGER = "REPEAT_TRIGGER"
}

export enum RunDisplayStatusType {
    SCHEDULED = 'SCHEDULED',
    QUEUED = 'QUEUED',
    RUNNING = 'RUNNING',
    SUCCESSFUL = 'SUCCESSFUL',
    FAILED = 'FAILED',
    KILLED = 'KILLED',
    QUEUED_FOR_RESOURCES = 'QUEUED_FOR_RESOURCES'
}

export enum RunActionType {
    KILL = 'KILL',
    RESTART = 'RESTART',
    DEQUEUE = 'DEQUEUE'
}

export enum IdentityObjectType {
    DirectoryUser = "DirectoryUser",
    DirectoryGroup = "DirectoryGroup"
  }
