import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';

export const appStyle = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            background: theme.palette.background.paper,
        },
        appContent: {
            display: 'flex',
            flexDirection: 'row',
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '100%',

            '&.isPortalNavDisabled': { left: 0 },
        },
        leftRail: {
            height: '100vh',
            width: '100%',
        },

        leftRailV2: { height: '100vh' },

        slotSspacing: { paddingLeft: '0px' },

        content: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            position: 'relative',
            overflowX: 'auto',
        },
        slotEnd: { display: 'flex' },
        createProject: {
            padding: '0',
            margin: '0',
            fontFamily: Tokens.FontFamily.FontTitle,
            fontStyle: 'normal',
            fontWeight: Tokens.FontFamily.FontWeightDefault,
            alignItems: 'center',
            fontSize: Tokens.FontFamily.FontMSize,
            lineHeight: Tokens.FontFamily.FontMLineHeight,
            color: theme.palette.semantic.colorForegroundHigh,
        },

        licenseUsageButton: {
            marginLeft: '15px',
            marginRight: '15px',

            '& .MuiSvgIcon-root': { fontSize: Tokens.FontFamily.FontHeader3Size },
        },
        languageLabel: {
            fontWeight: 'bold',
            fontSize: Tokens.FontFamily.FontMSize,
        },
        swaggerLinkContainer: { marginTop: theme.spacing(0.5) },
        swaggerLink: {
            color: theme.palette.semantic.colorIconDefault,
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(0.5),
            '&:hover': { textDecoration: 'none' },
            '&:visited': { color: theme.palette.semantic.colorIconDefault },
        },
        swaggerLinkIcon: {
            color: theme.palette.semantic.colorForegroundHigh,
            marginRight: theme.spacing(1),
        },
    }),
);
