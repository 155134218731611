import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import React, {
    useCallback,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

const notificationDuration = {
    NOTIFICATION_DURATION: 3000,
    TRANSITION_DURATION: 500,
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    iconWrapper: {
        paddingRight: '5px',
        paddingTop: '2px',
    },
    infoIcon: { color: theme.palette.semantic.colorInfoForegroundInverse },
    successIcon: { color: theme.palette.semantic.colorSuccessForegroundInverse },
    errorIcon: { color: theme.palette.semantic.colorErrorForegroundInverse },
    warningIcon: { color: theme.palette.semantic.colorWarningForegroundInverse },
    closeButton: {
        color: theme.palette.semantic.colorIconInvertedDefault,
        '&:hover': { color: theme.palette.semantic.colorForeground },
    },
    snackbarContent: {
        '& .notistack-MuiContent': {
            color: `${theme.palette.semantic.colorForegroundInverse} !important`,
            backgroundColor: `${theme.palette.semantic.colorBackgroundInverse} !important`,
            maxWidth: '600px',
        },
    },
}));

const ToastNotificationProvider = (props: any) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const notistackRef = useRef<any>();

    const onClickDismiss = useCallback(
        (key: any) => () => {
            notistackRef.current.closeSnackbar(key);
        },
        [ notistackRef ],
    );

    const renderSnackbarAction = useCallback((key: any) => (
        <IconButton
            onClick={onClickDismiss(key)}
            aria-label={t('close_notification')}
            className={classes.closeButton}
        >
            <CloseIcon />
        </IconButton>
    ), [ classes.closeButton, onClickDismiss, t ]);

    return (
        <SnackbarProvider
            ref={notistackRef}
            maxSnack={5}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            classes={{ containerRoot: classes.snackbarContent }}
            autoHideDuration={notificationDuration.NOTIFICATION_DURATION}
            iconVariant={{
                default: (
                    <div className={classes.iconWrapper}>
                        <InfoIcon className={classes.infoIcon} />
                    </div>
                ),
                success: (
                    <div className={classes.iconWrapper}>
                        <CheckCircleIcon className={classes.successIcon} />
                    </div>
                ),
                error: (
                    <div className={classes.iconWrapper}>
                        <ErrorIcon className={classes.errorIcon} />
                    </div>
                ),
                warning: (
                    <div className={classes.iconWrapper}>
                        <WarningIcon className={classes.warningIcon} />
                    </div>
                ),
            }}
            action={renderSnackbarAction}
            transitionDuration={{ enter: notificationDuration.TRANSITION_DURATION }}
        >
            {props.children}
        </SnackbarProvider>
    );
};

export default ToastNotificationProvider;
