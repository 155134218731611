import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import HelpIcon from '@mui/icons-material/Help';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle as MuiDialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type {
    BaseResponseOfFolderDto,
    BaseResponseOfPageListingDtoOfLabellingFileDto,
    DatasetDto,
    FolderDto,
    MLPackageVersionDto,
    ProjectDto,
    TierDto,
} from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import type { CancelTokenSource } from 'axios';
import type { FormikProps } from 'formik';
import {
    Field,
    FieldArray,
    Form,
    Formik,
} from 'formik';
import { RadioGroup } from 'formik-mui';
import {
    debounce,
    isNil,
} from 'lodash';
import React, {
    useCallback,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import URLManager from '../../src/config/URLManager';
import { http } from '../../src/http';
import {
    getFolders,
    importFile,
    sendToLabellers,
} from '../api/client/appManagerClient';
import {
    copyBlob,
    uploadFilesP,
} from '../api/client/datasetManagerClient';
import { getMlVersionsByPackageId } from '../api/client/pkgManagerClient';
import { useFeedback } from '../api/global/useFeedback';
import type {
    ProjectActions,
    ProjectState,
} from '../api/global/useProjects';
import { useProjects } from '../api/global/useProjects';
import type FileToUpload from '../components/fileDropzone/FileToUpload';
import Label from '../components/Label';
import { WithVisibility } from '../components/WithVisibility';
import type { MlPackageTier } from '../constants/AiappConstants';
import {
    DEFAULT_REPLICA_COUNT,
    DEFAULT_RESOURCE_PER_REPLICA_CONFIG,
    DEFAULT_RESOURCE_PER_REPLICA_LIMIT_CONFIG,
    GPU_AIUNITS,
    MAX_SUPPORTED_REPLICA_COUNT,
    RESOURCE_PER_REPLICA_CONFIG,
} from '../constants/AiappConstants';
import { EXPORT_COMPLETED } from '../constants/TelemetryConstants';
import { SkillUpdateType } from '../enums/SkillUpdate';
import type {
    MLPackageVersionDetailsData,
    MLPackageVersionPublicUpdateData,
} from '../pages/mlPackageVersion/MLPackageVersionsPage';
import type { SkillUpdateData } from '../pages/mlSkill/details/MlSkillDetailsPackageVersions';
import { RoutePath } from '../route/routeMap';
import {
    computeMlSkillResourcePerReplica,
    extractErrorMessage,
} from '../utils/CommonUtils';
import logger from '../utils/Logging';
import TooltipContainer from './AccessibleTooltip/TooltipContainer';
import AttributeTextField from './AttributeTextField';
import ColorDropdownField from './ColorDropdownField';
import type { ParametersFileEnvironmentVariable } from './EnvironmentVariablesTable';
import { ParametersFileParamsKeyPossibleTypes } from './EnvironmentVariablesTable';
import { FormAutoCompleteServerSide } from './FormAutoComplete';
import FormikErrorLabels from './FormikErrorLabels';
import NameTextField from './NameTextField';

const sendToLabellersDialogStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },

    form: {
        '& .formTitle': {
            margin: '0px',
            fontFamily: Tokens.FontFamily.FontNormal,
            fontSize: Tokens.FontFamily.FontHeader4Size,
            lineHeight: Tokens.FontFamily.FontHeader4LineHeight,
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },

        '& .MuiFormHelperText-root.Mui-error': { color: theme.palette.semantic.colorErrorText },

        '& .makeStyles-tsErrorMessage': { color: theme.palette.semantic.colorErrorText },

        '& .tsErrorMessage': { color: theme.palette.semantic.colorErrorText },
    },

    buttonFooter: {
        flexGrow: 0,
        position: 'relative',
        marginBottom: 'auto',

        '& div': {
            height: '70px',
            lineHeight: '70px',
            float: 'right',
        },

    },
    group: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '24px',
    },
    buttons: { flexGrow: 0 },
}));

const runningLogsScreenStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        maxWidth: '846px',

        '& .data': {
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            paddingLeft: '10px',
            maxWidth: '757px',
            minWidth: '420px',
            height: 'auto',
            overflowY: 'auto',

            '& .error': { color: theme.palette.semantic.colorErrorText },
        },
    },
}));

const dialogTitleStyle = makeStyles((theme: Theme) => createStyles({
    dailogTitle: {
        padding: '36px 0  0 24px',
        width: '440px',
        maxHeight: '72px',
        overflow: 'hidden',

        '&.MuiTypography-h6': {
            fontFamily: Tokens.FontFamily.FontNormal,
            fontSize: Tokens.FontFamily.FontHeader4Size,
            lineHeight: Tokens.FontFamily.FontHeader4LineHeight,
            fontStyle: 'normal',
            fontWeight: 600,
            alignItems: 'center',
            maxWidth: '95%',
            color: theme.palette.semantic.colorForeground,
        },

        '& .closeButton': {
            position: 'absolute',
            right: theme.spacing(1),
            marginTop: '0px',
            top: theme.spacing(1),
            color: theme.palette.semantic.colorForeground,
        },
    },
}));

const dialogActionButtonStyle = makeStyles((theme: Theme) => createStyles({
    dialogActionButton: {
        '& .MuiButton-root': { minWidth: '88px' },

        '& .MuiButton-containedSecondary': {
            fontFamily: Tokens.FontFamily.FontNormal,
            color: theme.palette.primary.contrastText,
            fontSize: Tokens.FontFamily.FontMSize,
            lineHeight: Tokens.FontFamily.FontMLineHeight,
            fontStyle: 'normal',
            fontWeight: '600',
            alignItems: 'center',
            textalign: 'center',
        },
    },

    root: {
        display: 'flex',
        alignItems: 'center',
    },

    buttonProgress: {
        color: '#0000ff',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

    primaryButtonAndLoadingbar: {
        position: 'relative',

        '& .MuiCircularProgress-colorPrimary': { color: theme.palette.semantic.colorForegroundLink },
    },

}));

interface TitleProps {
    closeIconButton?: boolean;
    disabled?: boolean;
    // todo: Sahil to fix this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClose?: (event: any) => void;
}

const DialogTitle: React.FC<TitleProps> = ({
    children, onClose, closeIconButton, disabled, ...other
}) => {
    const classes = dialogTitleStyle();
    const { t } = useTranslation();
    return (
        <MuiDialogTitle
            className={classes.dailogTitle}
            {...other}>
            {children}
            {closeIconButton ?
                <IconButton
                    size="small"
                    disabled={disabled}
                    aria-label={t('a11y_dialog_close')}
                    className="closeButton"
                    data-testid="dialogCloseButton"
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton> : null}
        </MuiDialogTitle>
    );
};

interface InactivePeriodThreshold {
    name: string;
    value: number;
}

interface MlSkillUpdateDialogProps extends DialogPopupProps {
    data?: SkillUpdateData;
    canBePublic?: boolean;
    isAutoUpdateEnabled?: boolean;
    isMLSkillInfraSettingsEnabled?: boolean;
    inactivePeriodThresholds: InactivePeriodThreshold[];
    isAiUnitEnabled?: boolean;
}

interface MLPackageVersionDetailsDialogProps extends DialogPopupProps {
    data: MLPackageVersionDetailsData;
}

interface MLPackageVersionPublicUpdateDialogProps extends DialogPopupProps {
    data?: MLPackageVersionPublicUpdateData;
}

export enum DialogType {
    SkillUpdate,
    VersionDetailTooltip,
    Custom,
    MLPackageVersionPublicUpdate,
    MLPackageVersionDetailsPage,
}

export interface DialogPopupProps {
    title?: string;
    open: boolean;
    // todo: Sahil to fix this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClose: any;
    closeIconButton?: boolean;
    infoText?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
    warningText?: string;
    confirmationText?: string;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    type?: DialogType | DialogType.Custom;
    // todo: Sahil to fix this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    primarybuttonCallback?: any;
    disablePrimaryButton?: boolean;
    // todo: Sahil to fix this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    secondarybuttonCallback?: any;
    customMessageComponent?: React.ReactNode;
    className?: string;
}

export const DialogPopup: React.FC<DialogPopupProps> = (
    {
        title,
        open,
        handleClose,
        closeIconButton,
        primaryButtonText,
        secondaryButtonText,
        primarybuttonCallback,
        disablePrimaryButton,
        secondarybuttonCallback,
        type,
        children,
        className,
    }) => {

    const [ loadingBar, setLoadingBar ] = React.useState(false);
    const { t } = useTranslation();
    React.useEffect(() => {
        if (open === false && loadingBar === true) {
            setLoadingBar(false);
        }
    }, [ open ]);

    /* Add loading bar and disable others button untill response come from backend */
    const hanldlePrimaryButtonCallback = (): void => {
        setLoadingBar(true);
        primarybuttonCallback();
    };

    const actionButtonClasses = dialogActionButtonStyle();

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose(event, reason);
                }
            }}
            className={className}>
            <DialogTitle
                onClose={handleClose}
                closeIconButton={closeIconButton}
                disabled={loadingBar === true}>
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions className={actionButtonClasses.dialogActionButton}>
                {primaryButtonText ? (
                    <div className={actionButtonClasses.root}>
                        <div className={actionButtonClasses.primaryButtonAndLoadingbar}>
                            <Button
                                aria-label={t('a11y_submit')}
                                onClick={hanldlePrimaryButtonCallback}
                                color="secondary"
                                variant="contained"
                                disabled={loadingBar === true || disablePrimaryButton === true}
                                data-testid="dialog-btn-primary"
                            >
                                {primaryButtonText}
                            </Button>

                            {/* we don't need to show progress bar for version detail toolip where no backend opeartion is happening */}
                            {loadingBar && type !== DialogType.VersionDetailTooltip && (
                                <CircularProgress
                                    size={24}
                                    className={actionButtonClasses.buttonProgress} />
                            )}
                        </div>
                    </div>
                ) : null}

                {secondaryButtonText ? (
                    <Button
                        onClick={secondarybuttonCallback}
                        aria-label={t('a11y_cancel')}
                        variant={primaryButtonText ? 'outlined' : 'contained'}
                        disabled={loadingBar === true}
                        data-testid="dialog-btn-secondary">
                        {secondaryButtonText}
                    </Button>
                ) : null}
            </DialogActions>
        </Dialog>
    );
};

const DialogStyles = makeStyles((theme: Theme) => createStyles({
    dialogPopupContent: {
        fontWeight: 300,
        width: '456px',
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        color: theme.palette.semantic.colorForeground,

        '& .info': { margin: 0 },
        '& .warning': { margin: 0 },
        '& .confirm': { margin: 0 },

        '& .dialogText': {
            color: theme.palette.semantic.colorForeground,
            marginBlockStart: 0,
            marginBlockEnd: '10px',
            fontSize: Tokens.FontFamily.FontMSize,
            lineHeight: Tokens.FontFamily.FontMLineHeight,
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
    },

    SkillUpdate: {
        margin: Tokens.Spacing.SpacingS,
        fontWeight: 600,
        fontSize: Tokens.FontFamily.FontMSize,
        fontStyle: 'normal',
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        color: theme.palette.semantic.colorForeground,

        '& .icon': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10px',
            marginTop: '10px',
        },

        '& .detail': {
            width: '100%',
            marginTop: '15px',
            marginBottom: '10px',
            display: 'flex',
            flexDirection: 'row',

            '& .header': {
                width: '30%',
                lineHeight: Tokens.FontFamily.FontMLineHeight,
            },

            '& .publicSkillHeader': {
                width: '40%',
                lineHeight: Tokens.FontFamily.FontMLineHeight,
            },

            '& .value': {
                lineHeight: Tokens.FontFamily.FontLLineHeight,
                marginLeft: '20px',
                color: theme.palette.semantic.colorForeground,
            },

            '& p': {
                margin: 0,
                fontWeight: 300,
            },

            'replicaError': {
                '& p': {
                    color: '#a6040a',
                    fontWeight: 400,
                    fontSize: '0.875rem',
                },
            },
        },
    },

    MLPackageVersionDetailsDialog: {
        fontWeight: Tokens.FontFamily.FontWeightSemibold,
        fontSize: Tokens.FontFamily.FontMSize,
        fontStyle: 'normal',
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        color: theme.palette.semantic.colorForeground,

        '& .staticContent': {

            '& p': {
                display: 'flex',
                fontWeight: Tokens.FontFamily.FontWeightSemibold,
            },

            '& span': {
                margin: '0 10px',
                fontWeight: Tokens.FontFamily.FontWeightLight,
            },
        },

        '& .dynamicContent': {
            width: '100%',

            '& h4': {
                marginBottom: '0px',
                fontWeight: Tokens.FontFamily.FontWeightSemibold,
            },

            '& p': {
                marginTop: '0px',
                fontWeight: Tokens.FontFamily.FontWeightLight,
                fontStyle: 'normal',
                lineHeight: Tokens.FontFamily.FontMLineHeight,
                fontSize: Tokens.FontFamily.FontMSize,
            },
        },
    },

    MLPackageVersionPublicUpdate: {
        fontWeight: 600,
        fontSize: Tokens.FontFamily.FontMSize,
        fontStyle: 'normal',
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        color: theme.palette.semantic.colorForeground,

        '& .detail': {
            width: '100%',
            marginBottom: '10px',
            display: 'flex',
            flexDirection: 'row',

            '& .header': {
                width: '60%',
                lineHeight: Tokens.FontFamily.FontMLineHeight,
            },

            '& p': {
                margin: 0,
                fontWeight: 300,
            },
        },
    },

    TooltipPopup: {
        fontWeight: 600,
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        color: theme.palette.semantic.colorForeground,

        '& .description': {
            marginTop: '15px',

            '& p': {
                margin: 0,
                fontWeight: 300,
            },
        },

        '& .inputType': {
            marginLeft: '5px',
            fontWeight: 300,
        },

        '& .MuiSvgIcon-root': {
            fontSize: Tokens.FontFamily.FontMSize,
            padding: 0,
        },

        '& .icons-label-span': {
            width: '150px',
            display: 'inline-block',
        },

        '& .spaceBefore': { marginBottom: '15px' },

        '& .spaceAfter': { marginBottom: '20px' },
    },
}),
);

export const CustomDialog: React.FC<DialogPopupProps> = (
    {
        title,
        open,
        handleClose,
        closeIconButton,
        infoText,
        warningText,
        confirmationText,
        primaryButtonText,
        secondaryButtonText,
        primarybuttonCallback,
        secondarybuttonCallback,
        customMessageComponent,
        children,
    }) => {

    const classes = DialogStyles();

    return (
        <DialogPopup
            open={open}
            handleClose={handleClose}
            title={title}
            type={DialogType.Custom}
            closeIconButton={closeIconButton}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            primarybuttonCallback={primarybuttonCallback}
            secondarybuttonCallback={secondarybuttonCallback}
        >
            <div className={classes.dialogPopupContent}>
                {children}
                {infoText ? (
                    <div className="info">
                        <p className="dialogText">
                            {infoText}
                        </p>
                    </div>
                ) : null}
                {warningText ? (
                    <div className="warning">
                        <p className="dialogText">
                            {warningText}
                        </p>
                    </div>
                ) : null}
                {confirmationText ? (
                    <div className="confirm">
                        <p className="dialogText">
                            {confirmationText}
                        </p>
                    </div>
                ) : null}
                {customMessageComponent ?
                    (<div className="custom-message">
                        {customMessageComponent}
                    </div>) : null}
            </div>
        </DialogPopup >
    );
};

export const TooltipDialog: React.FC<DialogPopupProps> = ({
    open, data, handleClose, closeIconButton, primaryButtonText,
}) => {

    const classes = DialogStyles();
    const { t } = useTranslation();

    return (
        <DialogPopup
            open={open}
            handleClose={handleClose}
            type={DialogType.VersionDetailTooltip}
            title={t('dialog_ml_package_detail_title', {
                mlPackageName: data.mlPackageName,
                mlPackageVersion: data.version + '.' + data.trainingVersion,
            })}
            closeIconButton={closeIconButton}
            primaryButtonText={primaryButtonText}
            primarybuttonCallback={handleClose}
        >
            <div className={classes.TooltipPopup}>
                <div>
                    <span>
                        {t('dialog_content_text_input_type')}
                    </span>
                    <span className="inputType">
                        {data.inputType}
                    </span>
                </div>

                <div className="description">
                    <span>
                        {t('dialog_content_text_input_description')}
                    </span>
                    <p>
                        {data.inputDescription}
                    </p>
                </div>

                <div className="description spaceAfter">
                    <span>
                        {t('dialog_content_text_output_description')}
                    </span>
                    <p>
                        {data.outputDescription}
                    </p>
                </div>

                <div className="spaceBefore">
                    <span className='icons-label-span'>
                        {t('dialog_content_text_recommand_gpu')}
                    </span>
                    <span>
                        {data.gpu === 0 ?
                            <ClearSharpIcon /> : <DoneSharpIcon />}
                    </span>
                </div>

                <div className="spaceBefore">
                    <span className='icons-label-span'>
                        {t('dialog_content_text_enable_training')}
                    </span>
                    <span>
                        {data.retrainable ?
                            <DoneSharpIcon /> : <ClearSharpIcon />}
                    </span>
                </div>
            </div>
        </DialogPopup >
    );
};

export const MLPackageVersionPublicUpdateDialog: React.FC<MLPackageVersionPublicUpdateDialogProps> = (
    {
        open,
        data,
        title,
        handleClose,
        closeIconButton,
        infoText,
        primaryButtonText,
        secondaryButtonText,
        primarybuttonCallback,
        secondarybuttonCallback,
    }) => {

    const [ isPublicMLPackageVersionChecked, setIsPublicMLPackageVersionChecked ] = React.useState(false);
    const [ disablePrimaryButton, setDisablePrimaryButton ] = React.useState(false);
    const classes = DialogStyles();
    const { t } = useTranslation();

    /* Update is Public mlpackage version state as per last usage from backend */
    React.useEffect(() => {
        if (data?.isPublic === true && isPublicMLPackageVersionChecked === false && open === true) {
            setIsPublicMLPackageVersionChecked(true);
        }

        if (open === false) {
            setIsPublicMLPackageVersionChecked(false);
        }
    }, [ open ]);

    React.useEffect(() => {
        if (data?.isPublic === isPublicMLPackageVersionChecked) {
            setDisablePrimaryButton(true);
        } else {
            setDisablePrimaryButton(false);
        }
    }, [ isPublicMLPackageVersionChecked, open ]);

    /* Toggle Is Public */
    const handlePublicMLPackageVersionToggle = (): void => {
        setIsPublicMLPackageVersionChecked(prev => !prev);
    };

    /* Call update is public for mlpackage version provided handler */
    const updateIsPublicMLPackageVersion = (): void => {
        primarybuttonCallback(isPublicMLPackageVersionChecked === true,
            data?.isPublic === true ? isPublicMLPackageVersionChecked === true : false);
    };

    return (
        <DialogPopup
            open={open}
            type={DialogType.MLPackageVersionPublicUpdate}
            title={title}
            handleClose={handleClose}
            closeIconButton={closeIconButton}
            primaryButtonText={primaryButtonText}
            infoText={infoText}
            secondaryButtonText={secondaryButtonText}
            primarybuttonCallback={updateIsPublicMLPackageVersion}
            secondarybuttonCallback={secondarybuttonCallback}
            disablePrimaryButton={disablePrimaryButton}
        >
            <div className={classes.MLPackageVersionPublicUpdate}>
                <div className="detail">
                    <div className="info">
                        <p className="dialogText">
                            {infoText}
                        </p>
                    </div>
                </div>
                <div className="detail">
                    <div className="header">
                        <span>
                            {t('ml_package_version_is_public_dialog_label', {
                                mlPackageName: data?.mlPackageName,
                                mlPackageVersion: data?.mlPackageVersion,
                            })}
                        </span>
                    </div>
                </div>
                <div>
                    <div>
                        <Switch
                            checked={isPublicMLPackageVersionChecked}
                            onChange={handlePublicMLPackageVersionToggle}
                            name="isPublic"
                            color="secondary"
                            data-testid="makePublicVersion"
                            aria-label={t('a11y_public_mlpackage_version_toggle_switch')}
                        />
                    </div>
                </div>
            </div>
        </DialogPopup >
    );
};

export const MLPackageVersionDetailsDialog: React.FC<MLPackageVersionDetailsDialogProps> = (
    {
        open,
        data,
        handleClose,
        closeIconButton,
        secondarybuttonCallback,
        secondaryButtonText,
    },
) => {
    const classes = DialogStyles();
    const { t } = useTranslation();

    return (
        <DialogPopup
            open={open}
            title={t('ml_package_version_details_dialog_label', {
                mlPackageName: data?.mlPackage,
                mlPackageVersion: ((data?.customVersion ? data?.customVersion : data?.version?.toString()) + '.' + data?.trainingVersion?.toString()),
            })}
            data={data}
            handleClose={handleClose}
            closeIconButton={closeIconButton}
            secondarybuttonCallback={secondarybuttonCallback}
            secondaryButtonText={secondaryButtonText}
        >

            <div className={classes.MLPackageVersionDetailsDialog}>
                <div className="dynamicContent">
                    <div>
                        <h4>
                            {t('mlpkg_create_inputDesc_label')}
                            :
                        </h4>
                        <p>
                            {data.inputDescription}
                        </p>
                    </div>

                    <div>
                        <h4>
                            {t('mlpkg_create_outputDesc_label')}
                            :
                        </h4>
                        <p>
                            {data.outputDescription}
                        </p>
                    </div>

                    <div>
                        <h4>
                            {t('mlpkg_create_changeLog_label')}
                            :
                        </h4>
                        <p>
                            {data.changeLog}
                        </p>
                    </div>
                </div>

                <div className="staticContent">
                    <div>
                        <p>
                            {t('mlpkg_create_inputType_label')}
                            :
                            {' '}
                            <span>
                                {data.inputType}
                            </span>
                        </p>
                    </div>

                    <div>
                        <p>
                            {t('mlpackage_create_enableGpu_label')}
                            :
                            {' '}
                            <span>
                                {data.recommendGpu ? <DoneSharpIcon /> : <ClearSharpIcon />}
                            </span>
                        </p>
                    </div>

                    <div>
                        <p>
                            {t('mlpackage_training_enabled_label')}
                            :
                            {' '}
                            <span>
                                {data.enableTraining ? <DoneSharpIcon /> : <ClearSharpIcon />}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </DialogPopup>
    );
};

interface DataLabellingImportProgressDialogProps extends DialogPopupProps {
    project: ProjectDto;
    dataset: DatasetDto;
    appId: string;
    azureStorageFQDNSuffix: string;
}

interface DataLabellingExportProgressDialogProps extends DialogPopupProps {
    projectId: string;
    datasetId: string;
    appId: string;
    folderName: string;
    itemsSelected: string[];
}

interface TextProps {
    content: string;
    startsWithNewLine: boolean;
    isError: boolean;
    addSpacesToPrefix: boolean;
}

const RunningLogsScreen: React.FC<any> = (
    { data }) => {

    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = (): void => {
        if (messagesEndRef?.current?.scrollIntoView !== undefined) {
            messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const classes = runningLogsScreenStyle();

    React.useEffect(() => {
        scrollToBottom();
    }, [ data ]);

    return (
        <div className={classes.root}>
            <div className="data">
                {
                    data?.map((element: any) => {
                        if (element.startsWithNewLine) {
                            if (element.isError) {
                                return element.addSpacesToPrefix ? <p className="data error">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {element.content}
                                </p> : <p className="data error">
                                    {element.content}
                                </p>;
                            }
                            return element.addSpacesToPrefix ? <p className="data">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {element.content}
                            </p> : <p className="data">
                                {element.content}
                            </p>;

                        }
                        if (element.isError) {
                            return element.addSpacesToPrefix ? <span className="error">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {element.content}
                            </span> : <span className="error">
                                {element.content}
                            </span>;
                        }
                        return element.addSpacesToPrefix ? <span className="data">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {element.content}
                        </span> : <span className="data">
                            {element.content}
                        </span>;

                    })
                }
            </div>
            <div ref={messagesEndRef} />
        </div>
    );
};

export const DataLabellingImportProgressDialog: React.FC<DataLabellingImportProgressDialogProps> = (
    {
        open,
        handleClose,
        data,
        project,
        dataset,
        appId,
        primaryButtonText,
        primarybuttonCallback,
        azureStorageFQDNSuffix,
    }) => {

    const { t } = useTranslation();
    const [ disablePrimaryButton, setDisablePrimaryButton ] = React.useState(true);
    const [ fileProcessingLogs, setFileProcessingLogs ] = React.useState([] as TextProps[]);

    const authToken = useSelector((state: any) => state.auth.authToken);

    React.useEffect(() => {

        let appendedLogs = [] as TextProps[];
        const sendToUploadProgressScreen = (content: string, startsWithNewLine: boolean, isError: boolean, addSpacesToPrefix: boolean) => {
            appendedLogs = [ ...appendedLogs, {
                content,
                startsWithNewLine,
                isError,
                addSpacesToPrefix,
            } as TextProps ];
            setFileProcessingLogs(appendedLogs);
        };

        const uploadToImportFolder = async (blobName: string, pathInDataset: string, file: FileToUpload) => {
            blobName = blobName + '/' + pathInDataset;

            try {
                await uploadFilesP({ blobName }, [ file ], authToken, azureStorageFQDNSuffix);

                await importFile({
                    fileName: file.path,
                    accessUri: getAccessUri(blobName, 'importFiles', file.path),
                },
                appId).then(() => {
                    sendToUploadProgressScreen(t('data_labelling_upload_successful'), true, false, true);
                    return true;
                })
                    .catch((error: any) => {
                        sendToUploadProgressScreen(t('data_labelling_upload_failed', {
                            fileName: file.path,
                            error: error?.response?.data?.respMsg || t('Server_Unavailable'),
                        }), true, true, true);
                    });

            } catch (err) {
                sendToUploadProgressScreen(t('data_labelling_upload_failed', {
                    fileName: file.path,
                    error: err,
                }), true, true, true);
            }
        };

        const getAccessUri = (datasetLocation: string, pathInDataset: string, fileName: string) => datasetLocation + '/' + pathInDataset + '/' + fileName;

        const startProcessing = async (shouldImport: boolean) => {
            if (shouldImport) {
                const blobName = project?.id + '/' + dataset?.id;
                let seqNumber = 1;
                // loop over the files.

                for (const file of data?.uploadDropzone) {

                    // upload the file to storage.
                    sendToUploadProgressScreen(t('data_labelling_processing_file', { seqNumber }), true, false, false);
                    sendToUploadProgressScreen(t('data_labelling_file_name', { fileName: file.path }), true, false, true);

                    await uploadToImportFolder(blobName, 'importFiles', file);

                    seqNumber = seqNumber + 1;
                }

                setDisablePrimaryButton(false);
            }
        };

        startProcessing(open);

    }, [ data?.uploadDropzone, open ]);

    return (
        <DialogPopup
            open={open}
            handleClose={handleClose}
            primaryButtonText={primaryButtonText}
            primarybuttonCallback={primarybuttonCallback}
            disablePrimaryButton={disablePrimaryButton}
            title={t('data_labelling_uploading_and_validating_files')}
        >
            <div>
                <RunningLogsScreen data={fileProcessingLogs} />
            </div>
        </DialogPopup>
    );
};

export const DataLabellingExportProgressDialog: React.FC<DataLabellingExportProgressDialogProps> = (
    {
        open,
        handleClose,
        projectId,
        datasetId,
        appId,
        folderName,
        primaryButtonText,
        primarybuttonCallback,
        itemsSelected,
    }) => {

    const { t } = useTranslation();
    const [ fileProcessingLogs, setFileProcessingLogs ] = React.useState([] as TextProps[]);
    const [ disablePrimaryButton, setDisablePrimaryButton ] = React.useState(true);

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    React.useEffect(() => {

        let appendedLogs = [] as TextProps[];
        const sendToUploadProgressScreen = (content: string, startsWithNewLine: boolean, isError: boolean, addSpacesToPrefix: boolean) => {
            appendedLogs = [ ...appendedLogs, {
                content,
                startsWithNewLine,
                isError,
                addSpacesToPrefix,
            } as TextProps ];
            setFileProcessingLogs(appendedLogs);
        };

        const copyToExportFolder = async (labellingFileDtos: any, folder: string, seqNumber: number) => {
            const sourceFilePath = projectId + '/' + datasetId + '/labelledFiles/';
            const destinationFilePath = projectId + '/' + datasetId + '/datalabelling_exportedFiles/' + folder + '/';

            for (const fileDto of labellingFileDtos) {
                const fileName = getFileName(fileDto.fileNameV2);
                try {
                    sendToUploadProgressScreen(t('data_labelling_processing_file', { seqNumber }), true, false, false);
                    sendToUploadProgressScreen(t('data_labelling_file_name', { fileName: fileDto.fileNameV2 }), true, false, true);
                    await copyBlob(datasetId, {
                        sourceBlobName: sourceFilePath + fileName,
                        destinationBlobName: destinationFilePath + fileName,
                    });
                    sendToUploadProgressScreen(t('data_labelling_upload_successful'), true, false, true);
                } catch (err) {
                    sendToUploadProgressScreen(t('data_labelling_upload_failed', {
                        fileName: fileDto.fileNameV2,
                        error: err,
                    }), true, true, true);
                }
                seqNumber = seqNumber + 1;
            }
        };

        const exportFilesToFolder = async () => {
            const currentTime = Date.now();

            if (folderName.length === 0) {
                folderName = String(Math.round((new Date()).getTime() / 1000));
            }
            // Export all labelled files.
            let allFilesDone = false;
            const pageSize = 100;
            let counter = 1;
            while (!allFilesDone) {
                const res = await http.get<BaseResponseOfPageListingDtoOfLabellingFileDto>(URLManager.url().apiAppManager + '/app/' + appId + '/files?labellingStatuses=LABEL_SUCCESS&sortBy=createdOn&sortOrder=DESC&pageNum=' + counter + '&pageSize=' + pageSize + '&projectId=' + projectId);

                let dataList: any = !isNil(res?.data?.data?.dataList) ? res?.data?.data?.dataList : [];
                if (itemsSelected.length > 0 && dataList.length > 0) {
                    dataList = dataList?.filter((item: any) => itemsSelected.includes(item.id));
                }

                // if no data received, break the loop.
                if (dataList?.length === 0) {
                    allFilesDone = true;
                } else {
                    // loop
                    await copyToExportFolder(dataList, folderName, ((counter - 1) * pageSize + 1));
                    counter = counter + 1;
                }
            }

            /* Add telemtery event for clicking the Download Dataset button */
            logger.customEvents({
                identifier: EXPORT_COMPLETED,
                message: 'Export completed',
                payload: {
                    appId,
                    accountId: currentProject?.accountId,
                    tenantId: currentProject?.tenantId,
                    projectId,
                    datasetId,
                    totalFiles: counter - 1,
                    timeTaken: Date.now() - currentTime,
                },
            }, EXPORT_COMPLETED);
            setDisablePrimaryButton(false);
        };

        const startProcessing = async (shouldExport: boolean) => {
            if (shouldExport) {
                exportFilesToFolder();
            }
        };

        startProcessing(open);

    }, [ open ]);

    const getFileName = (fileName: string): string => fileName.endsWith('.json') ? fileName : fileName + '.json';

    return (
        <DialogPopup
            open={open}
            title={t('export_dataset_dialog_title')}
            handleClose={handleClose}
            primaryButtonText={primaryButtonText}
            primarybuttonCallback={primarybuttonCallback}
            disablePrimaryButton={disablePrimaryButton}
        >
            <div>
                <RunningLogsScreen data={fileProcessingLogs} />
            </div>
        </DialogPopup>
    );
};

export const MlSkillUpdateDialog: React.FC<MlSkillUpdateDialogProps> = (
    {
        open,
        data,
        title,
        handleClose,
        closeIconButton,
        primaryButtonText,
        secondaryButtonText,
        primarybuttonCallback,
        secondarybuttonCallback,
        canBePublic,
        isAutoUpdateEnabled,
        isMLSkillInfraSettingsEnabled,
        inactivePeriodThresholds,
        isAiUnitEnabled,
    }) => {

    const [ gpuChecked, setGpuChecked ] = React.useState(false);
    const [ publicSkillChecked, setpublicSkillChecked ] = React.useState(false);
    const [ autoUpdateChecked, setAutoUpdateChecked ] = React.useState(false);
    const [ disablePrimaryButton, setDisablePrimaryButton ] = React.useState(false);
    const classes = DialogStyles();
    const { t } = useTranslation();
    const [ inactivityPeriodThresholdSelected, setInactivityPeriodThresholdSelected ] = useState(data?.inactivityPeriodInDays);
    const [ mlSkillInfraSettingsChecked, setMLSkillInfraSettingsChecked ] = React.useState(false);
    const [ replicas, setReplicas ] = React.useState(data?.replicas ?? DEFAULT_REPLICA_COUNT);
    const [ resourcePerReplica, setResourcePerReplica ] = React.useState(computeMlSkillResourcePerReplica(data?.requestMemory));
    const [ requestMemory, setRequestMemory ] = React.useState(data?.requestMemory ?? DEFAULT_RESOURCE_PER_REPLICA_CONFIG.memory!);
    const [ limitMemory, setLimitMemory ] = React.useState(data?.limitMemory ?? DEFAULT_RESOURCE_PER_REPLICA_LIMIT_CONFIG.memory!);
    const [ requestCPU, setRequestCPU ] = React.useState(data?.requestCPU ?? DEFAULT_RESOURCE_PER_REPLICA_CONFIG.cpu!);
    const [ limitCPU, setLimitCPU ] = React.useState(data?.limitCPU ?? DEFAULT_RESOURCE_PER_REPLICA_LIMIT_CONFIG.cpu!);
    const [ replicaError, setReplicaError ] = React.useState('');
    const [ requestMemoryError, setRequestMemoryError ] = React.useState('');
    const [ limitMemoryError, setLimitMemoryError ] = React.useState('');
    const [ requestCPUError, setRequestCPUError ] = React.useState('');
    const [ limitCPUError, setLimitCPUError ] = React.useState('');
    const [ aiUnitsConsumption, setAiUnitsConsumption ] = React.useState(0);
    const [ gpuWarnPopupOpen, setGpuWarnPopupOpen ] = React.useState(false);
    const [ mlPackageTiersValue, setMlPackageTiersValue ] = React.useState<MlPackageTier[]>([]);
    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>((state: ProjectState) => state.currentProject, (actions: ProjectActions) => actions.setCurrent);

    /* Update GPU and public skill state as per last usage from backend */
    React.useEffect(() => {
        if (!open) {
            setGpuChecked(false);
            setpublicSkillChecked(false);
            setAutoUpdateChecked(false);
            setMLSkillInfraSettingsChecked(false);
            return;
        }

        // Always display DB Values when opening dialog
        setInactivityPeriodThresholdSelected(data?.inactivityPeriodInDays);
        setRequestMemory(data?.requestMemory ?? DEFAULT_RESOURCE_PER_REPLICA_CONFIG.memory!);
        setLimitMemory(data?.limitMemory ?? DEFAULT_RESOURCE_PER_REPLICA_LIMIT_CONFIG.memory!);
        setRequestCPU(data?.requestCPU ?? DEFAULT_RESOURCE_PER_REPLICA_CONFIG.cpu!);
        setLimitCPU(data?.limitCPU ?? DEFAULT_RESOURCE_PER_REPLICA_LIMIT_CONFIG.cpu!);
        setResourcePerReplica(computeMlSkillResourcePerReplica(data?.requestMemory));
        setReplicas(data?.replicas ?? DEFAULT_REPLICA_COUNT);
        setGpuChecked(!!data?.gpuEnabled && !gpuChecked);
        setpublicSkillChecked(!!data?.isPublicSkill && !publicSkillChecked);
        setAutoUpdateChecked(!!data?.autoUpdate && !autoUpdateChecked);
        setMLSkillInfraSettingsChecked(!(data?.replicas == DEFAULT_REPLICA_COUNT && data?.requestMemory == 2 && data?.limitMemory == 8 && data?.requestCPU == 0.5 && data?.limitCPU == 2));
        calculateAiUnitsConsumption(data?.gpuEnabled, replicas, resourcePerReplica);
        getMlVersionsByPackageId(data?.mlPackageId, currentProject?.id)
            .then((versions: MLPackageVersionDto[] | undefined) => {
                if (versions?.length && versions[0].tiers !== undefined) {
                    const tiersValues = versions[0].tiers.map((pkgTier: TierDto) => ({
                        resource: pkgTier.resourcesPerReplica! + ' | ' + pkgTier.maxDocumentPages + ' | ' + pkgTier.throughput,
                        unitvalue: pkgTier.aiUnitsPerHour,
                    }));
                    setMlPackageTiersValue((prevValues) => [ ...prevValues, ...tiersValues.filter(tiersValue =>
                        !prevValues.some(prevValue => tiersValue.resource === prevValue.resource && tiersValue.unitvalue === prevValue.unitvalue)) ]);
                }

            });
    }, [ open ]);

    /* Disable ok button in case requirement is not modified from last usage */
    React.useEffect(() => {
        if (data?.updateType === SkillUpdateType.Modifying &&
            (
                data?.gpuEnabled === gpuChecked &&
                data?.isPublicSkill === publicSkillChecked &&
                data?.autoUpdate === autoUpdateChecked &&
                data.inactivityPeriodInDays === inactivityPeriodThresholdSelected &&
                data?.replicas === replicas &&
                data?.requestMemory === requestMemory &&
                data?.requestCPU === requestCPU
            )
        ) {
            setDisablePrimaryButton(true);
        } else {
            setDisablePrimaryButton(false);
        }
    }, [ gpuChecked, publicSkillChecked, autoUpdateChecked, inactivityPeriodThresholdSelected, replicas, requestMemory, limitMemory, requestCPU, limitCPU ]);

    React.useEffect(() => {
        calculateAiUnitsConsumption(gpuChecked, replicas, resourcePerReplica);
    }, [ gpuChecked, replicas, resourcePerReplica ]);

    React.useEffect(() => {
        // Reset Request CPU, Request Memory and ResourcePerReplica when activating GPU
        setRequestCPU(data?.requestCPU ?? DEFAULT_RESOURCE_PER_REPLICA_CONFIG.cpu!);
        setRequestMemory(data?.requestMemory ?? DEFAULT_RESOURCE_PER_REPLICA_CONFIG.memory!);
        setResourcePerReplica(computeMlSkillResourcePerReplica(data?.requestMemory));
    }, [ gpuChecked === true ]);

    const calculateAiUnitsConsumption = (isGpuChecked: boolean | undefined, replicaCount: number, resourcePerReplicaCount: number): void => {
        const aiUnits = isGpuChecked ? GPU_AIUNITS * replicaCount : resourcePerReplicaCount * replicaCount;
        setAiUnitsConsumption(aiUnits);
    };

    /* Toggle GPU */
    const handleGpuToggle = (event: any): void => {
        setGpuChecked(event.target.checked);
        setGpuWarnPopupOpen(event.target.checked);
    };

    /* Toggle Public Ml Skill */
    const handlePublicSkillToggle = (): void => {
        setpublicSkillChecked(prev => !prev);
    };

    const handleAutoUpdateToggle = (): void => {
        setAutoUpdateChecked(prev => !prev);
    };

    const handleMLSkillInfraSettingsToggle = (): void => {
        setMLSkillInfraSettingsChecked(prev => !prev);
    };

    const inactivityPeriodThresholdSelectedChange = useCallback((event) => {
        setInactivityPeriodThresholdSelected(event.target?.value);
    }, []);

    const resourcePerReplicaSelectedChange = useCallback((event) => {
        const unitValue: number = +event.target.value;

        const resourceConfigBasedOnValue = Object.values(RESOURCE_PER_REPLICA_CONFIG).find(r => r.unitvalue === unitValue);
        if (resourceConfigBasedOnValue) {
            setResourcePerReplica(unitValue);
            setRequestMemory(resourceConfigBasedOnValue.memory!);
            setRequestCPU(resourceConfigBasedOnValue.cpu!);

            // Set the default limits for the first 3 tiers
            if (unitValue <= DEFAULT_RESOURCE_PER_REPLICA_LIMIT_CONFIG.unitvalue) {
                setLimitMemory(DEFAULT_RESOURCE_PER_REPLICA_LIMIT_CONFIG.memory!);
                setLimitCPU(DEFAULT_RESOURCE_PER_REPLICA_LIMIT_CONFIG.cpu!);
            } else { // Set limits equal to requested resources for last 2 tiers
                setLimitMemory(resourceConfigBasedOnValue.memory!);
                setLimitCPU(resourceConfigBasedOnValue.cpu!);
            }
        }
    }, []);

    const handleReplicasChange = ((event: any) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value <= 0) {
            setReplicaError(t('ml_skill_infra_settings_value_greater_than_one_message'));
        } else if (value > MAX_SUPPORTED_REPLICA_COUNT) {
            setReplicaError(t('ml_skill_infra_settings_replica_should_be_less'));
        } else {
            setReplicaError('');
        }
        setReplicas(value);
    });

    const handleRequestMemoryChange = (event: any) => {
        const value: number = +event.target.value;
        if (value <= 0) {
            setRequestMemoryError(t('ml_skill_infra_settings_value_greater_than_one_message'));
        } else if (value > limitMemory) {
            setRequestMemoryError(t('ml_skill_infra_settings_request_memory_less_than_limit_memory_message'));
        } else {
            setRequestMemoryError('');
        }
        setRequestMemory(value);
    };

    const handleLimitMemoryChange = (event: any) => {
        const value: number = +event.target.value;
        if (value <= 0) {
            setLimitMemoryError(t('ml_skill_infra_settings_value_greater_than_one_message'));
        } else if (value < requestMemory) {
            setLimitMemoryError(t('ml_skill_infra_settings_limit_memory_greater_than_request_memory_message'));
        } else {
            setLimitMemoryError('');
        }
        setLimitMemory(value);
    };

    const handleRequestCPUChange = (event: any) => {
        const value: number = +event.target.value;
        if (value < 0.5) {
            setRequestCPUError(t('ml_skill_infra_settings_value_greater_than_half_message'));
        } else if (value > limitCPU) {
            setRequestCPUError(t('ml_skill_infra_settings_request_cpu_less_than_limit_cpu_message'));
        } else {
            setRequestCPUError('');
        }
        setRequestCPU(value);
    };

    const handleLimitCPUChange = (event: any) => {
        const value: number = +event.target.value;
        if (value < 0.5) {
            setLimitCPUError(t('ml_skill_infra_settings_value_greater_than_half_message'));
        } else if (value < requestCPU) {
            setLimitCPUError(t('ml_skill_infra_settings_limit_cpu_greater_than_request_cpu_message'));
        } else {
            setLimitCPUError('');
        }
        setLimitCPU(value);
    };

    /* Call update skill provided handler */
    const updateMLSkill = (): void => {
        primarybuttonCallback(
            gpuChecked,
            canBePublic ? publicSkillChecked : false,
            autoUpdateChecked,
            inactivityPeriodThresholdSelected,
            {
                replicas,
                requestMemory,
                requestCPU,
                limitMemory,
                limitCPU,
            },
            'UPDATE',
        );
    };

    const getCurrentPackageVersion = (): string | undefined => data?.currentCustomVersion != null ? data?.currentCustomVersion : data?.currentVersion;

    const getNextPackageVersion = (): string | undefined => data?.nextCustomVersion != null ? data?.nextCustomVersion : data?.nextVersion;

    const cancelDialog = (): void => {
        setGpuWarnPopupOpen(false);
        setGpuChecked(false);
    };

    const confirmDialog = (): void => {
        setGpuWarnPopupOpen(false);
    };

    return (
        <>
            <CustomDialog
                title={t('ml_skill_enable_gpu_warning_title')}
                open={gpuWarnPopupOpen}
                handleClose={cancelDialog}
                closeIconButton
                infoText={t('ml_skill_enable_gpu_warning_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={confirmDialog}
                secondarybuttonCallback={cancelDialog}
            />
            <DialogPopup
                open={open}
                type={DialogType.SkillUpdate}
                title={title}
                handleClose={handleClose}
                closeIconButton={closeIconButton}
                primaryButtonText={primaryButtonText}
                secondaryButtonText={secondaryButtonText}
                primarybuttonCallback={updateMLSkill}
                secondarybuttonCallback={secondarybuttonCallback}
                disablePrimaryButton={disablePrimaryButton}
            >
                <div className={classes.SkillUpdate}>
                    <div className="detail">
                        <div className="header">
                            <span>
                                {t('dialog_current_ml_package_label')}
                            </span>
                        </div>

                        <div className="value">
                            <p>
                                {data?.packageName + ' ' + getCurrentPackageVersion()}
                            </p>
                        </div>
                    </div>

                    {/* Next version will be empty when we are modifing current deployment */}
                    {data?.updateType !== SkillUpdateType.Modifying ?
                        (
                            <div>
                                <div className="icon">
                                    <SwapVertIcon />
                                </div>

                                <div className="detail">
                                    <div className="header">
                                        <span>
                                            {t('dialog_new_label')}
                                        </span>
                                    </div>

                                    <div className="value">
                                        <p>
                                            {data?.packageName + ' ' + getNextPackageVersion()}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                    {canBePublic === true ?
                        <div>
                            <div className="detail">
                                <div className="publicSkillHeader">
                                    <div>
                                        {t('common_make_ml_skill_public')}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <Switch
                                    checked={publicSkillChecked}
                                    onChange={handlePublicSkillToggle}
                                    name="publicSkill"
                                    color="secondary"
                                    data-testid="makePublicSkillGpu"
                                    aria-label={t('a11y_public_skill_toggle_switch')}
                                />
                            </div>
                        </div> : null}

                    {isAutoUpdateEnabled === true ?
                        <div>
                            <div className="detail">
                                <div className="autoUpdate">
                                    <div>
                                        {t('dialog_enable_autoUpdate_label')}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <Switch
                                    checked={autoUpdateChecked}
                                    onChange={handleAutoUpdateToggle}
                                    name="autoUpdate"
                                    color="secondary"
                                    data-testid="skillAutoUpdateDialog"
                                    aria-label={t('ally_autoUpdate_toggle_switch')}
                                />
                            </div>
                        </div> : null}
                    {
                        inactivePeriodThresholds.length ? (<FormControl>
                            <InputLabel id="demo-simple-select-label">
                                {t('ml_Skill_choose_mlSkillInactivityPeriod')}
                            </InputLabel>
                            <Select
                                labelId="inactivity-threshold-select-label"
                                id="inactivity-threshold-select"
                                value={inactivityPeriodThresholdSelected}
                                onChange={inactivityPeriodThresholdSelectedChange}
                                data-testid="inactivePeriodThresholds"
                            >
                                {
                                    inactivePeriodThresholds.map((periodThreshold) => (
                                        <MenuItem value={periodThreshold.value}>
                                            {periodThreshold.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>) : null
                    }
                    <WithVisibility visible={isMLSkillInfraSettingsEnabled === true}>
                        <div>
                            <div>
                                <div className="detail">
                                    <div className="header">
                                        <div style={{ width: '150px' }}>
                                            {t('dialog_enable_mlskill_infra_settings')}
                                        </div>
                                    </div>
                                </div>

                                <Switch
                                    checked={mlSkillInfraSettingsChecked}
                                    onChange={handleMLSkillInfraSettingsToggle}
                                    name="mlSkillInfraSettings"
                                    color="secondary"
                                    aria-label={t('ally_mlskill_infra_settings_toggle_switch')}
                                    data-testid="skillInfraSettings"
                                />
                            </div>

                            <WithVisibility visible={mlSkillInfraSettingsChecked === true}>
                                <div className="detail">
                                    <div className="header">
                                        <span>
                                            {t('dialog_enable_gpu_label')}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <Switch
                                        checked={gpuChecked}
                                        onChange={handleGpuToggle}
                                        name="requireGPU"
                                        color="secondary"
                                        data-testid="skillUpdateGpu"
                                        aria-label={t('a11y_gpu_toggle_switch')}
                                    />
                                </div>
                                {isAiUnitEnabled !== true ?
                                    <div>
                                        <Label value={t('ml_skill_infra_settings_replicas_label')} />
                                        <Input
                                            style={{ width: '5em' }}
                                            name="replicas"
                                            type="number"
                                            inputProps={{
                                                step: '1',
                                                min: '1',
                                            }}
                                            error={replicaError != ''}
                                            color="secondary"
                                            aria-hidden
                                            value={replicas}
                                            onChange={handleReplicasChange}
                                            data-testid="replicas"
                                            aria-label={t('ally_enter_replicas')}
                                        />
                                        <WithVisibility visible={replicaError != ''}>
                                            <p style={{
                                                color: '#a6040a',
                                                fontWeight: 400,
                                                fontSize: '0.875rem',
                                            }}>
                                                {replicaError}
                                            </p>
                                        </WithVisibility>
                                        <Label value={t('ml_skill_infra_settings_resources_label')} />
                                        <div className="resources">
                                            <Table className="table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            {t('ml_skill_infra_settings_request_label')}
                                                        </TableCell>
                                                        <TableCell />
                                                        <TableCell>
                                                            {t('ml_skill_infra_settings_limit_label')}
                                                        </TableCell>
                                                        <TableCell />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            {t('ml_skill_infra_settings_ram_gb_label')}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Input
                                                                style={{ width: '5em' }}
                                                                name="requestMemory"
                                                                type="number"
                                                                inputProps={{
                                                                    step: '1',
                                                                    min: '1',
                                                                }}
                                                                error={requestMemoryError != ''}
                                                                color="secondary"
                                                                aria-hidden
                                                                value={requestMemory}
                                                                onChange={handleRequestMemoryChange}
                                                                data-testid="requestMemory"
                                                                aria-label={t('ally_enter_request_memory')}
                                                            />
                                                            <WithVisibility visible={requestMemoryError != ''}>
                                                                <p style={{ color: '#a6040a' }}>
                                                                    {requestMemoryError}
                                                                </p>
                                                            </WithVisibility>
                                                        </TableCell>
                                                        <TableCell>
                                                            {t('ml_skill_infra_settings_ram_gb_label')}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Input
                                                                style={{ width: '5em' }}
                                                                name="limitMemory"
                                                                type="number"
                                                                inputProps={{
                                                                    step: '1',
                                                                    min: '1',
                                                                }}
                                                                error={limitMemoryError != ''}
                                                                color="secondary"
                                                                aria-hidden
                                                                value={limitMemory}
                                                                onChange={handleLimitMemoryChange}
                                                                data-testid="limitMemory"
                                                                aria-label={t('ally_enter_limit_memory')}
                                                            />
                                                            <WithVisibility visible={limitMemoryError != ''}>
                                                                <p style={{ color: '#a6040a' }}>
                                                                    {limitMemoryError}
                                                                </p>
                                                            </WithVisibility>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            {t('ml_skill_infra_settings_cpu_label')}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Input
                                                                style={{ width: '5em' }}
                                                                name="requestCPU"
                                                                type="number"
                                                                inputProps={{
                                                                    step: '0.1',
                                                                    min: '0.5',
                                                                }}
                                                                error={requestCPUError != ''}
                                                                color="secondary"
                                                                aria-hidden
                                                                value={requestCPU}
                                                                onChange={handleRequestCPUChange}
                                                                data-testid="requestCPU"
                                                                aria-label={t('ally_enter_request_cpu')}
                                                            />
                                                            <WithVisibility visible={requestCPUError != ''}>
                                                                <p style={{ color: '#a6040a' }}>
                                                                    {requestCPUError}
                                                                </p>
                                                            </WithVisibility>
                                                        </TableCell>
                                                        <TableCell>
                                                            {t('ml_skill_infra_settings_cpu_label')}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Input
                                                                style={{ width: '5em' }}
                                                                name="limitCPU"
                                                                type="number"
                                                                inputProps={{
                                                                    step: '0.1',
                                                                    min: '0.5',
                                                                }}
                                                                error={limitCPUError != ''}
                                                                color="secondary"
                                                                aria-hidden
                                                                value={limitCPU}
                                                                onChange={handleLimitCPUChange}
                                                                data-testid="limitCPU"
                                                                aria-label={t('ally_enter_limit_cpu')}
                                                            />
                                                            <WithVisibility visible={limitCPUError != ''}>
                                                                <p style={{ color: '#a6040a' }}>
                                                                    {limitCPUError}
                                                                </p>
                                                            </WithVisibility>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </div> :
                                    <>
                                        <div>
                                            <Label value={t('ml_skill_infra_settings_replicas_label')} />
                                            <Input
                                                style={{ width: '5em' }}
                                                name="replicas"
                                                type="number"
                                                inputProps={{
                                                    step: '1',
                                                    min: '1',
                                                }}
                                                error={replicaError != ''}
                                                color="secondary"
                                                aria-hidden
                                                value={replicas}
                                                onChange={handleReplicasChange}
                                                data-testid="replicas"
                                                aria-label={t('ally_enter_replicas')}
                                            />
                                            <WithVisibility visible={replicaError != ''}>
                                                <p className="replicaError">
                                                    {replicaError}
                                                </p>
                                            </WithVisibility>

                                        </div>
                                        <div>
                                            <FormControl>
                                                <Label
                                                    value={t('ml_skill_infra_ai_units_label')}
                                                    aria-label={`${t('ml_skill_infra_ai_units_label')} label`} />
                                                <Select
                                                    labelId="ml_skill_infra_ai_units_label"
                                                    id="ml_skill_infra_ai_units"
                                                    onChange={resourcePerReplicaSelectedChange}
                                                    data-testid="resourcePerReplicaMapping"
                                                    value={resourcePerReplica}
                                                    disabled={gpuChecked}
                                                >
                                                    <MenuItem
                                                        value=""
                                                        disabled>
                                                        {' '}
                                                        {t('auto_complete_select_ml_skill_infra_ai_units_place_holder')}
                                                        {' '}
                                                    </MenuItem>
                                                    {(mlPackageTiersValue.length === 0 ? Object.values(RESOURCE_PER_REPLICA_CONFIG) : mlPackageTiersValue).map((mapping) => (
                                                        <MenuItem
                                                            key={uuid()}
                                                            value={mapping['unitvalue']}>
                                                            {mapping['resource']}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </>}
                            </WithVisibility>
                        </div>
                    </WithVisibility>
                    {isAiUnitEnabled === true ?
                        <div>
                            <Label value={`${t('infra_total_ai_units_per_hour_label')} ${aiUnitsConsumption}`} />
                        </div> : null}
                </div>
            </DialogPopup >
        </>
    );
};

interface SendToLabellersDialogProps extends DialogPopupProps {
    appId: string;
    projectId: string;
    datasetId: string;
    itemsSelected: string[];
}

enum SendToLabellersType {
    ALL = 'ALL_FILES',
    NOT_PUSHED = 'NOT_PUSHED',
    CURRENT_SELECTION = 'CURRENT_SELECTION',
}

export const SendToLabellersDialog: React.FC<SendToLabellersDialogProps> = (
    {
        appId,
        projectId,
        datasetId,
        open,
        title,
        handleClose,
        closeIconButton,
        itemsSelected,
    }) => {
    const [ folders, setFolders ] = useState<FolderDto[]>([] as FolderDto[]);
    const [ loading, setLoading ] = useState(false);
    const [ cancelToken, setCancelToken ] = React.useState<CancelTokenSource | null>(null);
    const feedback = useFeedback();
    const { t } = useTranslation();
    const classes = sendToLabellersDialogStyle();
    const history = useHistory();
    let selection = '';

    const debounceAction = debounce(() => {
        initiateLoadData();
    }, 300);

    const updateDropDown = (): any => {
        debounceAction();
        return function cleanup(): void {
            debounceAction.cancel();
        };
    };

    const initiateLoadData = (): any => {
        setFolders([]);
        setLoading(true);
        loadData();
        return function cleanup(): void {
            loadData.cancel();
            if (cancelToken) {
                cancelToken.cancel();
            }
        };
    };

    React.useEffect(() => {
        open && initiateLoadData();
    }, [ open ]);

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    const loadData = debounce(() => {
        const CancelToken = http.CancelToken;
        const source = CancelToken.source();
        setCancelToken(source);
        setLoading(true);
        getFolders(selection).then((response: BaseResponseOfFolderDto) => {
            setFolders(response?.data);
            setLoading(false);
            return true;
        })
            .finally(() => {
                setLoading(false);
            })
            .catch((error: any) => {
                feedback.enqueueError(extractErrorMessage(error, 'Error reading folders'));
            });
    });

    return (
        <DialogPopup
            open={open}
            title={title}
            handleClose={handleClose}
            closeIconButton={closeIconButton}>
            <Formik
                initialValues={{
                    organizationUnitId: '',
                    actionType: SendToLabellersType.NOT_PUSHED,
                }}
                onSubmit={async (values): Promise<any> => {
                    sendToLabellers({
                        fileIds: itemsSelected,
                        allUnlabelledFiles: SendToLabellersType.NOT_PUSHED === values.actionType,
                        allFiles: SendToLabellersType.ALL === values.actionType,
                        organizationUnitId: Number(values.organizationUnitId),
                    }, appId, projectId)
                        .then(() => {
                            feedback.enqueueSuccess(t('feedback_send_to_labellers_success'));
                            history.push(generatePath(RoutePath.LABELING_SESSION_DASHBOARD, { projectName: currentProject?.name }), {
                                appId,
                                projectId,
                                datasetId,
                            });
                            return true;
                        })
                        .catch((error: any) => {
                            feedback.enqueueError(extractErrorMessage(error, t('feedback_error_send_to_labellers'), {
                                10101: {},
                                10602: {},
                                71004: {},
                                71006: {},
                                72005: {},
                            }));
                        });
                }}
                validationSchema={
                    Yup.object().shape({ organizationUnitId: Yup.string().required(t('folder_required')) })
                }
            >
                {
                    (props: FormikProps<any>) => {
                        const {
                            isSubmitting, handleSubmit, setFieldValue, errors,
                        } = props;
                        const handleSelection = (inputChangedInfo: boolean, fieldName: string, fieldValue: string): void => {
                            setFieldValue(fieldName, fieldValue);
                            if (inputChangedInfo === true && fieldName === 'organizationUnitId' && fieldValue !== '') {
                                selection = fieldValue;
                                updateDropDown();
                            }
                        };

                        return (
                            <form
                                onSubmit={handleSubmit}
                                className={classes.root}
                                data-testid="send-to-labellers-form">
                                <div className={classes.form}>
                                    <FormikErrorLabels errors={errors} />
                                    <Label
                                        value={t('send_to_labellers_folder_label')}
                                        required
                                        aria-label={`${t('send_to_labellers_folder_label')} label`} />
                                    <Field
                                        name="organizationUnitId"
                                        options={folders}
                                        loading={loading}
                                        component={FormAutoCompleteServerSide}
                                        inputvalue=""
                                        type="select"
                                        labelKey="FullyQualifiedName"
                                        valueField="Id"
                                        loadingText={t('auto_complete_loading_text')}
                                        placeholder={t('auto_complete_select_folder_place_holder')}
                                        aria-label={`${t('send_to_labellers_folder_label')} select`}
                                        requiredField
                                        data-testid="folder-selector"
                                        handleSelection={handleSelection}
                                    />
                                    <Label
                                        value={t('send_to_labellers_send_section_title')}
                                        aria-label={`${t('send_to_labellers_send_section_title')} label`} />
                                    <Field
                                        name="actionType"
                                        column="true"
                                        aria-label={`${t('send_to_labellers_type')} radio group`}
                                        component={RadioGroup}>
                                        <FormControlLabel
                                            value={SendToLabellersType.CURRENT_SELECTION}
                                            id="current_selection"
                                            control={<Radio
                                                id="action-type"
                                                aria-label={`${t('send_to_labellers_type_current_selection')} radio button`}
                                                disabled={!itemsSelected || itemsSelected.length === 0} />}
                                            label={t('send_to_labellers_type_current_selection')}
                                            aria-label={`${t('send_to_labellers_type_current_selection')} radio button label`}
                                        />
                                        <FormControlLabel
                                            value={SendToLabellersType.ALL}
                                            id="all_files"
                                            control={<Radio
                                                id="action-type"
                                                aria-label={`${t('send_to_labellers_type_all')} radio button`} />}
                                            label={t('send_to_labellers_type_all')}
                                            aria-label={`${t('send_to_labellers_type_all')} radio button label`}
                                        />
                                        <FormControlLabel
                                            value={SendToLabellersType.NOT_PUSHED}
                                            id="all_not_pushed"
                                            control={<Radio
                                                id="action-type"
                                                aria-label={`${t('send_to_labellers_type_not_pushed')} radio button`} />}
                                            label={t('send_to_labellers_type_not_pushed')}
                                            aria-label={`${t('send_to_labellers_type_not_pushed')} radio button label`}
                                        />
                                    </Field>
                                </div>
                                <div className={classes.buttonFooter}>
                                    <div className={classes.group}>
                                        <div className={classes.buttons}>
                                            <Button
                                                style={{ minWidth: '88px' }}
                                                id="submitButton"
                                                data-cy="submitButton"
                                                data-testid="submitButton"
                                                variant="contained"
                                                type="submit"
                                                aria-label={t('OK')}
                                                disabled={isSubmitting}>
                                                {t('OK')}
                                            </Button>
                                            <Button
                                                aria-label={t('form_cancel_button_text')}
                                                style={{
                                                    marginLeft: '8px',
                                                    minWidth: '88px',
                                                }}
                                                id="cancelButton"
                                                data-cy="cancelButton"
                                                variant="outlined"
                                                onClick={() => handleClose()}
                                                disabled={isSubmitting}
                                            >
                                                {t('form_cancel_button_text')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }
                }
            </Formik>
        </DialogPopup>
    );
};

interface TaskEditorPopupProps {
    title: string;
    type: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    initialFormValues: any;
    handleSubmit: (value: any) => void;
    validationSchema?: Yup.ObjectSchema<any>;
    inputTypes: string[];
}

const taskEditorStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            '& .MuiTextField-root': { marginRight: theme.spacing(1) },

            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': { transform: 'none' },
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: { margin: theme.spacing(1) },
        attributeHeaderContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(2),
        },
        classificationInputTypeDropDown: {
            minWidth: '25%',
            marginRight: theme.spacing(1),
        },
        entityInputTypeDropDown: {
            minWidth: '50%',
            marginRight: theme.spacing(1),
        },
        entityColorDropDown: {
            minWidth: theme.spacing(16),
            marginRight: theme.spacing(1),
            height: theme.spacing(8),
        },
        inputLabel: {
            marginBottom: theme.spacing(1),
            fontSize: '14px',
        },
        addAttributeButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: theme.spacing(1),
        },
        classificationAttributeTextField: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            minHeight: theme.spacing(8),
        },
        entityAttributeTextField: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
            minHeight: theme.spacing(8),
        },
        tableCell: { padding: theme.spacing(1) },
        iconCell: { minHeight: theme.spacing(8) },
    }),
);

export const TaskEditorPopup: React.FC<TaskEditorPopupProps> = ({
    title, type, open, setOpen, initialFormValues, handleSubmit, validationSchema, inputTypes,
}) => {
    const cssClasses = taskEditorStyles();
    const { t } = useTranslation();

    const handleClose = (): void => {
        setOpen(false);
    };

    return (<>
        <DialogPopup
            title={title}
            open={open}
            handleClose={handleClose}
            closeIconButton
            className={cssClasses.dialog}
            data-testid='task-editor-popup'
        >
            <Formik
                initialValues={initialFormValues}
                onSubmit={(data): void => {
                    handleSubmit(data);
                }}
                validationSchema={validationSchema}
            >
                {({
                    values, resetForm,
                }): any => (
                    <Form>
                        <div className={cssClasses.attributeHeaderContainer}>
                            <NameTextField
                                name='name'
                                placeholder={t('common_name_label')}
                                type='input' />
                            {type === 'classification' && <FormControl
                                variant="outlined"
                                className={cssClasses.classificationInputTypeDropDown}>
                                <TooltipContainer title={t('tooltip_data_labelling_classification_type')}>
                                    <InputLabel
                                        id="classification-type-label"
                                        className={cssClasses.inputLabel}>
                                        {t('a11y_classification_type_field')}
                                    </InputLabel>
                                </TooltipContainer>
                                <Field
                                    type='select'
                                    as={Select}
                                    name='classificationType'
                                    labelId="classification-type-label"
                                    data-testid='classification-type-field'>
                                    <MenuItem value='single'>
                                        {t('a11y_classification_type_single')}
                                    </MenuItem>
                                    <MenuItem value='multiple'>
                                        {t('a11y_classification_type_multiple')}
                                    </MenuItem>
                                </Field>
                            </FormControl>}
                            <FormControl
                                variant="outlined"
                                className={type === 'classification' ? cssClasses.classificationInputTypeDropDown : cssClasses.entityInputTypeDropDown}>
                                <TooltipContainer title={t('tooltip_data_labelling_inputed_labelled')}>
                                    <InputLabel
                                        id="input-type-label"
                                        className={cssClasses.inputLabel}>
                                        {t('a11y_input_type_field')}
                                    </InputLabel>
                                </TooltipContainer>
                                <Field
                                    type='select'
                                    as={Select}
                                    name='inputType'
                                    labelId="input-type-label">
                                    {inputTypes.map((inputType: string) => (
                                        <MenuItem
                                            key={uuid()}
                                            value={inputType}
                                            data-testid='input-type-menu-item'>
                                            {inputType}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormControl>
                        </div>
                        <div>
                            <FieldArray name='attributes'>
                                {(arrayHelpers: { push: (arg0: any) => void; remove: (arg0: number) => void }): any => (
                                    <>
                                        <div className={cssClasses.addAttributeButtonContainer}>
                                            <Button
                                                color="primary"
                                                data-testid='add-attribute-btn'
                                                onClick={(): void => {
                                                    const newObj = type === 'entity' ? {
                                                        id: uuid(),
                                                        attributeName: '',
                                                        shortcut: '',
                                                        color: '',
                                                    } : {
                                                        id: uuid(),
                                                        attributeName: '',
                                                        shortcut: '',
                                                    };
                                                    arrayHelpers.push(newObj);
                                                }}>
                                                {t('evtable_add_new_button_label')}
                                            </Button>
                                        </div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={cssClasses.tableCell}>
                                                        {t('a11y_attribute_name_field')}
                                                    </TableCell>
                                                    <TableCell className={cssClasses.tableCell}>
                                                        {t('a11y_shortcut_field')}
                                                    </TableCell>
                                                    {type === 'entity' && <TableCell className={cssClasses.tableCell}>
                                                        {t('a11y_color_field')}
                                                    </TableCell>}
                                                    <TableCell className={cssClasses.tableCell} />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {values?.attributes?.map((attribute: any, index: number) => (
                                                    <TableRow
                                                        key={attribute.id}
                                                        data-testid={`table-row-${index}`}>
                                                        <TableCell className={cssClasses.tableCell}>
                                                            <AttributeTextField
                                                                name={`attributes[${index}].attributeName`}
                                                                placeholder={t('a11y_attribute_name_field')}
                                                                type='input'
                                                                className={type === 'classification' ? cssClasses.classificationAttributeTextField : cssClasses.entityAttributeTextField} />
                                                        </TableCell>
                                                        <TableCell className={cssClasses.tableCell}>
                                                            <AttributeTextField
                                                                name={`attributes[${index}].shortcut`}
                                                                placeholder={t('a11y_shortcut_field')}
                                                                type='input'
                                                                className={type === 'classification' ? cssClasses.classificationAttributeTextField : cssClasses.entityAttributeTextField} />
                                                        </TableCell>
                                                        {type === 'entity' && <TableCell
                                                            className={cssClasses.tableCell}
                                                            data-testid="color-dropdown-table-cell">
                                                            <FormControl
                                                                variant="outlined"
                                                                className={cssClasses.entityColorDropDown}>
                                                                <ColorDropdownField name={`attributes[${index}].color`} />
                                                            </FormControl>
                                                        </TableCell>}
                                                        <TableCell className={cssClasses.tableCell}>
                                                            <div className={cssClasses.iconCell}>
                                                                <IconButton
                                                                    color="primary"
                                                                    aria-label="Delete Attribute"
                                                                    component="span"
                                                                    onClick={(): void => arrayHelpers.remove(index)}
                                                                    data-testid={`delete-attribute-btn-${index}`}>
                                                                    <DeleteIcon color='primary' />
                                                                </IconButton>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </>
                                )}
                            </FieldArray>
                        </div>
                        <div className={cssClasses.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                type='submit'
                                className={cssClasses.button}
                                data-testid='task-editor-done-button'>
                                Done
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={cssClasses.button}
                                onClick={(): void => {
                                    resetForm();
                                    handleClose();
                                }}
                                data-testid='task-editor-cancel-button'
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </DialogPopup>
    </>);
};

interface DeleteAttrPopupProps {
    title: string;
    infoText: string;
    open: boolean;
    setOpen: (arg0: boolean) => void;
    handleDelete: () => void;
}

export const DeleteAttrPopup: React.FC<DeleteAttrPopupProps> = ({
    title, infoText, setOpen, open, handleDelete,
}) => {
    const { t } = useTranslation();

    const closeDialog = (): void => {
        setOpen(false);
    };

    return (<>
        <CustomDialog
            title={title}
            open={open}
            handleClose={closeDialog}
            closeIconButton
            infoText={infoText}
            confirmationText={t('attr_delete_dialog_confirmation_text')}
            primaryButtonText={t('dialog_button_confirm_text')}
            secondaryButtonText={t('dialog_button_cancel_text')}
            primarybuttonCallback={handleDelete}
            secondarybuttonCallback={closeDialog}
            data-testid='delete-attribute-dialog'
        />
    </>);
};

interface EnvironmentVariablePopupProps {
    tableData: ParametersFileEnvironmentVariable[];
    setTableData: (arg0: ParametersFileEnvironmentVariable[]) => void;
    title: string;
    open: boolean;
    handleClose: () => void;
}

interface SanitizedParametersFileEnvironmentVariable {
    name: string;
    type: string[];
    value: string;
    help: string;
    options: string[];
}

interface AddRowComponentProps {
    onAdd: (name: string, value: string) => void;
    onCancel: () => void;
    index: number;
    handleSetEditableRows: (index: number) => void;
}

interface EditableRowProps {
    data: ParametersFileEnvironmentVariable;
    onEditComplete: (name: string, value: string) => void;
    onDeleteRow: (name: string) => void;
    index: number;
    handleSetEditableRows: (index: number) => void;
}

interface HandleDropdownAndTextFieldSelectionProps {
    data: SanitizedParametersFileEnvironmentVariable;
    value: string;
    onChange: (name: string) => void;
}

const envVarPopupStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            '& .MuiTextField-root': { marginRight: theme.spacing(1) },

            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': { transform: 'none' },
        },
        button: { margin: theme.spacing(1) },
        addNewButton: {
            textTransform: 'none',
            fontSize: Tokens.FontFamily.FontMSize,
            fontWeight: Tokens.FontFamily.FontWeightDefault,
            margin: 0,
            marginTop: '7px',
        },
    }),
);

export const EnvironmentVariablePopup: React.FC<EnvironmentVariablePopupProps> = ({
    open, title, handleClose, tableData, setTableData,
}) => {
    const { t } = useTranslation();

    const [ envTableData, setEnvTableData ] = React.useState<ParametersFileEnvironmentVariable[]>(tableData);
    const [ shouldAdd, setShouldAdd ] = React.useState(false);
    const [ currentlyEditingRows, setCurrentlyEditingRows ] = React.useState<number[]>([]);
    const cssClasses = envVarPopupStyles();

    const handleSetEditableRows = (index: number): void => {
        const newCurrentlyEditingRows = currentlyEditingRows;
        if (newCurrentlyEditingRows.includes(index)) {
            const indexToRemove = newCurrentlyEditingRows.indexOf(index);
            newCurrentlyEditingRows.splice(indexToRemove, 1);
        } else {
            newCurrentlyEditingRows.push(index);
        }
        setCurrentlyEditingRows([ ...newCurrentlyEditingRows ]);
    };

    const onAdd = useCallback((name: string, value: string) => {
        if (name && !envTableData.find(envVar => envVar.name === name)) {
            setEnvTableData([ ...envTableData, {
                name,
                value,
                type: [ ParametersFileParamsKeyPossibleTypes.PIPELINE ],
            } as ParametersFileEnvironmentVariable ]);
            handleSetEditableRows(envTableData.length);
            setShouldAdd(false);
        }
    }, [ setEnvTableData, envTableData, setShouldAdd ]);

    const onCancel = useCallback(() => setShouldAdd(false), [ setShouldAdd ]);

    React.useEffect(() => {
        setEnvTableData(tableData);
    }, [ tableData ]);

    return (
        <DialogPopup
            title={t('pipeline_env_parameters_label')}
            open={open}
            handleClose={handleClose}
            closeIconButton
            className={cssClasses.dialog}
        >
            <TableContainer data-testid="env_vars_editor_popup">
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems='flex-start'>
                    <Grid item>
                        <Label
                            value={title}
                            aria-label={`${title} ${t('a11y_label')}`} />
                    </Grid>
                    <Grid item>
                        <Button
                            color="secondary"
                            className={cssClasses.addNewButton}
                            onClick={() => {
                                setShouldAdd(true);
                                if (currentlyEditingRows.indexOf(envTableData.length) === -1) {
                                    handleSetEditableRows(envTableData.length);
                                }
                            }}
                            data-testid="add_new_row"
                            aria-label={t('a11y_add_env_var_btn')}
                        >
                            {t('evtable_add_new_button_label')}
                        </Button>
                    </Grid>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="header">
                                {t('evtable_ev_header')}
                            </TableCell>
                            <TableCell className="value">
                                {t('evtable_val_header')}
                            </TableCell>
                            <TableCell className="iconsColumn" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            ((!envTableData || !envTableData.length) && !shouldAdd) ? (
                                <TableRow>
                                    <TableCell
                                        className="emptyTableRow leftAlign"
                                        colSpan={3}>
                                        {t('evtable_empty_ph')}
                                    </TableCell>
                                </TableRow>
                            ) :
                                envTableData.map((e, index) => (
                                    <EditableRow
                                        data={e}
                                        index={index}
                                        handleSetEditableRows={handleSetEditableRows}
                                        onEditComplete={
                                            (name: any, value: any) => {
                                                setEnvTableData(
                                                    envTableData.map(data => {
                                                        if (data.name === name) {
                                                            return {
                                                                ...data,
                                                                value,
                                                            };
                                                        }

                                                        return data;

                                                    }),
                                                );
                                            }
                                        }
                                        onDeleteRow={
                                            (name: any) => {
                                                setEnvTableData(
                                                    envTableData.filter((data) => data.name !== name),
                                                );
                                            }
                                        }
                                    />
                                ),
                                )
                        }
                        {
                            shouldAdd ? (
                                <AddRowComponent
                                    onAdd={onAdd}
                                    onCancel={onCancel}
                                    index={envTableData.length}
                                    handleSetEditableRows={handleSetEditableRows}
                                />
                            ) : null
                        }
                    </TableBody>
                </Table>
                <Grid
                    container
                    justifyContent="flex-end"
                    alignItems='flex-start'>
                    <Button
                        variant="contained"
                        color="primary"
                        data-testid="env_vars_editor_popup_save_btn"
                        aria-label={t('a11y_submit')}
                        disabled={(currentlyEditingRows !== null && currentlyEditingRows !== undefined && currentlyEditingRows?.length > 0)}
                        className={cssClasses.button}
                        onClick={(): void => {
                            setTableData(envTableData);
                            handleClose();
                        }}
                    >
                        {t('form_done_button_text')}
                    </Button>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="secondary"
                            data-testid="env_vars_editor_popup_cancel_btn"
                            aria-label={t('a11y_cancel')}
                            className={cssClasses.button}
                            onClick={handleClose}
                        >
                            {t('form_cancel_button_text')}
                        </Button>
                    </Grid>
                </Grid>
            </TableContainer>
        </DialogPopup>
    );
};

export const sanitizeTableData = (t: ParametersFileEnvironmentVariable): SanitizedParametersFileEnvironmentVariable => (
    {
        name: t.name,
        type: t.type,
        value: t.value || '',
        help: t.help || '',
        options: t.options || [],
    }
);

const useStylesEditableRow = makeStyles(() =>
    createStyles({
        root: {
            '& .MuiTableCell-root': {
                verticalAlign: 'top',

                '& .MuiTextField-root': { height: '56px' },

                '&.header': { width: '12rem' },

                '&.isEditableRow': {
                    '& .MuiTextField-root': { height: 'initial' },

                    '&.leftAlign': { paddingLeft: '28px' },

                    '& .leftAlignRevert': { marginLeft: '-16px' },
                },

                '&.value': { width: '12rem' },
            },
        },

        rightAligned: {
            textAlign: 'right',
            verticalAlign: 'middle !important',
            padding: '0',

            '& button': { '&.verticalFix': { marginBottom: '1.8rem' } },
        },

        dropDown: { width: '100%' },
    }),
);

export const HandleDropdownAndTextFieldSelection: React.FC<HandleDropdownAndTextFieldSelectionProps> = (props: HandleDropdownAndTextFieldSelectionProps): any => {

    const classes = useStylesEditableRow();

    const handleValueSelection = (e: any) => {
        props.onChange(e.target.value);
    };

    return props.data.options.length !== 0 ?
        (
            <div>
                <Select
                    value={props.value}
                    className={classes.dropDown + ' leftAlignRevert'}
                    onChange={handleValueSelection}
                >
                    {props.data.options.map((opt: string) => (
                        <MenuItem value={opt}>
                            {opt}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        )
        :
        (
            <TextField
                variant="outlined"
                value={props.value}
                className="leftAlignRevert"
                required
                autoComplete="off"
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => props.onChange(e.target.value)}
            />
        );
};

export const EditableRow: React.FC<EditableRowProps> = (props: EditableRowProps) => {
    const data: SanitizedParametersFileEnvironmentVariable = sanitizeTableData(props.data);
    const [ name, setName ] = useState('');
    const [ value, setValue ] = useState(props.data.value || '');
    const [ isEditable, setIsEditable ] = useState(false);
    const classes = useStylesEditableRow();

    return (
        <TableRow className={classes.root}>
            <TableCell className="leftAlign">
                {
                    data.name
                }
            </TableCell>
            <TableCell className="leftAlign isEditableRow">
                {isEditable ? <HandleDropdownAndTextFieldSelection
                    data={data}
                    value={value}
                    onChange={(receivedValue: any) => setValue(receivedValue)} /> : data.value}
            </TableCell>
            <TableCell className={classes.rightAligned}>
                {
                    isEditable ? (
                        <>

                            <Tooltip title="Save">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        props.onEditComplete(name, value);
                                        setIsEditable(false);
                                        props.handleSetEditableRows(props.index);
                                    }}
                                >
                                    <CheckIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Cancel">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setIsEditable(false);
                                        props.handleSetEditableRows(props.index);
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>

                        </>
                    ) : (
                        <>

                            <Tooltip title="Edit">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setIsEditable(true);
                                        setName(data.name);
                                        setValue(data.value);
                                        props.handleSetEditableRows(props.index);
                                    }}
                                >
                                    <CreateIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title='Delete'>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        props.onDeleteRow(data.name);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={data.help || 'Help'}>
                                <IconButton
                                    size="small"
                                >
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>

                        </>
                    )
                }
            </TableCell>
        </TableRow>
    );
};

export const AddRowComponent: React.FC<AddRowComponentProps> = (props: AddRowComponentProps) => {
    const { t } = useTranslation();
    const [ name, setName ] = useState('');
    const [ isNameDirty, setIsNameDirty ] = useState(false);
    const [ value, setValue ] = useState('');
    const [ nameError, setNameError ] = useState('');
    const classes = useStylesEditableRow();

    const onAdd = useCallback(() => {
        if (nameError !== '') {
            return;
        }
        props.onAdd(name, value);
    }, [ props.onAdd, name, value ]);

    React.useEffect(() => {
        if (!isNameDirty) {
            setIsNameDirty(true);
        } else {
            if (!name) {
                setNameError(t('evtable_field_req'));
            } else if (name.indexOf(' ') !== -1) {
                setNameError(t('evtable_field_error_no_spaces'));
            } else {
                setNameError('');
            }
        }
    }, [ name ]);

    return (
        <TableRow className={classes.root}>
            <TableCell>
                <TextField
                    name="envName"
                    variant="outlined"
                    value={name}
                    required
                    error={nameError !== ''}
                    helperText={nameError}
                    autoComplete="off"
                    InputProps={{ 'inputProps': { 'aria-label': t('a11y_variable_name_label') } }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
                        setName(e.target.value);
                    }}
                />
            </TableCell>
            <TableCell>
                <TextField
                    name="envValue"
                    value={value}
                    variant="outlined"
                    required
                    autoComplete="off"
                    InputProps={{ 'inputProps': { 'aria-label': t('a11y_variable_value_label') } }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => setValue(e.target.value)}
                />
            </TableCell>
            <TableCell className={classes.rightAligned}>

                <Tooltip title="Save">
                    <IconButton
                        className="verticalFix"
                        size="small"
                        onClick={onAdd}
                    >
                        <CheckIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Cancel">
                    <IconButton
                        size="small"
                        className="verticalFix"
                        onClick={(): void => {
                            props.onCancel();
                            props.handleSetEditableRows(props.index);
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Tooltip>

            </TableCell>
        </TableRow>
    );
};
