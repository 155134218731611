import {http} from "../../http";
import URLManager from "../../config/URLManager";
import {AxiosResponse} from "axios";
import {Origin, Scope, Service} from "../../enums/ClientErrorStrings";
import {getDisplayErrorCode} from "../../utils/CommonUtils";
import logger from "../../utils/Logging";
import {PERMISSIONS_FAILED_MSG} from "../../constants/TelemetryConstants";
import globalHook, {Store} from "use-global-hook";
import React from "react";
import _ from "lodash";
import {
  AppPermissionsActions,
  AppPermissionsState,
  FEToBEMapper,
  PermissionState,
  PossiblePermissions,
} from "./usePermissions.d";
import FeatureFlagManager from "../../feature-flag/FeatureFlagManager";

import {AppPermissions} from "../../enums/Authorization"


const permissionFields = Object.keys(PossiblePermissions);
const defaultPermission = [] as AppPermissions[];


// todo: Switch to this and remove flag based control of permission when migration complete
/**
 * Converts response data to list of AppPermission
 *
 * Eg: [{DATASET:{canCreate:false, canEdit:true, canView:true...}...]
 * to [MLStorage.canEdit, MLStorage.canView...]
 * @param responseData
 */
const convertToAppPermission = (responseData: any): AppPermissions[] => {
  return Object.keys(responseData).reduce((combined: AppPermissions[], serviceName: any) => {
    const serviceNameMapped = FEToBEMapper[serviceName as keyof typeof FEToBEMapper];
    const servicePermission = responseData[serviceName];

    const sub = Object.keys(servicePermission).reduce((acc: AppPermissions[], field: any) => {
      if (servicePermission[field]) {

        const field_ = _.capitalize(field.substring(3))
        const permissionName = serviceNameMapped + "_" + field_
        return [...acc, AppPermissions[permissionName as keyof typeof AppPermissions]];

      } else
        return [...acc]
    }, [])

    return combined.concat(sub)
  }, []);
}

/**
 * Fetches permissions from AIC url
 * @param url
 */
const getPermissionsFromAIC = async (url: string): Promise<AppPermissions[]> => {
  const response: AxiosResponse = await http.get(url);
  const responseData: any = response.data.data;

  return convertToAppPermission(responseData.reduce(
    (accumulator: any, curr: any) => {
      return {
        ...accumulator,
        [curr["permission"]]: _.pick(curr, permissionFields)
      }
    }, {}));
}

/**
 * Utility to get correct name of enum key
 * @param permission
 */
const getPermissionEnum = (permission: string): AppPermissions => {
  const typedPermission: keyof typeof AppPermissions = permission.replace('.', '_') as keyof typeof AppPermissions;
  return AppPermissions[typedPermission];
};


/**
 * Fetches permissions from AIC or Orch based on value of fromAIC
 * Updates the store asynchronously.
 * @param store
 * @param fromAIC
 */
const forceRefreshTenant = async (store: Store<AppPermissionsState, AppPermissionsActions>): Promise<void> => {

  try {
    const tenantPermissionUrl = `${URLManager.url().apiHelper}/permissions/users/tenant/`
    const tenantPermissionSet = await getPermissionsFromAIC(tenantPermissionUrl);

    store.setState(
      {
        ...store.state,
        tenantData: {
          ...store.state.tenantData,
          permissionSet: tenantPermissionSet,
          permissionState: PermissionState.LOADED,
        },
      }
    );
  } catch (error) {
    const backendCode = getDisplayErrorCode(Scope.Core, Service.HELPER, Origin.BOOTSTRAPROVIDER, error, error.response?.status);
    logger.error({
      identifier: Scope.Core,
      message: PERMISSIONS_FAILED_MSG,
      error: error,
      backendCode: backendCode
    });

    store.setState(
      {
        ...store.state,
        tenantData: {
          ...store.state.tenantData,
          permissionState: PermissionState.FAILED,
          backendCode: getDisplayErrorCode(Scope.Core, Service.HELPER, Origin.PROJECTLIST, error, error.response?.status)
        },
      }
    );
  }
};

/**
 * Fetches permissions of projectId from AIC/Orch based on value of fromAIC
 * Updates store asynchronously.
 * @param store
 * @param projectId
 * @param fromAIC
 */
const forceRefreshProject = async (store: Store<AppPermissionsState, AppPermissionsActions>, projectId: string): Promise<void> => {

  try {
    const projectPermissionUrl = `${URLManager.url().apiHelper}/permissions/users/project/?project-id=${projectId}`
    const projectPermissionSet = await getPermissionsFromAIC(projectPermissionUrl);

    const isEmpty = _.isEmpty(projectPermissionSet);
    store.setState(
      {
        ...store.state,
        projectData: {
          ...store.state.projectData,
          [projectId]: {
            permissionSet: projectPermissionSet,
            permissionState: PermissionState.LOADED,
          }
        }
      }
    )

  } catch (error) {
    const backendCode = getDisplayErrorCode(Scope.Projects, Service.HELPER, Origin.BOOTSTRAPROVIDER, error, error.response?.status);
    logger.error({
      identifier: Scope.Projects,
      message: `${PERMISSIONS_FAILED_MSG} - ${projectId}`,
      error: error,
      backendCode: backendCode
    });

    store.setState(
      {
        ...store.state,
        projectData: {
          ...store.state.projectData,
          [projectId]: {
            permissionSet: [],
            permissionState: PermissionState.FAILED,
            backendCode: getDisplayErrorCode(Scope.Core, Service.HELPER, Origin.PROJECTLIST, error, error.response?.status)
          }
        }
      }
    );
  }
};

/**
 * If tenant permissions are already loaded, then, returns permissions
 * If not, then fetches permission from AIC/Orch based on feature-flag
 * @param store
 */
const getTenantPermissions = (store: Store<AppPermissionsState, AppPermissionsActions>): AppPermissions[] => {

  const featureFlagManager = {} as FeatureFlagManager;
  const tenantData = store.state.tenantData;
  if (tenantData.permissionState == PermissionState.EMPTY) {
    store.setState(
      {
        ...store.state,
        tenantData:
          {
            ...store.state.tenantData,
            permissionState: PermissionState.INITIATED
          }
      });

    forceRefreshTenant(store);
    return defaultPermission;
  } else if (tenantData.permissionState == PermissionState.INITIATED) {
    return defaultPermission;
  } else if(tenantData.permissionState == PermissionState.LOADED) { // LOADED or FAILED
    return tenantData.permissionSet;
  }
  return defaultPermission;
}


/**
 * If projectId's permissions are already loaded, then, returns permissions
 * If not, then fetches permission from AIC/Orch based on feature-flag
 * @param store
 */
const getProjectPermissions = (store: Store<AppPermissionsState, AppPermissionsActions>, projectId: string) : AppPermissions[] => {
  // todo: fix this mock later @avin45h
  const featureFlagManager = {} as FeatureFlagManager;
  const projectData = store.state.projectData[projectId];
  if (!projectData) {
    store.setState(
      {
        ...store.state,
        projectData:
          {
            ...store.state.projectData,
            [projectId]: {
              permissionState: PermissionState.INITIATED,
              permissionSet: defaultPermission,
              backendCode: null,
              failureCode: null,
            }
          }
      }
    )
    forceRefreshProject(store, projectId);
    return defaultPermission;
  } else if (projectData.permissionState == PermissionState.INITIATED) {
    return defaultPermission;
  } else if(projectData.permissionState == PermissionState.LOADED) { // LOADED or FAILED
    return projectData.permissionSet;
  }
  return defaultPermission;
}

export const usePermissions = globalHook<AppPermissionsState, AppPermissionsActions>(React, {
  tenantData: {
    permissionSet: defaultPermission,
    permissionState: PermissionState.EMPTY,
    failureCode: null,
    backendCode: null
  },
  projectData: {}
}, {getProjectPermissions, getTenantPermissions});
