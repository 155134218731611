import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Card from './Card';
import type { ContextMenuItem } from './ContextPopup';
import NumberBadge from './NumberBadge';

interface ProjectCardProps {
    title: string;
    description: string;
    leftBadgeTitle: string;
    leftBadgeValue: number;
    rightBadgeTitle: string;
    rightBadgeValue: number;
    route: string;
    projectId: string;
    projectName: string;
    contextMenuItems: ContextMenuItem[];
}

const useCardStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            cursor: 'pointer',
            marginLeft: '24px',
            marginBottom: '24px',
        },
        rootCard: {
            height: '138px',
            cursor: 'pointer',
        },
        description: {
            fontSize: Tokens.FontFamily.FontSSize,
            fontFamily: Tokens.FontFamily.FontNormal,
            fontStyle: 'normal',
            marginTop: '10px',
            fontWeight: 'normal',
            lineHeight: Tokens.FontFamily.FontSLineHeight,
            color: theme.palette.semantic.colorForegroundDeEmp,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflowWrap: 'break-word',
            overflow: 'hidden',
            height: '2.5rem',
            WebkitLineClamp: 4,
        },
        badges: {
            paddingTop: '22px',
            display: 'flex',
            marginLeft: '-8px',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignSelf: 'flex-end',
        },
    }),
);

const ProjectCard: React.FC<ProjectCardProps> = ({
    title,
    description,
    leftBadgeTitle,
    leftBadgeValue,
    rightBadgeTitle,
    rightBadgeValue,
    route,
    contextMenuItems,
    projectId,
    projectName,
}) => {
    const classes = useCardStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const cb = useCallback((): void => {
        history.push({
            pathname: route,
            state: { projectId },
        });
    }, [ route, projectId ]);

    return (
        <div className={classes.root}>
            <Card
                hover
                onClick={cb}
                contextMenuItems={contextMenuItems.map((c) => ({
                    ...c,
                    click: (event) => {
                        if (c.click) {
                            c.click(event, {
                                id: projectId,
                                name: projectName,
                            });
                        }
                    },
                }))}
                title={title}
            >
                <div
                    aria-label={t('a11y_description')}
                    className={classes.description}>
                    {description}
                </div>
                <div className={classes.rootCard}>
                    <div className={classes.badges}>
                        <NumberBadge
                            title={leftBadgeTitle}
                            value={leftBadgeValue} />
                        <NumberBadge
                            title={rightBadgeTitle}
                            value={rightBadgeValue} />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ProjectCard;
