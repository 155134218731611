import './loadingDots.css';

import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { ReactElement } from 'react';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        margin: '100px auto 0',
        width: '70px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        '& > div': {
            width: '18px',
            height: '18px',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '100%',
            display: 'flex',
            webkitAnimation: 'sk-bouncedelay 1.4s infinite ease-in-out both',
            animation: 'sk-bouncedelay 1.4s infinite ease-in-out both',
        },

        '& .dot1': {
            webkitAnimationDelay: '-0.32s',
            animationDelay: '-0.32s',
        },

        '& .dot2': {
            webkitAnimationDelay: '-0.16s',
            animationDelay: '-0.16s',
        },
    },

}));

export default function LoadingDots(): ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className="dot1" />
            <div className="dot2" />
            <div className="dot3" />
        </div>
    );
}
