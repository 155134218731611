import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Card from './Card';

interface ActionCardProps {
    title: string;
    imgUrl: string;
    cardContent: string;
    route?: string;
    data?: any;
    onClick?: () => void;
}

const envPrefix = process.env.NODE_ENV === 'development' ? window.location.origin : '';

const useCardStyles = makeStyles(() =>
    createStyles({
        card: { marginBottom: '1em' },
        contentBase: {
            paddingTop: '1em',
            height: 130,
        },
        previewChip: { marginTop: '1em' },
    }),
);

export const ActionCard: React.FC<ActionCardProps> = ({
    title,
    imgUrl,
    cardContent,
    route,
    data,
    onClick,
}) => {

    const classes = useCardStyles();
    const history = useHistory();

    const handleCardClicked = useCallback((): void => {
        if (onClick) {
            onClick();
        } else {
            history.push({
                pathname: route,
                state: { data },
            });
        }
    }, [ onClick, route, data ]);

    return (
        <div className={classes.card}>
            <Card
                hover
                onClick={handleCardClicked}
                avatar={envPrefix + imgUrl}
                title={title}>
                <div>
                    <div
                        className={classes.contentBase}
                        data-testid="portal-action-card-description">
                        {cardContent}
                    </div>
                </div>
            </Card>
        </div>
    );
};
