import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { ReactElement } from 'react';
import React, { useRef } from 'react';

const useStyles = makeStyles(() => createStyles({
    background: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // otherwise it overlaps with portal-nav border
        marginLeft: '1px',

        '& portal-alert-error-page': { width: '100%' },
    },
}));

interface ErrorDialogProps {
    title: string;
    subtitle?: string;
    showLogo?: boolean;
    buttonCallback: () => void;
    secondaryButtonCallback: () => void;
    buttonText?: string;
    secondaryButtonText?: string;
    children?: React.ReactNode | JSX.Element;
    backendCode: null | undefined | string;
}

export default function ErrorDialog(props: ErrorDialogProps): ReactElement {
    const classes = useStyles();
    const portalErrorElement = useRef<HTMLPortalAlertErrorPageElement>(null);
    React.useEffect(() => {
        portalErrorElement.current?.addEventListener('primaryButtonClicked', () => {
            props.buttonCallback();
        });
        portalErrorElement.current?.addEventListener('secondaryButtonClicked', () => {
            props.secondaryButtonCallback();
        });
    }, [ portalErrorElement.current ]);

    return (
        <div
            className={classes.background}
            data-testid="error_dialog">
            <portal-alert-error-page
                ref={portalErrorElement}
                type="error"
                primary-action-text={props.buttonText}
                secondary-action-text={props.secondaryButtonText}
                heading={props.title}
                subheading={props.backendCode || ''}>
                {props.subtitle}
            </portal-alert-error-page>
        </div>
    );
}
