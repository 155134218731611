import { AxiosResponse } from 'axios';

import {
  BaseResponseOfDatasetDownloadDto,
  BaseResponseOfDatasetDto,
  BaseResponseOfPageListingDtoOfDatasetDto,
  BaseResponseOfSignedURLDto,
  BaseResponseOfstring,
  BlobMetadata,
  CopyBlobRequestDto,
  DatasetDownloadDto,
  DatasetDto,
  DatasetRequestDto,
} from '@uipath/aifabric';

import FileToUpload from '../../components/fileDropzone/FileToUpload';
import URLManager from '../../config/URLManager';
import {
  DATASET_CREATE_DUPLICATE_SUCCESS_RESP,
} from '../../constants/AiappConstants';
import { http } from '../../http';
import {
  BaseResponseOfDatasetListingDTO,
  DataSetItem,
} from '../../pages/datasets/Dataset';
import { uploadPackageToAzure } from './pkgManagerClient';

export const createDataset = async (createDataset: DatasetRequestDto): Promise<DatasetDto | undefined> => {
  const res = await http.post<DatasetRequestDto, AxiosResponse<BaseResponseOfDatasetDto>>(
    URLManager.url().apiTrainer + "/datasets" + `?projectId=${createDataset.projectId}`,
    createDataset
  );
  if (res?.data?.respCode === 208) {
    throw new Error(DATASET_CREATE_DUPLICATE_SUCCESS_RESP);
  }
  return res.data.data;
};

export const rotateKey = async (datasetId: string, projectId?: string): Promise<DatasetDto | undefined> => {
  const res = await http.post<void, AxiosResponse<BaseResponseOfDatasetDto>>(
    URLManager.url().apiTrainer + "/datasets/" + datasetId + "/rotateKey" + `?projectId=${projectId}`
  );
  return res.data.data;
};

export const downloadDataset = async (
  datasetId: string,
  projectId?: string
): Promise<DatasetDownloadDto[] | undefined> => {
  const res = await http.post<void, AxiosResponse<BaseResponseOfDatasetDownloadDto>>(
    `${URLManager.url().apiTrainer}/datasets:download/?projectId=${projectId}&datasetId=${datasetId}`
  );
  return res.data.dataList;
};

export const deleteDatasetDownload = async (id: string, datasetId: string, projectId?: string) => {
  const res = await http.delete(
    `${URLManager.url().apiTrainer}/datasets:download/${id}?projectId=${projectId}&datasetId=${datasetId}`
  );
  return res.data;
};

export const getDatasets = async (pageSize: Number, projectId?: string): Promise<DatasetDto[] | undefined> => {
  const res = await http.get<BaseResponseOfPageListingDtoOfDatasetDto>(
    `${URLManager.url().apiTrainer}/datasets?pageSize=${pageSize}&projectId=${projectId}`
  );
  return res.data?.data?.dataList;
};

export const getDatasetByExactName = async (projectId?: string, name?: string): Promise<DatasetDto[] | undefined> => {
  const res = await http.get<BaseResponseOfPageListingDtoOfDatasetDto>(
    `${URLManager.url().apiTrainer}/datasets?projectId=${projectId}&name=${name}&exactMatchOnName=true`
  );
  return res.data?.data?.dataList;
};

export const updateDataset = async (
  updateDataset: DatasetRequestDto,
  datasetId: string
): Promise<DatasetDto | undefined> => {
  const res = await http.post<DatasetRequestDto, AxiosResponse<BaseResponseOfDatasetDto>>(
    URLManager.url().apiTrainer + "/datasets/" + datasetId + `?projectId=${updateDataset.projectId}`,
    updateDataset
  );
  return res.data.data;
};

export const deleteDataset = async (datasetId: string, projectId: string | undefined) => {
  const res = await http.delete<AxiosResponse<BaseResponseOfstring>>(
    URLManager.url().apiTrainer + "/datasets/" + datasetId + "?projectId=" + projectId
  );
  return res.data;
};

export const getDatasetsByDirectoryName = async (
  dirname: string | undefined | null,
  projectId?: string
): Promise<DataSetItem[] | undefined> => {
  const res = await http.get<BaseResponseOfDatasetListingDTO>(
    `${URLManager.url().apiTrainer}/datasets/listDataset?directoryName=${dirname}&pageSize=1000&projectId=${projectId}`
  );
  return res.data.data?.dataList;
};

export const checkUniqueDatasetByName = async (
  datasetName: string,
  projectId?: string
): Promise<Boolean | undefined> => {
  const res = await http.get<Boolean>(
    URLManager.url().apiTrainer + "/datasets/search?name=" + datasetName + "&projectId=" + projectId
  );
  return res.data;
};

export const getDatasetById = async (
  datasetId: string,
  projectId: string | undefined
): Promise<DatasetDto | undefined> => {
  const res = await http.get<BaseResponseOfDatasetDto>(
    URLManager.url().apiTrainer + "/datasets/" + datasetId + "?projectId=" + projectId
  );
  return res.data?.data;
};

// update dataset after structure change with last modified date and modified by
export const updateDatasetAfterStructureChange = (datasetId: string, projectId: string | undefined): void => {
  getDatasetById(datasetId, projectId).then(dataset => {
    updateDataset(
      {
        name: dataset?.name,
        description: dataset?.description,
        projectId: projectId,
        public: dataset?.public
      },
      datasetId
    );
  });
};

export const getSignedUrl = async (blobName: string): Promise<BaseResponseOfSignedURLDto> => {
  const res = await http.get<BaseResponseOfSignedURLDto>(URLManager.url().apiTrainer + "/signedURL", {
    params: {
      contentType: "application/octet-stream",
      blobName: encodeURIComponent(blobName),
      signingMethod: "PUT",
      encodedUrl: true
    }
  });
  return res.data;
};

export const uploadFileV2 = async (file: File, signedUrlDto: BaseResponseOfSignedURLDto, authToken: string): Promise<Response> => {
  const formData = new FormData();
  formData.append(file.name, file);
  const uploadUrl = signedUrlDto.data?.url ?? "";
  const headers: { [key: string]: string } = {
    "Content-Type": "application/octet-stream"
  }
  if (signedUrlDto.data?.authRequired) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }
  return fetch(uploadUrl, {
    method: "PUT",
    headers: headers,
    body: file
  });
}

export const uploadFilesP = async (
  blob: BlobMetadata,
  uploadFiles: FileToUpload[],
  authToken: string,
  azureStorageFQDNSuffix: string,
): Promise<Response[] | undefined | void> => {
  if (blob.blobName) {
    const uploadPromises: Response[] = [];
    await Promise.all(
      uploadFiles.map(async file => {
        const signedUrl = await getSignedUrl(blob.blobName + "/" + file.path);
        if (signedUrl && signedUrl.data && signedUrl.data.url) {
          /* Upload to azure storage */
          if (signedUrl.data.url.indexOf(azureStorageFQDNSuffix) > -1) {
            await uploadPackageToAzure(file.file, signedUrl.data.url);
          } else {
            uploadPromises.push(await uploadFileV2(file.file, signedUrl, authToken));
          }
        }
      })
    );
    return uploadPromises;
  } else {
    return Promise.reject(new Error("Dataset file/files upload opeartion failed"));
  }
};

export const uploadFiles = async (
  blob: BlobMetadata,
  uploadFiles: FileToUpload[],
  authToken: string,
  azureStorageFQDNSuffix: string,
): Promise<Response[] | undefined | void> => {
  return new Promise((resolve, reject) => {
    if (blob.blobName) {
      let promisePtr: Promise<Response[] | undefined | void>;
      const batchSize = 20;
      let batches: FileToUpload[][] = [];
      for (let i = 0; i < uploadFiles.length; i += batchSize) {
        batches.push(uploadFiles.slice(i, i + batchSize));
      }

      batches.forEach((uploadFilesBatch: FileToUpload[], index) => {
        if (index === 0) {
          promisePtr = uploadFilesP(blob, uploadFilesBatch, authToken, azureStorageFQDNSuffix)
            .then(() => {
              if (index + 1 === batches.length) {
                resolve();
              }
              return undefined;
              /* Reject Promise if something unexpected happens */
            })
            .catch(error => {
              return reject(new Error("Dataset file/files upload opeartion failed"));
            });
        } else {
          promisePtr = promisePtr.then(() => {
            if (index + 1 === batches.length) {
              resolve();
            }
            return uploadFilesP(blob, uploadFilesBatch, authToken, azureStorageFQDNSuffix);
          });

          /* Reject Promise if something unexpected happens */
          promisePtr.catch(() => {
            reject(new Error("Dataset file/files upload opeartion failed"));
          });
        }
      });
    } else {
      reject(new Error("Dataset file/files upload opeartion failed"));
    }
  });
};

export const copyBlob = async (datasetId: string, copyBlobRequestDto: CopyBlobRequestDto) => {
  const res = await http.post<BaseResponseOfstring>(
    URLManager.url().apiTrainer + "/datasets/" + datasetId + "/copyBlob",
    copyBlobRequestDto
  );
  return res.data;
};
