import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import * as React from 'react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-keycode-enum';

import { bindKeyTo } from '../../utils/a11y';
import logger from '../../utils/Logging';
import type FileToUpload from './FileToUpload';
import { ParseFolder } from './ParseFolder';

interface FileDropZoneProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [field: string]: any;
    accept?: string;
    maxSize?: number;

    /* When multiple is true allow multiple folders or multiple files
  Will be true when folders and files are both true */
    multiple?: boolean;

    /* Setting both folders and files to true allows drag and drop of folders with file siblings
   Allow folder(s); defaults to false */
    folders?: boolean;

    // Allow file(s); defaults to true
    files?: boolean;

    // Disabled form for event like submitting
    disabledDropZone?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '340px',

        '& #dropzone': { overflow: 'hidden' },
    },

    dropzone: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '0.5rem',
        cursor: 'pointer',

        width: '340px',
        height: '108px',
        borderRadius: '4px',
        boxSizing: 'border-box',
        border: `solid 1px ${theme.palette.semantic.colorBorder}`,
    },

    shouldHover: { '&:hover': { border: `solid 1px ${theme.palette.semantic.colorForegroundEmp}` } },

    shouldFocus: {
        boxSizing: 'border-box',
        borderRadius: '4px',
        margin: 0,
        '&:focus': { border: `solid 2px ${theme.palette.semantic.colorForegroundLink}` },
    },

    error: { border: `solid 2px ${theme.palette.semantic.colorErrorText}` },

    upload: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    icon: {
        fontSize: Tokens.FontFamily.FontHeroSize,
        color: theme.palette.semantic.colorForegroundDisable,
        alignSelf: 'center',
    },

    iconOnDrop: {
        fontSize: Tokens.FontFamily.FontHeroSize,
        color: theme.palette.semantic.colorForegroundDisable,
        alignSelf: 'center',
    },

    iconOnError: {
        fontSize: Tokens.FontFamily.FontHeroSize,
        color: theme.palette.semantic.colorForegroundDisable,
        alignSelf: 'center',
    },

    iconOnDisabled: {
        fontSize: Tokens.FontFamily.FontHeroSize,
        color: theme.palette.semantic.colorForegroundDisable,
        alignSelf: 'center',
    },

    content: {
        color: theme.palette.semantic.colorForeground,
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
    },

    activeContent: {
        color: theme.palette.semantic.colorForeground,
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
    },

    errorContent: {
        color: theme.palette.semantic.colorErrorText,
        fontSize: Tokens.FontFamily.FontSSize,
        fontWeight: Tokens.FontFamily.FontWeightDefault,
        margin: '8px 14px 0',
    },
}));

export const FileDropZone: React.FC<FileDropZoneProps> = (
    {
        accept,
        maxSize,
        multiple,
        folders,
        files,
        disabledDropZone,
        ...props
    }) => {

    const {
        form: {
            setFieldValue, errors, touched, setFieldTouched, submitCount,
        },
    } = props;
    const { field: { name } } = props;

    const { t } = useTranslation();
    const defaultMaxSize = 1e11;

    const maxSizeAllowed = maxSize ? maxSize : defaultMaxSize;
    const multipleAllowed = multiple;
    const takeFiles = files === undefined || files;
    const takeFolders = folders === undefined ? false : folders;
    const classes = useStyles();
    const maxNameLength = 20;

    const [ filesToUpload, setFilesToUpload ] = React.useState<FileToUpload[]>([]);
    const [ numItems, setNumItems ] = React.useState(0);
    const drop = React.useRef(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = React.createRef<any>();
    const cb = useCallback(bindKeyTo({
        traps: {
            [Key.Enter]: () => {
                ref?.current?.click();
            },
            [Key.Space]: () => {
                ref?.current?.click();
            },
        },
        ignoreTarget: true,
    }), [ ref ]);
    const error = (errors && errors[name] && (touched && touched[name] === true) || submitCount > 0) ? errors[name] : '';

    const {
        isDragActive, getRootProps, getInputProps, acceptedFiles, rejectedFiles,
    } = useDropzone({
        onDrop: (incomingFiles) => {
            if (takeFiles && !takeFolders) {
                setFilesToUpload(incomingFiles.map(file => ({
                    path: file.name,
                    file,
                } as FileToUpload)));
            }
        },

        onFileDialogCancel: () => {
            setFieldTouched(name, true);
        },

        disabled: disabledDropZone,
        accept,
        minSize: 0,
        maxSize: maxSizeAllowed,
        multiple: multipleAllowed || takeFolders,
    });

    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSizeAllowed;

    React.useEffect(() => {
        if (takeFolders) {
            ref.current.directory = true;
            ref.current.webkitdirectory = true;
        }
    }, [ acceptedFiles, ref ]);

    React.useEffect(() => {
        if (filesToUpload.length > 0) {
            setFieldValue(name, filesToUpload);
        }
    }, [ filesToUpload ]);

    return (
        <section className={classes.container}>
            <div
                id="dropzone"
                {...getRootProps(takeFolders ? {
                    onDrop: (event): void => {
                        drop.current = true;
                        setNumItems(0);
                        const entries = [];
                        const dtItems = event.dataTransfer.items;
                        for (let i = 0; i < dtItems.length; i++) {
                            const entry = dtItems[i].webkitGetAsEntry();
                            if ((!takeFiles && entry?.isDirectory) || takeFiles) {
                                entries.push(entry);
                                if (!multiple) {
                                    break;
                                }
                            }
                        }

                        // Check if there were any valid entries
                        if (!entries.length) {
                            setFieldTouched(name, true);
                        } else {
                            ParseFolder(entries).then(files => {
                                setFilesToUpload(files);
                                setNumItems(entries.length);
                                return true;
                            })
                                .catch((error: Error) => {
                                    logger.error({
                                        identifier: 'FileDropZone',
                                        message: 'Error parsing folders',
                                        error,
                                    });
                                });
                        }
                    },
                    onClick: (): void => ref?.current?.click(),
                } : {
                    onDrop: (event): void => {
                        const dtItems = event.dataTransfer.items;
                        for (let i = 0; i < dtItems.length; i++) {
                            const entry = dtItems[i].webkitGetAsEntry();
                            if (entry?.isDirectory) {
                                break;
                            }
                        }
                    },
                })}
                onKeyDown={cb}
                tabIndex={-1}
                aria-describedby={error ? `${name}-error-label` : ''}
            >
                {takeFolders ?
                    <input
                        {...getInputProps()}
                        ref={ref}
                        id={name}
                        name={name}
                        disabled={disabledDropZone}
                        onChange={(event): void => {
                            const entries: FileToUpload[] = [];
                            const files = event.target.files;
                            if (files && files.length > 0) {
                                for (let i = 0; i < files.length; i++) {
                                    const file = files[i];
                                    entries.push({
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        path: (file as any).webkitRelativePath,
                                        file,
                                    });
                                }
                                setFilesToUpload(entries);
                            }
                        }}
                    />
                    : <input
                        {...getInputProps()}
                        id={name}
                        name={name}
                        disabled={disabledDropZone}
                    />}

                <div
                    tabIndex={0}
                    className={
                        classes.dropzone +
          (error ? ' ' + classes.error : '') +
          ' ' + classes.shouldFocus +
          ' ' + (!disabledDropZone ? (' ' + classes.shouldHover) : '')
                    }>
                    {!error && !isDragActive && rejectedFiles.length === 0 &&
            <div className={classes.upload}>
                <CloudUploadIcon className={disabledDropZone ? classes.iconOnDisabled : classes.icon} />
                {takeFolders ?
                    <div className={numItems === 0 ? classes.content : classes.activeContent}>
                        {filesToUpload.length === 0 ? t('fileDropZone_initialState') :
                            (filesToUpload[0].path.split('/')[0].length < maxNameLength ? filesToUpload[0].path.split('/')[0] : (filesToUpload[0].path.split('/')[0].substring(0, maxNameLength) + '...') +
                      (numItems > 1 ? ` and ${numItems - 1} more` : ''))}
                    </div> :
                    <div className={(acceptedFiles && acceptedFiles.length === 0) ? classes.content : classes.activeContent}>
                        {acceptedFiles && (acceptedFiles.length === 0 ? t('fileDropZone_initialState') :
                            (acceptedFiles[0].name.length < maxNameLength ? acceptedFiles[0].name : (acceptedFiles[0].name.substring(0, maxNameLength) + '...')) +
                    (acceptedFiles.length > 1 ? ` and ${acceptedFiles.length - 1} more` : ''))}
                    </div>}
            </div>}

                    {
                        isDragActive && rejectedFiles.length === 0 &&
            <div className={classes.upload}>
                <CloudUploadIcon className={classes.iconOnDrop} />
                <div className={classes.content}>
                    {t('fileDropZone_hoverState')}
                </div>
            </div>
                    }

                    {
                        (!isDragActive && (error || isFileTooLarge || rejectedFiles.length > 0)) &&
            <div className={classes.upload}>
                <CloudUploadIcon className={classes.iconOnError} />
                <div className={classes.content}>
                    {t('fileDropZone_errorState')}
                </div>
            </div>
                    }
                </div>
            </div>

            { /* Display error */}
            {
                error &&
        <p className={classes.errorContent}>
            {errors[name]}
        </p>
            }
        </section >
    );
};
