import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';

import { usePermissions } from '../../api/global/usePermissions';
import { useProjects } from '../../api/global/useProjects';
import Section from '../../components/Section';
import { AppPermissions } from '../../enums/Authorization';
import { RoutePath } from '../../route/routeMap';
import { RoleAssignmentListing } from './Base';

const ProjectLevel: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [ state ] = useProjects();
    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(state.currentProjectId as string);

    const roleCreationButtons = [];
    if (permissions.indexOf(AppPermissions.Settings_Create) > -1) {
        roleCreationButtons.push({
            buttonText: t('assign_user'),
            buttonCallback: () => {
                history.push(
                    { pathname: generatePath(RoutePath.ProjectRoleAssignment, { projectName: state.currentProjectName }) },
                );
            },
        });
    }

    return (
        <Section
            title={t('user_management_title')}
            buttonItems={roleCreationButtons}
            tooltipText={t('tooltip_user_management_role_type')}
        >
            <RoleAssignmentListing
                level="PROJECT"
                projectId={state.currentProjectId}
                projectName={state.currentProjectName} />
        </Section>
    );
};

export default ProjectLevel;
