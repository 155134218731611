import {
    Button,
    TextField,
} from '@mui/material';
import type { ProjectDto } from '@uipath/aifabric';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
    getLabelStudioTemplate,
    updateLabelStudioTemplate,
} from '../../../api/client/dataLabelling';
import { useExternalScript } from '../../../api/global/useExternalScripts';
import { useExternalStyle } from '../../../api/global/useExternalStyle';
import { useFeedback } from '../../../api/global/useFeedback';
import { usePermissions } from '../../../api/global/usePermissions';
import type {
    ProjectActions,
    ProjectState,
} from '../../../api/global/useProjects';
import { useProjects } from '../../../api/global/useProjects';
import Label from '../../../components/Label';
import Section from '../../../components/Section';
import { WithVisibility } from '../../../components/WithVisibility';
import {
    DATALABELLING_ADVANCED_TAB_TEMPLATE_UPDATE,
    DATALABELLING_GENERAL_TAB_TEMPLATE_UPDATE,
} from '../../../constants/TelemetryConstants';
import { AppPermissions } from '../../../enums/Authorization';
import { extractErrorMessage } from '../../../utils/CommonUtils';
import logger from '../../../utils/Logging';
import { recursiveMultiSearch } from '../../../utils/searchObject';
import {
    parseObjectToXml,
    parseXmlToObject,
} from '../../../utils/xmlParser';
import GeneralEditorDetails from '../details/GeneralEditorDetails';
import { dashboardStyle } from '../LabelingSessionDashboard';
import { initializeLabelStudio } from './initializeLabelStudio';

const assetsPath = process.env.NODE_ENV === 'development' ? '' : 'AIF_APP_ASSETS_PATH';
const labelStudioUrl = assetsPath + '/label-studio/main.js';
const labelStudioCSSrl = assetsPath + '/label-studio/main.css';

export const ConfigureLabelling: React.FC<any> = ({
    isConfigureFirst, isGeneralTab,
}) => {
    const { t } = useTranslation();
    const cssClasses = dashboardStyle();
    useExternalStyle(labelStudioCSSrl);
    const scriptState = useExternalScript(labelStudioUrl);
    const isLabelStudioReady = scriptState === 'ready';
    const [ configureMode, setConfigureMode ] = useState(isConfigureFirst);
    const [ isTabEditable, setIsTabEditable ] = useState(isConfigureFirst || false);
    const openConfigureMode = useCallback(() => {
        setConfigureMode(true);
        setIsTabEditable((prev: boolean) => !prev);
    }, [ setConfigureMode ]);

    const initializeTemplate = () => {
        // eslint-disable-next-line promise/catch-or-return
        getLabelStudioTemplate(appId, projectId).then((r: any) => {
            setTemplate(r.data.template);
            updateGeneralTemplate(r.data.template);
            return true;
        });
    };

    const closeConfigureMode = useCallback(() => {
        initializeTemplate();
        setIsTabEditable((prev: boolean) => !prev);
        return setConfigureMode(false);
    }, [ setConfigureMode ]);

    const locationState: any = useLocation()?.state;
    const appId = locationState?.appId;
    const projectId = locationState?.projectId;

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(currentProject?.id as string);
    const [ _, setLabelStudio ] = useState();

    React.useEffect(() => {
        if (appId) {
            initializeTemplate();
        }
    }, [ appId ]);

    const [ template, setTemplate ] = useState('');
    const [ templateObj, setTemplateObj ] = useState<Record<string, any>>({});
    const [ searchedTemplateObj, setSearchedTemplateObj ] = useState<Record<string, any>>();
    const [ isEdited, setIsEdited ] = useState<boolean>(false);
    const feedback = useFeedback();

    const updateGeneralTemplate = (updatedTemplateObj: string): void => {
        const parsedXML = parseXmlToObject(updatedTemplateObj);
        setSearchedTemplateObj(recursiveMultiSearch(parsedXML, [ 'Choices', 'Labels', 'Text' ]));
        setTemplateObj(parsedXML);
    };

    const submitConfiguration = useCallback(() => {
        updateLabelStudioTemplate(appId, projectId, template).then(() => {
            setConfigureMode(false);
            setIsTabEditable((prev: boolean) => !prev);
            updateGeneralTemplate(template);
            feedback.enqueueSuccess('Template updated');

            const telemetryPayload = {
                appId,
                accountId: currentProject?.accountId,
                tenantId: currentProject?.tenantId,
                projectId,
            };

            if (isGeneralTab) {
                /* Add telemtery events when the template is updated with general tab */
                logger.customEvents(
                    {
                        identifier: DATALABELLING_GENERAL_TAB_TEMPLATE_UPDATE,
                        message: 'Data Labeling Template updated through General Tab',
                        payload: { ...telemetryPayload },
                    },
                    DATALABELLING_GENERAL_TAB_TEMPLATE_UPDATE,
                );
            } else {
                /* Add telemtery events when the template is updated with advanced tab */
                logger.customEvents(
                    {
                        identifier: DATALABELLING_ADVANCED_TAB_TEMPLATE_UPDATE,
                        message: 'Data Labeling Template updated through Advanced Tab',
                        payload: { ...telemetryPayload },
                    },
                    DATALABELLING_ADVANCED_TAB_TEMPLATE_UPDATE,
                );
            }

            return true;
        })
            .catch((error: any) => {
                feedback.enqueueError(extractErrorMessage(error, 'Error updating template', { 72004: {} }));
                return false;
            });
    }, [ setConfigureMode, template ]);

    useEffect(() => {
        if (!configureMode || !template) {
            return;
        }
        if (!isLabelStudioReady) {
            return;
        }
        setLabelStudio(initializeLabelStudio(template));
    }, [ template, configureMode, isLabelStudioReady ]);

    useEffect(() => {
        const xmlObj = templateObj;
        const parsedXML = parseObjectToXml(xmlObj);
        setIsEdited(false);
        setTemplate(parsedXML);
    }, [ isEdited === true ]);

    useEffect(() => {
        setLabelStudio(initializeLabelStudio(template));
    }, [ isTabEditable, template ]);

    return (<Section>
        <div
            id='labeling-configuration'
            className={cssClasses.configureTab}>
            <div id="tab-content">
                <div className={cssClasses.configureWrapper}>
                    {isGeneralTab ?
                        (<div className={cssClasses.templateGeneral}>
                            <GeneralEditorDetails
                                templateObj={templateObj}
                                setTemplateObj={setTemplateObj}
                                searchedTemplateObj={searchedTemplateObj}
                                isEdited={isEdited}
                                setIsEdited={(): void => setIsEdited(true)}
                                isTabEditable={isTabEditable} />
                        </div>) :
                        (<div className={cssClasses.templateInput}>
                            <TextField
                                multiline
                                data-testid="reconfigure-textarea"
                                maxRows={20}
                                value={template}
                                onChange={(event): void => {
                                    setTemplate(event.target.value);
                                }}
                                InputProps={{
                                    'inputProps': {
                                        'aria-label': t('a11y_template_text'),
                                        disabled: !isTabEditable,
                                    },
                                }}
                            />
                        </div>)}
                    <div className={cssClasses.templatePreview}>
                        <div id="label-studio" />
                    </div>
                </div>
                <WithVisibility visible={!isTabEditable}>
                    <div
                        id="warning-text"
                        className={cssClasses.warningText}>
                        <Label value={t('reconfigure-warning-title')} />
                        {t('reconfigure-warning-text')}
                        <WithVisibility visible={permissions.indexOf(AppPermissions.DataLabeling_Edit) > -1}>
                            <Button
                                onClick={openConfigureMode}
                                data-testid="reconfigure"
                                aria-label="reconfigure"
                                variant="outlined"
                                style={{
                                    display: 'block',
                                    marginTop: '1em',
                                }}>
                                {t('reconfigure-session')}
                            </Button>
                        </WithVisibility>
                    </div>
                </WithVisibility>
                <WithVisibility visible={isTabEditable}>
                    <Button
                        className={cssClasses.templateSubmit}
                        onClick={submitConfiguration}
                        data-testid="reconfigure-submit"
                        aria-label={t('form_done_button_text')}
                        variant="outlined">
                        Done
                    </Button>
                    <Button
                        onClick={closeConfigureMode}
                        data-testid="reconfigure-cancel"
                        aria-label={t('form_cancel_button_text')}
                        variant="outlined">
                        Cancel
                    </Button>
                </WithVisibility>
            </div>
        </div>
    </Section >);
};
