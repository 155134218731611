import type { MLSkillDto } from '@uipath/aifabric';
import type { AxiosResponse } from 'axios';
import enLocale from 'date-fns/locale/en-US';
import jaLocale from 'date-fns/locale/ja';
// @ts-ignore | no ts def by author, but this is what crontab.guru uses
import prettycron from 'prettycron';
import React from 'react';

import type { MLPackageVersionDetailsData } from '../../src/pages/mlPackageVersion/MLPackageVersionsPage';
import type { RequestedMemory } from '../constants/AiappConstants';
import {
    DATASET_CREATE_DUPLICATE_ERROR_CODE,
    DATASET_CREATE_DUPLICATE_SUCCESS_RESP,
    DEFAULT_RESOURCE_PER_REPLICA_CONFIG,
    RESOURCE_PER_REPLICA_CONFIG,
} from '../constants/AiappConstants';
import Keys from '../constants/FeatureManagerKeys';
import type {
    Origin,
    Scope,
    Service,
} from '../enums/ClientErrorStrings';
import type FeatureFlagManager from '../feature-flag/FeatureFlagManager';
import { http } from '../http';
import i18n from '../il8n';
import ExceptionMapper from './ExceptionMapper';
import logger from './Logging';

const localeMap: {
    [k: string]: Locale;
} = {
    en: enLocale,
    ja: jaLocale,
};

const UUID_REGEX = '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$';

const checkIfValueExists = (value: string | undefined): string => {
    if (typeof value === 'undefined' || !value) {
        return '--';
    }
    return value;
};

export const getMLPackageVersionDetailData = (original: any): MLPackageVersionDetailsData => {
    const mlPackageVersionDetailData: MLPackageVersionDetailsData = {};
    mlPackageVersionDetailData.changeLog = original.changeLog;
    mlPackageVersionDetailData.inputType = original.inputType;
    mlPackageVersionDetailData.inputDescription = original.inputDescription;
    mlPackageVersionDetailData.outputDescription = original.outputDescription;
    mlPackageVersionDetailData.recommendGpu = original.gpu;
    mlPackageVersionDetailData.enableTraining = original.retrainable;
    mlPackageVersionDetailData.mlPackage = original.mlPackageName;
    mlPackageVersionDetailData.version = original.version;
    mlPackageVersionDetailData.trainingVersion = original.trainingVersion;
    mlPackageVersionDetailData.customVersion = original.customVersion;
    return mlPackageVersionDetailData;
};

// todo: Vipin to fix this any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const calculateDuration = (original: any): string => {
    if (original && typeof original !== 'undefined') {
        const startedOn: number = original.startedOn;
        const completedOn: number = original.completedOn;

        if (startedOn && completedOn) {
            const seconds = Math.ceil((completedOn - startedOn) / 1000);

            if (seconds < 600) {
                return seconds + ' s';
            } else if (seconds >= 600 && seconds < 36000) {
                return Math.ceil(seconds / 60) + ' m';
            } else if (seconds >= 36000 && seconds < 864000) {
                return Math.ceil(seconds / (60 * 60)) + ' h';
            }
            return Math.ceil(seconds / (24 * 60 * 60)) + ' d';

        }
    }
    return '--';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFileOrFolderNameForPath = (data: any): string => {
    if (!data || typeof data === 'undefined' || !data.cell.value) {
        return '';
    }

    return getFileOrFolderNameForPathFromString(data.cell.value, data.cell.row.original.contentType);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFileOrFolderNameForPathFromString = (path: string, contentType: string): string => {
    let value: string;

    if (contentType) {
        value = path.slice(path.lastIndexOf('/') + 1);
    } else {
        value = path.slice(0, path.length - 1);
        value = value.slice(value.lastIndexOf('/') + 1);
    }
    return value;
};

const downloadMLPackageVersionMetadata = (content: any, fileName: string, contentType: string): void => {
    const link = document.createElement('a');
    const file = new Blob([ content ], { type: contentType });
    link.href = URL.createObjectURL(file);
    link.target = '_blank';
    link.download = fileName;
    link.click();
};

const downloadContentWithSignUrl = (url: string, isInstanceProfileEnabled: boolean, authToken: string, azureStorageFQDNSuffix: string): void => {
    http.get(url).then((res: AxiosResponse) => {
        const link = document.createElement('a');
        link.setAttribute('download', 'download');
        link.target = '_blank';
        const signedUrlData = res.data.data;
        let signedUrl = res.data.data.url;
        if (signedUrlData?.authRequired) {
            const iFrame: HTMLIFrameElement = document.createElement('iframe');
            iFrame.setAttribute('style', 'display: none');

            const form: HTMLFormElement = document.createElement('form');
            form.setAttribute('action', signedUrl);
            form.setAttribute('method', 'post');

            const input: HTMLInputElement = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', 'BearerToken');
            input.setAttribute('value', authToken);

            form.appendChild(input);
            iFrame.appendChild(form);

            document.body.append(iFrame);
            form.submit();
            document.body.removeChild(iFrame);
        } else {
            /* In case of azure, url is coming as encoded   */
            if (!isInstanceProfileEnabled && (signedUrl.indexOf(azureStorageFQDNSuffix) > -1 || signedUrl.indexOf('%') > -1)) {
                signedUrl = decodeURIComponent(signedUrl);
            }

            const path = signedUrl.substr(0, signedUrl.indexOf('?'));
            const queryParam = signedUrl.substr(signedUrl.indexOf('?'));
            link.href = encodeURI(path).concat(queryParam);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        return true;
    })
        .catch((error: Error) => {
            logger.error({
                identifier: 'Download content',
                message: 'Error while getting signed URL',
                error,
            });
        });
};

/**
 * Copies given text to clipboard
 * @param text
 */
const copyToClipBoard = (text: string | undefined): void => {
    const textField = document.createElement('textarea');
    textField.innerText = text || '';
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
};

const dataFormatter = (data: string | undefined, locale?: string): string => {
    if (typeof data === 'undefined' || !data || data.length <= 0) {
        return '';
    }

    data = data.split('_').join(' ');
    if (!locale || locale === 'en') {
        return data.charAt(0).toLocaleUpperCase() + (data.length > 1 ? data.substr(1).toLowerCase() : '');
    }
    return data;
};

/**
 * Used for mapping backend enity data
 * @param key
 * @param type
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const entityDataMapper = (key: string | undefined, type: Record<string, any>): string => {
    if (typeof key === 'undefined' || !key) {
        return '';
    }

    const data: string = type[key];
    return data ? data : '';
};

/**
 *
 * @param createdBy
 */
const returnValueIfNotUUID = (value: string | undefined): string => {
    if (typeof value === 'undefined' || !value || value.length <= 0) {
        return '';
    }
    return value.match(UUID_REGEX) ? '' : value;
};

interface Dictionary {
    [k: number]: {
        [c: string]: string;
    };
}

class CustomError extends Error {
    response: any;

    constructor(message: string, response: any) {
        super(message);
        this.response = response;
    }
}

/**
 * This method return message from error messgae if any, othersewise default message
 *
 * @param error
 * @param defaultMessage
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractErrorMessage = (error: any, defaultMessage: string, dictionary: Dictionary = {}): any => {
    const errorCode =
        error?.message === DATASET_CREATE_DUPLICATE_SUCCESS_RESP
            ? DATASET_CREATE_DUPLICATE_ERROR_CODE
            : getBackendErrorCode(error);
    return errorCode && dictionary[errorCode]
        ? i18n.t(ExceptionMapper(errorCode), dictionary[errorCode])
        : defaultMessage;
};

export const extractKeyValuePairs = (inputString: string): string => {
    // Regular expression pattern to match key->value pairs after "pairs are:"
    const pattern: RegExp = /pairs are: (.*?),$/;

    // Use RegExp.exec() to find the match and optional chaining to access the result
    const match = pattern.exec(inputString)?.[1];

    return match ?? ''; // Return the extracted key-value pairs as a string or an empty string if no match is found
};

export const getBackendErrorCode = (error: any) => error && error.response && error.response.data && error.response.data.respCode;

export const getDisplayErrorCode = (
    scope: Scope,
    svc: Service,
    origin: Origin,
    error?: any,
    httpCode?: number | string,
) => `AICNTR_${scope}_${svc}_${origin}_${getBackendErrorCode(error) || 'XX'}_${httpCode || 'XX'}`;

const shouldReverseAMPM = (): boolean => i18n.language === 'ja';

const padIfNecessary = (n: number): string => `${n < 10 ? '0' + n : n}`;

const formatAMPM = (date: Date): string => {
    if (!date) {
        return '';
    }
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? i18n.t('PM') : i18n.t('AM');
    hours = hours % 12;
    hours = hours ? hours : 12;
    const label = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    return shouldReverseAMPM() ? `${ampm} ${label}` : `${label} ${ampm}`;
};

export const labelFunc = (date: any): string => formatAMPM(date);

export const labelFuncWithDate = (date: any): string => `${date.toLocaleDateString()} ${formatAMPM(date)}`;

const setCookie = (cname: string, cvalue: string, expiry: number, path = '/'): void => {
    const expires = new Date(expiry);
    document.cookie = cname + '=' + cvalue + ';expires=' + expires.toUTCString() + ';path=' + path;
};

const isPublicCapable = (
    skill: MLSkillDto,
    isOnPrem: boolean,
    isPublicTenant: boolean,
    featureFlagManager: FeatureFlagManager,
): boolean => {
    const isPrivateTenant = !isPublicTenant;
    if (isOnPrem) {
        return true;
    }
    if (isPrivateTenant && featureFlagManager) {
        if (skill.mlPackageLanguageGroup === 'DU') {
            return featureFlagManager.isEnabled(Keys.APIAuthSkillsEnabled);
        }
        return featureFlagManager.isEnabled(Keys.APIAuthSkillsEnabledNonPublic);
    }
    return false;
};

export const computeMlSkillResourcePerReplica = (requestedMemory?: RequestedMemory): number => requestedMemory
    ? RESOURCE_PER_REPLICA_CONFIG[requestedMemory].unitvalue
    : DEFAULT_RESOURCE_PER_REPLICA_CONFIG.unitvalue;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractErrorFields = (error: any): string => {
    let errorFields = '';
    if (error && error.response && error.response.data && error.response.data.respMsg) {
        const allFields = error.response.data.respMsg.match(/\[(.*?)\]/);
        errorFields = allFields ? allFields[1] : errorFields;
    }
    return errorFields;
};

export const isPublicEndpointEnabled = (isOnPrem: boolean, featureFlagManager: FeatureFlagManager): boolean => {
    if (isOnPrem) {
        return featureFlagManager.isEnabled(Keys.PublicDS);
    }
    return true;
};

export const isBlobEncryptionEnabled = (featureFlagManager: FeatureFlagManager): boolean => featureFlagManager.isEnabled(Keys.BlobEncryptionEnabled);

export const isPortalShellV3Enabled = (featureFlagManager: FeatureFlagManager): boolean => featureFlagManager?.isEnabled(Keys.EnablePortalShellV3);

export const isMlSkillReportDownloadEnabled = (featureFlagManager: FeatureFlagManager): boolean => featureFlagManager?.isEnabled(Keys.mlSkillReportDownloadEnabled);

export const isPipelineReportDownloadEnabled = (featureFlagManager: FeatureFlagManager): boolean => featureFlagManager?.isEnabled(Keys.pipelineReportDownloadEnabled);

const getCronStr = (cron: string): string => prettycron.toString(cron);

const nextRecurrence = (days: number, date: Date | null): string => {
    if (!date) {
        return '';
    }
    const d = new Date(date.valueOf());
    d.setDate(d.getDate() + days);
    return `${padIfNecessary(d.getDate())}-${padIfNecessary(d.getMonth() + 1)}-${d.getFullYear()}, ${formatAMPM(date)}`;
};

const ADMINISTRATORS_GUID = '35551807-06b1-4cda-90a1-2fb84851eee7';
const AUTOMATION_USERS_GUID = 'cdc34b5b-77d2-4ae1-9744-209d21ce557d';
const AUTOMATION_DEVELOPERS_GUID = '4d161738-7204-4794-b839-8f7fed28366c';
const EVERYONE_GUID = 'ce684f6f-5af3-4e43-8516-1adad6e98fc9';

const DIRECTORY_USER = 'DirectoryUser';
const DIRECTORY_GROUP = 'DirectoryGroup';

const getObjectType = (type: any) => {
    if (type === 1) {
        return DIRECTORY_GROUP;
    }
    if (type === DIRECTORY_GROUP) {
        return DIRECTORY_GROUP;
    }
    return DIRECTORY_USER;
};

export {
    ADMINISTRATORS_GUID,
    AUTOMATION_DEVELOPERS_GUID,
    AUTOMATION_USERS_GUID,
    calculateDuration,
    checkIfValueExists,
    copyToClipBoard,
    CustomError,
    dataFormatter,
    downloadContentWithSignUrl,
    downloadMLPackageVersionMetadata,
    downloadReport,
    entityDataMapper,
    EVERYONE_GUID,
    getCronStr,
    getFileOrFolderNameForPath,
    getFileOrFolderNameForPathFromString,
    getObjectType,
    isPublicCapable,
    localeMap,
    nextRecurrence,
    returnValueIfNotUUID,
    setCookie,
};

export const LinkText = (props: any) => (
    <a
        href={props.to || '#'}
        target="_blank"
        rel="noopener noreferrer"
        title={props.title || ''}>
        {props.children}
    </a>
);

interface DownloadResult {
    success: boolean;
    error?: Error;
}

function downloadReport(url: string): Promise<DownloadResult> {
    return http.get(url, { responseType: 'blob' })
        .then((res: AxiosResponse) => {
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'file.txt'; // Default filename if not found in the response

            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="(.*?)"/);
                filename = filenameMatch?.[1] || filename;
            }

            const blob = new Blob([ res.data ], { type: res.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.setAttribute('download', filename);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Clean up the object URL
            window.URL.revokeObjectURL(url);
            return { success: true };
        })
        .catch((error: Error) => {
            logger.error({
                identifier: 'Download Pipeline Report',
                message: 'Error while getting pipeline report',
                error,
            });
            return {
                success: false,
                error,
            };
        });
}
