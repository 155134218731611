import {
    Box,
    Container,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useEffect,
    useState,
} from 'react';

import { findIndexOfKey } from '../../../utils/searchObject';
import ClassificationTasks from './ClassificationTasks';
import NerTasks from './NerTasks';

interface GeneralEditorDetailsProps {
    templateObj: Record<string, any>;
    setTemplateObj: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    searchedTemplateObj?: Record<string, any>;
    isEdited: boolean;
    setIsEdited: () => void;
    isTabEditable: boolean;
}

const styles = makeStyles(() =>
    createStyles({ container: { overflow: 'auto' } }),
);

const GeneralEditorDetails: React.FC<GeneralEditorDetailsProps> = ({
    templateObj, setTemplateObj, searchedTemplateObj, isEdited, setIsEdited, isTabEditable,
}) => {
    const cssClasses = styles();

    const [ choices, setChoices ] = useState(searchedTemplateObj ? searchedTemplateObj['Choices'] : []);
    const [ labels, setLabels ] = useState(searchedTemplateObj ? searchedTemplateObj['Labels'] : []);
    const [ inputTypes, setInputTypes ] = useState<string[]>([]);

    const createViewObject = (tempObj: any, attrName: string, attrObj: any): any[] => {
        const oldViewObj = tempObj;
        const newViewObj: Record<string, any> = { '@_style': 'padding: 0 1em; margin: 1em 0; background: #f1f1f1; position: sticky; top: 0; border-radius: 3px' };
        newViewObj[`${attrName}`] = attrObj;
        const viewArr = [];
        viewArr.push(newViewObj);
        viewArr.push(oldViewObj);
        return viewArr;
    };

    useEffect(() => {
        if (searchedTemplateObj) {
            setLabels(searchedTemplateObj ? searchedTemplateObj['Labels'] : []);
            setChoices(searchedTemplateObj ? searchedTemplateObj['Choices'] : []);
            if (searchedTemplateObj['Text']) {
                const { Text } = searchedTemplateObj;
                const inputTypesArr: string[] = inputTypes;
                Text.forEach((text: any) => {
                    inputTypesArr.push(text['@_name']);
                });
                setInputTypes(inputTypesArr);
            }
        }
    }, [ searchedTemplateObj ]);

    useEffect(() => {
        const newTemplateObj: Record<string, any> = templateObj;
        if (!newTemplateObj || newTemplateObj === undefined || Object.keys(newTemplateObj).length === 0) {
            return;
        }
        let choicesObj = [];
        if (choices !== undefined && choices.length !== 0) {
            choicesObj = choices.length > 1 ? [ ...choices ] : choices[0];
        }
        const index = findIndexOfKey(newTemplateObj['View']['View'], 'Choices', 'Labels');
        if (index === -1) {
            newTemplateObj['View']['View'] = createViewObject(newTemplateObj['View']['View'], 'Choices', choicesObj);
        } else {
            newTemplateObj['View']['View'][index]['Choices'] = choicesObj;
        }
        setTemplateObj(newTemplateObj);
    }, [ choices && isEdited === true ]);

    useEffect(() => {
        const newTemplateObj: Record<string, any> = templateObj;
        if (!newTemplateObj || newTemplateObj === undefined || Object.keys(newTemplateObj).length === 0) {
            return;
        }
        let labelsObj = [];
        if (labels !== undefined && labels.length !== 0) {
            labelsObj = labels.length > 1 ? [ ...labels ] : labels[0];
        }
        const index = findIndexOfKey(newTemplateObj['View']['View'], 'Labels', 'Choices');
        if (index === -1) {
            newTemplateObj['View']['View'] = createViewObject(newTemplateObj['View']['View'], 'Labels', labelsObj);
        } else {
            newTemplateObj['View']['View'][index]['Labels'] = labelsObj;
        }
        setTemplateObj(newTemplateObj);
    }, [ labels && isEdited === true ]);

    return (
        <Container className={cssClasses.container}>
            <Box mt={2}>
                <NerTasks
                    labels={labels}
                    setLabels={setLabels}
                    inputTypes={inputTypes}
                    setIsEdited={setIsEdited}
                    isTabEditable={isTabEditable}
                />
            </Box>
            <Box mt={2}>
                <ClassificationTasks
                    choices={choices}
                    setChoices={setChoices}
                    inputTypes={inputTypes}
                    setIsEdited={setIsEdited}
                    isTabEditable={isTabEditable}
                />
            </Box>
        </Container>
    );
};

export default GeneralEditorDetails;
