import React, {
    useCallback,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';

import { getIsAIUnitEnabled } from '../../api/client/deployerManagerClient';
import { selfMigrateTenant } from '../../api/client/pkgManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import { usePermissions } from '../../api/global/usePermissions';
import { PermissionState } from '../../api/global/usePermissions.d';
import type { MenuItemProps } from '../../components/Menu';
import { AppPermissions } from '../../enums/Authorization';
import {
    getAccountAndTenantFromCannonicalPath,
    isCannonicalPath,
} from '../../route/routeHelper';
import { RoutePath } from '../../route/routeMap';
import { UserActions } from '../../state-management/Actions';
import { store } from '../../state-management/store';
import getInstance from '../../utils/AppInsights';
import {
    ADMINISTRATORS_GUID,
    extractErrorMessage,
} from '../../utils/CommonUtils';
import { getAvailableLanguageCodeList } from '../../utils/langUtils';
import logger from '../../utils/Logging';
import type { AiCenterAppProps } from './App.d';
import { appStyle } from './App.style';
import PortalShellV3 from './PortalShellV3';

export const AiCenterApp: React.FC<AiCenterAppProps> = ({
    loggedIn,
    isOnPrem,
    isErrored,
    logoutCallback,
    onTenantChanged,
    themeChangeCallback,
    installType,
    portalNavEnabled,
    accountId,
    userGroupIds,
    isTenantMigrated,
}) => {
    const classes = appStyle();
    const {
        t, i18n,
    } = useTranslation();
    const history = useHistory();

    const languages = getAvailableLanguageCodeList();
    const {
        account, tenant,
    } = getAccountAndTenantFromCannonicalPath();
    const [ state, permissionActions ] = usePermissions();
    const feedback = useFeedback();
    const isLoggedInAndPortalNavDisabled = loggedIn && (portalNavEnabled === false);
    const isOOBPackgeImportEnabled = installType && installType === 'offline';

    const [ isAiUnitEnabled, setIsAiUnitEnabled ] = React.useState<any>(false);
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [ permissions, setPermissions ] = useState<any[]>([]);
    const [ showUpdateToNewAccessControl, setShowUpdateToNewAccessControl ] = useState(false);
    const [ isUpdateToNewAccessControlDialogOpen, setUpdateToNewAccessControlDialogOpen ] = React.useState(false);

    const closeUpdateToNewAccessControlDialog = useCallback((): void => {
        setUpdateToNewAccessControlDialogOpen(false);
    }, [ isUpdateToNewAccessControlDialogOpen ]);

    const toggleLoginState = useCallback((): void => {
        setAnchorEl(null);

        if (loggedIn) {
            store.dispatch({
                type: UserActions.LOGOUT_INITIATED,
                payload: null,
            });
        } else {
            store.dispatch({
                type: UserActions.LOGIN_INITIATED,
                payload: null,
            });
        }
    }, [ store, loggedIn ]);

    const updateTenantToNewAccessControl = useCallback((): void => {
        selfMigrateTenant().then(() => {
            feedback.enqueueSuccess(t('feedback_update_to_new_access_control'));
            return true;
        })
            .finally(() => {
                closeUpdateToNewAccessControlDialog();
            })
            .catch((error) => {
                feedback.enqueueError(extractErrorMessage(error, t('feedback_update_to_new_access_control_error')));
            });
    }, [ isUpdateToNewAccessControlDialogOpen ]);

    const items: MenuItemProps[] = [];

    if (permissions.indexOf(AppPermissions.Profile_View) > -1) {
        items.push({
            idx: 'profile_page_label',
            label: t('profile_page_label'),
            onClick: (event: React.MouseEvent<HTMLLIElement>): void => {
                handleClose();
                event.stopPropagation();
                history.push(RoutePath.PROFILE_PAGE);
            },
        });
    }

    if ((permissions.indexOf(AppPermissions.Licenses_View) > -1) && (isAiUnitEnabled !== true)) {
        items.push({
            idx: 'license_usage',
            label: t('license_usage'),
            onClick: (event: React.MouseEvent<HTMLLIElement>): void => {
                handleClose();
                event.stopPropagation();
                history.push(RoutePath.LICENSES_DETAILS);
            },
        });
    }

    if (permissions.indexOf(AppPermissions.Users_View) > -1) {
        items.push({
            idx: 'user_management_label',
            label: t('user_management_label'),
            onClick: (event: React.MouseEvent<HTMLLIElement>): void => {
                handleClose();
                event.stopPropagation();
                history.push(RoutePath.TenantUserManagement);
            },
        });
    }

    if (showUpdateToNewAccessControl) {
        items.push({
            idx: 'update_to_new_access_control',
            label: t('update_to_new_access_control'),
            onClick: (event: React.MouseEvent<HTMLLIElement>): void => {
                handleClose();
                event.stopPropagation();
                setUpdateToNewAccessControlDialogOpen(true);
            },
        });
    }

    if (isOOBPackgeImportEnabled) {
        items.push({
            idx: 'import_oob_package',
            label: t('import_oob_package_label'),
            onClick: (event: React.MouseEvent<HTMLLIElement>): void => {
                handleClose();
                event.stopPropagation();
                history.push(RoutePath.IMPORT_MLPACKAGE_OOB_LOGS);
            },
        });
    }
    if (isLoggedInAndPortalNavDisabled) {
        items.push({
            idx: loggedIn ? 'logout' : 'login',
            label: t(loggedIn ? 'logout' : 'login'),
            onClick: toggleLoginState,
            dataCY: 'logout',
        });
        items.push({
            label: 'Languages',
            disabled: true,
            idx: 'language',
        });
        languages.forEach((lang) => {
            items.push({
                label: t(`language_name_${lang}`),
                onClick: () => {
                    updateLang(lang);
                },
                dataCY: 'langSwitcher',
                idx: `language_name_${lang}`,
            });
        });
    }

    let link = '';

    if (isCannonicalPath()) {
        link = window.location.origin + generatePath('/:account/:tenant/aifabric_/ai-helper/swagger-ui/index.html', {
            account,
            tenant,
        });
    }

    const handleClick = useCallback((event: any): void => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback((): void => {
        setAnchorEl(null);
    }, []);

    React.useEffect(() => {
        const userGroupIdsArr = userGroupIds ? Array.from(userGroupIds) : [];
        const isUpdateToNewAccessControlVisible: boolean = !isOnPrem && !isTenantMigrated && userGroupIdsArr.length !== 0 && userGroupIdsArr.indexOf(ADMINISTRATORS_GUID) !== -1;
        setShowUpdateToNewAccessControl(isUpdateToNewAccessControlVisible);
    }, [ isOnPrem, isTenantMigrated, userGroupIds ]);

    React.useEffect(() => {
        if (state.tenantData.permissionState === PermissionState.LOADED) {
            setPermissions(permissionActions.getTenantPermissions());
        }
    }, [ state.tenantData, state.tenantData.permissionState ]);

    React.useEffect(() => {
        if (accountId !== undefined) {
            getIsAIUnitEnabled(accountId)
                .then((res) => {
                    setIsAiUnitEnabled(res?.data);
                    return res?.data;
                })
                .catch((error) => {
                    logger.error({
                        identifier: 'Core',
                        message: t('ml_aiunitenabled_fetch_error_message'),
                        error,
                    });
                    return false;
                });
        }
    }, [ accountId ]);

    const updateLang = useCallback((lang: string) => {
        // send event for update lang.
        const tracker = getInstance();
        tracker?.setLanguage(lang);

        localStorage.setItem('language', lang);
        if (lang === 'keys') {
            i18n.changeLanguage('cimode');
        } else {
            i18n.changeLanguage(lang);
        }
        document.documentElement.lang = lang;
    }, [ i18n ]);

    const langChangeCallback = useCallback((e: any) => {
        if (e?.detail?.selectedLanguageId) {
            updateLang(e?.detail?.selectedLanguageId);
        }
    }, [ updateLang ]);

    return (
        <div className={classes.root}>
            <PortalShellV3
                loggedIn={loggedIn}
                isErrored={isErrored}
                isOnPrem={isOnPrem}
                logoutCallback={logoutCallback}
                onTenantChanged={onTenantChanged}
                themeChangeCallback={themeChangeCallback}
                langChangeCallback={langChangeCallback}
                updateLang={updateLang}
                items={items}
                link={link}
                handleClick={handleClick}
                showUpdateToNewAccessControl={showUpdateToNewAccessControl}
                isUpdateToNewAccessControlDialogOpen={isUpdateToNewAccessControlDialogOpen}
                closeUpdateToNewAccessControlDialog={closeUpdateToNewAccessControlDialog}
                updateTenantToNewAccessControl={updateTenantToNewAccessControl}
                anchorEl={anchorEl}
                handleClose={handleClose}
            />
        </div>
    );
};
