import {
    Tab,
    Tabs,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TooltipContainer from '../../../components/AccessibleTooltip/TooltipContainer';
import { TabPanel } from '../LabelingSessionDashboard';
import { ConfigureLabelling } from '../tabs/ConfigureTab';

interface DataLabellingTabProps {
    isConfigureFirst: boolean;
}

type handleChangeFunc = (event: React.ChangeEvent<{}>, newValue: number) => void;

const dashboardStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    }),
);

enum TabTypes {
    General,
    AdvancedEditor,
}

const DataLabellingTab: React.FC<DataLabellingTabProps> = ({ isConfigureFirst }) => {

    const { t } = useTranslation();
    const [ value, setValue ] = React.useState(TabTypes.General);
    const cssClasses = dashboardStyle();

    const handleChange: handleChangeFunc = (_: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={cssClasses.root}>
            <Tabs
                color="default"
                indicatorColor="primary"
                textColor="primary"
                value={value}
                onChange={handleChange}
                aria-label={t('a11y_data_labeling_configure_tab_label')}>
                <Tab
                    label={t('general-tab')}
                    data-testid="general-tab" />
                <Tab
                    label={<TooltipContainer title={t('tooltip_data_labelling_advanced_editor')}>
                        <div>
                            {t('advanced-editor-tab')}
                        </div>
                    </TooltipContainer>}
                    data-testid="advanced-editor-tab" />
            </Tabs>
            <TabPanel
                value={value}
                index={TabTypes.General}>
                <ConfigureLabelling
                    isConfigureFirst={isConfigureFirst}
                    isGeneralTab />
            </TabPanel>
            <TabPanel
                value={value}
                index={TabTypes.AdvancedEditor}>
                <ConfigureLabelling
                    isConfigureFirst={isConfigureFirst}
                    isGeneralTab={false} />
            </TabPanel>
        </div>
    );
};

export default DataLabellingTab;
