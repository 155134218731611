import {
    FormHelperText,
    MenuItem,
    Select,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { FieldAttributes } from 'formik';
import {
    Field,
    useField,
} from 'formik';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { COLOUR_LIST } from '../constants/AiappConstants';

const colorDropDownStyles = makeStyles(() =>
    createStyles({ formHelperText: { color: '#ff8484' } }),
);

const ColorDropdownField: React.FC<FieldAttributes<{}>> = ({ ...props }) => {
    const [ field, meta ] = useField<{}>(props);
    const colorList = COLOUR_LIST;
    const errorText = meta.error && meta.touched ? meta.error : '';
    const cssClasses = colorDropDownStyles();

    return (
        <>
            <Field
                {...field}
                type='select'
                as={Select}
                error={!!errorText}
                labelId="color-label">
                {colorList.map((color: string) => (
                    <MenuItem
                        key={uuid()}
                        value={color}>
                        {color}
                    </MenuItem>
                ))}
            </Field>
            {errorText ? <FormHelperText className={cssClasses.formHelperText}>
                {errorText}
            </FormHelperText> : null}
        </>
    );
};

export default ColorDropdownField;
