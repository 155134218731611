import GetAppSharpIcon from '@mui/icons-material/GetAppSharp';
import {
    Button,
    IconButton,
    LinearProgress,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { ProjectDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React, {
    useCallback,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getPipelineRunLogs } from '../../api/client/pipelineManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import Section from '../../components/Section';
import URLManager from '../../config/URLManager';
import { BaseTableIcons } from '../../enums/BaseTableIcons';
import type FeatureFlagManager from '../../feature-flag/FeatureFlagManager';
import {
    downloadContentWithSignUrl,
    downloadReport,
    extractErrorMessage,
    isPipelineReportDownloadEnabled,
} from '../../utils/CommonUtils';
import logger from '../../utils/Logging';

interface PipelineRunLogsProps {
    currentProject: ProjectDto | undefined;
    isInstanceProfileEnabled: boolean;
    featureManager: FeatureFlagManager;
    azureStorageFQDNSuffix: string;
}

const pipelineLogStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        width: '100%',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontFamily: Tokens.FontFamily.FontNormal,

        '& .pipelineRunLogBlock': {
            marginTop: '12px',
            marginBottom: '15px',
            borderRadius: '2px',
            width: '100%',
            border: `solid 1px ${theme.palette.semantic.colorBorder}`,
            boxShadow: 'none',
            height: 'auto',
            maxHeight: '350px',
            overflowY: 'scroll',

            '& .runLogs': {
                color: theme.palette.semantic.colorForegroundDeEmp,
                padding: '12px',
                whiteSpace: 'pre-line',
                lineHeight: Tokens.FontFamily.FontLLineHeight,
            },
        },

        '& .downloadBlock': {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '20px',

            '& .downloadIcon': {
                marginLeft: '10px',
                marginTop: '-4px',
            },

            '& .partialLogsText': {
                fontFamily: Tokens.FontFamily.FontNormal,
                color: theme.palette.semantic.colorForeground,
                fontWeight: 600,
                paddingTop: '4px',
            },
        },
    },
}));

export const PipelineRunLogs: React.FC<PipelineRunLogsProps> = ({
    currentProject, isInstanceProfileEnabled, featureManager, azureStorageFQDNSuffix,
}) => {

    const [ runLogs, setRunLogs ] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [ isDownloadPipelineRunLogsButtonDisabled, setIsDownloadPipelineRunLogsButtonDisabled ] = useState(true);
    const classes = pipelineLogStyles();
    const feedback = useFeedback();
    const authToken = useSelector((state: any) => state.auth.authToken);

    const { t } = useTranslation();
    const { pipelineRunId } = useParams<{ pipelineRunId: string }>();
    const downloadRef = useRef<HTMLDivElement | null>(null);

    const fetchOrUpdateLogs = useCallback(() => {
        if (currentProject?.id) {
            setLoading(true);
            return getPipelineRunLogs(pipelineRunId, currentProject?.id)
                .then((res) => {
                    setLoading(false);
                    setRunLogs(res?.runLogs ? res?.runLogs : '');
                    setIsDownloadPipelineRunLogsButtonDisabled(res?.availableForDownload ? false : true);
                    return true;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                })
                .catch((error: any) => {
                    logger.error({
                        identifier: 'Pipeline logs',
                        message: 'Error getting pipeline logs',
                        error,
                    });
                    setLoading(false);
                    feedback.enqueueError(extractErrorMessage(error, t('pipeline_get_run_logs_failed_default_error')));
                });
        }
        return Promise.resolve();
    }, []);

    const fetchOrUpdateLogsAndFocus = useCallback(() => {
        fetchOrUpdateLogs().then(() => {
            if (downloadRef?.current?.scrollIntoView) {
                downloadRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            return true;
        })
            .catch((error) => {
                logger.error({
                    identifier: 'Pipeline logs',
                    message: 'Error while scrolling',
                    error,
                });
            });
    }, [ fetchOrUpdateLogs, downloadRef ]);

    React.useEffect(() => {
        fetchOrUpdateLogs();
    }, [ currentProject ]);

    const downlaodPipelineRunLogs = (): void => {
        const url = URLManager.url().apiTrainer + '/signedURL?blobName=logs/' + pipelineRunId + '/' + pipelineRunId + '.log&signingMethod=GET&projectId=' + currentProject?.id;
        downloadContentWithSignUrl(url, isInstanceProfileEnabled, authToken, azureStorageFQDNSuffix);
    };

    const downloadFullPipelineReport = async () => {
        const url = URLManager.url().apiTrainer + '/runs/runReport/' + pipelineRunId + '?projectId=' + currentProject?.id;
        const downloaded = await downloadReport(url);
        if (downloaded.success) {
            feedback.enqueueSuccess(t('feedback_pipeline_report_download_success'));
        } else {
            feedback.enqueueError(extractErrorMessage(
                downloaded.error, t('feedback_pipeline_report_download_failure_reason'),
                { 10009: {} },
            ));
        }
    };

    return (
        <div>
            {runLogs && runLogs.length > 0 ? (
                <Section
                    title={t('pipeline_logs_text')}
                    iconsButtons={[
                        {
                            type: BaseTableIcons.REFRESH,
                            click: fetchOrUpdateLogsAndFocus,
                        },
                    ]}
                >
                    {
                        isPipelineReportDownloadEnabled(featureManager) ?
                            (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    aria-label={t('a11y_download')}
                                    onClick={downloadFullPipelineReport}
                                    data-testid="pipeline-download-btn"
                                    data-cy="Download pipeline_report_button"
                                >
                                    {t('pipeline_data_detail_download')}
                                </Button>
                            ) : (<></>)
                    }
                    <div className={classes.root}>
                        <div className="pipelineRunLogBlock">
                            {
                                loading === true ?
                                    <LinearProgress color="secondary" /> :
                                    <div className="runLogs">
                                        {runLogs}
                                    </div>
                            }
                        </div>

                        {
                            loading === false ?
                                <div
                                    className="downloadBlock"
                                    ref={downloadRef}>
                                    <div className="partialLogsText">
                                        {t('pipeline_data_detail_showing_partial_log_text')}
                                    </div>

                                    <div className="downloadIcon">
                                        <IconButton
                                            aria-label={t('a11y_download_logs')}
                                            disabled={isDownloadPipelineRunLogsButtonDisabled}
                                            onClick={downlaodPipelineRunLogs}>
                                            <GetAppSharpIcon
                                                data-testid="run-logs-download-button"
                                                data-cy="run-logs-download-button" />
                                        </IconButton>
                                    </div>
                                </div> : null
                        }
                    </div>
                </Section>
            ) : null}
        </div>
    );
};
