import AddIcon from '@mui/icons-material/Add';
import {
    Box,
    IconButton,
    Typography,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { WithVisibility } from '../../../components/WithVisibility';

interface TaskHeaderProps {
    headerText: string;
    handleOpen?: () => void;
    isTabEditable: boolean;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        headerBox: {
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 'bold',
        },
        headerText: { marginTop: theme.spacing(1) },
    }),
);

const TaskHeader: React.FC<TaskHeaderProps> = ({
    headerText, handleOpen, isTabEditable,
}) => {
    const cssClasses = styles();
    const { t } = useTranslation();

    return <Box className={cssClasses.headerBox}>
        <Typography
            className={cssClasses.headerText}
            variant='h4'>
            {headerText}
        </Typography>
        <WithVisibility visible={isTabEditable}>
            <IconButton
                data-testid='add-new-task-btn'
                color="primary"
                aria-label={t('a11y_add_new_task')}
                component="span"
                onClick={handleOpen}>
                <AddIcon color='primary' />
            </IconButton>
        </WithVisibility>
    </Box>;
};

export default TaskHeader;
