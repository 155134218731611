import {
  BaseResponseOfProjectDto,
  ProjectRequestDto,
  ProjectDto,
  BaseResponseOfPageListingDtoOfProjectDto,
  BaseResponseOfstring,
  BaseResponseOfRBACProjectDto, ListingDtoOfRBACProjectDto
} from "@uipath/aifabric"
import { http } from "../../http"

import URLManager from "../../config/URLManager";
import { AxiosResponse } from "axios";

export const updateProject = async (updateProject: ProjectRequestDto, projectId: string): Promise<ProjectDto | undefined> => {
  const res = await http.post<ProjectRequestDto, AxiosResponse<BaseResponseOfProjectDto>>(URLManager.url().apiPkgManager + "/projects/" + projectId, updateProject);
  return res.data.data;
}

export const createProject = async (createRequest: ProjectRequestDto): Promise<ProjectDto | undefined> => {
  const res = await http.post<ProjectRequestDto, AxiosResponse<BaseResponseOfProjectDto>>(URLManager.url().apiPkgManager + "/projects", createRequest);
  return res.data.data;
}

const checkUniqueProjectByName = async (projectName: string, projectId?: string): Promise<Boolean | undefined> => {
  const res = await http.get<Boolean>(URLManager.url().apiPkgManager + "/projects/search?name=" + projectName + "&projectId=" + projectId);
  return res.data;
}

export const getProjects = async (pageNum: number, pageSize: number) => {
  const res = await http.get<BaseResponseOfPageListingDtoOfProjectDto>(URLManager.url().apiPkgManager + `/projects?pageNum=${pageNum}&pageSize=${pageSize}`);
  return res.data?.data;
}


export const getProjectsRBAC = async () : Promise<ListingDtoOfRBACProjectDto | undefined> => {
  const res = await http.get<BaseResponseOfRBACProjectDto>(URLManager.url().apiPkgManager + `/projects/user`);
  return res.data?.data;
}


const getAllProjects = async () => {
  const res = await http.get<BaseResponseOfPageListingDtoOfProjectDto>(URLManager.url().apiPkgManager + "/projects?pageSize=10000");
  return res.data?.data?.dataList;
}

export const deleteProject = async (projectId: string) => {
  const res = await http.delete<AxiosResponse<BaseResponseOfstring>>(URLManager.url().apiPkgManager + `/projects/${projectId}`);
  return res.data;
}
