import type {
    CombinedState,
    Store,
} from 'redux';
import {
    applyMiddleware,
    createStore,
} from 'redux';
import { createLogger } from 'redux-logger';

import type { ActionType } from './Actions';
import { reducers } from './reducers';
import type { AppStore } from './store.d';

const logger = createLogger({});
const store: Store<CombinedState<AppStore>, ActionType> = process.env.NODE_ENV === 'development' ? createStore(reducers, applyMiddleware(logger)) : createStore(reducers);

export { store };

export default store;
