import LinkIcon from '@mui/icons-material/Link';
import {
    IconButton,
    Link,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useProjects } from '../../api/global/useProjects';
import { ContextMenu } from '../../components/Menu';
import { WithVisibility } from '../../components/WithVisibility';
import { Configuration } from '../../enums/Configuration';
import {
    getAccountAndTenantFromCannonicalPath,
    isCannonicalPath,
} from '../../route/routeHelper';
import type { PortalShellV3Props } from './App.d';
import { appStyle } from './App.style';
import AppContent from './AppContent';
import ToastNotificationProvider from './ToastNotificationProvider';
import { UpdateAccessControlDialog } from './UpdateAccessControlDialog';

const PortalShellV3: React.FC<PortalShellV3Props> = ({
    loggedIn,
    isErrored,
    isOnPrem,
    portalNavEnabled,
    logoutCallback,
    onTenantChanged,
    themeChangeCallback,
    configState,
    items,
    updateLang,
    langChangeCallback,
    link,
    handleClick,
    showUpdateToNewAccessControl,
    isUpdateToNewAccessControlDialogOpen,
    closeUpdateToNewAccessControlDialog,
    updateTenantToNewAccessControl,
    anchorEl,
    handleClose,
    authConfig,
    isFirstParty,
    isOnStandalone,
}) => {
    const { t } = useTranslation();
    const classes = appStyle();
    const [ homeUrl, setHomeUrl ] = useState<string>('');

    const [ state ] = useProjects();
    const projectName = React.useMemo(() => state.currentProject?.name, [ state ]);

    React.useEffect(() => {
        document.addEventListener('languageChanged', langChangeCallback);
        document.addEventListener('logout', logoutCallback);
        document.addEventListener('tenantChanged', onTenantChanged);
        document.addEventListener('themeChanged', themeChangeCallback);
    }, [ isOnPrem, updateLang ]);

    React.useEffect(() => {
        if (configState === Configuration.APPREADY && typeof portalNavEnabled !== undefined) {
            // event handling for portal-app-bar component
            document.addEventListener('tenantChanged', onTenantChanged);
        }
    }, [ isOnPrem, configState, portalNavEnabled ]);

    React.useEffect(() => {
        const {
            account, tenant,
        } = getAccountAndTenantFromCannonicalPath(!isFirstParty);
        let homeUrlTemp = `${authConfig?.config?.redirectUrl?.replace(':account', account)?.replace(':tenant', tenant)}`;
        homeUrlTemp = homeUrlTemp?.substring(0, homeUrlTemp.lastIndexOf('/'));
        setHomeUrl(homeUrlTemp);
    }, [ authConfig ]);

    return (
        <div className={classes.leftRail}>
            <ap-shell
                product-name={`${t('portal_nav_aifabric_title')}`}
                feature={projectName || ''}
                focus-mode={!portalNavEnabled}
                logo-click-href={`${homeUrl}`}
                skip-to-target="#page-title"
                showPreferences={!isOnStandalone}
            >
                <div
                    slot="slot-end"
                    className={classes.slotEnd}>
                    {isCannonicalPath() && (
                        <div className={classes.swaggerLinkContainer}>
                            <Link
                                href={link}
                                target="_blank"
                                className={classes.swaggerLink}>
                                <LinkIcon className={classes.swaggerLinkIcon} />
                                <div>
                                    {t('api_documentation')}
                                </div>
                            </Link>
                        </div>
                    )}
                    {loggedIn ? (
                        <div className={classes.licenseUsageButton}>
                            <IconButton
                                aria-label={t('a11y_app_options')}
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <i className="pt-material-icons-outlined material-icons-outlined">
                                    more_vert
                                </i>
                            </IconButton>
                        </div>
                    ) : null}
                </div>
                <div className={classes.content}>
                    <ToastNotificationProvider>
                        <WithVisibility visible={configState === Configuration.APPREADY && typeof portalNavEnabled !== undefined}>
                            <UpdateAccessControlDialog
                                showUpdateToNewAccessControl={showUpdateToNewAccessControl}
                                isUpdateToNewAccessControlDialogOpen={isUpdateToNewAccessControlDialogOpen}
                                closeUpdateToNewAccessControlDialog={closeUpdateToNewAccessControlDialog}
                                updateTenantToNewAccessControl={updateTenantToNewAccessControl}
                            />
                        </WithVisibility>
                        <ContextMenu
                            id="license-menu"
                            style={{ marginLeft: '10px' }}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            keepMounted
                            anchorReference="anchorEl"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handleClose}
                            items={items}
                        />
                        <AppContent
                            loggedIn={loggedIn}
                            isErrored={isErrored} />
                    </ToastNotificationProvider>
                </div>
            </ap-shell>
        </div>
    );
};

export default connect((state: any) => ({
    isOnStandalone: state.config.paths ? state.config.paths.isOnStandalone : false,
    isHostAdmin: !!state.auth.isHostAdmin,
    configState: state.config.state,
    portalNavEnabled: !!state.config.paths?.portalNavEnabled,
    authConfig: state.config.paths ? state.config.paths.authConfig : {},
    isFirstParty: !!state.config.paths.isFirstParty,
}))(PortalShellV3);
