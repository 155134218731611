// eslint-disable-next-line simple-import-sort/imports
import queryString from 'querystring';

import {
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FilterList from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import type {
    AxiosResponse,
    CancelTokenSource,
} from 'axios';
import axios from 'axios';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import type { ChangeEvent } from 'react';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';

import { useFeedback } from '../../../api/global/useFeedback';
import getPageSize from '../../../config/Pagination';
import {
    Origin,
    Scope,
    Service,
} from '../../../enums/ClientErrorStrings';
import { http } from '../../../http';
import { getDisplayErrorCode } from '../../../utils/CommonUtils';
import type { Icon } from '../../../utils/IconsInterface';
import logger from '../../../utils/Logging';
import type {
    FiltersObj,
    PaginationArgs,
    SelectionItem,
} from '../BaseTable/BaseTable';
import Table from '../BaseTable/BaseTable';

interface ServerSideTableProps {
    url: string;
    totalKey: string;
    mapper: Column[];
    dataKey: string;
    showRefresh?: boolean;
    contextMenuItems?: ContextMenuItem[];
    isSelectAble?: boolean;
    searchable?: boolean;
    hasFilters?: boolean;
    searchKey?: string;
    selectionItems?: SelectionItem;
    alwaysFilterVisible?: true;
    tooltip?: string;
    icons?: Icon[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onTableCellClicked?(row: any, event: any): any;
    totalCountListener?(count: number): void;
    itemDeletedFlag?: boolean;
    sortDict?: {
        [key: string]: (key: string) => string;
    };
    itemChangeIsPublicFlag?: boolean;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRowHoverTooltip?: ((row: any) => string) | string ;
    defaultPageSize?: number;
    onItemSelection?: any;
    entityName?: string;
    keyColumnIndex?: number;
    level?: string;
}

interface ContextMenuItem {
    text: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    click?: (event: React.MouseEvent<HTMLLIElement>, data: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disable?: ((row: any) => boolean) | boolean;
}

interface QueryParamsPagination {
    [filterKey: string]: string | number | string[] | undefined;
}

const useStyles = makeStyles((theme) => ({
    topSection: { background: theme.palette.semantic.colorBackgroundSecondary },
    topContainer: {
        width: '100%',
        display: 'flex',
        background: theme.palette.semantic.colorBackgroundSecondary,
        height: '54px',
        alignItems: 'center',

        '& .MuiTextField-root': {
            background: theme.palette.background.paper,
            marginLeft: '14px',
        },

        '& .MuiInputAdornment-root': { color: '#0067DF' },

        '& input': { background: theme.palette.background.paper },

        '& .filters': { marginLeft: '10px' },
    },
    searchBox: { '&::placeholder': { color: theme.palette.semantic.colorForeground } },
}));

const ServerSideTable: React.FC<ServerSideTableProps> = (
    {
        url,
        mapper,
        dataKey,
        contextMenuItems,
        isSelectAble,
        icons,
        onTableCellClicked,
        totalCountListener,
        totalKey,
        selectionItems,
        searchable,
        searchKey,
        itemDeletedFlag,
        itemChangeIsPublicFlag,
        hasFilters,
        alwaysFilterVisible,
        sortDict = {},
        name,
        showRefresh = true,
        onRowHoverTooltip,
        onItemSelection,
        entityName = '',
        keyColumnIndex,
        level = '',
    }) => {

    const feedback = useFeedback();
    const [ data, setData ] = useState([]);
    const [ total, setTotal ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ refresh, setRefresh ] = useState(false);
    const [ empty, setEmpty ] = useState(false);

    const entries = queryString.parse(url.split('?')[1]);

    const [ paginationArgs, setPaginationArgs ] = useState<QueryParamsPagination>({
        pageSize: getPageSize(level),
        pageNum: 1,
        ...entries,
    });

    const refreshButtonClicked = (): void => setRefresh(prev => !prev);

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ cancelToken, setCancelToken ] = useState<CancelTokenSource | null>(null);
    const [ debouncedSearchTerm, setDebouncedSearchTerm ] = useState('');
    const [ isfiltersVisible, setFiltersVisible ] = useState(alwaysFilterVisible || false);

    const debounceAction = debounce(() => {
        setDebouncedSearchTerm(searchTerm);
    }, 500);

    const { t } = useTranslation();
    const classes = useStyles();

    const loadData = debounce(() => {
        setLoading(true);
        const CancelToken = http.CancelToken;
        const source = CancelToken.source();
        setCancelToken(source);
        const params: { [key: string]: string | undefined | number | string[] } = { ...paginationArgs };

        if (params.pageNum === 1) {
            delete params.pageNum;
        }

        if (searchKey && debouncedSearchTerm) {
            params[searchKey] = debouncedSearchTerm;
        }

        http.get(url.split('?')[0], {
            params,
            cancelToken: source.token,
        }).then((response: AxiosResponse) => {
            let responseData = response.data;
            let totalDataSet = response.data;
            dataKey.split('.').forEach(key => (responseData = responseData[key]));
            totalKey.split('.').forEach(key => (totalDataSet = totalDataSet[key]));
            if (!responseData || !responseData.length) {
                setEmpty(true);
                setData([]);
            } else {
                setEmpty(false);
                setData(responseData);
                if (totalCountListener) {
                    totalCountListener(typeof totalDataSet === 'number' ? totalDataSet : 0);
                }
                setTotal(typeof totalDataSet === 'number' ? totalDataSet : 0);
            }
            setLoading(false);
            return true;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        })
            .catch((error: any) => {
            /** Don't do anything if request is cancelled by debounce action */
                if (!axios.isCancel(error)) {
                    logger.error({
                        identifier: 'Server side table',
                        message: 'Error making API call',
                        error,
                        payload: params,
                        backendCode: getDisplayErrorCode(Scope.Core, Service.JS, Origin.TABLE, error, error?.response?.status),
                    });
                    setEmpty(true);
                    setData([]);
                    setLoading(false);
                    feedback.enqueueError(t('feedback_table_loading_error'));
                }
            });
    }, 10);

    useEffect(() => {
        debounceAction();
        return function cleanup(): void {
            debounceAction.cancel();
        };
    }, [ searchTerm ]);

    useEffect(() => {
        loadData();
        return function cleanup(): void {
            loadData.cancel();
            if (cancelToken) {
                cancelToken.cancel();
            }
        };
    }, [ paginationArgs, dataKey, totalKey, refresh, debouncedSearchTerm, itemDeletedFlag, itemChangeIsPublicFlag ]);

    const paginate = useCallback((args: PaginationArgs): void => {
        const newArgs: QueryParamsPagination = { ...entries };
        newArgs.pageSize = args.pageSize;
        newArgs.pageNum = args.pageIndex + 1;
        if (args.sortBy && args.sortDirection && args.sortBy !== 'undefined') {
            newArgs.sortBy = sortDict[args.sortBy] ? sortDict[args.sortBy](args.sortBy) : args.sortBy;
            newArgs.sortOrder = args.sortDirection;
        }

        if (args.filters && args.filters.length) {
            args.filters.forEach((filterObj: FiltersObj) => {
                newArgs[filterObj.value.apiField] = filterObj.value.value;
            });
        }

        if (!isEmpty(paginationArgs) && !isEqual(newArgs, paginationArgs)) {
            setPaginationArgs({ ...newArgs });
        }
    }, [ entries, paginationArgs ]);

    return (
        <div
            className={classes.topSection}
            aria-label={name || t('a11y_grid_label')}>
            {
                (searchable || hasFilters) ? (
                    <div className={classes.topContainer}>
                        {searchable ? (<TextField
                            data-cy="search"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            name="search-input"
                            placeholder={t('search_query_text')}
                            type="text"
                            value={searchTerm}
                            autoComplete="off"
                            onChange={(event: ChangeEvent<HTMLInputElement>): void => setSearchTerm(event.target?.value)}
                            InputProps={{
                                classes: { input: classes.searchBox },
                                'inputProps': { 'aria-label': t('a11y_grid_search_field', { entityName }) },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }} />) : null}

                        {hasFilters ? (<div className="filters">
                            <IconButton
                                color="secondary"
                                aria-label={t('a11y_open_filter_label')}
                                onClick={(): void => setFiltersVisible(!isfiltersVisible)}
                                component="span"
                            >
                                <FilterList />
                            </IconButton>
                        </div>) : null}
                    </div>
                ) : null
            }
            <Table
                data={data}
                defaultSortBy={`${entries.sortBy}`}
                defaultSortOrder={`${entries.sortOrder}`}
                columns={mapper}
                empty={empty}
                onPagination={paginate}
                isLoading={loading}
                contextMenuItems={contextMenuItems}
                isSelectAble={isSelectAble}
                total={total}
                icons={icons}
                showRefresh={showRefresh}
                refreshButtonClicked={refreshButtonClicked}
                onTableCellClicked={onTableCellClicked}
                selectionItems={selectionItems}
                isfiltersVisible={isfiltersVisible}
                onRowHoverTooltip={onRowHoverTooltip}
                onItemSelection={onItemSelection}
                entityName={entityName}
                keyColumnIndex={keyColumnIndex}
                level={level}
            />
        </div>
    );
};

export default ServerSideTable;
