import {
    Input,
    Typography,
} from '@mui/material';
import {
    DateTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
    localeMap,
    nextRecurrence,
} from '../../utils/CommonUtils';
import {
    addMinutes,
    getUtcDate,
} from '../../utils/DateFormatter';
import Label from '../Label';
import type { CronDialogFooterProps } from './CronInputFooter';
import { CronDialogFooter } from './CronInputFooter';

interface RecurringCronBuilderProps extends CronDialogFooterProps {
    onRecurrenceDaysChange: (evt: any, time: Date) => void;
    recurrenceDays: number;
    time: Date;
    setTime: (time: any) => void;
}

const isDateAtLeastFiveMinutesInFuture = (date: Date) => {
    const d = addMinutes(new Date(), 5);
    d.setSeconds(0);

    // so that we do not modify actual date
    const dateCopy = new Date(+date);
    dateCopy.setSeconds(0);

    return +dateCopy >= +d;
};

const RecurrenceInfo: React.FC<{
    recurrence: number;
    time: Date;
}> = ({
    recurrence, time,
}) => {
    const { t } = useTranslation();
    /*  The date that is passed to this component is in UTC.
      So to find the number of days between the current time and the passed in time,
      we find the current time in UTC,
      convert the difference between the current time in UTC and the passed in time to days.
      We use Math.ceil so that we also get the next day
      Example: 11 AM Mar 1 as previous day and 11.02 AM Mar 2 as current day should display the 11 AM March 3 as the next run date
      We then compute the next run date using the elapsed days computed above.
      There are 2 scenarios here:
        1. daysElaped is a multiple of recurrence number
            In this case, we add back the remanining number to make it a multiple of recurrence
        2. daysElapsed is not a multiple of recurrence number
            We can use the computed elapsed days as it is
    */
    const currentDate = getUtcDate(new Date());
    const daysElapsed = ((currentDate.valueOf() - time.valueOf()) > 0 ?
        Math.ceil((currentDate.valueOf() - time.valueOf()) / (3600 * 24 * 1000)) : 0);
    let daysToAdd = daysElapsed;
    if (daysElapsed % recurrence > 0) {
        daysToAdd += recurrence - (daysElapsed % recurrence);
    }
    return (
        <>
            <div>
                <Typography color="textSecondary">
                    {t('cron_first_run')}
                </Typography>
                <div className="hintText">
                    <Typography color="textSecondary">
                        {recurrence > 0 ? nextRecurrence(0, time) : '--'}
                    </Typography>
                </div>
            </div>
            <div>
                <Typography color="textSecondary">
                    {t('cron_next_run')}
                </Typography>
                <div className="hintText">
                    <Typography
                        data-testid="next-run-time"
                        color="textSecondary">
                        {recurrence > 0 ? nextRecurrence(+daysToAdd, time) : '--'}
                    </Typography>
                </div>
            </div>
        </>
    );
};

export const RecurringCronBuilder: React.FC<RecurringCronBuilderProps> = ({
    recurrenceDays,
    onRecurrenceDaysChange,
    time,
    setTime,
    callback,
    closeDialog,
}) => {
    const {
        i18n, t,
    } = useTranslation();
    const [ isDirty, setIsDirty ] = useState(false);
    const [ isTimeDirty, setIsTimeDirty ] = useState(false);
    // minimum date has to be at least 5 minutes from now
    const minDate = useMemo(() => (addMinutes(new Date(), new Date().getTimezoneOffset() + 5)), []);

    const onSubmit = useCallback(() => {
        if (recurrenceDays < 1) {
            setIsDirty(true);
            return;
        }
        if (!isDateAtLeastFiveMinutesInFuture(time)) {
            setIsTimeDirty(true);
            return;
        }
        callback();
    }, [ callback, recurrenceDays, time ]);

    return (
        <>
            <div className="flexed">
                <div className="time-container custom-input">
                    <Label
                        value="Start Time"
                        aria-label="Start Time label" />
                    <LocalizationProvider
                        adapterLocale={localeMap[i18n.language]}
                        dateAdapter={AdapterDateFns}
                    >
                        <DateTimePicker
                            minDate={minDate}
                            slotProps={{
                                textField: {
                                    'aria-label': t('a11y_start_time'),
                                    variant: 'filled',
                                    size: 'small',
                                },
                            }}
                            className="timePicker"
                            value={time}
                            onChange={(timePickerVal: any): void => {
                                setIsTimeDirty(true);
                                setTime(timePickerVal);
                            }}
                            name="time"
                        />
                    </LocalizationProvider>
                    {/* Using this method as default minDate of time picker only checks date */}
                    {
                        (isTimeDirty && (!isDateAtLeastFiveMinutesInFuture(time))) ? (
                            <div className="errorContent">
                                {t('time_validation_gt_five_min_UTC')}
                            </div>) : null
                    }
                </div>
                <div className="custom-input">
                    <Label
                        htmlFor="recurrence-days"
                        value="Recur Every"
                        aria-label="Recur Every" />
                    <Input
                        name="recur-date"
                        type="number"
                        data-testid="recurrence-input"
                        onChange={(evt: any) => {
                            setIsDirty(true);
                            onRecurrenceDaysChange(evt, time);
                        }}
                        inputProps={{
                            'id': 'recurrence-days',
                            'aria-label': t('a11y_cron_days', { number: recurrenceDays }),
                        }}
                        value={recurrenceDays}
                        endAdornment={t('label_cron_days')} />
                    {(isDirty && (recurrenceDays < 1)) ? (
                        <div
                            className="errorContent"
                            data-testid="recurrence-zero-error">
                            {t('recurrence_validation_gt_zero')}
                        </div>) : null}
                </div>

            </div>
            <div className="flexInputContainer scheduled-container">
                <RecurrenceInfo
                    recurrence={recurrenceDays}
                    time={time} />
            </div>
            <CronDialogFooter
                callback={onSubmit}
                closeDialog={closeDialog} />
        </>
    );
};
