import {
    Button,
    LinearProgress,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface FormButtonGroupProps {
    dirty: boolean;
    isSubmitting: boolean;
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleReset?: (e?: React.SyntheticEvent<any> | undefined) => void;
    submitButtonText?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    group: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '24px',
    },
    buttons: { flexGrow: 0 },
    alertContainer: { flexGrow: 1 },
    alert: { backgroundColor: theme.palette.semantic.colorWarningBackground },
}));

const FormButtonGroup: React.FC<FormButtonGroupProps> = ({
    dirty, isSubmitting, handleReset, submitButtonText, disabled,
}) => {

    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const submitLabel = submitButtonText ? submitButtonText : t('form_create_button_text');
    return (
        <div className={classes.root}>
            <div>
                {isSubmitting ? <LinearProgress
                    color="secondary"
                    id="progressBar" /> : null}
            </div>
            <div className={classes.group}>
                <div className={classes.buttons}>
                    <Button
                        style={{ minWidth: '88px' }}
                        id="submitButton"
                        data-cy="submitButton"
                        variant="contained"
                        disableRipple
                        type="submit"
                        aria-label={submitLabel}
                        disabled={isSubmitting || disabled}>
                        {submitLabel}
                    </Button>
                    {handleReset && <Button
                        aria-label={t('form_reset_button_text')}
                        id="resetButton"
                        data-cy="resetButton"
                        variant="contained"
                        disableRipple
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting || disabled}
                    >
                        {t('form_reset_button_text')}
                    </Button>}
                    <Button
                        aria-label={t('form_cancel_button_text')}
                        style={{
                            marginLeft: '8px',
                            minWidth: '88px',
                        }}
                        id="cancelButton"
                        data-cy="cancelButton"
                        disableRipple
                        variant="outlined"
                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                        onClick={() => history.goBack()}
                        disabled={isSubmitting || disabled}
                    >
                        {t('form_cancel_button_text')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FormButtonGroup;
