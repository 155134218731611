import { makeStyles } from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React from 'react';

const useTableStyles = makeStyles(theme => ({
    tableHeading: {
        fontSize: Tokens.FontFamily.FontHeader4Size,
        lineHeight: Tokens.FontFamily.FontHeader4LineHeight,
        color: theme.palette.semantic.colorForeground,
    },
}));
export const TableHeading: React.FC = ({ children }) => {
    const classes = useTableStyles();
    return <h4 className={classes.tableHeading}>
        {children}
    </h4>;
};
