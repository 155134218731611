import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type {
    DatasetDto,
    ProjectDto,
} from '@uipath/aifabric';
import {
    Field,
    Formik,
} from 'formik';
import { Switch } from 'formik-mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';
import * as Yup from 'yup';

import {
    createDataset,
    getDatasetByExactName,
    updateDatasetAfterStructureChange,
    uploadFiles,
} from '../../../api/client/datasetManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import type {
    ProjectActions,
    ProjectState,
} from '../../../api/global/useProjects';
import { useProjects } from '../../../api/global/useProjects';
import AccessibleFormikInput from '../../../components/AccessibleFormikInput';
import TooltipContainer
    from '../../../components/AccessibleTooltip/TooltipContainer';
import { FileDropZone } from '../../../components/fileDropzone/FileDropZone';
import type FileToUpload from '../../../components/fileDropzone/FileToUpload';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormikErrorLabels from '../../../components/FormikErrorLabels';
import FormLayout from '../../../components/FormLayout';
import Label from '../../../components/Label';
import { AZURE_SAS_TOKEN_SUBS_STRING } from '../../../constants/BlobStorageConstants';
import { Validation as val } from '../../../constants/validation';
import type FeatureFlagManager from '../../../feature-flag/FeatureFlagManager';
import { RoutePath } from '../../../route/routeMap';
import {
    isBlobEncryptionEnabled,
    isPublicEndpointEnabled,
} from '../../../utils/CommonUtils';

const useStyles = makeStyles(() => createStyles({
    root: { width: '100%' },
    label: {
        display: 'block',
        marginTop: '15px',
        marginBottom: '5px',
    },
    buttonBar: { marginTop: '30px' },
    customTooltipClass: { marginTop: '20px' },
}));

export enum ItemType {
    FILES = 'files',
    FOLDER = 'folder',
}

interface UploadItemsProps {
    isOnPrem: boolean;
    featureManager: FeatureFlagManager;
    authToken: string;
    azureStorageFQDNSuffix: string;
}

export const UploadItemsFC: React.FC<UploadItemsProps> = ({
    isOnPrem, featureManager, authToken, azureStorageFQDNSuffix,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        datasetId, datasetFolderName, currentLocation, itemType,
    } = useParams<{ datasetId: string; datasetFolderName: string; currentLocation: string; itemType: string }>();
    const history = useHistory();
    const feedback = useFeedback();

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    uploadDropzone: [] as FileToUpload[],
                    uploadDescription: '',
                    publicDS: false,
                    encryptedDS: false,
                }}
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onSubmit={async values => {
                    try {

                        if (!datasetId) {
                            if (values.uploadDropzone.length < 1 ||
                                !values.uploadDropzone[0] ||
                                !values.uploadDropzone[0].path ||
                                !values.uploadDropzone[0].path.includes('/')) {
                                throw new Error(t('upload_folder_invalid_folder_error'));
                            }

                            const name = values.uploadDropzone[0].path.split('/')[0];
                            let responseDataset: DatasetDto | undefined;

                            /* Get List of dataest matching with exact name */
                            const matchingdatasets: DatasetDto[] | undefined =
                                await getDatasetByExactName(currentProject?.id, name);

                            /* If dataset is already created then we will just replace content in storage */
                            if (matchingdatasets && matchingdatasets?.length > 0) {
                                responseDataset = matchingdatasets[0];
                            } else {
                                /* Lets Create dataset first and then upload content */
                                responseDataset = await createDataset({
                                    name,
                                    description: values.uploadDescription,
                                    projectId: currentProject?.id,
                                    public: values.publicDS,
                                    encrypted: values.encryptedDS,
                                });
                            }

                            feedback.enqueueInProgressIndefinite(t('feedback_upload_in_progress'));

                            // Remove folder name because its the dataset name now
                            const files = values.uploadDropzone.map(f => ({
                                ...f,
                                path: f.path.substring(name.length + 1),
                            } as FileToUpload));

                            // Add empty file to folder
                            // addEmptyFileToFolders(files);

                            await uploadFiles(
                                { blobName: currentProject?.id + '/' + responseDataset?.id },
                                files, authToken, azureStorageFQDNSuffix,
                            );

                            feedback.closeSnackBar(t('feedback_upload_in_progress'));
                            feedback.enqueueSuccess((matchingdatasets && matchingdatasets?.length > 0) ?
                                t('feedback_update_success_dataset') : t('feedback_create_success_dataset'));

                            // eslint-disable-next-line
                            history.push(generatePath(RoutePath.DATASET_FOLDER_VIEW, {
                                datasetId: responseDataset?.id,
                                datasetFolderName: responseDataset?.name,
                                projectName: currentProject?.name,
                                currentLocation: responseDataset?.name,
                            }));

                        } else {
                            let basePath = '';
                            if (currentLocation && datasetFolderName) {
                                const path = decodeURIComponent(currentLocation).substring(datasetFolderName.length);
                                basePath = (path && path.startsWith('/')) ? path.substring(1) : path;
                            }

                            const files = values.uploadDropzone.map(file => ({
                                ...file,
                                path: basePath ? basePath + '/' + file.path : file.path,
                            } as FileToUpload));

                            // Add empty file to folder
                            // addEmptyFileToFolders(files);

                            feedback.enqueueInProgressIndefinite(t('feedback_upload_in_progress'));

                            await uploadFiles(
                                { blobName: currentProject?.id + '/' + datasetId },
                                files, authToken, azureStorageFQDNSuffix,
                            );

                            feedback.closeSnackBar(t('feedback_upload_in_progress'));
                            feedback.enqueueSuccess(t('feedback_upload_success'));

                            // update dataset with last modified date
                            updateDatasetAfterStructureChange(datasetId, currentProject?.id);

                            history.push(generatePath(RoutePath.DATASET_FOLDER_VIEW, {
                                datasetId,
                                datasetFolderName,
                                projectName: currentProject?.name,
                                currentLocation,
                            }));
                        }
                    } catch (error) {
                        /* Close the indefinate feedback */
                        feedback.closeSnackBar(t('feedback_upload_in_progress'));
                        feedback.enqueueError(t('upload_default_error'));
                    }
                }}
                validationSchema={Yup.object().shape({

                    uploadDropzone: Yup.array()
                        .test('check ds name', '', function(this: Yup.TestContext, value: FileToUpload[]) {
                            if (value.length === 0) {
                                if (datasetId && itemType === ItemType.FILES) {
                                    return this.createError({
                                        path: this.path,
                                        message: t('upload_file_uploadDropzone_req'),
                                    });
                                }

                                if (itemType === ItemType.FOLDER) {
                                    return this.createError({
                                        path: this.path,
                                        message: t('upload_folder_uploadDropzone_req'),
                                    });
                                }

                                return this.createError({
                                    path: this.path,
                                    message: t('upload_items_uploadDropzone_req'),
                                });

                            }

                            if (datasetId && itemType === ItemType.FILES) {
                                return false;
                            }

                            const name = value[0].path.split('/')[0];
                            if (!name.match(val.folderNameValidation)) {
                                return this.createError({
                                    path: this.path,
                                    message: t('upload_folder_name_validation_error'),
                                });
                            }
                            if (name.length > val.datasetCreateDatasetNameMax) {
                                return this.createError({
                                    path: this.path,
                                    message: t('upload_folder_dsName_max', { max: val.datasetCreateDatasetNameMax }),
                                });
                            }
                            return false;
                        }),

                    uploadDescription: Yup.string()
                        .max(val.uploadDescriptionMax,
                            t('upload_folder_uploadDesc_max', { max: val.uploadDescriptionMax })),
                })}
            >
                {
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    props => {
                        const {
                            dirty,
                            isSubmitting,
                            handleSubmit,
                            submitForm,
                            errors,
                            values,
                        } = props;
                        return (
                            <FormLayout
                                onSubmit={handleSubmit}
                                submitForm={submitForm}
                                footer={
                                    <FormButtonGroup
                                        dirty={dirty}
                                        isSubmitting={isSubmitting}
                                        submitButtonText={t('upload_folder_upload_button_text')}
                                    />
                                }
                            >
                                <FormikErrorLabels errors={errors} />
                                {(datasetId && currentLocation) ?
                                    <>
                                        <h2
                                            className='formTitle'
                                            style={{ display: 'inline-block' }}
                                            aria-label={`${itemType === ItemType.FILES ? t('upload_files_title') : t('upload_folder_title')} title`}>
                                            {(itemType === ItemType.FILES) ? t('upload_files_title') : t('upload_folder_title')}
                                        </h2>
                                        <div style={{
                                            display: 'inline-block',
                                            marginLeft: '5px',
                                        }}>
                                            {decodeURIComponent(currentLocation)}
                                        </div>
                                    </> :
                                    <h2
                                        className='formTitle'
                                        aria-label={`${t('upload_dataset_folder_title')} title`}
                                        id='page-title'
                                        tabIndex={0}
                                    >
                                        {t('upload_dataset_folder_title')}
                                    </h2>}

                                <Label
                                    value={(datasetId && itemType === ItemType.FILES) ? t('upload_files_uploadDropzone_label') : t('upload_folder_uploadDropzone_label')}
                                    required
                                    aria-label={`${(datasetId && itemType === ItemType.FILES) ? t('upload_files_uploadDropzone_label') : t('upload_folder_uploadDropzone_label')} label`}
                                />

                                <Field
                                    name="uploadDropzone"
                                    id="uploadDropone"
                                    component={FileDropZone}
                                    multiple={datasetId ? itemType === ItemType.FILES : false}
                                    folders={!datasetId || itemType === ItemType.FOLDER}
                                    files={datasetId ? itemType === ItemType.FILES : false}
                                    aria-label={`${(datasetId && itemType === ItemType.FILES) ? t('upload_files_uploadDropzone_label') : t('upload_folder_uploadDropzone_label')} dropzone`}
                                    disabledDropZone={isSubmitting}
                                />

                                {!datasetId && (
                                    <>
                                        <Label
                                            value={t('upload_folder_uploadDesc_label')}
                                            aria-label={`${t('upload_folder_uploadDesc_label')} label`} />
                                        <Field
                                            name="uploadDescription"
                                            type="text"
                                            component={AccessibleFormikInput}
                                            className="min-width-override"
                                            variant="outlined"
                                            color="secondary"
                                            placeholder={t('upload_folder_uploadDesc_ph')}
                                            aria-label={`${t('upload_folder_uploadDesc_label')} text field`}
                                            autoComplete="off"
                                            disabled={isSubmitting}
                                            multiline
                                            rows={6}
                                        />
                                        {isPublicEndpointEnabled(isOnPrem, featureManager) && (
                                            <div className="publicDS">
                                                <TooltipContainer
                                                    title={t('tooltip_datasets_make_dataset_public')}
                                                    customClass={classes.customTooltipClass}>
                                                    <Label value={t('dataset_create_publicDS_label')} />
                                                </TooltipContainer>
                                                <div
                                                    aria-label={`${t('dataset_create_publicDS_label')} switch ${values.publicDS ? 'checked' : 'unchecked'
                                                    }`}
                                                >
                                                    <Field
                                                        name="publicDS"
                                                        component={Switch}
                                                        color="secondary"
                                                        type="checkbox"
                                                        disabled={isSubmitting}
                                                        aria-hidden
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {isBlobEncryptionEnabled(featureManager) ? (
                                            <div className="encryptedDataset">
                                                <TooltipContainer
                                                    title={t('tooltip_datasets_encrypt_dataset')}
                                                    customClass={classes.customTooltipClass}>
                                                    <Label value={t('dataset_create_encrypted_label')} />
                                                </TooltipContainer>
                                                <div
                                                    aria-label={`${t('dataset_create_encrypted_label')} switch ${values.encryptedDS ? 'checked' : 'unchecked'
                                                    }`}
                                                >
                                                    <Field
                                                        name="encryptedDS"
                                                        component={Switch}
                                                        color="secondary"
                                                        type="checkbox"
                                                        inputProps={{ 'aria-label': t('ally_encrypted_ds_switch') }}
                                                        data-testid="encryptedDS-testid"
                                                        disabled={isSubmitting || datasetId}
                                                    />
                                                </div>
                                            </div>)
                                            : (<></>)}
                                    </>
                                )}
                            </FormLayout>
                        );
                    }
                }
            </Formik>
        </div>
    );
};

export const UploadItems = connect((state: any) => ({
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined,
    featureManager: state.featureManagement.featureManager,
    authToken: state.auth.authToken,
    azureStorageFQDNSuffix: state.config.paths ? state.config.paths.azureStorageFQDNSuffix : AZURE_SAS_TOKEN_SUBS_STRING,
}))(UploadItemsFC);
