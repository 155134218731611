import { makeStyles } from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    bodyText: {
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        color: theme.palette.semantic.colorForegroundHigh,
    },
}));
export const Accent: React.FC = ({ children }) => {
    const classes = useStyles();
    return <span className={classes.bodyText}>
        {children}
    </span>;
};
