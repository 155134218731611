import {
    Tab,
    Tabs,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';
import type { Column } from 'react-table';

import { useFeedback } from '../../api/global/useFeedback';
import { usePermissions } from '../../api/global/usePermissions';
import { IdentityObjectType } from '../../api/types/common';
import { CustomDialog } from '../../components/Dialog';
import type { ButtonItemDropDown } from '../../components/Section';
import Section from '../../components/Section';
import ServerSideTable from '../../components/Table';
import URLManager from '../../config/URLManager';
import { AppPermissions } from '../../enums/Authorization';
import { http } from '../../http';
import { RoutePath } from '../../route/routeMap';
import {
    ADMINISTRATORS_GUID,
    AUTOMATION_DEVELOPERS_GUID,
    AUTOMATION_USERS_GUID,
    EVERYONE_GUID,
} from '../../utils/CommonUtils';

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            width: '100%',
            paddingBottom: '64px',
            overflowY: 'scroll',
            height: 'calc(100vh - 70px)',
        },
    }),
);
const UserManagementComponent: React.FC<{ level: string; projectId?: string }> = ({
    level, projectId,
}) => {

    const [ value, setValue ] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getTenantPermissions();

    const buttonItems = [];
    if (permissions.indexOf(AppPermissions.Roles_Create) > -1) {
        buttonItems.push({
            buttonText: t('create_tenant_role'),
            buttonCallback: () => {
                history.push({
                    pathname: RoutePath.RoleCreation,
                    state: { data: { permissionLevel: 'TENANT' } },
                });
            },
        },
        {
            buttonText: t('create_project_role'),
            buttonCallback: () => {
                history.push({
                    pathname: RoutePath.RoleCreation,
                    state: { data: { permissionLevel: 'PROJECT' } },
                });
            },
        });
    }

    const roleCreationButtons: ButtonItemDropDown = {
        label: t('create_role'),
        variant: 'outlined',
        items: buttonItems,
    };

    const roleAssignmentButton: any = [];
    if (permissions.indexOf(AppPermissions.Users_Edit) > -1) {
        roleAssignmentButton.push({
            buttonText: t('assign_roles'),
            buttonCallback: () => {
                history.push({ pathname: RoutePath.TenantRoleAssignment });
            },
        });
    }

    return (
        <div className={classes.wrapper}>
            <Section
                title={t('user_management_title')}
                buttonItems={value === 0 ? roleAssignmentButton : []}
                dropDownButtonItems={value === 0 || buttonItems.length === 0 ? undefined : [ roleCreationButtons ]}
            >
                <Tabs
                    color="default"
                    indicatorColor="primary"
                    textColor="primary"
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example">
                    <Tab
                        label={t('user_roles_header_user_group')}
                        data-testid="user_group_tab" />
                    <Tab
                        label={t('user_roles_header_roles')}
                        data-testid="user_roles_tab" />
                </Tabs>

                <div hidden={value !== 0}>
                    <RoleAssignmentListing
                        level={level}
                        projectId={projectId} />
                </div>
                <div hidden={value !== 1}>
                    <RolesListing level={level} />
                </div>
            </Section>
        </div>

    );
};

export const RoleAssignmentListing: React.FC<{ level: string; projectId?: string; projectName?: string }> = ({
    level, projectId, projectName,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const deleteTenantAssignmentURLPrefix = URLManager.url().apiHelper + '/roles/users/tenant/';
    const deleteProjectAssignmentURLPrefix = URLManager.url().apiHelper + '/roles/users/project/';
    const feedback = useFeedback();
    const [ , permissionActions ] = usePermissions();
    const tenantPermissions = permissionActions.getTenantPermissions();
    const projectPermissions = projectId ? permissionActions.getProjectPermissions(projectId) : [];

    const deleteAssignment = useCallback((userId, userName, roles) => {
        if (projectId) {
            http.patch(deleteProjectAssignmentURLPrefix + userId + '/' + projectId, {
                roles,
                delete: true,
            }).then(() => {
                feedback.enqueueSuccess(`Role assignment for ${userName} succesfully deleted`);
                history.push(generatePath(RoutePath.SETTINGS, { projectName }));
                return true;
            })
                .catch(() => {
                    feedback.enqueueError(`Failed to delete role assignment for ${userName}`);
                    return false;
                });
        } else {
            http.patch(deleteTenantAssignmentURLPrefix + userId, {
                roles,
                delete: true,
            }).then(() => {
                feedback.enqueueSuccess(`Role assignment for ${userName} succesfully deleted`);
                history.push(RoutePath.TenantUserManagement);
                return true;
            })
                .catch(() => {
                    feedback.enqueueError(`Failed to delete role assignment for ${userName}`);
                    return false;
                });
        }
    }, [ projectId ]);

    const dataMapperUserGroup: Column[] = [
        {
            Header: `${t('user_roles_header_user_group')}`,
            accessor: 'displayName',
            Cell: (data: { row: { original: { email: string; displayName: string } } }): string => (
                data?.row?.original?.email || data?.row?.original?.displayName
            ),
            sortable: true,
        },
        {
            Header: `${t('user_roles_header_roles')}`,
            accessor: 'roles',
            sortable: false,
            Cell: (data: { row: { original: { roles: any[] } } }): string => {
                const roles = data?.row?.original?.roles || [];
                if (roles.length === 0) {
                    return '';
                }
                const roleNames = roles.map((role) => t(`label_${role.roleDisplayName.replace(' ', '_').replace(' ', '_')}`, role.roleDisplayName));
                return roleNames.join(', ');
            },
        },
    ];
    const menuItemsUserGroup = [];
    const systemGroupIds = [ ADMINISTRATORS_GUID, AUTOMATION_USERS_GUID, AUTOMATION_DEVELOPERS_GUID, EVERYONE_GUID ];
    if ((level === 'TENANT' && tenantPermissions.indexOf(AppPermissions.Users_Edit) > -1) || projectPermissions.indexOf(AppPermissions.Settings_Edit) > -1) {
        menuItemsUserGroup.push({
            text: 'assignment_roles_edit',
            disable: (data: any): boolean => ((level === 'TENANT') && ((data.identifier || data.id) === ADMINISTRATORS_GUID)),
            click: (event: any, row: any): void => {
                history.push({
                    pathname: projectId ? generatePath(RoutePath.ProjectRoleAssignmentEdit, {
                        userId: row.original.id,
                        projectName,
                    }) : generatePath(RoutePath.TenantRoleAssignmentEdit, { userId: row.original.id }),
                    state: { data: { userInfo: row.original } },
                });
            },
        },
        {
            text: 'assignment_roles_delete',
            disable: (data: any): boolean => (!data.roles || !data.roles.length || (IdentityObjectType.DirectoryGroup === data.objectType && systemGroupIds.indexOf(data.id) !== -1)),
            click: (event: any, row: any): void => {
                deleteAssignment(
                    row.original.id,
                    row.original.email || row.original.displayName,
                    row.original.roles.map((r: any) => r.roleId),
                );
            },
        });
    }
    const roleType: string = (level === 'TENANT' ? 'TENANT' : 'PROJECT');
    let url = URLManager.url().apiHelper + '/roles/users/allRoles?level=' + roleType;

    if (level === 'PROJECT') {
        url = url + '&projectId=' + projectId;
    }

    return (
        <ServerSideTable
            url={url}
            searchable={false}
            searchKey="displayKey"
            totalKey="data.totalCount"
            dataKey="data.dataList"
            mapper={dataMapperUserGroup}
            contextMenuItems={menuItemsUserGroup}
            itemDeletedFlag={false}
            defaultPageSize={10}
            level="settings_pagesize"
        />
    );
};

export const RolesListing: React.FC<{ level: string }> = ({ level }) => {
    const history = useHistory();
    const feedback = useFeedback();
    const { t } = useTranslation();
    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getTenantPermissions();

    const [ activeRole, setActiveRole ] = useState<any>(undefined);
    const [ deleteRoleDialogOpen, setRoleDeleteDialogOpen ] = useState(false);

    const closeDeleteRoleDialog = (): void => {
        setRoleDeleteDialogOpen(false);
    };

    const deleteRole = (): void => {
        if (!activeRole) {
            return;
        }
        http.delete(`${URLManager.url().apiHelper}/roles/${activeRole.original.id}/`)
            .then(() => {
                closeDeleteRoleDialog();
                feedback.enqueueSuccess(t('feedback_delete_role_success'));
                history.push({ pathname: RoutePath.TenantUserManagement });
                return true;
            })
            .catch(() => {
                closeDeleteRoleDialog();
                feedback.enqueueError(t('feedback_delete_role_error'));
                return false;
            });

    };

    const dataMapperRoles: Column[] = [
        {
            Header: `${t('role_listing_header_name')}`,
            accessor: 'roleDisplayName',
            Cell: (data: { row: { original: { displayName: string } } }): string => t(`label_${data.row.original.displayName.replace(' ', '_').replace(' ', '_')}`, data.row.original.displayName),
            sortable: true,
        },
        {
            Header: `${t('role_listing_header_type')}`,
            accessor: 'type',
            Cell: (data: { row: { original: { type: string } } }): string => t(`label_${data.row.original.type.replace(' ', '_').replace(' ', '_')}`, data.row.original.type),
            sortable: true,
        },
    ];

    const menuItemsRoles = [
        {
            text: t('role_listing_view'),
            click: (event: any, row: any): void => {
                history.push({
                    pathname: RoutePath.RoleView,
                    state: {
                        data: {
                            permissionLevel: row.original.type,
                            roleId: row.original.id,
                            roleName: row.original.displayName,
                        },
                    },
                });
            },
        },
        {
            text: t('role_listing_edit'),
            disable: (data: any): boolean => !!data.isStatic || permissions.indexOf(AppPermissions.Roles_Edit) < 0,
            click: (event: any, row: any): void => {
                history.push({
                    pathname: RoutePath.RoleEdit,
                    state: {
                        data: {
                            permissionLevel: row.original.type,
                            roleId: row.original.id,
                            roleName: row.original.displayName,
                        },
                    },
                });
            },
        },
        {
            text: t('role_listing_delete'),
            disable: (data: any): boolean => !!data.isStatic || permissions.indexOf(AppPermissions.Roles_Delete) < 0,
            click: (event: any, row: any): void => {
                setActiveRole(row);
                setRoleDeleteDialogOpen(true);
            },
        },
    ];

    const roleType: string = (level === 'TENANT' ? 'TENANT,PROJECT,MIXED' : 'PROJECT,MIXED');
    const url = URLManager.url().apiHelper + '/roles/?roleType=' + roleType;

    return (
        <>
            <CustomDialog
                title={t('role_remove_dialog_title_text')}
                open={deleteRoleDialogOpen}
                handleClose={closeDeleteRoleDialog}
                closeIconButton
                infoText={t('role_remove_dialog_info_text')}
                confirmationText={t('role_delete_confirmation_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={deleteRole}
                secondarybuttonCallback={closeDeleteRoleDialog}
            />
            <ServerSideTable
                url={url}
                searchable={false}
                searchKey="displayName"
                totalKey="data.totalCount"
                dataKey="data.dataList"
                mapper={dataMapperRoles}
                contextMenuItems={menuItemsRoles}
                itemDeletedFlag={false}
                defaultPageSize={10}
                level="settings_pagesize"
            />
        </>
    );
};

export default UserManagementComponent;
