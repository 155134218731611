import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

import AccessibleTooltip from './AccessibleTooltip';

const styles = makeStyles(() => createStyles({
    tooltipContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));

interface TooltipContainerProps {
    title: string;
    customClass?: string;
}

const TooltipContainer: React.FC<TooltipContainerProps> = ({
    children, title, customClass,
}) => {
    const classes = styles();
    return (
        <div className={classes.tooltipContainer}>
            {children}
            <AccessibleTooltip
                title={title}
                customClass={customClass} />
        </div>
    );
};

export default TooltipContainer;
