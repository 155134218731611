import {AppPermissions} from "../../enums/Authorization";

export enum PermissionState {
  EMPTY = "PERMISSIONS_EMPTY",
  INITIATED = "PERMISSIONS_FETCH_INITIATED",
  LOADED = "PERMISSIONS_LOADED",
  FAILED = "PERMISSIONS_FAILED"
}

export enum PossiblePermissions {
  "canCreate"="canCreate",
  "canEdit"="canEdit",
  "canDelete"="canDelete",
  "canView"="canView"
}

export type PermissionData = {
  permissionSet: AppPermissions[],
  permissionState: PermissionState,
  failureCode?: null | number | string;
  backendCode?: undefined | null | string;
}

export type AppPermissionsState = {
  tenantData: PermissionData,
  projectData: { [projectId: string]: PermissionData }
};

export type AppPermissionsActions = {
  getTenantPermissions: () => AppPermissions[],
  getProjectPermissions: (projectId: string) => AppPermissions[]
};

export type GetPermissionSetOfService = (service: string) => PermissionSet;

export enum FEToBEMapper {
  "ML_PACKAGES" = "MLPackages",
  "DATASET" = "MLStorage",
  "PIPELINES" = "MLPipeline",
  "ML_LOGS" = "MLLogs",
  "DATA_LABELING" = "DataLabeling",
  "ML_SKILLS" = "MLSkills",
  "SETTINGS" = "Settings",
  "PROFILE" = "Profile",
  "LICENSES" = "Licenses",
  "ROLES" = "Roles",
  "USERS" = "Users",
  "PROJECT" = "MLProjects",
  "OOB_UPLOAD" = "OOBUpload"
}
