import type { SelectChangeEvent } from '@mui/material';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import React, {
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useFeedback } from '../../../api/global/useFeedback';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormLayout from '../../../components/FormLayout';
import URLManager from '../../../config/URLManager';
import { http } from '../../../http';
import { getAccountAndTenantFromCannonicalPath } from '../../../route/routeHelper';
import {
    extractErrorMessage,
    getObjectType,
} from '../../../utils/CommonUtils';

const useStyles = makeStyles(() =>
    createStyles({ formElements: { width: '30vw' } }),
);

interface RoleFormData {
    users: any[];
    roles: any;
}

const RoleMultiSelect: React.FC<{ formProps: FormikProps<RoleFormData>; level: 'PROJECT' | 'TENANT'; projectId?: string }> =
  ({
      formProps, level, projectId,
  }) => {

      const feedback = useFeedback();
      const { t } = useTranslation();
      const [ roles, setRoles ] = React.useState<any[]>([]);

      const handleChange = (event: SelectChangeEvent<unknown>) => {

          setRoles(event.target.value as any[]);
      };

      const handleClose = () => {
          formProps.setValues({
              ...formProps.values,
              roles,
          });
      };

      const [ permissionState, setPermissionState ] = useState<any[]>([]);
      React.useEffect(() => {
          const url = level === 'TENANT' ? URLManager.url().apiHelper + '/roles/?roleType=TENANT,MIXED'
              : URLManager.url().apiHelper + '/roles/?roleType=PROJECT&pageSize=100&projectId=' + projectId;
          http.get(url).then((response: any) => {
              const backendRoles = response.data.data.dataList.reduce((accumulator: any[], role: any) => [
                  ...accumulator,
                  role,
              ], []);

              setPermissionState(backendRoles);
              if (formProps.values?.roles) {
                  const mappedValues: any[] = [];
                  (formProps.values?.roles as any[]).forEach((v: any) => {
                      const element = backendRoles.find((b: any) => b.id === v.id);
                      if (element) {
                          mappedValues.push(element);
                      }
                  });
                  setRoles(mappedValues);
              }
              formProps.setValues({
                  ...formProps.values,
                  'roles': formProps.values?.roles || [],
              });
              return true;
          })
              .catch((error: any) => {
                  feedback.enqueueError(extractErrorMessage(
                      error,
                      t('error_fetching_roles'),
                      { 20205: {} },
                  ));
              });
      }, []);

      // https://github.com/mui-org/material-ui/issues/19245 This will be fixed with library update, however
      // library update at this point of time will have regressions
      const positionBugHack = { getContentAnchorEl: () => null };

      return (
          <FormControl style={{ width: '100%' }}>
              <InputLabel htmlFor="roles-select">
                  {t('select_role_for_users')}
              </InputLabel>
              <Select
                  // @ts-ignore
                  MenuProps={positionBugHack}
                  multiple
                  onClose={handleClose}
                  value={roles.map((role: any) => role)}
                  onChange={handleChange}
                  input={<Input id="roles-select" />}
                  renderValue={(selected: any) => (selected.map((s: any) => ( t(`label_${s.name.replace(' ', '_').replace(' ', '_')}`, s.displayName || s.name) )) as string[]).join(', ')}
                  data-testid="role-selector"
                  inputProps={{ 'aria-label': t('select_role_for_users') }}
              >
                  {
                      permissionState.map((name: any) => (
                          <MenuItem
                              key={name.name}
                              value={name}>
                              <FormControlLabel
                                  control={<Checkbox
                                      checked={roles.find((r: any) => (name.id === r.id))}
                                      data-cy={name.name} />}
                                  label={ t(`label_${name.name.replace(' ', '_').replace(' ', '_')}`, name.name) }
                                  labelPlacement="end"
                              />
                          </MenuItem>
                      ))
                  }
              </Select>
          </FormControl>
      );
  };

interface RoleCreationDTO {
    roleId: string[];
    usersAndGroups: any[];
    replace: boolean;
}

const Base: React.FC<{
    level: 'PROJECT' | 'TENANT';
    projectId?: string;
    projectName?: string;
    userInfo?: any;
    token: string;
    identityUrl: string;
}> = ({
    level, projectId, userInfo, token, identityUrl,
}) => {

    const classes = useStyles();
    const history = useHistory();
    const feedback = useFeedback();
    const { t } = useTranslation();
    const isEdit = !!userInfo;
    const portalPeoplePickerRef = useRef<HTMLPortalPeoplePickerElement>(null);
    const [ assignees, setAssignees ] = React.useState(userInfo === undefined ? [] : [ userInfo ]);
    const { account } = getAccountAndTenantFromCannonicalPath();

    React.useEffect(() => {

        const handlePeoplePickerChange = (e: any) => {
            setAssignees(e.detail?.data);
        };

        portalPeoplePickerRef.current?.addEventListener('peoplePickerChanged', handlePeoplePickerChange);
        return () => portalPeoplePickerRef.current?.removeEventListener('peoplePickerChanged', handlePeoplePickerChange);
    }, [ portalPeoplePickerRef ]);

    const getIdentityApiUrl = (): string => identityUrl ? identityUrl.replace(':account', account) : identityUrl;

    const initialValues: RoleFormData = {
        users: isEdit ? [ {
            name: userInfo.name,
            displayName: userInfo.displayName,
            email: userInfo.email,
            id: userInfo.id,
            objectType: userInfo.objectType,
            roles: null,
            userName: userInfo.userName,
            surname: userInfo.surname,
            source: userInfo.source,
        } ] : [],
        roles: (isEdit && userInfo.roles) ? userInfo.roles.map((r: any) => ({
            id: r.roleId,
            name: r.roleDisplayName,
        })) : [],
    };
    /*
  private UUID id;
    private String userName;
    private String displayName;
    private ObjectType objectType;
    private String email;
    private List<MemberRoleDto> roles;
  * */
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                const body: RoleCreationDTO = {
                    roleId: values.roles.map((role: any) => role.id),
                    usersAndGroups: assignees.map((user: any) => {
                        if (typeof user.objectType === 'undefined') {
                            user.objectType = user.type;
                        }
                        return {
                            userName: user.identityName,
                            // directory api returns identifier, and our db has id.
                            id: user.identifier || user.id,
                            displayName: user.displayName,
                            objectType: getObjectType(user.objectType),
                            email: user.email,
                            source: user.source,
                            roles: null,
                        };
                    }),
                    replace: isEdit,
                };

                const urlPrefix = URLManager.url().apiHelper + '/roles/users/';
                const urlSuffix = (level === 'PROJECT') ? `project/?projectId=${projectId}` : `tenant/`;
                const url = urlPrefix + urlSuffix;

                http.post(url, body).then(() => {
                    history.go(-1);
                    return true;
                })
                    .catch((error: any) => {
                        feedback.enqueueError(extractErrorMessage(
                            error,
                            t('error_assigning_roles'),
                            { 20205: {} },
                        ));
                    });
            }}
        >
            {
                (props) => (
                    <FormLayout
                        data-testid="assignment-form"
                        onSubmit={props.handleSubmit}
                        submitForm={props.submitForm}
                        footer={
                            <FormButtonGroup
                                dirty={props.dirty}
                                isSubmitting={props.isSubmitting}
                                submitButtonText={isEdit ? t('form_edit_button_text') : undefined}
                            />
                        }
                    >
                        <div className={classes.formElements}>
                            {
                                isEdit ?
                                    <>
                                        <InputLabel htmlFor="users-select">
                                            {t('edit_users_to_role')}
                                        </InputLabel>
                                        <TextField
                                            value={userInfo.email || userInfo.displayName}
                                            variant="outlined"
                                            color="secondary"
                                            autoComplete="off"
                                            id="users-select"
                                            disabled
                                        />
                                    </> :
                                    <portal-people-picker
                                        people-picker-id="portal-people-picker"
                                        label-text={t('add_users_to_role')}
                                        token={token}
                                        ref={portalPeoplePickerRef}
                                        identity-base-url={getIdentityApiUrl()}
                                        sourceFilters={JSON.stringify([ 'localUsers', 'localGroups', 'directoryUsers', 'directoryGroups' ])} />
                            }
                            <RoleMultiSelect
                                formProps={props}
                                level={level}
                                projectId={projectId} />
                        </div>
                    </FormLayout>
                )
            }
        </Formik>
    );
};

export default Base;
