import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import Last from '@mui/icons-material/SkipNext';
import First from '@mui/icons-material/SkipPrevious';
import type { SelectChangeEvent } from '@mui/material';
import {
    IconButton,
    Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React, {
    useCallback,
    useEffect,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-keycode-enum';

import { bindKeyTo } from '../../../utils/a11y';

const usePaginationStyles = makeStyles((theme) => (
    {
        pagination: {
            background: theme.palette.background.paper,
            height: '54px',
            padding: '0 16px',
            display: 'flex',
            justifyContent: 'space-between',

            '& .MuiSelect-select.MuiSelect-select': { width: 'auto' },

            '& .cursor-pointer': { cursor: 'pointer' },

            '& .paginationValues': {
                lineHeight: Tokens.FontFamily.FontHeader1LineHeight,
                fontSize: Tokens.FontFamily.FontMSize,
                color: theme.palette.semantic.colorForegroundDeEmp,

                '& span': { marginRight: '4px' },
            },

            '& .paginationControl': {
                height: '54px',
                color: theme.palette.semantic.colorForeground,
                fill: theme.palette.semantic.colorForegroundDeEmp,
                display: 'flex',
                alignItems: 'center',

                '& .disabled': { fill: theme.palette.semantic.colorForegroundDeEmp },

                '& .control': {
                    lineHeight: Tokens.FontFamily.FontHeader1LineHeight,

                    '& span': { marginRight: '8px' },

                    '& .label': { color: theme.palette.semantic.colorForegroundDeEmp },

                    '& .of': {
                        color: theme.palette.semantic.colorForeground,
                        fontWeight: 'bold',
                    },
                },
            },

            '& .paginationQtyControl': {
                lineHeight: Tokens.FontFamily.FontHeader1LineHeight,

                '& .MuiSelect-icon': { top: 'calc(50% - 8px)' },

                '& .option': { color: theme.palette.semantic.colorForeground },

                '& .label': {
                    color: theme.palette.semantic.colorForeground,
                    marginRight: '4px',
                },
            },
        },
    }));

interface PaginationProps {
    pageNum: number;
    recordsPerPage: 10 | 20 | 30 | 50 | 100;
    total: number;
    nextPage: Function;
    setPageSize: Function;
    previousPage: Function;
    gotoPage: Function;
    isLoading: boolean;
    firstPage?: Function;
    canNextPage?: boolean;
    paginationChangeActionType: PaginationChangeActionType;
    entityName?: string;
    level?: string;
}

export enum PaginationChangeActionType {
    NONE = 'NONE',
    PAGE_SIZE = 'PAGE_SIZE',
    FIRST_PAGE = 'FIRST_PAGE',
    LAST_PAGE = 'LAST_PAGE',
    PREV_PAGE = 'PREV_PAGE',
    NEXT_PAGE = 'NEXT_PAGE',
}

const allowedRecordsPerPage: number[] = [ 10, 20, 30, 50, 100 ];

const Pagination: React.FC<PaginationProps> = ({
    recordsPerPage,
    total,
    pageNum,
    setPageSize,
    nextPage,
    previousPage,
    isLoading,
    gotoPage,
    canNextPage,
    paginationChangeActionType,
    entityName = '',
    level = '',
}) => {
    const classes = usePaginationStyles();
    const { t } = useTranslation();
    let toPage = pageNum * recordsPerPage;
    const canPreviousPage = pageNum > 1;
    const canNextPageCalc = isFinite(total) ? (pageNum < Math.ceil(total / recordsPerPage)) : canNextPage;
    const onPaginationSelect = (event: SelectChangeEvent<unknown>): void => {
        localStorage.setItem(`${level}`, event.target.value as string);
        const pageSize = localStorage.getItem(`${level}`);
        setPageSize(pageSize);
    };
    toPage = total < toPage ? total : toPage;

    const gotoFirstPage = useCallback((): void => {
        !isLoading && canPreviousPage && gotoPage(0);
    }, [ canPreviousPage, isLoading, gotoPage ]);

    const gotoFirstPageKeyCB = useCallback(bindKeyTo({ traps: { [Key.Space]: gotoFirstPage } }), [ gotoFirstPage ]);

    const gotoPrevPage = useCallback((): void => {
        !isLoading && canPreviousPage && previousPage();
    }, [ canPreviousPage, isLoading, previousPage ]);

    const gotoPrevPageKeyCB = useCallback(bindKeyTo({ traps: { [Key.Space]: gotoPrevPage } }), [ gotoFirstPage ]);

    const gotoNextPage = useCallback((): void => {
        !isLoading && canNextPageCalc && nextPage();
    }, [ canNextPageCalc, isLoading, nextPage ]);

    const gotoNextPageKeyCB = useCallback(bindKeyTo({ traps: { [Key.Space]: gotoNextPage } }), [ gotoFirstPage ]);

    const gotoLastPage = useCallback((): void => {
        !isLoading && canNextPageCalc && gotoPage(Math.ceil(total / recordsPerPage) - 1);
    }, [ canNextPageCalc, isLoading, gotoPage, total, recordsPerPage ]);

    const gotoLastPageKeyCB = useCallback(bindKeyTo({ traps: { [Key.Space]: gotoLastPage } }), [ gotoFirstPage ]);

    const nextPageRef = useRef<HTMLButtonElement>(null);
    const prevPageRef = useRef<HTMLButtonElement>(null);
    const firstPageRef = useRef<HTMLButtonElement>(null);
    const lastPageRef = useRef<HTMLButtonElement>(null);
    const pageSizeRef = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        switch (paginationChangeActionType) {
            case PaginationChangeActionType.NEXT_PAGE:
                nextPageRef?.current?.focus();
                return;
            case PaginationChangeActionType.PREV_PAGE:
                prevPageRef?.current?.focus();
                return;
            case PaginationChangeActionType.FIRST_PAGE:
                firstPageRef?.current?.focus();
                return;
            case PaginationChangeActionType.LAST_PAGE:
                lastPageRef?.current?.focus();
                return;
            case PaginationChangeActionType.PAGE_SIZE:
                pageSizeRef?.current?.focus();
                return;
        }
    });

    return !isLoading ? (
        <div className={classes.pagination}>
            <div className="paginationValues">
                <span aria-label={t('a11y_pagination_records_from')}>
                    {(pageNum - 1) * recordsPerPage + 1}
                </span>
                <span aria-hidden>
-
                </span>
                <span aria-label={t('a11y_pagination_records_till')} >
                    {toPage}
                </span>
                <span aria-hidden>
                    {t('pagination_label_of')}
                </span>
                <span aria-label={t('a11y_pagination_total_records')}>
                    {isFinite(total) ? total : '....'}
                </span>
            </div>
            <div className="paginationControl">
                <IconButton
                    tabIndex={0}
                    ref={firstPageRef}
                    data-testid="pagination-first"
                    className={canPreviousPage ? 'cursor-pointer' : 'disabled'}
                    aria-label={t('a11y_pagination_goto_first', { entityName })}
                    onKeyDown={gotoFirstPageKeyCB}
                    onClick={gotoFirstPage}>
                    <First />
                </IconButton>
                <IconButton
                    tabIndex={0}
                    ref={prevPageRef}
                    aria-label={t('a11y_pagination_goto_previous', { entityName })}
                    data-testid="pagination-prev"
                    className={canPreviousPage ? 'cursor-pointer' : 'disabled'}
                    onClick={gotoPrevPage}
                    onKeyDown={gotoPrevPageKeyCB}
                >
                    <NavigateBefore />
                </IconButton>
                <span className="control">
                    <span
                        className="label"
                        aria-hidden>
                        {t('pagination_label_page')}
                    </span>
                    <span
                        className="of"
                        aria-label={t('a11y_pagination_current_page')}>
                        {pageNum}
                    </span>
                    <span
                        className="separator"
                        aria-hidden>
/
                    </span>
                    <span
                        className="total"
                        aria-label={t('a11y_pagination_total_pages')}>
                        {isFinite(total) ? Math.ceil(total / recordsPerPage) : '....'}
                    </span>
                </span>
                <IconButton
                    aria-label={t('a11y_pagination_goto_next', { entityName })}
                    tabIndex={0}
                    ref={nextPageRef}
                    data-testid="pagination-next"
                    className={canNextPageCalc ? 'cursor-pointer' : 'disabled'}
                    onClick={gotoNextPage}
                    onKeyDown={gotoNextPageKeyCB}
                >
                    <NavigateNext />
                </IconButton>
                {
                    isFinite(total) ? (
                        <IconButton
                            aria-label={t('a11y_pagination_goto_last', { entityName })}
                            tabIndex={0}
                            data-testid="pagination-last"
                            ref={lastPageRef}
                            onKeyDown={gotoLastPageKeyCB}
                            className={pageNum === Math.ceil(total / recordsPerPage) ? 'disabled' : 'cursor-pointer'}
                            onClick={gotoLastPage}
                        >
                            <Last />
                        </IconButton>
                    ) : null
                }
            </div>
            <div className="paginationQtyControl">
                <span className="label">
                    {t('pagination_label_show_by')}
:
                </span>
                <Select
                    native
                    inputRef={pageSizeRef}
                    className="cursor-pointer"
                    data-testid="page-size-dd"
                    value={recordsPerPage}
                    onChange={onPaginationSelect}
                    inputProps={{ 'aria-label': t('a11y_page_size', { entityName }) }}>
                    {allowedRecordsPerPage.map((pageNumber: number) => (
                        <option
                            value={pageNumber}
                            key={pageNumber}>
                            {pageNumber}
                        </option>
                    ))}
                </Select>
            </div>
        </div>
    ) : (<div className={classes.pagination} />);
};

export default Pagination;
