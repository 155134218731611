import type { ErrorInfo } from 'react';
import React from 'react';
import { Translation } from 'react-i18next';

import ErrorDialog from '../../components/ErrorDialog';
import logger from '../../utils/Logging';
import {
    aiCenterCallback,
    homePageCallback,
} from './ProjectHost';

export class ErrorBoundary extends React.Component {
    state: { hasError: boolean; error?: Error };
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return {
            hasError: true,
            error,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logger.error({
            identifier: 'Error Boundary',
            message: errorInfo.componentStack,
            error,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Translation>
                    {
                        (t) => (
                            <div style={{
                                width: '100vw',
                                height: '100vh',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <ErrorDialog
                                    backendCode={null}
                                    title={t('generic_error_title')}
                                    subtitle={t('generic_error_subtitle')}
                                    buttonText={t('generic_error_home_button')}
                                    buttonCallback={homePageCallback}
                                    secondaryButtonCallback={aiCenterCallback}
                                    secondaryButtonText={t('generic_error_AIF_home_button')}
                                />
                            </div>
                        )
                    }
                </Translation>
            );
        }
        return this.props.children;
    }
}
