import { BaseResponseOfBasePipelineDto, PipelineCreationRequest, BasePipelineDto, BaseResponseOfRunDto, PipelineUpdationRequest, BaseResponseOfRunLogsDto, BaseRunLogsDto, BaseResponseOfstring, RunUpdationRequest, RunDto } from "@uipath/aifabric"
import { http } from "../../http"
import URLManager from "../../config/URLManager";
import { AxiosResponse } from "axios";
import { RunActionType } from "../types/common";

/* Added project id in headers also so that backend MDC context can be populated properly */
export const updatePipeline = async (updateRequest: PipelineUpdationRequest, pipelineId: string, projectId: string | undefined): Promise<BasePipelineDto | undefined> => {
  const res = await http.put<PipelineUpdationRequest, AxiosResponse<BaseResponseOfBasePipelineDto>>(URLManager.url().apiTrainer + "/pipelines/" + pipelineId + "?projectId=" + projectId, updateRequest);
  return res.data?.data;
}

export const createPipeline = async (createRequest: PipelineCreationRequest): Promise<BasePipelineDto | undefined> => {
  const res = await http.post<PipelineCreationRequest, AxiosResponse<BaseResponseOfBasePipelineDto>>(URLManager.url().apiTrainer + "/pipelines" + `?projectId=${createRequest.projectId}`, createRequest);
  return res.data?.data;
}

export const getPipelineRunLogs = async (pipelineRunId: string | undefined, projectId: string | undefined): Promise<BaseRunLogsDto | undefined> => {
  const res = await http.get<BaseResponseOfRunLogsDto>(URLManager.url().apiTrainer + "/runs/logs/" + pipelineRunId + '?projectId=' + projectId);
  return res.data?.data;
}

export const getPipelineById = async (pipelineId: string, projectId: string | undefined): Promise<RunDto | undefined> => {
  const res = await http.get(`${URLManager.url().apiTrainer}/pipelines/${pipelineId}?projectId=${projectId}`)
  return res.data?.data;
}

export const deletePipelineRun = async (pipelineRunId: string, projectId?: string) => {
  const res = await http.delete<BaseResponseOfstring>(URLManager.url().apiTrainer + `/runs/${pipelineRunId}` + `?projectId=${projectId}`);
  return res.data;
}

export const updateRun = async (pipelineRunId: string | undefined, projectId: string | undefined, action: string | undefined): Promise<BaseResponseOfRunDto | undefined> => {
  const res = await http.put<RunUpdationRequest, AxiosResponse<BaseResponseOfRunDto>>(`${URLManager.url().apiTrainer}/runs/${pipelineRunId}?projectId=${projectId}`,
    { runAction: action?.toUpperCase() } as RunUpdationRequest)
  return res.data;
}
