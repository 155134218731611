import {
    ArrowDropDown,
    ArrowDropUp,
} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import InfoIcon from '@mui/icons-material/Info';
import More from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
    Button,
    Checkbox,
    Hidden,
    IconButton,
    Menu,
    MenuItem,
    Skeleton,
    Table as MatTable,
    TableBody as MatTableBody,
    TableCell as MatTableCell,
    TableContainer as MatTableContainer,
    TableHead as MatTableHead,
    TableRow as MatTableRow,
    Tooltip,
} from '@mui/material';
import type { TableOwnProps } from '@mui/material/Table';
import { makeStyles } from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import type { ReactElement } from 'react';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import type {
    Column,
    ColumnInstance,
    HeaderGroup,
    Row,
} from 'react-table';
import {
    useFilters,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table';
import { Key } from 'ts-keycode-enum';
import { v4 as uuid } from 'uuid';

import getPageSize from '../../../config/Pagination';
import { BaseTableIcons } from '../../../enums/BaseTableIcons';
import { bindKeyTo } from '../../../utils/a11y';
import type { Icon } from '../../../utils/IconsInterface';
import { WithVisibility } from '../../WithVisibility';
import Pagination, { PaginationChangeActionType } from '../Pagination/Pagination';
import {
    BodyText,
    ColumnTitle,
} from '../Typography';

interface ContextMenuItem {
    text: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    click?: (event: React.MouseEvent<HTMLLIElement>, data: any, selectedData?: any) => void;
    type?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disable?: ((row: any) => boolean) | boolean;
}

export interface SelectionItem {
    text: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    click: (data: any) => void;
}

interface Conditional {
    visible?: boolean;
}

interface TableProps {
    columns: Column[];
    showRefresh: boolean;
    defaultSortBy?: string;
    defaultSortOrder?: string;
    data: Array<{}>;
    size?: TableOwnProps['size'];
    total: number;
    onPagination?: Function;
    isLoading: boolean;
    contextMenuItems?: ContextMenuItem[];
    isSelectAble?: boolean;
    icons?: Icon[];
    tooltip?: string;
    refreshButtonClicked: () => void;
    selectionItems?: SelectionItem;
    isfiltersVisible?: boolean;
    empty?: boolean;
    canNextPageProp?: boolean;
    // todo: Sahil to fix this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onTableCellClicked?(row: any, event: any): any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRowHoverTooltip?: ((row: any) => string) | string;
    onItemSelection?: any;
    entityName?: string;
    keyColumnIndex?: number;
    level?: string;
}

interface TableHeadersProps {
    hasActionColumn?: boolean;
    headerGroups: HeaderGroup[];
    gotoPage: Function;
    isSelectAble?: boolean;
    isfiltersVisible?: boolean;
    refreshButtonClicked?: () => void;
    toggleSortBy: (ColumnId: string, descending?: boolean, isMulti?: boolean) => void;
}

export interface PaginationArgs {
    pageIndex: number;
    pageSize: number;
    sortBy?: string;
    sortOrder?: string;
    pageCount: number;
    canNextPage: boolean;
    canPreviousPage: boolean;
    sortDirection: 'ASC' | 'DESC' | null;
    filters?: FiltersObj[];
}

export interface FiltersObj {
    id: string;
    value: {
        apiField: string;
        value: string;
    };
}

export const useTableStyles = makeStyles(theme => ({
    refreshIcon: {
        display: 'flex',
        position: 'absolute',
        right: '6px',
        top: '6px',
        zIndex: 99,
        justifyContent: 'flex-end',
    },
    table: {
        position: 'relative',
        minWidth: 650,

        '& tbody': {
            borderTop: `solid 1px #e0e0e0`,
            background: theme.palette.background.paper,
        },

        '& .MuiTableCell-body': {
            fontWeight: Tokens.FontFamily.FontWeightDefault,
            fontFamily: Tokens.FontFamily.FontNormal,
            fontStyle: 'normal',
            fontSize: Tokens.FontFamily.FontMSize,
            lineHeight: Tokens.FontFamily.FontMLineHeight,
            color: theme.palette.semantic.colorForeground,
        },

        '& .tableHead': {
            background: theme.palette.semantic.colorBackgroundSecondary,
            color: theme.palette.semantic.colorForeground,
            fontSize: Tokens.FontFamily.FontMSize,
            lineHeight: Tokens.FontFamily.FontMLineHeight,

            '& .MuiTableCell-head': {
                fontWeight: 600,
                padding: '0 16px',
                height: '52px',

                '&:hover': { background: theme.palette.semantic.colorHover },

                '&:focus': { background: theme.palette.semantic.colorHover },

            },

            '& .sortBlock': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '2em',

                '& svg': {
                    fontSize: Tokens.FontFamily.FontHeader4Size,
                    margin: '-6px',
                },
            },

            '& .disabled': { fill: 'gray' },

            '& .filter': {
                display: 'flex',
                paddingLeft: '4px',

                '& .MuiButton-text': { fontSize: Tokens.FontFamily.FontMSize },
            },
        },

        '& th, td': {
            fontSize: Tokens.FontFamily.FontSSize,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 200,
            whiteSpace: 'nowrap',
        },
    },

    cursorPointer: { cursor: 'pointer' },

    loaderContainer: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        bottom: '10px',
        right: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectionContainer: {
        display: 'flex',

        '& .selection-btn': {
            padding: '8px 16px',
            color: '#0067DF',
        },
    },
}));

interface ClassOnly {
    className: string;
}

interface ClassAndClick extends ClassOnly {
    onClick: any;
    entityName?: string;
}

const RefreshBtn: React.FC<ClassAndClick> = ({
    className, onClick, entityName = '',
}) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <IconButton
                name="refresh-button"
                aria-label={t('a11y_refresh_grid', { entityName })}
                data-testid="refreshIcon-btn"
                data-cy="grid-refresh"
                onClick={onClick}>
                <RefreshIcon />
            </IconButton>
        </div>
    );
};

interface EmptyContentProps {
    empty?: boolean;
    colspan: number;
    text: string;
}

const EmptyContent: React.FC<EmptyContentProps> = ({
    empty, colspan, text,
}) => (
    empty ? (
        <MatTableRow >
            <MatTableCell
                colSpan={colspan}
                data-testid="table-no-data"
                data-cy="table-no-data">
                <BodyText>
                    {text}
                </BodyText>
            </MatTableCell>
        </MatTableRow>
    ) : null
);

const CustomTable: React.FC<TableProps> = (
    {
        columns,
        data,
        size,
        contextMenuItems,
        isSelectAble,
        onPagination,
        isLoading,
        total,
        icons,
        onTableCellClicked,
        showRefresh,
        refreshButtonClicked,
        selectionItems,
        empty,
        canNextPageProp,
        defaultSortBy,
        defaultSortOrder,
        isfiltersVisible,
        onRowHoverTooltip,
        onItemSelection,
        entityName = '',
        keyColumnIndex,
        level = '',
    }) => {

    const sortParams = [];

    if (defaultSortBy && defaultSortOrder) {
        sortParams.push({
            id: defaultSortBy,
            desc: defaultSortOrder === 'DESC' || defaultSortOrder === 'desc',
        });
    }
    const {
        nextPage,
        previousPage,
        headerGroups,
        rows,
        prepareRow,
        pageCount,
        canNextPage,
        canPreviousPage,
        setPageSize,
        gotoPage,
        toggleSortBy,
        selectedFlatRows,
        state: {
            pageIndex, pageSize, sortBy, filters,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: getPageSize(level),
                pageIndex: 0,
                sortBy: sortParams,
            },
            manualPagination: true,
            manualSortBy: true,
            manualFilters: true,
        },
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
    );

    useEffect(() => {
        onItemSelection && onItemSelection(selectedFlatRows.map((row: any) => row.original.id));
    }, [ onItemSelection, selectedFlatRows ]);

    useEffect(() => {
        const paginationArgs: PaginationArgs = {
            pageIndex,
            pageSize,
            pageCount,
            canNextPage,
            canPreviousPage,
            sortBy: (sortBy && sortBy[0]) ? sortBy[0].id : '',
            sortDirection: (sortBy && sortBy[0]) ? (sortBy[0].desc ? 'DESC' : 'ASC') : null,
            filters,
        };
        if (onPagination) {
            onPagination(paginationArgs);
        }
    }, [ canNextPage, canPreviousPage, onPagination, pageCount, pageIndex, pageSize, sortBy, filters ]);

    const [ paginationChangeType, setPaginationChangeType ] = useState(PaginationChangeActionType.NONE);

    const gotoNextPage = (): void => {
        nextPage();
        setPaginationChangeType(PaginationChangeActionType.NEXT_PAGE);
    };

    const gotoPrevPage = (): void => {
        previousPage();
        setPaginationChangeType(PaginationChangeActionType.PREV_PAGE);
    };

    const onGoToPage = (toPageIndex: number): void => {
        setPaginationChangeType(toPageIndex === 0 ? PaginationChangeActionType.FIRST_PAGE : PaginationChangeActionType.LAST_PAGE);
        gotoPage(toPageIndex);
    };

    const onPageSizeChange = (newPageSize: number): void => {
        localStorage.setItem(`${level}`, newPageSize.toString());
        setPageSize(localStorage.getItem(`${level}`) ? parseInt(localStorage.getItem(`${level}`) || '0') : newPageSize);
        setPaginationChangeType(PaginationChangeActionType.PAGE_SIZE);
    };
    const classes = useTableStyles();
    const { t } = useTranslation();
    const hasActionColumn = !!contextMenuItems || !!icons;

    const sortCB = useCallback((ColumnId: string) => {
        toggleSortBy(ColumnId);
    }, [ toggleSortBy ]);

    const colWidth = isSelectAble ? headerGroups[0].headers.length + 2 : headerGroups[0].headers.length + 1;

    return (
        <MatTableContainer
            data-testid="table-container"
            className="selector-base-table"
            style={{ position: 'relative' }}
            data-cy="table-container">
            <WithVisibility visible={showRefresh}>
                <RefreshBtn
                    className={classes.refreshIcon}
                    onClick={refreshButtonClicked}
                    entityName={entityName} />
            </WithVisibility>
            <MatTable
                className={classes.table + ' ' + (onTableCellClicked ? classes.cursorPointer : '')}
                size={size}
                aria-label={t('a11y_grid_content', { entityName })}>
                <TableHeaders
                    isfiltersVisible={isfiltersVisible}
                    isSelectAble={isSelectAble}
                    headerGroups={headerGroups}
                    gotoPage={gotoPage}
                    hasActionColumn={hasActionColumn}
                    toggleSortBy={sortCB}
                    refreshButtonClicked={refreshButtonClicked} />
                <MatTableBody>
                    <EmptyContent
                        empty={empty}
                        colspan={isSelectAble ? headerGroups[0].headers.length + 2 : headerGroups[0].headers.length + 1}
                        text={t('table_no_data')} />

                    {
                        isLoading ? (
                            Array(+pageSize).fill('')
                                .map((_, idx) => (
                                    <MatTableRow key={idx}>
                                        {Array(colWidth).fill('')
                                            .map((__, idxSk) => (
                                                <MatTableCell key={idxSk}>
                                                    <Skeleton
                                                        height="1.4rem"
                                                        variant="rectangular"
                                                        animation="wave" />
                                                </MatTableCell>
                                            ))}
                                    </MatTableRow>
                                ))
                        ) : rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <TableRow
                                    selectedFlatRows={selectedFlatRows}
                                    key={i}
                                    rowIndex={i}
                                    data-testid="table-row"
                                    data-cy="table-row"
                                    row={row}
                                    contextMenuItems={contextMenuItems}
                                    isSelectAble={isSelectAble}
                                    hasActionColumn={hasActionColumn}
                                    icons={icons}
                                    onTableCellClicked={onTableCellClicked}
                                    tooltip={onRowHoverTooltip ?
                                        typeof onRowHoverTooltip === 'string' ? onRowHoverTooltip : onRowHoverTooltip(row)
                                        : undefined}
                                    keyColumnIndex={keyColumnIndex}
                                />
                            );
                        })
                    }
                </MatTableBody>
            </MatTable>
            {
                empty ? null : (<Pagination
                    nextPage={gotoNextPage}
                    previousPage={gotoPrevPage}
                    pageNum={pageIndex + 1}
                    recordsPerPage={pageSize}
                    total={total}
                    isLoading={isLoading}
                    gotoPage={onGoToPage}
                    setPageSize={onPageSizeChange}
                    canNextPage={canNextPageProp}
                    paginationChangeActionType={paginationChangeType}
                    entityName={entityName}
                    level={level}
                />)
            }
            {
                (selectedFlatRows && selectedFlatRows.length && typeof selectionItems !== undefined && selectionItems) ? (
                    <div className={classes.selectionContainer}>
                        <Button
                            aria-label={t('a11y_apply_ops_selected_content')}
                            color="secondary"
                            onClick={(event): void => {
                                event.stopPropagation();
                                selectionItems.click(selectedFlatRows);
                            }}
                            startIcon={<DeleteIcon />}
                            data-testid={`selection-${selectionItems.text}`}
                            data-cy={`selection-${selectionItems.text}`}
                        >
                            {t(selectionItems.text)}
                        </Button>
                    </div>
                ) : null
            }
        </MatTableContainer >
    );
};

interface FiltersProps extends Conditional {
    headerGroup: HeaderGroup;
    gotoPage: any;
}

const Filters: React.FC<FiltersProps> = ({
    visible, headerGroup, gotoPage,
}) => {
    const { t } = useTranslation();
    return (
        visible ? (
            <MatTableRow>
                <td
                    aria-label={t('a11y_filter_control_label')}
                    colSpan={headerGroup.headers.length + 1}
                    style={{ padding: 0 }} >
                    <div style={{ display: 'flex' }}>
                        {headerGroup.headers.map((column: ColumnInstance, index) => (
                            (column.canFilter && column.Filter) ? (
                                <div
                                    className='filter'
                                    key={`filter_${index}`}>
                                    {column.render('Filter', { gotoPage })}
                                </div>
                            ) : null
                        ))}
                    </div>
                </td>
            </MatTableRow>
        ) : null
    );
};

const EmptyCell: React.FC<Conditional> = ({ visible }) => (visible ? <MatTableCell /> : null);

interface SortButtonParams extends Conditional {
    column: ColumnInstance;
}

const SortButtons: React.FC<SortButtonParams> = ({
    visible, column,
}) => {
    const { t } = useTranslation();
    return visible ? (
        <span>
            <div
                className="sortBlock"
                data-testid={`sort-${column.id}`}
                data-cy={`sort-${column.id}`}>
                <WithVisibility visible={column.isSortedDesc === undefined}>
                    <ArrowDropUp
                        aria-hidden="false"
                        aria-label={t('a11y_sort_asc')}
                        fontSize="small" />
                    <ArrowDropDown
                        aria-hidden="false"
                        aria-label={t('a11y_sort_desc')}
                        fontSize="small" />
                </WithVisibility>
                <WithVisibility visible={column.isSortedDesc === false}>
                    <ArrowDropUp
                        aria-hidden="false"
                        aria-label={t('a11y_sort_asc')}
                        fontSize="small" />
                </WithVisibility>
                <WithVisibility visible={column.isSortedDesc === true}>
                    <ArrowDropDown
                        aria-hidden="false"
                        aria-label={t('a11y_sort_desc')}
                        fontSize="small" />
                </WithVisibility>
            </div>
        </span>
    ) : null;
};

const TableHeaders: React.FC<TableHeadersProps> = ({
    hasActionColumn, headerGroups, isfiltersVisible, isSelectAble, toggleSortBy, gotoPage,
}) => (
    <MatTableHead className="tableHead">
        <Filters
            visible={isfiltersVisible}
            headerGroup={headerGroups[0]}
            gotoPage={gotoPage} />
        {headerGroups.map((headerGroup: HeaderGroup) => (
            <MatTableRow {...headerGroup.getHeaderGroupProps()}>
                <EmptyCell visible={isSelectAble} />
                {headerGroup.headers.map((column: any, index: number) => (
                    <Hidden
                        key={'column_' + index}
                        xlDown={column.xlDown}
                        lgDown={column.lgDown}
                        mdDown={column.mdDown}
                        smDown={column.smDown}
                        xlUp={column.xlUp}
                        lgUp={column.lgUp}
                        mdUp={column.mdUp}
                        smUp={column.smUp}
                    >
                        <TableHeadCell
                            column={column}
                            toggleSortBy={toggleSortBy} />
                    </Hidden>
                ))}
                <EmptyCell visible={hasActionColumn} />
            </MatTableRow>
        ))}
    </MatTableHead>
);
interface TableHeadCellProps {
    column: any;
    toggleSortBy: (ColumnId: string, descending?: boolean, isMulti?: boolean) => void;
}
const TableHeadCell: React.FC<TableHeadCellProps> = ({
    column, toggleSortBy,
}) => {
    const cellRef = useRef<HTMLDivElement | null>(null);
    const cb = useCallback(bindKeyTo({
        ref: cellRef,
        cycle: true,
    }), [ cellRef ]);
    return (
        <MatTableCell
            onClick={(): void => {
                column.sortable && toggleSortBy(column.id);
            }}
            {...(column.sortable ? column.getHeaderProps(column.getSortByToggleProps()) : column.getHeaderProps())}
            title=" "
            ref={cellRef}
            data-testid="table-cell-head"
            onKeyDown={cb}
            tabIndex={0}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }} >
                <ColumnTitle>
                    {column.render('Header')}
                </ColumnTitle>
                <SortButtons
                    visible={column.sortable}
                    column={column} />
            </div>
        </MatTableCell>
    );
};
interface TableRowProps {
    row: Row;
    contextMenuItems?: ContextMenuItem[];
    isSelectAble?: boolean;
    icons?: Icon[];
    hasActionColumn: boolean;
    tooltip?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedFlatRows?: any;
    // todo: Sahil to fix this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onTableCellClicked?(row: any, event: any): any;
    rowIndex: number;
    keyColumnIndex?: number;
}

export const useTableRowStyles = makeStyles((theme) => ({
    row: {
        '&:hover': { background: theme.palette.semantic.colorHover },
        '&:focus': { background: theme.palette.semantic.colorHover },
    },

    actionRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },

    selectedRow: { background: theme.palette.semantic.colorBackgroundDisabled },

    contextPopup: {
        marginLeft: '15px',
        border: 'none',
        margin: 0,
        padding: 0,

        '& .MuiPopover-paper': {
            minWidth: '250px',
            width: 'auto',
        },
    },
}));

const TableRow: React.FC<TableRowProps> = (
    {
        row,
        contextMenuItems,
        isSelectAble,
        hasActionColumn,
        icons,
        onTableCellClicked,
        selectedFlatRows,
        rowIndex,
        tooltip,
        keyColumnIndex,
    }) => {
    const { t } = useTranslation();
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const classes = useTableRowStyles();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checked = !!selectedFlatRows.find((r: any) => r.id === row.id);
    const [ clicked, setClicked ] = React.useState(false);
    const ref = useRef<any>();
    const iconsToRender: Icon[] = icons ? [ ...icons ] : [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMoreIconClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleContextPopupClose = (): void => {
        setAnchorEl(null);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOnTableCellClick = (event: any): void => {
        if (onTableCellClicked) {
            onTableCellClicked(row, event);
            setClicked(true);
        }
    };

    const keyInputHandler = useCallback(bindKeyTo({
        ref,
        traps: {
            [Key.Enter]: handleOnTableCellClick,
            [Key.Space]: handleOnTableCellClick,
            [Key.RightArrow]: () => {
                if (ref.current && filteredContextMenuItems && filteredContextMenuItems.length) {
                    ref.current.lastChild?.firstChild.focus();
                }
            },
        },
        cycle: true,
    }), [ ref ]);

    const rowProps = isSelectAble ?
        {
            ...row.getRowProps(),
            ...row.getToggleRowSelectedProps(),
        } :
        { ...row.getRowProps() };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cleanedRowProps: any = { ...rowProps };
    delete cleanedRowProps.indeterminate;
    const filteredContextMenuItems = contextMenuItems ? contextMenuItems.filter((item: ContextMenuItem) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rowInfo: any = row.original;
        return !(rowInfo && rowInfo.blobType && rowInfo.blobType === 'DIRECTORY' && item.text === 'dataset_download_label');
    }) : [];
    const rowComponent = (
        <MatTableRow
            className={(checked || clicked) ? classes.selectedRow : (onTableCellClicked ? ('cursor-pointer ' + classes.row) : classes.row)}
            data-testid="table-row"
            data-cy="table-row"
            {...cleanedRowProps}
            title=""
            tabIndex={rowIndex === 0 ? 0 : -1}
            ref={ref}
            onKeyDown={keyInputHandler}
        >
            {isSelectAble ?
                <MatTableCell>
                    <Checkbox
                        data-testid="table-selection-checkBox"
                        data-cy="table-selection-checkBox"
                        checked={checked}
                        value="primary"
                        color="secondary" />
                </MatTableCell> : null}
            {row.cells.map((cell, index) => {
                const pointerClass = onTableCellClicked ? 'cursor-pointer' : '';
                const column: any = cell.column;
                return (
                    <Hidden
                        key={index}
                        xlDown={column.xlDown}
                        lgDown={column.lgDown}
                        mdDown={column.mdDown}
                        smDown={column.smDown}
                        xlUp={column.xlUp}
                        lgUp={column.lgUp}
                        mdUp={column.mdUp}
                        smUp={column.smUp}
                    >
                        {
                            keyColumnIndex !== undefined && keyColumnIndex === index ? (
                                <MatTableCell
                                    onClick={handleOnTableCellClick}
                                    data-testid="tableCell"
                                    component="th"
                                    scope="row"
                                    className={pointerClass}
                                    title=""
                                    {...cell.getCellProps()}>
                                    <BodyText>
                                        {cell.render('Cell')}
                                    </BodyText>
                                </MatTableCell>
                            ) : (
                                <MatTableCell
                                    onClick={handleOnTableCellClick}
                                    data-testid="tableCell"
                                    className={pointerClass}
                                    title=""
                                    {...cell.getCellProps()}>
                                    <BodyText>
                                        {cell.render('Cell')}
                                    </BodyText>
                                </MatTableCell>
                            )
                        }
                    </Hidden>
                );
            })}

            {hasActionColumn ? (
                <MatTableCell>
                    <div className={classes.actionRow}>
                        {
                            iconsToRender.map((item: Icon, index) => (
                                <GetIcon
                                    key={`${index}_${item.title}_${item.iconType}`}
                                    row={row}
                                    type={item}
                                />))
                        }
                        {(filteredContextMenuItems && filteredContextMenuItems.length) ?
                            (
                                <>
                                    <IconButton
                                        aria-label={t('a11y_more')}
                                        key={`more-btn-click-${rowIndex}`}
                                        data-testid="contextMenuOpen-btn"
                                        data-cy="edit-action-menu"
                                        size="small"
                                        onClick={handleMoreIconClick} >
                                        <More />
                                    </IconButton>
                                    <Menu
                                        className={classes.contextPopup}
                                        autoFocus={false}
                                        style={{ marginLeft: '8px' }}
                                        id={`table-menu-${rowIndex}`}
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        anchorReference="anchorEl"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        onClose={handleContextPopupClose}>
                                        {
                                            filteredContextMenuItems.map((item: ContextMenuItem, key: number) => {
                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                const rowInfo: any = row.original;
                                                if (item.type && item.type === 'deleteMLPackageVersionDto' && rowInfo &&
                                                (rowInfo.status === 'DEPLOYED' || rowInfo.status === 'VALIDATING' || rowInfo.status === 'DEPLOYING')) {
                                                    return (
                                                        <MenuItem
                                                            disabled
                                                            data-testid={`context-item-${item.text}`}
                                                            data-cy={`${item.text}`}
                                                            key={key}>
                                                            {t(item.text)}
                                                        </MenuItem>
                                                    );
                                                } else if (item.type && item.type === 'downloadMLPackageVersion' && rowInfo && (!rowInfo?.contentUri ||
                                                    (rowInfo.status === 'VALIDATING' || rowInfo.status === 'VALIDATION FAILED'))) {
                                                    // For docker image content url won't be there, disabling download button
                                                    return (<MenuItem
                                                        disabled
                                                        data-testid={`context-item-${item.text}`}
                                                        data-cy={`${item.text}`}
                                                        key={key}>
                                                        {t(item.text)}
                                                    </MenuItem>
                                                    );
                                                } else if (item.disable) {
                                                    return (
                                                        <MenuItem
                                                            data-testid={`context-item-${item.text}`}
                                                            data-cy={`${item.text}`}
                                                            key={key}
                                                            disabled={typeof item.disable === 'boolean' ? item.disable : item.disable(rowInfo)}
                                                            onClick={(event: React.MouseEvent<HTMLLIElement>): void => {
                                                                if (item.click) {
                                                                    item.click(event, row);
                                                                }
                                                                handleContextPopupClose();
                                                            }}
                                                        >
                                                            {t(item.text)}
                                                        </MenuItem>
                                                    );
                                                }
                                                return (
                                                    <MenuItem
                                                        data-testid={`context-item-${item.text}`}
                                                        data-cy={`${item.text}`}
                                                        key={key}
                                                        onClick={(event: React.MouseEvent<HTMLLIElement>): void => {
                                                            if (item.click) {
                                                                item.click(event, row);
                                                            }
                                                            handleContextPopupClose();
                                                        }}
                                                    >
                                                        {t(item.text)}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Menu>
                                </>
                            ) : null}
                    </div>
                </MatTableCell>
            ) : null}
        </MatTableRow>
    );
    if (tooltip) {
        return (
            <Tooltip title={tooltip}>
                {rowComponent}
            </Tooltip>);
    }
    return rowComponent;
};

interface GetIconProps {
    type: Icon;
    row: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GetIcon: React.FC<GetIconProps> = (props: any): ReactElement => {
    const {
        type, row,
    } = props;
    const additionalProps: any = { size: 'small' };
    const ref = useRef();
    const cb = useCallback(bindKeyTo({
        ref,
        cycle: true,
        cycleByLR: true,
    }), [ ref ]);
    if (ref) {
        additionalProps.buttonRef = ref;
        additionalProps.onKeyDown = cb;
        additionalProps.tabIndex = -1;
    }
    const SKILL_DELETE_ALLOWED_STATUS: string[] = [ 'AVAILABLE', 'FAILED' ];
    const SKILL_CANCEL_RESTRICTED_STATUS: string[] = [ '' ];
    const { t } = useTranslation();

    switch (type.iconType) {
        case BaseTableIcons.DELETE:
            if (row.original.status && (SKILL_CANCEL_RESTRICTED_STATUS.indexOf(row.original.status) == -1) || row.original.blobName) {
                return (
                    <IconButton
                        aria-label={t('a11y_delete')}
                        {...additionalProps}
                        key={`delete-btn-${row.original.status}-${row.original.blobName}`}
                        data-testid="delete-btn"
                        data-cy="delete-button"
                        onClick={(event): void => {
                            if (type.click) {
                                type.click(event, row);
                            }
                        }}>
                        <DeleteIcon />
                    </IconButton>
                );
            }
            return (
                <IconButton
                    aria-label={t('a11y_delete')}
                    {...additionalProps}
                    key={`delete-btn-disabled-${row.original.id || uuid()}`}
                    disabled
                    data-testid="delete-btn"
                    data-cy="delete-button">
                    <DeleteIcon />
                </IconButton>
            );

        case BaseTableIcons.INFO:
            return (
                <IconButton
                    aria-label={t('a11y_info')}
                    {...additionalProps}
                    key={`info-btn-click-${row.original.id || uuid()}`}
                    data-testid="info-btn"
                    data-cy="info-button"
                    onClick={(event): void => {
                        if (type.click) {
                            type.click(event, row);
                        }
                    }} >
                    <InfoIcon />
                </IconButton>
            );

        case BaseTableIcons.DOWNLOAD:

            /* Don't show download button when blobType is directory */
            if (row.original.blobType && row.original.blobType === 'DIRECTORY') {
                return <span />;
            }

            return (
                <IconButton
                    aria-label={t('a11y_download')}
                    {...additionalProps}
                    key={`download-btn-click-${row.original.id || uuid()}`}
                    data-testid="download-btn"
                    data-cy="download-button"
                    onClick={(event): void => {
                        if (type.click) {
                            type.click(event, row);
                        }
                    }} >
                    <GetAppIcon />
                </IconButton>
            );

        case BaseTableIcons.MORE:

            return (
                <IconButton
                    {...additionalProps}
                    aria-label={t('a11y_more')}
                    key={`more-btn-click-${row.original.id || uuid()}`}
                    data-testid="contextMenuOpen-btn"
                    data-cy="edit-action-menu"
                    onClick={(event): void => {
                        if (type.click) {
                            type.click(event, row);
                        }
                    }} >
                    <More />
                </IconButton>
            );

        case BaseTableIcons.SWAPVERT:
            if (window.currentVersion === (row.original.version + '.' + row.original.trainingVersion)
        || (SKILL_DELETE_ALLOWED_STATUS.indexOf(window.currentStatus ? window.currentStatus : '') === -1)) {
                return (
                    <IconButton
                        aria-label={t('a11y_update')}
                        {...additionalProps}
                        key={`swap-vert-btn-click-${row.original.id || uuid()}`}
                        disabled
                        data-testid="swapvert-btn"
                        data-cy="swapvert-button" >
                        <SwapVertIcon />
                    </IconButton>
                );
            }
            return (
                <IconButton
                    {...additionalProps}
                    aria-label={t('a11y_update')}
                    key={`swap-vert-2-btn-click-${row.original.id || uuid()}`}
                    data-testid="swapvert-btn"
                    data-cy="swapvert-button"
                    onClick={(event): void => {
                        if (type.click) {
                            type.click(event, row);
                        }
                    }} >
                    <SwapVertIcon />
                </IconButton>
            );

    }
    return <span />;
};

export default CustomTable;
