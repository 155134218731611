import { AppProvisionDto, AppDto, LabellingFileRequestDto, BaseResponseOfstring, BaseResponseOfAppDto, BaseResponseOfListOfAppDto, BaseResponseOfLabellingFileDto, TaskCreateRequestDto, BaseResponseOfFolderDto, BaseResponseOfLabellingFileStatsDto } from "@uipath/aifabric";
import { http } from "../../http";
import { AxiosResponse } from "axios";
import URLManager from "../../config/URLManager";

export const createApp = async (createApp: AppProvisionDto): Promise<AppDto | undefined> => {
  const res = await http.post<AppProvisionDto, AxiosResponse<BaseResponseOfAppDto>>(
    URLManager.url().apiAppManager + "/app",
    createApp
  );
  return res.data.data;
};

export const deleteApp = async (appId: string, projectId: string) => {
  const res = await http.delete<AxiosResponse<BaseResponseOfstring>>(
    URLManager.url().apiAppManager + "/app/" + appId + "?projectId=" + projectId
  );
  return res.data;
};

export const getAppsForDataset = async (datasetId: string, projectId: string | undefined) => {
  const res = await http.get<BaseResponseOfListOfAppDto>(
    URLManager.url().apiAppManager + `/dataset/${datasetId}/apps?projectId=${projectId}`
  );
  return res;
};

export const importFile = async(fileRequestDto: LabellingFileRequestDto, appId: string): Promise<BaseResponseOfLabellingFileDto> => {
  const res = await http.post<LabellingFileRequestDto, AxiosResponse<BaseResponseOfLabellingFileDto>>(URLManager.url().apiAppManager + "/app/" + appId + "/files", fileRequestDto);
  return res.data;
}

export const sendToLabellers = async(createTaskRequestDto: TaskCreateRequestDto, appId: string, projectId: string ) => {
  const res = await http.post<TaskCreateRequestDto, AxiosResponse<BaseResponseOfstring>>(URLManager.url().apiAppManager + "/app/task/"
  + appId + "?projectId=" + projectId, createTaskRequestDto);
  return res.data;
}

export const getFolders = async (name?: string) => {
  const res = await http.get<BaseResponseOfFolderDto>(URLManager.url().apiAppManager + "/app/task/folders?name=" + name);
  return res.data;
}

export const getLabellingFileStats = async (projectId: string, appId: string): Promise<BaseResponseOfLabellingFileStatsDto> => {
  const res = await http.get<BaseResponseOfLabellingFileStatsDto>(URLManager.url().apiAppManager + "/labellingfiles/stats/" + appId  +  "?projectId=" + projectId);
  return res?.data;
}

export const deleteLabelingFile = async (projectId: string, datasetId: string, appId: string, fileName: string): Promise<AxiosResponse<BaseResponseOfstring>> => {
  const res = await http.delete<AxiosResponse<BaseResponseOfstring>>(
    URLManager.url().apiAppManager + "/app/" + appId + "/files?projectId=" + projectId + "&datasetId=" + datasetId + "&fileName=" + fileName
  );
  return res.data;
}
