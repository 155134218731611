import type { ProjectDto } from '@uipath/aifabric';
import React from 'react';
import { connect } from 'react-redux';

import type {
    ProjectActions,
    ProjectState,
} from '../../../api/global/useProjects';
import { useProjects } from '../../../api/global/useProjects';
import { ImportMLPackage } from '../../../components/mlPackage/importPackage';
import { AZURE_SAS_TOKEN_SUBS_STRING } from '../../../constants/BlobStorageConstants';

interface MLPackageImportProps {
    azureStorageFQDNSuffix: string;
}

const MLPackageImport: React.FC<MLPackageImportProps> = ({ azureStorageFQDNSuffix }) => {
    const importPackageType = 'ML_PACKAGE_VERSION_IMPORT';

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    return (
        <ImportMLPackage
            importPackageType={importPackageType}
            currentProject={currentProject}
            isOobImport={false}
            azureStorageFQDNSuffix={azureStorageFQDNSuffix}
        />
    );
};
export default connect((state: any) => ({ azureStorageFQDNSuffix: state.config.paths ? state.config.paths.azureStorageFQDNSuffix : AZURE_SAS_TOKEN_SUBS_STRING }))(MLPackageImport);
