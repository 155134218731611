import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { ProjectDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { Column } from 'react-table';

import { usePermissions } from '../../../api/global/usePermissions';
import type {
    ProjectActions,
    ProjectState,
} from '../../../api/global/useProjects';
import { useProjects } from '../../../api/global/useProjects';
import Section from '../../../components/Section';
import ServerSideTable from '../../../components/Table/ServerSideTable/ServerSideTable';
import URLManager from '../../../config/URLManager';
import { MLlogsSeverityMapping } from '../../../constants/EntityFieldMapping';
import { AppPermissions } from '../../../enums/Authorization';
import { RoutePath } from '../../../route/routeMap';
import {
    dataFormatter,
    entityDataMapper,
    returnValueIfNotUUID,
} from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';

const usePageStyles = makeStyles(() =>
    createStyles({
        oobLogsPage: {
            width: '100%',
            fontSize: Tokens.FontFamily.FontMSize,
            fontFamily: Tokens.FontFamily.FontNormal,
            overflowY: 'auto',
        },
    }),
);

interface ImportOOBLogsPageProps {
    permissions: AppPermissions[];
}

interface ImportOOBLogsListProps {
    auditsUrl: string;
}

const MLPackageImportOOBLogsPageContent: React.FC<ImportOOBLogsPageProps> = () => {

    const classes = usePageStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const auditsUrl = URLManager.url().apiHelper + '/audits?components=ML_PACKAGE&sortBy=createdOn&sortOrder=DESC&isPublic=true';
    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );
    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(currentProject?.id as string);

    const buttonItems = [];
    if ((permissions.indexOf(AppPermissions.MLPackages_Create) > -1)) {
        buttonItems.push({
            buttonText: 'import_oob_package_create_button_label',
            buttonCallback: () => {
                history.push(RoutePath.IMPORT_MLPACKAGE_OOB);
            },
        });
    }

    return (permissions.indexOf(AppPermissions.MLLogs_View) > -1) ? (
        <div className={classes.oobLogsPage}>
            <Section
                title={t('import_oob_ml_logs_title')}
                buttonItems={buttonItems}>
                <MLPackageImportOOBLogs auditsUrl={auditsUrl} />
            </Section>
        </div >
    ) : null;
};

const MLPackageImportOOBLogs: React.FC<ImportOOBLogsListProps> = ({ auditsUrl }) => {
    const {
        t, i18n,
    } = useTranslation();
    const dataMapper: Column[] = [
        {
            Header: `${t('label_severity')}`,
            accessor: 'severity',
            sortable: true,
            Cell: ({ cell: { value } }): string => dataFormatter(value),
        },
        {
            Header: `${t('label_about')}`,
            accessor: 'entityTypeName',
            sortable: true,
            Cell: ({ cell: { value } }): string => entityDataMapper(value, MLlogsSeverityMapping),
        },
        {
            Header: `${t('label_user')}`,
            accessor: 'createdBy',
            Cell: ({ cell: { value } }): string => returnValueIfNotUUID(value),
        },
        {
            Header: `${t('label_description')}`,
            accessor: 'message',
        },
        {
            Header: `${t('label_time')}`,
            accessor: 'createdOn',
            sortable: true,
            Cell: ({ cell: { value } }): string => dateFormatter(value, i18n.language),
        },
    ];

    return (
        <ServerSideTable
            url={auditsUrl}
            totalKey="data.totalCount"
            dataKey="data.dataList"
            mapper={dataMapper}
            searchable
            searchKey="searchText"
            level="mlpackages_pagesize"
        />
    );
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ImportOOBLogs = MLPackageImportOOBLogsPageContent;
