import type { BlobMetadata } from '@uipath/aifabric';
import prettyBytes from 'pretty-bytes';
import type { Column } from 'react-table';

import i18n from '../il8n';
import type { PackageVersionProps } from '../pages/mlPackageVersion/MLPackageVersionsPage';
import {
    dataFormatter,
    getFileOrFolderNameForPath,
} from '../utils/CommonUtils';
import { dateFormatter } from '../utils/DateFormatter';

const TableHeaderMappings: {
    VersionsList: Column[];
    DatasetsFolderMappingsBase: Column[];
    DatasetsBase: Column[];
} = {
    VersionsList: [
        {
            Header: `${i18n.t('common_package_version_label')}`,
            accessor: 'versions',
            sortable: true,
            Cell: ({ row }): string => {
                const packageVersionDetail: PackageVersionProps = row.original;

                if (packageVersionDetail.customVersion) {
                    return packageVersionDetail.customVersion;
                }
                return packageVersionDetail.version?.toString() || '';

            },
        },
        {
            Header: `${i18n.t('common_train_version_label')}`,
            accessor: 'trainingVersions',
            sortable: false,
            Cell: ({ row }): string => {
                const packageVersionDetail: PackageVersionProps = row.original;
                return packageVersionDetail.trainingVersion?.toString() || '';
            },
        },
        {
            Header: `${i18n.t('common_created_time_label')}`,
            accessor: 'createdOn',
            sortable: true,
            Cell: ({ cell: { value } }): string => dateFormatter(value, i18n.language),
        },
        {
            Header: `${i18n.t('common_change_log_label')}`,
            accessor: 'changeLog',
        },
    ],
    DatasetsFolderMappingsBase: [
        {
            Header: `${i18n.t('common_name_label')}`,
            accessor: 'blobName',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Cell: (data: any): string => getFileOrFolderNameForPath(data),
        },
        {
            Header: `${i18n.t('common_size_label')}`,
            accessor: 'blobSize',
            Cell: ({ cell }): string => {
                const blobInfo: BlobMetadata = cell.row.original;
                if (blobInfo && blobInfo.blobType !== 'DIRECTORY' && typeof cell.value !== 'undefined' && cell.value) {
                    return prettyBytes(cell.value);
                }
                return '--';

            },
        },
        {
            Header: `${i18n.t('common_type_label')}`,
            accessor: 'blobType',
            Cell: ({ cell: { value } }): string => dataFormatter(i18n.t(`label_${value?.toLowerCase()}`), i18n.language),
            mdDown: true,
        },
        {
            Header: `${i18n.t('common_created_label')}`,
            accessor: 'createTime',
            Cell: ({ cell: { value } }): string => dateFormatter(value, i18n.language),
            smDown: true,
        },
    ],
    DatasetsBase: [
        {
            Header: `${i18n.t('common_name_label')}`,
            accessor: 'name',
            sortable: true,
        },
        {
            Header: `${i18n.t('common_description_label')}`,
            accessor: 'description',
            sortable: true,
            mdDown: true,
        },
        {
            Header: `${i18n.t('common_created_label')}`,
            accessor: 'createdOn',
            sortable: true,
            Cell: ({ cell: { value } }): string => dateFormatter(value, i18n.language),
            smDown: true,
        },
    ],
};
export default TableHeaderMappings;
