import AddIcon from '@mui/icons-material/Add';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
    Box,
    Button,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { ProjectDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import type { ReactElement } from 'react';
import React, {
    useCallback,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';

import {
    deleteProject,
    getProjectsRBAC,
} from '../../api/client/projectManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import { usePermissions } from '../../api/global/usePermissions';
import {
    UpdateType,
    useProjects,
} from '../../api/global/useProjects';
import type { ContextMenuItem } from '../../components/ContextPopup';
import { CustomDialog } from '../../components/Dialog';
import FullPageLoader from '../../components/FullPageLoader';
import ProjectCard from '../../components/ProjectCard';
import { AppPermissions } from '../../enums/Authorization';
import {
    Origin,
    Scope,
    Service,
} from '../../enums/ClientErrorStrings';
import { RoutePath } from '../../route/routeMap';
import { AppMetaActions } from '../../state-management/Actions';
import { store } from '../../state-management/store';
import {
    extractErrorMessage,
    getDisplayErrorCode,
} from '../../utils/CommonUtils';
import logger from '../../utils/Logging';
import landingImage from './defaultImage.png';

const useStyle = makeStyles((theme: Theme) => createStyles({
    projectList: {
        display: 'block',
        padding: '24px 24px 0 24px',
        width: '100%',
        overflowY: 'auto',
        height: 'calc(100vh - 82px)',

        '& .portal-landing': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexGrow: 1,
            backgroundColor: theme.palette.semantic.colorBackground,
            padding: '24px',

            '& .text-area': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            },

            '& .image-spacing': {
                display: 'flex',
                minWidth: '48px',
                minHeight: '48px',
            },
            '& .image': {
                width: '100vh',
                height: '70vh',
            },
            '& .footer-slots': {
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '48px',
            },
            '& .middle-slots': {
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '48px',
                paddingRight: '24px',
            },
            '& .sub-title': {
                display: 'flex',
                flexDirection: 'row',
                fontSize: Tokens.FontFamily.FontLSize,
                lineHeight: Tokens.FontFamily.FontLLineHeight,
                color: theme.palette.semantic.colorForegroundDeEmp,
            },
            '& .title': {
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '24px',
                fontSize: Tokens.FontFamily.FontHeader3Size,
                lineHeight: Tokens.FontFamily.FontHeader3LineHeight,
                color: theme.palette.semantic.colorForegroundEmp,
                fontWeight: Tokens.FontFamily.FontWeightBold,
            },
        },
    },
    createProject: {
        marginRight: theme.spacing(6),
        fontFamily: Tokens.FontFamily.FontTitle,
        fontStyle: 'normal',
        fontWeight: Tokens.FontFamily.FontWeightBold,
        alignItems: 'right',
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        color: theme.palette.semantic.colorForegroundHigh,
    },
    landingButton: { width: '280px' },
    projectsLabel: {
        padding: '0px',
        margin: '0px',
        color: theme.palette.semantic.colorForegroundEmp,
        width: '50%',
    },
    createProjectButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px',
        margin: '0px',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px',
        margin: theme.spacing(1),
    },
}));

const ProjectList: React.FC = () => {

    const [ , permissionsActions ] = usePermissions();
    const permissions = permissionsActions.getTenantPermissions();
    const feedback = useFeedback();
    const [ projects, setProjects ] = useState<ProjectDto[] | undefined>([]);
    const [ otherProjects, setOtherProjects ] = useState<ProjectDto[] | undefined>([]);
    const [ isProjectsLoaded, setIsProjestsLoaded ] = useState(false);
    const [ isError, setIsError ] = useState(false);

    const updateProjectsList = (projectList: ProjectDto[] | undefined): void => {
        setProjects(projectList);
    };

    const updateOtherProjectsList = (projectList: ProjectDto[] | undefined): void => {
        setOtherProjects(projectList);
    };

    const projectListFetchError = (error: any) => {
        logger.error({
            identifier: 'Project List',
            message: 'Error while getting projects',
            error,
        });
        setIsError(true);
        setIsProjestsLoaded(false);
        if (error?.response?.status === 403) {
            store.dispatch({
                type: AppMetaActions.FAIL,
                payload: {
                    status: 'empty_permissions_project',
                    backendCode: getDisplayErrorCode(Scope.Core, Service.HELPER, Origin.PROJECTLIST, error, error.response.status),
                },
            });
        } else {
            feedback.enqueueError(extractErrorMessage(
                error,
                t('feedback_project_list_failed'),
                { 20205: {} },
            ));
        }
    };

    React.useEffect(() => {
        getProjectsRBAC().then((projectsData) => {
            if (projectsData) {
                setProjects(projectsData?.owned?.map(p => p.projectDto) || []);
                setOtherProjects(projectsData?.others?.map(p => p.projectDto) || []);
                setIsError(false);
                setIsProjestsLoaded(true);
            }
            return true;
        })
            .catch((error) => {
                projectListFetchError(error);
            });
    }, []);

    const { t } = useTranslation();
    const classes = useStyle();
    /* Div id is being used */
    return <div
        id="scrollableDiv"
        data-testid="projectInfiniteScroller"
        className={classes.projectList}>
        <ProjectListView
            projects={projects}
            otherProjects={otherProjects}
            canDelete={(permissions.indexOf(AppPermissions.MLProjects_Delete) > -1)}
            canEdit={(permissions.indexOf(AppPermissions.MLProjects_Edit) > -1)}
            canCreate={(permissions.indexOf(AppPermissions.MLProjects_Create) > -1)}
            isProjectsLoaded={isProjectsLoaded}
            updateProjectsList={updateProjectsList}
            updateOtherProjectsList={updateOtherProjectsList}
            isError={isError}
        />
    </div>
    ;
};

interface ProjectListViewProps {
    projects: ProjectDto[] | undefined;
    otherProjects: ProjectDto[] | undefined;
    canDelete: boolean;
    canEdit: boolean;
    canCreate: boolean;
    isProjectsLoaded: boolean;
    isError: boolean;
    updateProjectsList: (projects: ProjectDto[] | undefined) => void;
    updateOtherProjectsList: (projects: ProjectDto[] | undefined) => void;
}

enum TabTypes {
    Owned,
    Others,
}

interface TabPanelProps {
    children?: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    index: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
}

const TabPanel = (props: TabPanelProps): ReactElement => {
    const {
        children, value, index, ...other
    } = props;
    const { t } = useTranslation();

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-label={t('a11y_public_tab_panel')}
            {...other}
        >
            {value === index && <Box p={3}>
                {children}
            </Box>}
        </Typography>
    );
};

const ProjectListView: React.FC<ProjectListViewProps> = ({
    projects,
    otherProjects,
    canDelete,
    canEdit,
    canCreate,
    updateProjectsList,
    updateOtherProjectsList,
    isProjectsLoaded,
    isError,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const feedback = useFeedback();
    const [ , actions ] = useProjects();
    const classes = useStyle();
    const [ open, setOpen ] = React.useState(false);
    const [ activeProjectId, setActiveProjectId ] = React.useState('');
    const [ activeTab, setActiveTab ] = useState(TabTypes.Owned);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => setActiveTab(newValue);

    const menuItems: ContextMenuItem[] = [];

    const handleNewProjectClick = useCallback((): void => {
        history.push(RoutePath.CREATE_PROJECT);
    }, []);

    if (canEdit) {
        menuItems.push({
            text: 'project_list_context_menu_project_edit',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, data: any): void => {
                history.push(generatePath(RoutePath.EDIT_PROJECT, { projectName: data.name }));
            },
        });
    }

    if (canDelete) {
        menuItems.push({
            text: 'project_list_context_menu_project_delete',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, data: any): void => {
                setOpen(true);
                setActiveProjectId(data.id);
            },
        });
    }

    const deleteSelectedProject = (): void => {
        deleteProject(activeProjectId).then(() => {
            closeDialog();
            /* Update state so that page can load faster with new list */
            const projectList = TabTypes.Owned === activeTab ? projects : otherProjects;
            const index = projectList?.findIndex(project => project.id === activeProjectId);
            if (typeof index !== 'undefined' && index !== -1 && projectList) {
                if (TabTypes.Owned === activeTab) {
                    actions.updateProjectsList(projectList[index], UpdateType.Delete);
                    updateProjectsList(projects?.filter(project => project.id !== activeProjectId));
                } else {
                    updateOtherProjectsList(projectList?.filter(project => project.id !== activeProjectId));
                }
            }
            feedback.enqueueSuccess(t('feedback_delete_success_project'));
            return true;
        })
            .catch(error => {
                closeDialog();
                logger.error({
                    identifier: 'Project Details',
                    message: 'Error while deleting project',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('error_delete_project_unkonwn_error'),
                    {
                        20203: { 0: activeProjectId || '' },
                        20209: { 0: activeProjectId || '' },
                        20204: { 0: activeProjectId || '' },
                        20208: { 0: activeProjectId || '' },
                        20212: { 0: activeProjectId || '' },
                    },
                ));
            });
    };

    const closeDialog = (): void => {
        setOpen(false);
    };

    if (!isProjectsLoaded && !isError) {
        return (<FullPageLoader
            open
            transparent />);
    }

    return <>
        <div className={classes.headerContainer}>
            <h2 className={classes.projectsLabel}>
                {t('projects')}
            </h2>
            {
                canCreate ? <div className={classes.createProjectButtonContainer}>
                    <Button
                        id="create-project-button"
                        disableElevation
                        disableFocusRipple
                        color="primary"
                        onClick={handleNewProjectClick}
                        data-cy="newProject"
                        aria-label={t('create_project')}
                        className={classes.createProject}
                    >
                        {t('create_project')}
            &nbsp;
                        <AddRoundedIcon />
                    </Button>
                </div> : null
            }
        </div>
        <div>
            <CustomDialog
                title={t('project_delete_dialog_title_text')}
                open={open}
                handleClose={closeDialog}
                closeIconButton
                infoText={t('project_delete_dialog_info_text')}
                warningText={t('project_delete_dialog_warning_text')}
                confirmationText={t('project_delete_dialog_confirmation_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={deleteSelectedProject}
                secondarybuttonCallback={closeDialog} />

            <Tabs
                value={activeTab}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleChange}
                aria-label={t('a11y_pipeline_scheduler')}
            >
                <Tab
                    label={t('my_projects')}
                    data-testid="my-projects-tab" />
                <Tab
                    label={t('other_projects')}
                    data-testid="other-projects-tab" />
            </Tabs>
            <TabPanel
                value={activeTab}
                index={TabTypes.Owned}>
                <div style={{
                    display: 'flex',
                    marginLeft: '-24px',
                    flexWrap: 'wrap',
                }}>
                    {
                        (projects && projects.length > 0) ?
                            (
                                projects.map((project: ProjectDto, index: number) =>
                                    <div
                                        data-cy={`project_${project.name!}`}
                                        key={index}>
                                        <ProjectCard
                                            title={project.name!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                            description={project.description!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                            leftBadgeValue={project.activePipelines!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                            rightBadgeValue={project.deployedPackages!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                            leftBadgeTitle={t('project_list_active_pipelines')}
                                            rightBadgeTitle={t('project_list_deployed_packages')}
                                            route={generatePath(RoutePath.PROJECT_DASHBOARD, { projectName: project?.name })}
                                            projectId={project.id || ''}
                                            projectName={project.name || ''}
                                            contextMenuItems={menuItems} />
                                    </div>,
                                )
                            ) : (
                                <PortalLanding>
                                    {canCreate && (
                                        <Button
                                            id="create-project-button"
                                            className={classes.landingButton}
                                            data-cy="landing-create"
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            name="create project"
                                            aria-label={t('a11y_create_project')}
                                            size='large'
                                            startIcon={<AddIcon />}
                                            onClick={(): void => {
                                                history.push(RoutePath.CREATE_PROJECT);
                                            }}
                                        >
                                            {t('create_project')}
                                        </Button>)}
                                </PortalLanding>
                            )
                    }
                </div>
            </TabPanel>
            <TabPanel
                value={activeTab}
                index={TabTypes.Others}>
                <div style={{
                    display: 'flex',
                    marginLeft: '-24px',
                    flexWrap: 'wrap',
                }}>
                    {
                        (otherProjects && otherProjects.length > 0) ?
                            (
                                otherProjects.map((project: ProjectDto, index: number) =>
                                    <div
                                        data-cy={`project_${project.name!}`}
                                        key={index}>
                                        <ProjectCard
                                            title={project.name!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                            description={project.description!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                            leftBadgeValue={project.activePipelines!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                            rightBadgeValue={project.deployedPackages!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                            leftBadgeTitle={t('project_list_active_pipelines')}
                                            rightBadgeTitle={t('project_list_deployed_packages')}
                                            route={generatePath(RoutePath.PROJECT_DASHBOARD, { projectName: project?.name })}
                                            projectId={project.id || ''}
                                            projectName={project.name || ''}
                                            contextMenuItems={menuItems} />
                                    </div>,
                                )
                            ) : null
                    }
                </div>
            </TabPanel>
        </div>
    </>;
};

const PortalLanding: React.FC = ({ children }) => {
    const { t } = useTranslation();
    return (
        <div className="portal-landing">
            <div className="text-area">
                <div
                    className="title"
                    title="">
                    {t('project_list_landing_title')}
                </div>
                <div className="sub-title">
                    {t('project_list_landing_subtitle')}
                </div>
                <div className="middle-slots">
                    {children}
                </div>
                <div className="footer-slots" />
            </div>

            <div className="image-spacing" />
            <img
                className="image"
                src={landingImage} />
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default ProjectList;
