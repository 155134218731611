import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';

export const cronStyles = makeStyles((theme) =>
    createStyles({
        recurrenceDialog: {
            '& .MuiDialogTitle-root': {
                padding: '0 0 0 24px',
                marginTop: '24px',
            },
            '& .MuiDialogContent-root': { padding: '0 0 0 24px' },
        },
        cronContainer: {
            position: 'relative',
            minHeight: '384px',
            minWidth: '400px',

            '& .MuiOutlinedInput-root': {
                height: Tokens.FontFamily.FontHeader1LineHeight,
                lineHeight: Tokens.FontFamily.FontHeader1LineHeight,
            },

            '& .MuiTypography-colorTextPrimary': {
                fontSize: Tokens.FontFamily.FontMSize,
                height: Tokens.FontFamily.FontHeader1LineHeight,
                fontWeight: 'normal',
                lineHeight: Tokens.FontFamily.FontHeader1LineHeight,
            },
            '& .timePicker': { '& .MuiInputBase-input': { width: '10em' } },
            '& .buttonControls': {
                position: 'absolute',
                right: 24,
                bottom: 24,
            },
            '& .flexed': {
                display: 'flex',
                alignItems: 'center',

                '& .errorContent': {
                    color: theme.palette.semantic.colorErrorText,
                    fontSize: Tokens.FontFamily.FontSSize,
                    fontWeight: Tokens.FontFamily.FontWeightDefault,
                },

                '& .custom-input': {
                    position: 'relative',

                    '& .errorContent': {
                        position: 'absolute',
                        bottom: 0,
                        transform: 'translateY(100%)',
                        width: '150px',
                        wordBreak: 'break-word',
                    },
                },

                '& .time-container': {
                    marginRight: '50px',

                    '& .MuiFilledInput-inputMarginDense': { paddingTop: '8px' },
                },

                '& .MuiInputBase-input': { width: '6rem' },
            },
            '& .flexInputContainer': {
                marginBottom: '56px',

                '&.scheduled-container': {
                    marginTop: '32px',
                    height: '8rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                },

                '& .cronExpression': {
                    minWidth: '260px',

                    '& .cronExpressionTextField': { width: '200px' },
                },

                '& .hintText': {
                    fontStyle: 'italic',
                    fontWeight: 500,
                    fontSize: Tokens.FontFamily.FontMSize,
                },
                '& .label': {
                    minWidth: '50px',
                    marginRight: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '32px',
                    lineHeight: Tokens.FontFamily.FontLLineHeight,
                },
            },
        },
    }),
);
