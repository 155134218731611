import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { ProjectDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import type { TFunction } from 'i18next';
import React, {
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';
import type { Column } from 'react-table';

import {
    deleteMLSkill,
    updateMLSkill,
} from '../../../api/client/deployerManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import { usePermissions } from '../../../api/global/usePermissions';
import { useProjects } from '../../../api/global/useProjects';
import { CustomDialog } from '../../../components/Dialog';
import Section from '../../../components/Section';
import ServerSideTable from '../../../components/Table/ServerSideTable/ServerSideTable';
import URLManager from '../../../config/URLManager';
import { AppPermissions } from '../../../enums/Authorization';
import { BaseTableIcons } from '../../../enums/BaseTableIcons';
import { RoutePath } from '../../../route/routeMap';
import {
    dataFormatter,
    extractErrorMessage,
} from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';
import logger from '../../../utils/Logging';

const useMlSkillsPageStyles = makeStyles(() =>
    createStyles({
        mlSkillsPage: {
            width: '100%',
            fontSize: Tokens.FontFamily.FontMSize,
            fontFamily: Tokens.FontFamily.FontNormal,
            overflowY: 'auto',
        },
    }),
);

export const setClearOrDoneIcon = (value: number, t: TFunction): React.ReactElement => (
    <div>
        {' '}
        {typeof value === 'undefined' || value === 0 ?
            <ClearSharpIcon
                aria-hidden="false"
                aria-label={t('a11y_no')} />
            : <DoneSharpIcon
                aria-hidden="false"
                aria-label={t('a11y_yes')} />}
    </div>
);

const setGPUEnabled = (value: string, t: TFunction): React.ReactElement => (
    <div>
        {' '}
        {typeof value === 'undefined' || value === 'CPU' ?
            <ClearSharpIcon
                aria-hidden="false"
                aria-label={t('a11y_no')} />
            : <DoneSharpIcon
                aria-hidden="false"
                aria-label={t('a11y_yes')} />}
    </div>
);

interface MLSkillVersionProps {
    currentVersion?: number;
    currentTrainingVersion?: number;
    currentCustomVersion?: string;
}

interface MlSkillListPageContentProps {
    permissions: AppPermissions[];
    currentProject: ProjectDto;
}

const MlSkillListPageContent: React.FC = () => {

    const { t } = useTranslation();
    const classes = useMlSkillsPageStyles();
    const history = useHistory();
    const [ state ] = useProjects();

    const currentProject = React.useMemo(() => state.currentProject, [ state ]);

    const [ , permissionsActions ] = usePermissions();
    const permissions = permissionsActions.getProjectPermissions(currentProject?.id as string);

    const buttonItems = [];
    if ((permissions.indexOf(AppPermissions.MLSkills_Create) > -1)) {
        buttonItems.push({
            buttonText: 'pipeline_list_create_new_button',
            buttonCallback: () => {
                history.push(generatePath(RoutePath.CREATE_MLSKILL, { projectName: currentProject?.name }));
            },
        });
    }

    return currentProject?.id ? (
        <div className={classes.mlSkillsPage}>
            <Section
                title={t('ml_skills_title')}
                buttonItems={buttonItems}>
                <MlSkillList
                    permissions={permissions}
                    currentProject={currentProject} />
            </Section>
        </div >
    ) : null;
};

export const MlSkillListPage = connect(() => ({}))(MlSkillListPageContent);

const MlSkillList: React.FC<MlSkillListPageContentProps> = ({
    permissions, currentProject,
}) => {
    const {
        t, i18n,
    } = useTranslation();

    const dataMapper: Column[] = [
        {
            Header: `${t('ml_skill_list_skill_name_header_label')}`,
            sortable: true,
            accessor: 'name',
        },
        {
            Header: `${t('common_package_name_label')}`,
            sortable: true,
            accessor: 'mlPackageName',
        },
        {
            Header: `${t('common_version_label')}`,
            sortable: true,
            accessor: 'currentVersion',
            Cell: ({ row }): string => {
                const skillVersionDetail: MLSkillVersionProps = row.original;
                if (skillVersionDetail.currentCustomVersion) {
                    return skillVersionDetail.currentCustomVersion + '.' + skillVersionDetail.currentTrainingVersion;
                }
                return skillVersionDetail.currentVersion + '.' + skillVersionDetail.currentTrainingVersion;

            },
            mdDown: true,
        },
        {
            Header: `${t('common_status_label')}`,
            sortable: true,
            accessor: 'status',
            Cell: ({ cell: { value } }): string => dataFormatter(t(`ML_SKILL_STATUS_${value}`), i18n.language),
        },
        {
            Header: `${t('common_deployed_label')}`,
            sortable: true,
            accessor: 'createdOn',
            Cell: ({ cell: { value } }): string => dateFormatter(value, i18n.language),
            smDown: true,
        },
        {
            Header: `${t('common_gpu_label')}`,
            accessor: 'processor',
            sortable: true,
            Cell: ({ cell: { value } }): React.ReactElement => setGPUEnabled(value, t),
            mdDown: true,
        },
        {
            Header: `${t('common_prediction_label')}`,
            sortable: true,
            accessor: 'predictionCount',
            smDown: true,
        },
    ];

    const [ open, setOpen ] = useState(false);
    const mlSkillsBaseUrl = URLManager.url().apiDeployer + '/mlskills';
    const feedback = useFeedback();
    const [ itemDeletedFlag, setItemDeletedFlag ] = useState(false);
    const [ cancelDeployment, setCancelDeployment ] = useState(false);

    const SKILL_DELETE_ALLOWED_STATUS: string[] = [ 'AVAILABLE', 'FAILED', 'STOPPED' ];

    const url = React.useMemo(() => mlSkillsBaseUrl + '?sortBy=createdOn&sortOrder=DESC&includedLanguageGroups=ALL&projectId=' + currentProject?.id, [ currentProject ]);

    const mlSkillId = useRef('');
    const mlPackageVersionId = useRef('');
    const mlSkillName = useRef('');
    const history = useHistory();

    const closeDialog = (): void => {
        setOpen(false);
    };

    const icons = [];
    if ((permissions.indexOf(AppPermissions.MLSkills_Delete) > -1)) {
        icons.push({
            iconType: BaseTableIcons.DELETE,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, rowInfo: any): void => {
                mlSkillId.current = rowInfo.original.id;
                mlSkillName.current = rowInfo.original.name;
                mlPackageVersionId.current = rowInfo.original.mlPackageVersionId;
                setCancelDeployment(SKILL_DELETE_ALLOWED_STATUS.indexOf(rowInfo.original.status) == -1);
                setOpen(true);
            },
        });
    }

    /*  Delete Skill deployment */
    const deleteSelectedMlSkill = (): void => {
        deleteMLSkill(mlSkillId.current, currentProject?.id).then(() => {
            closeDialog();
            feedback.enqueueSuccess(t('feedback_delete_success_mlskill'));
            /* Toggle the flag to reload table data */
            setItemDeletedFlag(prev => !prev);
            return true;
        })
            .catch(error => {
                closeDialog();
                logger.error({
                    identifier: 'ML Skill list',
                    message: 'Error deleting ML Skill',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error, t('feedback_delete_error_mlskill'),
                    { 10009: {} },
                ));
            });
    };

    /*  Cancel in Progress Skill deployment */
    const cancelSkillDeployment = async (): Promise<void> => {
        await updateMLSkill(
            {
                deploymentsRequired: 1,
                gpuRequired: 0,
                mlPackageVersionId: mlPackageVersionId.current,
                processor: 'CPU',
                publicSkill: false,
            },
            mlSkillId.current, /* ML Skill ID */
            'CANCEL',
            currentProject?.id, /* Project ID */
        ).then(() => {
            closeDialog();
            feedback.enqueueSuccess(t('feedback_cancel_success_mlskill'));
            /* Toggle the flag to reload table data */
            setItemDeletedFlag(prev => !prev);
            return true;
        })
            .catch(error => {
                closeDialog();
                logger.error({
                    identifier: 'ML Skill list',
                    message: 'Error cancelling ML Skill',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error, t('feedback_cancel_error_mlskill'),
                    {
                        10009: {},
                        40016: {},
                    },
                ));
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMlSkillClickEvent = (rowInfo: any): void => {
        history.push(
            {
                pathname: generatePath(RoutePath.MLSKILL_DETAILS, { projectName: currentProject?.name }),
                state: { data: rowInfo.original },
            },
        );
    };

    return (
        <div>
            <CustomDialog
                title={cancelDeployment === true ? (t('ml_skill_cancel_dialog_title_text', { mlSkillName: mlSkillName.current }))
                    : (t('ml_skill_delete_dialog_title_text', { mlSkillName: mlSkillName.current }))}
                open={open}
                handleClose={closeDialog}
                closeIconButton
                infoText={cancelDeployment === true ? '' : t('ml_skill_delete_dialog_info_Text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={cancelDeployment === true ? cancelSkillDeployment : deleteSelectedMlSkill}
                secondarybuttonCallback={closeDialog}
            />

            {
                (permissions.indexOf(AppPermissions.MLSkills_View) > -1) ? (
                    <ServerSideTable
                        url={url}
                        searchable
                        searchKey="name"
                        totalKey="data.totalCount"
                        dataKey="data.dataList"
                        mapper={dataMapper}
                        icons={icons}
                        onTableCellClicked={handleMlSkillClickEvent}
                        itemDeletedFlag={itemDeletedFlag}
                        keyColumnIndex={0}
                        level="mlskills_pagesize"
                    />
                ) : null
            }
        </div>
    );
};
