import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

const styles = makeStyles((theme: Theme) => createStyles({
    infoIcon: {
        marginLeft: '4px',
        color: theme.palette.semantic.colorForeground,
        fontSize: '1em',
    },
}));

interface AccessibleTooltipProps {
    title: string;
    customClass?: string;
}

export function useEventListener<E extends Event>(
    eventName: string,
    handler: (event: E) => void,
) {
    const savedHandler = React.useRef<(event: E) => void>();

    React.useEffect(() => {
        savedHandler.current = handler;
    }, [ handler ]);

    React.useEffect(() => {
        const isSupported = window?.addEventListener;
        if (!isSupported) {
            return;
        }

        const eventListener: EventListener = (event) => {
            savedHandler.current?.(event as E);
        };

        window.addEventListener(eventName, eventListener);

        return () => {
            window.removeEventListener(eventName, eventListener);
        };
    }, [ eventName ]);
}

const AccessibleTooltip: React.FC<AccessibleTooltipProps> = (props) => {
    const classes = styles();
    const [ open, setOpen ] = React.useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);
    const {
        title, customClass, ...rest
    } = props;

    const handler = React.useCallback((e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    }, []);

    useEventListener('keydown', handler);

    return (
        <Tooltip
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            title={title}
            aria-label={title}
            {...rest}>
            <InfoOutlinedIcon
                className={customClass ? `${customClass} ${classes.infoIcon}` : classes.infoIcon}
                aria-label={`${title} info icon`}
                tabIndex={0}
            />
        </Tooltip>
    );
};

export default AccessibleTooltip;
