import type { ThemeChangedEvent } from '@uipath/portal-shell/dist/types/models/events/themeChangedEvent';
import jwt from 'jsonwebtoken';
import React, {
    useCallback,
    useState,
} from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import type { CombinedState } from 'redux';

import { usePermissions } from '../../api/global/usePermissions';
import { PermissionState } from '../../api/global/usePermissions.d';
import { DEFAULT_PAGE_SIZE } from '../../constants/AiappConstants';
import { Authentication } from '../../enums/Authentication';
import type { FeatureFlagParams } from '../../feature-flag/FeatureFlagFactory';
import FeatureFlagFactory from '../../feature-flag/FeatureFlagFactory';
import type FeatureFlagManager from '../../feature-flag/FeatureFlagManager';
import { getAccountAndTenantFromCannonicalPath } from '../../route/routeHelper';
import { RoutePath } from '../../route/routeMap';
import {
    ConfigurationActions,
    FeatureFlagActions,
} from '../../state-management/Actions';
import type { FeatureManagement } from '../../state-management/states';
import { store } from '../../state-management/store';
import type { AppStore } from '../../state-management/store.d';
import { AiCenterApp } from './AiCenterApp';
import type {
    AppProps,
    TenantChangeEventDetail,
} from './App.d';
import { ErrorBoundary } from './ErrorBoundary';

const { account } = getAccountAndTenantFromCannonicalPath();

const themeChangeCallback = ((evt: CustomEvent<ThemeChangedEvent>) => {
    if (evt && evt.detail && evt.detail.selectedThemeId) {
        localStorage.setItem('aic_theme', evt.detail.selectedThemeId);
        store.dispatch({
            type: ConfigurationActions.UPDATE_THEME,
            payload: { theme: evt.detail.selectedThemeId },
        });
    }
}) as EventListener;

const onTenantChanged = ((evt: CustomEvent<TenantChangeEventDetail>) => {
    if (evt && evt.detail && evt.detail.selectedTenantId) {
        const {
            account, tenant,
        } = getAccountAndTenantFromCannonicalPath();
        if (account !== '' && tenant !== '' && evt.detail.selectedTenantId?.toLowerCase() !== tenant?.toLowerCase()) {
            window.location.href = window.location.origin + generatePath(RoutePath.BASE, {
                account,
                tenant: evt.detail.selectedTenantId,
            });
        }
    }
}) as EventListener;

const updateFeatureFlagToStore = ({ isOnPrem }: FeatureFlagParams, featureManagement: FeatureManagement, decodedToken: any) => {
    const featureManager: FeatureFlagManager | null = FeatureFlagFactory.getInstance({
        isOnPrem,
        clientKey: featureManagement.clientKey,
        userKey: account,
        userEmail: decodedToken?.email,
        flags: featureManagement.flags,
    });

    if (featureManager) {
        featureManager
            .waitUntilReady()
            .then(() => {
                store.dispatch({
                    type: FeatureFlagActions.INITIALIZED,
                    payload: featureManager,
                });
                return true;
            })
            .catch(error => {
                console.error(error);
                return false;
            });
    } else {
        store.dispatch({
            type: FeatureFlagActions.NOT_AVAILABLE,
            payload: undefined,
        });
    }
};

export const AppFC: React.FC<AppProps> = ({
    userState,
    isOnPrem,
    token,
    featureManagement,
    isTenantMigrated,
    installType,
    userGroupIds,
    portalNavEnabled,
    accountId,
    logoutCallback,
}) => {
    const [ isErrored, setErrored ] = useState(false);

    // todo: Link it to useTenant permissions
    const [ permState ] = usePermissions();

    React.useEffect(() => {
        if (permState.tenantData.permissionState === PermissionState.FAILED) {
            setErrored(true);
        }
    }, [ permState.tenantData, permState.tenantData.permissionState ]);

    React.useEffect(() => {
        if (!localStorage.getItem('datalabelling_pagesize')) {
            localStorage.setItem('datalabelling_pagesize', `${DEFAULT_PAGE_SIZE}`);
        }
        if (!localStorage.getItem('settings_pagesize')) {
            localStorage.setItem('settings_pagesize', `${DEFAULT_PAGE_SIZE}`);
        }
        if (!localStorage.getItem('dataset_pagesize')) {
            localStorage.setItem('dataset_pagesize', `${DEFAULT_PAGE_SIZE}`);
        }
        if (!localStorage.getItem('mllogs_pagesize')) {
            localStorage.setItem('mllogs_pagesize', `${DEFAULT_PAGE_SIZE}`);
        }
        if (!localStorage.getItem('mlpackages_pagesize')) {
            localStorage.setItem('mlpackages_pagesize', `${DEFAULT_PAGE_SIZE}`);
        }
        if (!localStorage.getItem('mlskills_pagesize')) {
            localStorage.setItem('mlskills_pagesize', `${DEFAULT_PAGE_SIZE}`);
        }
        if (!localStorage.getItem('pipelines_pagesize')) {
            localStorage.setItem('pipelines_pagesize', `${DEFAULT_PAGE_SIZE}`);
        }
    }, []);

    const [ loggedIn, setLoggedIn ] = React.useState(false);

    const handleAppManagerFeatureAndCookies = useCallback((): void => {
        if (userState === Authentication.LOGGEDIN) {
            const decodedToken: any = jwt.decode(token);
            if (featureManagement) {
                updateFeatureFlagToStore({
                    isOnPrem,
                    clientKey: featureManagement.clientKey,
                    userKey: account,
                    userEmail: decodedToken?.email,
                    flags: featureManagement.flags,
                }, featureManagement, decodedToken);
            }
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, [ featureManagement, token, userState ]);

    React.useEffect(() => {
        handleAppManagerFeatureAndCookies();
    }, [ userState, token ]);

    return (
        <ErrorBoundary>
            <AiCenterApp
                loggedIn={loggedIn}
                isOnPrem={isOnPrem}
                isErrored={isErrored}
                logoutCallback={logoutCallback}
                themeChangeCallback={themeChangeCallback}
                onTenantChanged={onTenantChanged}
                isTenantMigrated={isTenantMigrated}
                installType={installType}
                userGroupIds={userGroupIds}
                portalNavEnabled={portalNavEnabled}
                accountId={accountId}
            />
        </ErrorBoundary>
    );
};

export default connect((state: CombinedState<AppStore>) => ({
    userState: state.auth.userState,
    isOnPrem: !!state.config.paths?.isOnPrem,
    token: state.auth.authToken,
    featureManagement: state.config.paths?.featureManagement,
    isTenantMigrated: state.config.isTenantMigrated,
    installType: state.config.paths?.installType,
    userGroupIds: state.authZ.groupIds,
    portalNavEnabled: !!state.config.paths?.portalNavEnabled,
    accountId: state.authZ.accountId,
}))(AppFC);
