import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type {
    BaseResponseOfListOfAppDto,
    ProjectDto,
} from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import type { AxiosResponse } from 'axios';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';
import type {
    Column,
    Row,
} from 'react-table';

import { getAppsForDataset } from '../../../api/client/appManagerClient';
import { deleteDataset } from '../../../api/client/datasetManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import { usePermissions } from '../../../api/global/usePermissions';
import type {
    ProjectActions,
    ProjectState,
} from '../../../api/global/useProjects';
import { useProjects } from '../../../api/global/useProjects';
import { CustomDialog } from '../../../components/Dialog';
import Section from '../../../components/Section';
import ServerSideTable from '../../../components/Table/ServerSideTable/ServerSideTable';
import URLManager from '../../../config/URLManager';
import TableHeaderMappings from '../../../constants/TableHeaderMappings';
import { AppPermissions } from '../../../enums/Authorization';
import { RoutePath } from '../../../route/routeMap';
import { extractErrorMessage } from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';
import logger from '../../../utils/Logging';
import { ItemType } from '../upload/UploadItems';

const useDataSetsPageStyles = makeStyles(() =>
    createStyles({
        datasetsPage: {
            width: '100%',
            fontSize: Tokens.FontFamily.FontMSize,
            fontFamily: Tokens.FontFamily.FontNormal,
            overflowY: 'auto',
        },
    }),
);

const DataSetsPageFC: React.FC = () => {

    const classes = useDataSetsPageStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { projectName } = useParams<{ projectName: string }>();

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(currentProject?.id as string);

    const buttonItems = [];
    if (permissions.indexOf(AppPermissions.MLStorage_Create) > -1) {
        buttonItems.push({
            buttonText: 'dataset_create_new_label',
            buttonCallback: (): void => history.push(generatePath(RoutePath.CREATE_DATASET, { projectName })),
        });
        buttonItems.push({
            buttonText: 'dataset_upload_folder_label',
            buttonCallback: (): void => history.push(generatePath(RoutePath.CREATE_DATASET_FROM_FOLDER, {
                projectName: currentProject?.name,
                itemType: ItemType.FOLDER,
            })),
        });
    }

    return currentProject?.id ? (
        <div className={classes.datasetsPage}>
            <Section
                title={t('dataset_title')}
                buttonItems={buttonItems}>
                <DataSetsList
                    currentProject={currentProject}
                    permissions={permissions} />
            </Section>
        </div >
    ) : null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DataSetsPage = DataSetsPageFC;

interface DataSetListProps {
    currentProject: ProjectDto;
    permissions: AppPermissions[];
}

const DataSetsList: React.FC<DataSetListProps> = ({
    currentProject, permissions,
}) => {

    const {
        t, i18n,
    } = useTranslation();
    const dataMapper: Column[] = [
        ...TableHeaderMappings.DatasetsBase,
        {
            Header: `${t('common_last_modified_label')}`,
            accessor: 'modifiedOn',
            sortable: true,
            Cell: ({ cell: { value } }): string => dateFormatter(value, i18n.language),
        },
    ];

    const history = useHistory();
    const feedback = useFeedback();
    const [ itemDeletedFlag, setItemDeletedFlag ] = React.useState(false);
    const [ open, setOpen ] = React.useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ activeDataset, setActiveDataset ] = React.useState<Row<any> | undefined>(undefined);
    const projectName = currentProject?.name;

    const url = React.useMemo(() => URLManager.url().apiTrainer + '/datasets?sortBy=createdOn&sortOrder=DESC&projectId=' + currentProject?.id, [ currentProject ]);

    /**
   * Validates if the dataset is assoicated with any labeling session
   * @param data All labeling sessions associated with the dataset
   * @returns Boolean response
   */
    const isDatasetDeletable = (data: AxiosResponse<BaseResponseOfListOfAppDto>): boolean => {
        if (data && data.data && data.data.data && data.data.data?.length > 0) {
            data.data.respCode = 70129;
            // eslint-disable-next-line no-throw-literal
            throw { response: data };
        }
        return true;
    };

    const deleteSelectedDataset = (): void => {
        if (activeDataset) {
            getAppsForDataset(activeDataset.original.id, currentProject?.id)
                .then(isDatasetDeletable)
                .then(() => deleteDataset(activeDataset.original.id, currentProject?.id))
                .then(() => {
                    feedback.enqueueSuccess(t('feedback_delete_success_dataset'));
                    closeDialog();
                    /* Refresh Table */
                    setItemDeletedFlag(prev => !prev);
                    return true;
                })
                .catch((error: any) => {
                    closeDialog();
                    logger.error({
                        identifier: 'Datasets page',
                        message: 'Error deleting dataset',
                        error,
                    });
                    feedback.enqueueError(extractErrorMessage(error, t('feedback_delete_error_dataset'), {
                        10101: {},
                        60103: { 0: activeDataset.original.id },
                        70129: {
                            0: activeDataset.original.name,
                            1: error?.response?.data?.data[0]?.name,
                        },
                    }));
                });
        }
    };

    const closeDialog = (): void => {
        setOpen(false);
    };

    const menuItems = [];

    if (permissions.indexOf(AppPermissions.MLStorage_Delete) > -1) {
        menuItems.push({
            text: 'dataset_delete_label',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, rowInfo: Row<any>) => {
                setActiveDataset(rowInfo);
                setOpen(true);
            },
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDataSetClicked = useCallback((rowInfo: any): void => {
        history.push(generatePath(RoutePath.DATASET_FOLDER_VIEW,
            {
                projectName,
                datasetId: rowInfo.original.id,
                datasetFolderName: rowInfo.original.name,
                currentLocation: rowInfo.original.name,
            }));
    }, [ projectName ]);

    return (currentProject?.id && permissions) ? (
        <>
            <CustomDialog
                title={t('dataset_delete_dialog_title_text')}
                open={open}
                handleClose={closeDialog}
                closeIconButton
                infoText={t('dataset_delete_dialog_info_text')}
                warningText={t('dataset_delete_dialog_warning_text')}
                confirmationText={t('dataset_delete_dialog_confirmation_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={deleteSelectedDataset}
                secondarybuttonCallback={closeDialog} />
            <ServerSideTable
                url={url}
                totalKey="data.totalCount"
                dataKey="data.dataList"
                mapper={dataMapper}
                searchable
                searchKey="name"
                onTableCellClicked={handleDataSetClicked}
                contextMenuItems={menuItems}
                itemDeletedFlag={itemDeletedFlag}
                keyColumnIndex={0}
                level="dataset_pagesize"
            />
        </>
    ) : null;
};
