import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { ProjectDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';
import type { Column } from 'react-table';

import { usePermissions } from '../../api/global/usePermissions';
import type {
    ProjectActions,
    ProjectState,
} from '../../api/global/useProjects';
import { useProjects } from '../../api/global/useProjects';
import Section from '../../components/Section';
import ServerSideTable from '../../components/Table/ServerSideTable/ServerSideTable';
import URLManager from '../../config/URLManager';
import { MLlogsSeverityMapping } from '../../constants/EntityFieldMapping';
import { AppPermissions } from '../../enums/Authorization';
import { RoutePath } from '../../route/routeMap';
import {
    dataFormatter,
    entityDataMapper,
    returnValueIfNotUUID,
} from '../../utils/CommonUtils';
import { dateFormatter } from '../../utils/DateFormatter';

const useMlLogsPageStyles = makeStyles(() =>
    createStyles({
        mlLogsPage: {
            width: '100%',
            fontSize: Tokens.FontFamily.FontMSize,
            fontFamily: Tokens.FontFamily.FontNormal,
            overflowY: 'auto',
        },
    }),
);

interface MlLogsPageProps {
    auditsUrl: string;
    breadCrumbPath: string;
    breadCrumbData?: any;
    entityLogsPage: boolean;
}

interface MlLogsListProps {
    auditsUrl: string;
    breadCrumbPath: string;
    breadCrumbData?: any;
}

export const MlLogsPageContent: React.FC<MlLogsPageProps> = ({
    auditsUrl, breadCrumbPath, breadCrumbData, entityLogsPage,
}) => {

    const classes = useMlLogsPageStyles();
    const { t } = useTranslation();

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(currentProject?.id as string);

    return (permissions.indexOf(AppPermissions.MLLogs_View) > -1) ? (
        <div className={classes.mlLogsPage}>
            {entityLogsPage === false ?
                (<Section title={t('ml_logs_title')}>
                    <MlLogsList
                        auditsUrl={auditsUrl}
                        breadCrumbPath={breadCrumbPath}
                        breadCrumbData={breadCrumbData} />
                </Section>)
                :
                <MlLogsList
                    auditsUrl={auditsUrl}
                    breadCrumbPath={breadCrumbPath}
                    breadCrumbData={breadCrumbData} />}
        </div >
    ) : null;
};

const MlLogsList: React.FC<MlLogsListProps> = ({
    auditsUrl, breadCrumbPath, breadCrumbData,
}) => {
    const history = useHistory();
    const {
        t, i18n,
    } = useTranslation();
    const dataMapper: Column[] = [
        {
            Header: `${t('label_severity')}`,
            accessor: 'severity',
            sortable: true,
            Cell: ({ cell: { value } }): string => dataFormatter(value),
        },
        {
            Header: `${t('label_about')}`,
            accessor: 'entityTypeName',
            sortable: true,
            Cell: ({ cell: { value } }): string => entityDataMapper(value, MLlogsSeverityMapping),
        },
        {
            Header: `${t('label_user')}`,
            accessor: 'createdBy',
            Cell: ({ cell: { value } }): string => returnValueIfNotUUID(value),
        },
        {
            Header: `${t('label_description')}`,
            accessor: 'message',
        },
        {
            Header: `${t('label_time')}`,
            accessor: 'createdOn',
            sortable: true,
            Cell: ({ cell: { value } }): string => dateFormatter(value, i18n.language),
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMLLogClicked = (rowInfo: any): void => {
        const projectName = currentProject?.name;
        history.push(
            {
                pathname: generatePath(RoutePath.MLLOGS_DETAILS, { projectName }),
                state: {
                    data: rowInfo.original,
                    // Add breadcumb for central ML Logs page if not given for entity logs
                    breadCrumbPath: (typeof breadCrumbPath === 'undefined' || !breadCrumbPath) ?
                        generatePath(RoutePath.MLLOGS, { projectName }) : breadCrumbPath,
                    breadCrumbData,
                },
            },
        );
    };

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    const updatedAuditUrl = React.useMemo(() => auditsUrl + '&projectId=' + currentProject?.id, [ currentProject ]);

    return currentProject?.id ? (
        <ServerSideTable
            url={updatedAuditUrl}
            totalKey="data.totalCount"
            dataKey="data.dataList"
            mapper={dataMapper}
            onTableCellClicked={handleMLLogClicked}
            searchable
            searchKey="searchText"
            level="mllogs_pagesize"
        />
    ) : null;
};

export const MlLogsPage = connect(() => ({
    // Add default audit logs URL for central ML Log page
    auditsUrl: URLManager.url().apiHelper + '/audits?components=ML_SKILL,ML_PACKAGE,PIPELINE,RUN,ML_SERVICE,DATASET&sortBy=createdOn&sortOrder=DESC',
    entityLogsPage: false,
}))(MlLogsPageContent);
