// eslint-disable-next-line simple-import-sort/imports
import crypto from 'crypto';

import {
    useIdentityAuth,
    useSignoutRedirect,
} from '@uipath/auth-react';
import * as React from 'react';
import { connect } from 'react-redux';

import type { Authentication } from '../../enums/Authentication';
import App from '../../main/app/App';
import BootstrapProvider from '../../providers/BootstrapProvider';
import { UserActions } from '../../state-management/Actions';
import {
    logoutFromStore,
    updateStoreWithAuthCreds,
} from '../../state-management/dispatchers/auth';
import store from '../../state-management/store';
import { getDeploymentType } from '../../utils/AppInsights';

interface AuthorizationHostProps {
    isOnPrem: boolean;
    portalNavEnabled: boolean;
    userState: Authentication;
}

const AuthorizationHost: React.FC<AuthorizationHostProps> = ({
    isOnPrem, portalNavEnabled,
}) => {
    const { user } = useIdentityAuth();
    const signout = useSignoutRedirect();

    const logoutCallback = () => {
        store.dispatch({
            type: UserActions.LOGOUT_INITIATED,
            payload: null,
        });

        // redirect to logout page in portal nav, after a second to clear state
        setTimeout(() => {
            logoutFromStore();
            localStorage.removeItem('aic-sessionId');
            signout();
        }, 1000);
    };

    React.useEffect(() => {
        if (user) {
            localStorage.setItem('aic-sessionId', crypto.randomBytes(8).toString('hex'));
            updateStoreWithAuthCreds(user.access_token, user.id_token, getDeploymentType(isOnPrem, portalNavEnabled));
        }
    }, [ user ]);

    return (
        <BootstrapProvider>
            <App logoutCallback={logoutCallback} />
        </BootstrapProvider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined,
    portalNavEnabled: !!state.config.paths?.portalNavEnabled,
    userState: state.auth.userState,
}))(AuthorizationHost);
