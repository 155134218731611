import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { ProjectDto } from '@uipath/aifabric';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useParams,
} from 'react-router-dom';

import { usePermissions } from '../../api/global/usePermissions';
import type {
    ProjectActions,
    ProjectState,
} from '../../api/global/useProjects';
import { useProjects } from '../../api/global/useProjects';
import extractionPkgImg from '../../assets/extract-pkg.svg';
import { ActionCard } from '../../components/ActionCard';
import Section from '../../components/Section';
import { WithVisibility } from '../../components/WithVisibility';
import { AppPermissions } from '../../enums/Authorization';
import type FeatureFlagManager from '../../feature-flag/FeatureFlagManager';
import { RoutePath } from '../../route/routeMap';
import { DataLabelingSessionList } from './list/DataLabelingSessionList';

const datalabelingSessionPage = makeStyles(() =>
    createStyles({
        dlPage: {
            '& .apps-container': {
                display: 'flex',

                '& .app': { marginRight: '2em' },
            },
        },
    }),
);

interface Props {
    featureManager: FeatureFlagManager;
    appsLaunchUrlTemplate: string;
}

const DataLabelingSessionsPageFC: React.FC<Props> = ({
    featureManager,
    appsLaunchUrlTemplate,
}) => {
    const { t } = useTranslation();
    const { projectName } = useParams<{ projectName: string }>();
    const cssClasses = datalabelingSessionPage();

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(currentProject?.id as string);

    const isDataLabelingEnabled: boolean = featureManager ? featureManager.isEnabled('data-labeling-enabled') : false;
    const isLabelStudioEnabled: boolean = featureManager ? featureManager.isEnabled('label-studio-enabled') : false;

    return isDataLabelingEnabled ? (
        <div className={cssClasses.dlPage}>
            <WithVisibility visible={permissions.indexOf(AppPermissions.DataLabeling_Create) > -1}>
                <Section title={t('dl_create_new_app_header')}>
                    <div className="apps-container">
                        <div
                            className="app"
                            data-testid="app-datamanager">
                            <ActionCard
                                title={t('dl_document_understanding_suite_title')}
                                cardContent={t('dl_document_understanding_suite_description')}
                                imgUrl={extractionPkgImg}
                                route={generatePath(RoutePath.CREATE_DATALABELING, { projectName })}
                                data={{ projectName }}
                            />
                        </div>
                        <WithVisibility visible={isLabelStudioEnabled}>
                            <div
                                className="app"
                                data-cy="uipath-dl-oob-template">
                                <ActionCard
                                    title={t('labeling-card-title')}
                                    cardContent={t('labeling-card-content')}
                                    imgUrl={extractionPkgImg}
                                    route={generatePath(RoutePath.CHOOSE_LABELING_TEMPLATE, { projectName })}
                                    data={{ projectName }}
                                />
                            </div>
                        </WithVisibility>
                    </div>
                </Section>
            </WithVisibility>
            <Section title={t('dl_session_header')}>
                <DataLabelingSessionList
                    appsLaunchUrlTemplate={appsLaunchUrlTemplate}
                    permissions={permissions} />
            </Section>
        </div>
    ) : null;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    featureManager: state.featureManagement.featureManager,
    appsLaunchUrlTemplate: state.config.paths.appsLaunchUrlTemplate,
}))(DataLabelingSessionsPageFC);
