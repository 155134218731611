import type {
    FormikErrors,
    FormikValues,
} from 'formik/dist/types';
import React from 'react';

interface Errors {
    errors: FormikErrors<FormikValues>;
}

export default (props: Errors) =>
    (
        <div style={{ display: 'none' }}>
            {
                Object.keys(props.errors).map((field: string) => (
                    <div
                        id={`${field}-error-label`}
                        key={`${field}-error-label`} >
                        {props.errors[field]}
                    </div>
                ))
            }
        </div>
    );
