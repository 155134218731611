import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import type { SelectChangeEvent } from '@mui/material';
import {
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { ProjectDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React, {
    useCallback,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getProjectsRBAC } from '../../api/client/projectManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import { usePermissions } from '../../api/global/usePermissions';
import Label from '../../components/Label';
import Section from '../../components/Section';
import { WithVisibility } from '../../components/WithVisibility';
import { AppPermissions } from '../../enums/Authorization';
import { getAccountAndTenantFromCannonicalPath } from '../../route/routeHelper';
import { extractErrorMessage } from '../../utils/CommonUtils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageRoot: {
            overflowY: 'scroll',
            marginLeft: '1rem',
            height: 'calc(100vh - 58px)',

            '& .projectSelection': {
                display: 'flex',
                alignItems: 'center',

                '& label': {
                    marginLeft: '2rem',
                    marginTop: 0,
                    marginBottom: 0,
                },
            },
        },
        root: {
            fontSize: Tokens.FontFamily.FontMSize,
            width: '100vw',
            fontFamily: Tokens.FontFamily.FontNormal,
            color: theme.palette.semantic.colorForeground,

            '& .container': {
                marginLeft: '50px',
                border: `1px solid ${theme.palette.semantic.colorBorder}`,
                padding: '10px',
                paddingLeft: '20px',
                marginRight: '2rem',
                boxSizing: 'border-box',
                display: 'flex',
                width: '80%',

                '& icon': { paddingRight: '50px' },

                '& .content': {
                    width: '100%',
                    paddingLeft: '20px',

                    '& .row': {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        lineHeight: Tokens.FontFamily.FontHeader2LineHeight,

                        '& .data': {
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            alignItems: 'center',
                        },

                        '& .header': {
                            display: 'inline-block',
                            fontSize: Tokens.FontFamily.FontSSize,
                            lineHeight: Tokens.FontFamily.FontMLineHeight,
                            fontStyle: 'normal',
                            width: 'inherit',
                            fontWeight: 600,
                        },

                        '& .value': {
                            marginLeft: '10px',
                            fontWeight: 300,
                            width: '75%',
                        },
                    },
                },
            },

            '& .table': {
                width: '80%',
                borderColor: theme.palette.semantic.colorBorder,
                borderCollapse: 'collapse',
            },
        },
    }),
);

interface PermissionIconProps {
    isAllowed?: boolean;
    testId: string;
}

interface ProfilePageProps {
    firstName: string;
    lastName: string;
    email: string;
    accountId: string;
    tenantId: string;
    isFirstParty: boolean;
    isTenantMigrated: boolean;
}

interface ProfileInfoProps {
    firstName: string;
    lastName: string;
    email: string;
    accountId: string;
    tenantId: string;
    isFirstParty: boolean;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({
    firstName, lastName, email, accountId, tenantId, isFirstParty,
}) => {
    const { t } = useTranslation();
    const {
        account, tenant,
    } = getAccountAndTenantFromCannonicalPath();

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className="container">
                <IconButton
                    className="icon"
                    disabled>
                    <InfoIcon fontSize="large" />
                </IconButton>
                <div className="content">
                    <div className="row">
                        <WithVisibility visible={isFirstParty}>
                            <div className="data">
                                <div className="header">
                                    {t('aicenter_profile_page_general_account_name')}
                                </div>
                                <div
                                    className="value"
                                    data-testid="account-name">
                                    {account}
                                </div>
                            </div>

                            <div className="data">
                                <div className="header">
                                    {t('aicenter_profile_page_general_account_id')}
                                </div>
                                <div
                                    className="value"
                                    data-testid="account-id">
                                    {accountId}
                                </div>
                            </div>
                        </WithVisibility>
                    </div>
                    <div className="spaceFromTop" />
                    <div className="row">
                        <WithVisibility visible={isFirstParty}>
                            <div className="data">
                                <div className="header">
                                    {t('aicenter_profile_page_general_tenant_name')}
                                </div>
                                <div
                                    className="value"
                                    data-testid="tenant-name">
                                    {tenant}
                                </div>
                            </div>
                        </WithVisibility>
                        <div className="data">
                            <div className="header">
                                {t('aicenter_profile_page_general_tenant_id')}
                            </div>
                            <div
                                className="value"
                                data-testid="tenant-id">
                                {tenantId}
                            </div>
                        </div>
                    </div>
                    <div className="spaceFromTop" />
                    <div className="row">
                        <div className="data">
                            <div className="header">
                                {t('aicenter_profile_page_general_label_name')}
                            </div>
                            <div
                                className="value"
                                data-testid="first-name">
                                {firstName}
                            </div>
                        </div>

                        <div className="data">
                            <div className="header">
                                {t('aicenter_profile_page_general_surname')}
                            </div>
                            <div
                                className="value"
                                data-testid="last-name">
                                {lastName}
                            </div>
                        </div>
                    </div>
                    <div className="spaceFromTop" />
                    <div className="row">
                        <div className="data">
                            <div className="header">
                                {t('aicenter_profile_page_general_label_email')}
                            </div>
                            <div
                                className="value"
                                data-testid="email">
                                {email}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PermissionIcon: React.FC<PermissionIconProps> = ({
    isAllowed, testId,
}) => {
    switch (isAllowed) {
        case true: return <CheckIcon data-testid={testId + '_Check'} />;
        case false: return <CloseIcon data-testid={testId + '_UnCheck'} />;
        default: return <BlockIcon data-testid={testId + '_Block'} />;
    }
};

const PermissionTable: React.FC<{ isPermissionOnRBAC: boolean }> = ({ isPermissionOnRBAC }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getTenantPermissions();

    return (
        <TableContainer className={classes.root}>
            <WithVisibility visible={!isPermissionOnRBAC} >
                <Table
                    className="table"
                    data-testid="table">
                    {/* add a way to use spread operator to collect the parameters and assign column labels dynamically. */}
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_header_permission')}
                            </TableCell>
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_header_view')}
                            </TableCell>
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_header_edit')}
                            </TableCell>
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_header_create')}
                            </TableCell>
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_header_delete')}
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow data-testid="mlpackages">
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_record_ml_packages')}
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLPackages_View}
                                    isAllowed={permissions.indexOf(AppPermissions.MLPackages_View) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLPackages_Edit}
                                    isAllowed={permissions.indexOf(AppPermissions.MLPackages_Edit) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLPackages_Create}
                                    isAllowed={permissions.indexOf(AppPermissions.MLPackages_Create) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLPackages_Delete}
                                    isAllowed={permissions.indexOf(AppPermissions.MLPackages_Delete) > -1} />
                            </TableCell>
                        </TableRow>

                        <TableRow data-testid="mlskills">
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_record_ml_skills')}
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLSkills_View}
                                    isAllowed={permissions.indexOf(AppPermissions.MLSkills_View) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLSkills_Edit}
                                    isAllowed={permissions.indexOf(AppPermissions.MLSkills_Edit) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLSkills_Create}
                                    isAllowed={permissions.indexOf(AppPermissions.MLSkills_Create) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLSkills_Delete}
                                    isAllowed={permissions.indexOf(AppPermissions.MLSkills_Delete) > -1} />
                            </TableCell>
                        </TableRow>

                        <TableRow data-testid="mllogs">
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_record_ml_logs')}
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLLogs_View}
                                    isAllowed={permissions.indexOf(AppPermissions.MLLogs_View) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLLogs_Edit}
                                    isAllowed={permissions.indexOf(AppPermissions.MLLogs_Edit) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLLogs_Create}
                                    isAllowed={permissions.indexOf(AppPermissions.MLLogs_Create) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLLogs_Delete}
                                    isAllowed={permissions.indexOf(AppPermissions.MLLogs_Delete) > -1} />
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </WithVisibility>
            <WithVisibility visible={isPermissionOnRBAC}>
                <Table
                    className="table"
                    data-testid="table">
                    {/* add a way to use spread operator to collect the parameters and assign column labels dynamically. */}
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_header_permission')}
                            </TableCell>
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_header_view')}
                            </TableCell>
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_header_edit')}
                            </TableCell>
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_header_create')}
                            </TableCell>
                            <TableCell>
                                {t('aicenter_profile_page_permission_table_header_delete')}
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow data-testid="projects">
                            <TableCell>
                                {t('projects')}
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLProjects_View}
                                    isAllowed={permissions.indexOf(AppPermissions.MLProjects_View) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLProjects_Edit}
                                    isAllowed={permissions.indexOf(AppPermissions.MLProjects_Edit) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLProjects_Create}
                                    isAllowed={permissions.indexOf(AppPermissions.MLProjects_Create) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.MLProjects_Delete}
                                    isAllowed={permissions.indexOf(AppPermissions.MLProjects_Delete) > -1} />
                            </TableCell>
                        </TableRow>

                        <TableRow data-testid="licenses">
                            <TableCell>
                                {t('licenses_title')}
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Licenses_View}
                                    isAllowed={permissions.indexOf(AppPermissions.Licenses_View) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Licenses_Edit}
                                    isAllowed={permissions.indexOf(AppPermissions.Licenses_Edit) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Licenses_Create}
                                    isAllowed={permissions.indexOf(AppPermissions.Licenses_Create) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Licenses_Delete}
                                    isAllowed={permissions.indexOf(AppPermissions.Licenses_Delete) > -1} />
                            </TableCell>
                        </TableRow>

                        <TableRow data-testid="users">
                            <TableCell>
                                {t('label_user')}
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Users_View}
                                    isAllowed={permissions.indexOf(AppPermissions.Users_View) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Users_Edit}
                                    isAllowed={permissions.indexOf(AppPermissions.Users_Edit) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Users_Create}
                                    isAllowed={permissions.indexOf(AppPermissions.Users_Create) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Users_Delete}
                                    isAllowed={permissions.indexOf(AppPermissions.Users_Delete) > -1} />
                            </TableCell>
                        </TableRow>

                        <TableRow data-testid="roles">
                            <TableCell>
                                {t('user_roles_header_roles')}
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Roles_View}
                                    isAllowed={permissions.indexOf(AppPermissions.Roles_View) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Roles_Edit}
                                    isAllowed={permissions.indexOf(AppPermissions.Roles_Edit) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Roles_Create}
                                    isAllowed={permissions.indexOf(AppPermissions.Roles_Create) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Roles_Delete}
                                    isAllowed={permissions.indexOf(AppPermissions.Roles_Delete) > -1} />
                            </TableCell>
                        </TableRow>

                        <TableRow data-testid="profile_label">
                            <TableCell>
                                {t('profile_label')}
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Profile_View}
                                    isAllowed={permissions.indexOf(AppPermissions.Profile_View) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Profile_Edit}
                                    isAllowed={permissions.indexOf(AppPermissions.Profile_Edit) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Profile_Create}
                                    isAllowed={permissions.indexOf(AppPermissions.Profile_Create) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.Profile_Delete}
                                    isAllowed={permissions.indexOf(AppPermissions.Profile_Delete) > -1} />
                            </TableCell>
                        </TableRow>
                        <TableRow data-testid="oob_upload_label">
                            <TableCell>
                                {t('oob_upload_label')}
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.OOBUpload_View}
                                    isAllowed={permissions.indexOf(AppPermissions.OOBUpload_View) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.OOBUpload_Edit}
                                    isAllowed={permissions.indexOf(AppPermissions.OOBUpload_Edit) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.OOBUpload_Create}
                                    isAllowed={permissions.indexOf(AppPermissions.OOBUpload_Create) > -1} />
                            </TableCell>
                            <TableCell>
                                <PermissionIcon
                                    testId={AppPermissions.OOBUpload_Delete}
                                    isAllowed={permissions.indexOf(AppPermissions.OOBUpload_Delete) > -1} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </WithVisibility>
        </TableContainer>
    );
};

export const ProjectPermissionTableView: React.FC<{ permissions: AppPermissions[] }> = ({ permissions }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <TableContainer className={classes.root}>
            <Table
                className="table"
                data-testid="table">
                {/* add a way to use spread operator to collect the parameters and assign column labels dynamically. */}
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('aicenter_profile_page_permission_table_header_permission')}
                        </TableCell>
                        <TableCell>
                            {t('aicenter_profile_page_permission_table_header_view')}
                        </TableCell>
                        <TableCell>
                            {t('aicenter_profile_page_permission_table_header_edit')}
                        </TableCell>
                        <TableCell>
                            {t('aicenter_profile_page_permission_table_header_create')}
                        </TableCell>
                        <TableCell>
                            {t('aicenter_profile_page_permission_table_header_delete')}
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow data-testid="dataset_title">
                        <TableCell>
                            {t('dataset_title')}
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLStorage_View}
                                isAllowed={permissions.indexOf(AppPermissions.MLStorage_View) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLStorage_Edit}
                                isAllowed={permissions.indexOf(AppPermissions.MLStorage_Edit) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLStorage_Create}
                                isAllowed={permissions.indexOf(AppPermissions.MLStorage_Create) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLStorage_Delete}
                                isAllowed={permissions.indexOf(AppPermissions.MLStorage_Delete) > -1} />
                        </TableCell>
                    </TableRow>

                    <TableRow data-testid="pipeline_list_title">
                        <TableCell>
                            {t('pipeline_list_title')}
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLPipeline_View}
                                isAllowed={permissions.indexOf(AppPermissions.MLPipeline_View) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLPipeline_Edit}
                                isAllowed={permissions.indexOf(AppPermissions.MLPipeline_Edit) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLPipeline_Create}
                                isAllowed={permissions.indexOf(AppPermissions.MLPipeline_Create) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLPipeline_Delete}
                                isAllowed={permissions.indexOf(AppPermissions.MLPipeline_Delete) > -1} />
                        </TableCell>
                    </TableRow>

                    <TableRow data-testid="nav_data_labeling">
                        <TableCell>
                            {t('nav_data_labeling')}
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.DataLabeling_View}
                                isAllowed={permissions.indexOf(AppPermissions.DataLabeling_View) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.DataLabeling_Edit}
                                isAllowed={permissions.indexOf(AppPermissions.DataLabeling_Edit) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.DataLabeling_Create}
                                isAllowed={permissions.indexOf(AppPermissions.DataLabeling_Create) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.DataLabeling_Delete}
                                isAllowed={permissions.indexOf(AppPermissions.DataLabeling_Delete) > -1} />
                        </TableCell>
                    </TableRow>

                    <TableRow data-testid="common_ml_package_label">
                        <TableCell>
                            {t('common_ml_package_label')}
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLPackages_View}
                                isAllowed={permissions.indexOf(AppPermissions.MLPackages_View) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLPackages_Edit}
                                isAllowed={permissions.indexOf(AppPermissions.MLPackages_Edit) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLPackages_Create}
                                isAllowed={permissions.indexOf(AppPermissions.MLPackages_Create) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLPackages_Delete}
                                isAllowed={permissions.indexOf(AppPermissions.MLPackages_Delete) > -1} />
                        </TableCell>
                    </TableRow>
                    <TableRow data-testid="ml_skills_title">
                        <TableCell>
                            {t('ml_skills_title')}
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLSkills_View}
                                isAllowed={permissions.indexOf(AppPermissions.MLSkills_View) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLSkills_Edit}
                                isAllowed={permissions.indexOf(AppPermissions.MLSkills_Edit) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLSkills_Create}
                                isAllowed={permissions.indexOf(AppPermissions.MLSkills_Create) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLSkills_Delete}
                                isAllowed={permissions.indexOf(AppPermissions.MLSkills_Delete) > -1} />
                        </TableCell>
                    </TableRow>
                    <TableRow data-testid="ml_logs_title">
                        <TableCell>
                            {t('ml_logs_title')}
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLLogs_View}
                                isAllowed={permissions.indexOf(AppPermissions.MLLogs_View) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLLogs_Edit}
                                isAllowed={permissions.indexOf(AppPermissions.MLLogs_Edit) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLLogs_Create}
                                isAllowed={permissions.indexOf(AppPermissions.MLLogs_Create) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.MLLogs_Delete}
                                isAllowed={permissions.indexOf(AppPermissions.MLLogs_Delete) > -1} />
                        </TableCell>
                    </TableRow>

                    <TableRow data-testid="settings">
                        <TableCell>
                            {t('settings')}
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.Settings_View}
                                isAllowed={permissions.indexOf(AppPermissions.Settings_View) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.Settings_Edit}
                                isAllowed={permissions.indexOf(AppPermissions.Settings_Edit) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.Settings_Create}
                                isAllowed={permissions.indexOf(AppPermissions.Settings_Create) > -1} />
                        </TableCell>
                        <TableCell>
                            <PermissionIcon
                                testId={AppPermissions.Settings_Delete}
                                isAllowed={permissions.indexOf(AppPermissions.Settings_Delete) > -1} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ProjectPermissionTable: React.FC <{ projectId: string }> = ({ projectId }) => {
    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(projectId);

    return <ProjectPermissionTableView permissions={permissions} />;
};

export const ProjectPermissionsWrapper = () => {
    const [ projects, setProjects ] = useState<ProjectDto[] | undefined>([]);
    const [ currentSelection, setCurrentSelection ] = useState('');
    const setCurrentSelectionWrapper = useCallback((evt: SelectChangeEvent) => {
        setCurrentSelection(evt.target.value);
    }, [ setCurrentSelection ]);
    const { t } = useTranslation();
    const feedback = useFeedback();
    React.useEffect(() => {
        getProjectsRBAC().then((projectsData) => {
            if (projectsData) {
                setProjects(projectsData?.owned?.map(p => p.projectDto) || []);
                if (projectsData?.owned.length > 0) {
                    setCurrentSelection(projectsData.owned[0].projectDto.id);
                }
            }
            return true;
        })
            .catch((error) => {
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('feedback_project_list_failed'),
                    { 20205: {} },
                ));
            });
    }, []);

    return (projects && projects?.length > 0) ? (
        <>
            <div className="projectSelection">
                <Label value={t('common_select_label')} />
                <Select
                    value={currentSelection}
                    onChange={setCurrentSelectionWrapper}
                >
                    {
                        projects.map(p => (
                            <MenuItem
                                value={p.id}
                                data-testid={p.name}>
                                <em>
                                    {p.name}
                                </em>
                            </MenuItem>
                        ),
                        )
                    }

                </Select>
            </div>
            <WithVisibility visible={!!currentSelection} >
                <ProjectPermissionTable projectId={currentSelection} />
            </WithVisibility>
        </>
    ) : null;
};

export const ProfilePageFC: React.FC<ProfilePageProps> = ({
    firstName, lastName, email, accountId, tenantId, isFirstParty, isTenantMigrated,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.pageRoot}>
            <Section title={t('aicenter_profile_page_heading_general')}>
                <ProfileInfo
                    accountId={accountId}
                    tenantId={tenantId}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    isFirstParty={isFirstParty} />
            </Section>
            <Section title={t('aicenter_profile_page_heading_permissions')}>
                <PermissionTable isPermissionOnRBAC={isTenantMigrated} />
            </Section>
            <WithVisibility visible={isTenantMigrated}>
                <Section title={t('label_PROJECT') + ' ' + t('aicenter_profile_page_heading_permissions')}>
                    <ProjectPermissionsWrapper />
                </Section>
            </WithVisibility>
        </div>
    );
};

// export default ProfilePage;
export default connect((state: any) => ({
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    email: state.auth.email,
    accountId: state.authZ.accountId,
    tenantId: state.authZ.tenantId,
    isFirstParty: !!state.config.paths.isFirstParty,
    isTenantMigrated: state.config.isTenantMigrated,
}))(ProfilePageFC);
