import { MLSkillUpdateRequest, MLSkillDto, BaseResponseOfMLSkillDto, MLSkillCreationRequest, BaseResponseOfstring, MLSkillLicenseUpdateRequest, BaseResponseOfMLSkillReplicasDto, BaseResponseOfMLSkillLogsDto, MLSkillLogsDto, MLSkillReplicasDto, IsAIUnitEnabledDto, IsAIUnitEnabledResponseDto} from "@uipath/aifabric"
import URLManager from "../../config/URLManager";
import { http } from "../../http"
import { AxiosResponse } from "axios";

export const createMLSkill = async (createRequest?: MLSkillCreationRequest): Promise<MLSkillDto | undefined> => {
  const res = await http.post<MLSkillCreationRequest, AxiosResponse<BaseResponseOfMLSkillDto>>(URLManager.url().apiDeployer + "/mlskills" + `?projectId=${createRequest?.projectId}`, createRequest);
  return res.data.data;
}

export const updateMLSkill = async (updateRequest: MLSkillUpdateRequest, mlSkillId: string | undefined, updateType: string, projectId?: string): Promise<MLSkillDto | undefined> => {
  const res = await http.post<MLSkillUpdateRequest, AxiosResponse<BaseResponseOfMLSkillDto>>(URLManager.url().apiDeployer
    + "/mlskills/" + mlSkillId + "?projectId=" + projectId + "&updateType=" + updateType, updateRequest);
  return res.data.data;
}

export const rollbackMLSkill = async (rollbackRequest: MLSkillLicenseUpdateRequest, mlSkillId: string | undefined, projectId?: string): Promise<MLSkillDto | undefined> => {
  const res = await http.post<MLSkillUpdateRequest, AxiosResponse<BaseResponseOfMLSkillDto>>(URLManager.url().apiDeployer
    + "/mlskills/" + mlSkillId + "/rollback?projectId=" + projectId, rollbackRequest);
  return res.data.data;
}

export const deleteMLSkill = async (mlSkillId: string, projectId?: string) => {
  const res = await http.delete<AxiosResponse<BaseResponseOfstring>>(URLManager.url().apiDeployer + `/mlskills/${mlSkillId}?projectId=${projectId}`);
  return res.data;
}

export const stopMLSkill = async (mlSkillId: string | undefined, projectId?: string) => {
  const res = await http.put<AxiosResponse<BaseResponseOfstring>>(URLManager.url().apiDeployer + `/mlskills/stop/${mlSkillId}?projectId=${projectId}`);
  return res.data;
}

export const checkUniqueMLSkillByName = async (skillName: string, projectId?: string): Promise<Boolean | undefined> => {
  const res = await http.get<Boolean>(URLManager.url().apiDeployer + "/mlskills/search?name=" + skillName + "&projectId=" + projectId);
  return res.data;
}

export const createTenant = async (tenantId: string, tenantName: string) => {
  const res = await http.post<AxiosResponse<BaseResponseOfstring>>(URLManager.url().apiDeployer + `/tenant`, {
    "aifabricEnabled": true,
    "allocatedAIGPULicenses": 100,
    "allocatedAIRobotLicenses": 100,
    "licenseExpiryTime": 0,
    "orchestratorTenantId": 0,
    "tenantIdToProvision": tenantId,
    "tenantName": tenantName
  });
  return res.data;
}

export const deleteTenant = async (tenantId: string) => {
  const res = await http.delete<AxiosResponse<BaseResponseOfstring>>(URLManager.url().apiDeployer + `/tenant/${tenantId}` + "?deletePackage=true");
  return res.data;
}

export const getSkillReplicas = async (skillId: string | undefined, projectId?: string | undefined): Promise<MLSkillReplicasDto | undefined> => {
  const res = await http.get<BaseResponseOfMLSkillReplicasDto>(URLManager.url().apiDeployer + "/mlskills/" + skillId + "/replicas" + "?projectId=" + projectId);
  return res.data?.data;
}

export const getIsAIUnitEnabled = async (accountId: string): Promise<IsAIUnitEnabledResponseDto | undefined> => {
  const res = await http.get<IsAIUnitEnabledResponseDto>(URLManager.url().apiDeployer + "/aiunits/isaiunitenabled");
  return res.data;
}

export const getSkillRunLogs = async (skillId: string | undefined, replicaId: string, projectId?: string | undefined): Promise<MLSkillLogsDto | undefined> => {
  const res = await http.get<BaseResponseOfMLSkillLogsDto>(URLManager.url().apiDeployer + "/mlskills/" + skillId + "/logs/" + replicaId + "?projectId=" + projectId);
  return res.data?.data;
}
