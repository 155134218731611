import { Typography } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { Field } from 'formik';
import { Switch } from 'formik-mui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type FeatureFlagManager from '../../feature-flag/FeatureFlagManager';
import {
    isBlobEncryptionEnabled,
    isPublicEndpointEnabled,
} from '../../utils/CommonUtils';
import AccessibleFormikInput from '../AccessibleFormikInput';
import TooltipContainer from '../AccessibleTooltip/TooltipContainer';
import Label from '../Label';

const useStyles = makeStyles(() => createStyles({
    root: {
        width: '100%',
        '& .publicDS': { marginTop: '20px' },
    },
    errorLabel: {
        marginTop: '5px',
        marginBottom: '5px',
    },
    customTooltipClass: { marginTop: '20px' },
}));

interface DatasetCreateProps {
    isPublicDS: boolean;
    isEncryptedDS: boolean;
    disabled: boolean;
    isOnPrem: boolean;
    isLabelBoxApp: boolean;
    featureManager: FeatureFlagManager;
}

export const CreateDataset: React.FC<DatasetCreateProps> = ({
    isPublicDS, isEncryptedDS, disabled, isOnPrem, featureManager, isLabelBoxApp,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dsEncryptionChecked = isEncryptedDS ? `checked` : `unchecked`;
    const dsPublicChecked = isPublicDS ? `checked` : `unchecked`;
    const encryptedCheckBoxAriaLabel = `${t('dataset_create_encrypted_label')} switch ${dsEncryptionChecked}`;
    const publicCheckBoxAriaLabel = `${t('dataset_create_publicDS_label')} switch ${dsPublicChecked}`;
    return (
        <div>
            <Label
                value={t('dataset_create_dsName_label')}
                required
                aria-label={`${t('dataset_create_dsName_label')} label`}>
                <div className={classes.errorLabel}>
                    <Typography>
                        {t('dataset_name_validation_error')}
                    </Typography>
                </div>
            </Label>
            <Field
                component={AccessibleFormikInput}
                requiredField
                className="textFeild min-width-override"
                type="text"
                name="datasetName"
                variant="outlined"
                color="secondary"
                placeholder={t('dataset_create_dsName_ph')}
                aria-label={`${t('dataset_create_dsName_label')} text field`}
                autoComplete="off"
                disabled={disabled}
            />

            <Label
                value={t('dataset_create_dsDesc_label')}
                aria-label={`${t('dataset_create_dsDesc_label')} label`} />
            <Field
                name="datasetDescription"
                type="text"
                component={AccessibleFormikInput}
                className="min-width-override"
                variant="outlined"
                color="secondary"
                placeholder={t('dataset_create_dsDesc_ph')}
                aria-label={`${t('dataset_create_dsDesc_label')} text field`}
                autoComplete="off"
                multiline
                rows={6}
            />

            {isPublicEndpointEnabled(isOnPrem, featureManager) ? (
                <div className="publicDS">
                    <TooltipContainer
                        title={t('tooltip_datasets_make_dataset_public')}
                        customClass={classes.customTooltipClass}>
                        <Label value={t('dataset_create_publicDS_label')} />
                    </TooltipContainer>
                    <div aria-label={publicCheckBoxAriaLabel}>
                        <Field
                            name="publicDS"
                            component={Switch}
                            color="secondary"
                            type="checkbox"
                            disabled={disabled}
                            aria-hidden
                            data-testid="publicDS-testid"
                            inputProps={{ 'color': 'secondary' }}
                        />
                    </div>
                </div>
            ) : (
                <div />
            )}
            {isBlobEncryptionEnabled(featureManager) && !isLabelBoxApp ? (
                <div className="encryptedDataset">
                    <TooltipContainer
                        title={t('tooltip_datasets_encrypt_dataset')}
                        customClass={classes.customTooltipClass}>
                        <Label value={t('dataset_create_encrypted_label')} />
                    </TooltipContainer>
                    <div
                        aria-label={encryptedCheckBoxAriaLabel}
                    >
                        <Field
                            name="encryptedDS"
                            component={Switch}
                            color="secondary"
                            type="checkbox"
                            aria-hidden
                            data-testid="encryptedDS-testid"
                            disabled={disabled}
                        />
                    </div>
                </div>)
                : (<></>)}
        </div>

    );
};
