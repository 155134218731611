import {
    Dialog,
    DialogContent,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

interface DialogProps {
    open: boolean;
    transparent?: boolean;
}

const useStyles = makeStyles(() =>
    createStyles({
        overlay: {
            '& .MuiPaper-root': {
                border: 'none',
                boxShadow: 'none',
                background: 'transparent',
            },
        },
        transparent: {
            '& .MuiBackdrop-root': {
                border: 'none',
                boxShadow: 'none',
                background: 'transparent',
            },
        },
    }),
);

const FullPageLoader: React.FC<DialogProps> = ({
    open, transparent,
}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            className={classes.overlay + (transparent ? ` ${classes.transparent}` : '')}>
            <DialogContent>
                <portal-loader />
            </DialogContent>
        </Dialog>
    );
};

export default FullPageLoader;
