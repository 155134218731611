import React from 'react';

interface VisibilityProps {
    visible: boolean;
}

export const WithVisibility: React.FC<VisibilityProps> = (props) => {
    if (!props.visible) {
        return null;
    }
    return <>
        {props.children}
    </>;
};
