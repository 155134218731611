import './custom-polyfill.js';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import './il8n';
import './index.scss';
import '@uipath/portal-shell';

import React from 'react';
import ReactDOM from 'react-dom';

import { StoreHost } from './hosts/storeHost/StoreHost';

// window.portalNavVersion = version;
ReactDOM.render(<StoreHost />, document.getElementById('ai-app'));
