import { TextField } from '@mui/material';
import type { FieldAttributes } from 'formik';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NameTextField: React.FC<FieldAttributes<{}>> = ({
    placeholder, ...props
}) => {
    const [ field, meta ] = useField<{}>(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const { t } = useTranslation();

    return (
        <>
            <TextField
                {...field}
                helperText={errorText}
                placeholder={placeholder}
                error={!!errorText}
                variant="outlined"
                label={t('common_name_label')}
                inputProps={{ 'data-testid': 'name-field-task-editor-popup' }} />
        </>
    );
};

export default NameTextField;
