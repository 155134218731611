import InfoIcon from '@mui/icons-material/Info';
import { Hidden } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

const iconStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '3em !important',
            color: theme.palette.semantic.colorBackgroundInverse,
        },
    },
}));
export default () => {
    const classes = iconStyle();
    return (
        <Hidden mdDown>
            <div className={classes.root}>
                <InfoIcon />
            </div>
        </Hidden>
    );
};

