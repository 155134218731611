import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NumberBadgeProps {
    title: string;
    value: number;
}

const useBadgeStyles = makeStyles((theme: Theme) =>
    createStyles({
        projectCard: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: theme.palette.semantic.colorBackgroundSecondary,
            width: '100%',
            height: '80px',
            marginLeft: '8px',
        },
        desc: {
            paddingTop: '6px',
            fontWeight: Tokens.FontFamily.FontWeightDefault,
            fontSize: Tokens.FontFamily.FontSSize,
            lineHeight: Tokens.FontFamily.FontSLineHeight,
            fontFamily: Tokens.FontFamily.FontNormal,
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        number: {
            paddingTop: '12px',
            fontWeight: Tokens.FontFamily.FontWeightDefault,
            fontSize: Tokens.FontFamily.FontHeader2Size,
            lineHeight: Tokens.FontFamily.FontHeader2LineHeight,
            fontFamily: Tokens.FontFamily.FontNormal,
            color: theme.palette.semantic.colorForeground,
        },
    }),
);

const NumberBadge: React.FC<NumberBadgeProps> = ({
    title, value,
}) => {
    const classes = useBadgeStyles();
    const { t } = useTranslation();
    return (
        <div
            className={classes.projectCard}
            aria-label={t('a11y_numeric_info')}>
            <div
                className={classes.desc}
                aria-label={t('a11y_label')}>
                {title}
            </div>
            <div
                className={classes.number}
                aria-label={t('a11y_value')}>
                {value}
            </div>
        </div>
    );
};

export default NumberBadge;
