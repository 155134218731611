import AutoRenew from '@mui/icons-material/Autorenew';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { IconButton } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { DatasetDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import HeroInfoIcon from '../../../components/HeroInfoIcon';
import URLManager from '../../../config/URLManager';
import type FeatureFlagManager from '../../../feature-flag/FeatureFlagManager';
import { getAccountAndTenantFromCannonicalPath } from '../../../route/routeHelper';
import {
    copyToClipBoard,
    dataFormatter,
    isBlobEncryptionEnabled,
    isPublicEndpointEnabled,
} from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';

const useDatasetDetailsStyles = makeStyles((theme: Theme) =>
    createStyles({
        dsDetails: {
            height: 'auto',
            border: `solid 1px ${theme.palette.semantic.colorBorder}`,
            borderRadius: '2px',
            boxSizing: 'border-box',
            padding: '24px',
            boxShadow: 'none',
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
            flexDirection: 'row',

            '& .MuiSvgIcon-root': {
                fontSize: '1em',
                padding: 0,
            },

            '& .image': {
                display: 'flex',
                justifyContent: 'center',

                '& .MuiIconButton-root': { fontSize: Tokens.FontFamily.FontHeroSize },
            },

            '& .content': {
                marginLeft: '50px',
                width: '100%',

                '& .row': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    lineHeight: Tokens.FontFamily.FontHeader2LineHeight,

                    '& .data': {
                        color: theme.palette.semantic.colorForeground,
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'row',
                        overflow: 'hidden',
                        alignItems: 'center',
                    },

                    '& .header': {
                        lineHeight: Tokens.FontFamily.FontMLineHeight,
                        width: '20%',
                        display: 'inline-block',
                    },

                    '& .value': {
                        marginLeft: '10px',
                        fontWeight: 300,
                        width: '75%',
                        '& p': { lineHeight: '1.5rem' },
                    },

                    '& .publicUrlContainer': {
                        display: 'flex',
                        flexDirection: 'row',
                        width: '75%',

                        '& .value': {
                            '& p': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                        },
                    },

                    '& .publicUrlText': {
                        marginLeft: '10px',
                        fontWeight: 300,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center',
                    },

                    '& .copyIcon': {
                        marginTop: '-2px',
                        marginLeft: '10px',
                    },

                    '& .fullWidthRowValue': {
                        marginLeft: '10px',
                        fontWeight: 300,
                        width: '85%',
                    },

                    '& .truncateText': {
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflowWrap: 'break-word',
                    },

                    '& .threeLineHeight': { WebkitLineClamp: 3 },

                    '& .oneLineHeight': { WebkitLineClamp: 1 },

                    '& .twoLineHeight': { WebkitLineClamp: 2 },

                    '& .fullWidthRowHeader': {
                        lineHeight: Tokens.FontFamily.FontMLineHeight,
                        width: '10%',
                    },

                    '& .fullWidthRow': { width: '100%' },
                },
            },
        },
    }),
);

interface DSDetailsDataProps {
    openRotateSkillDialog: Function;
    dsDto: DatasetDto;
    isOnPrem: boolean;
    featureManager: FeatureFlagManager;
}

export const DSDetailsData: React.FC<DSDetailsDataProps> = ({
    openRotateSkillDialog, dsDto, isOnPrem, featureManager,
}) => {
    const {
        t, i18n,
    } = useTranslation();

    const copyKey = (): void => {
        copyToClipBoard(dsDto.apiKey);
    };

    const classes = useDatasetDetailsStyles();

    const {
        account, tenant,
    } = getAccountAndTenantFromCannonicalPath();

    const publicUrl =
    URLManager.url()
        .apiTrainer.replace(':account', account)
        .replace(':tenant', tenant) +
    '/signedURL/public?blobName=' +
    dsDto.projectId +
    '/' +
    dsDto.id;

    const copyUrl = (): void => {
        copyToClipBoard(publicUrl);
    };

    return (
        <div className={classes.dsDetails}>
            <HeroInfoIcon />

            <div className="content">
                <div className="row">
                    <div className="data">
                        <div className="header">
                            {t('common_createdOn_label')}
                        </div>
                        <div
                            className="value"
                            data-testid="createdOn">
                            {dateFormatter(dsDto.createdOn, i18n.language)}
                        </div>
                    </div>

                    <div className="data">
                        <div className="header">
                            {t('common_last_modified_label')}
                        </div>
                        <div
                            className="value"
                            data-testid="modifiedOn">
                            {dateFormatter(dsDto.modifiedOn, i18n.language)}
                        </div>
                    </div>
                </div>
                <div className="row spaceFromTop">
                    {isBlobEncryptionEnabled(featureManager) ? (
                        <div className="data">
                            <div className="header">
                                {t('common_encrypted_label')}
                            </div>
                            <div
                                className="value"
                                data-testid="encrypted">
                                {dsDto.encrypted ? t('form_text_yes') : t('form_text_no')}
                            </div>
                        </div>)
                        : (<div className="data" />)}
                </div>
                <div className="row spaceFromTop">
                    {isPublicEndpointEnabled(isOnPrem, featureManager) && dsDto.apiKey && dsDto.public ? (
                        <div className="data">
                            <div className="header">
Endpoint
                            </div>
                            <div className="publicUrlContainer">
                                <div
                                    className="value"
                                    data-testid="endPoint">
                                    <p>
                                        {publicUrl}
                                    </p>
                                </div>
                                <div className="copyIcon">
                                    <IconButton
                                        aria-label={t('a11y_copy_file_url')}
                                        data-testid="copy-url"
                                        onClick={copyUrl}>
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="data" />
                    )}

                    {isPublicEndpointEnabled(isOnPrem, featureManager) && dsDto.apiKey && dsDto.public ? (
                        <div className="data">
                            <div className="header">
                                {t('common_apiKey_label')}
                            </div>
                            <div className="publicUrlContainer">
                                <div
                                    className="publicUrlText"
                                    data-testid="apiKey">
                                    {dsDto.apiKey}
                                </div>
                                <div className="copyIcon">
                                    <IconButton
                                        aria-label={t('a11y_copy_api_key')}
                                        data-testid="copy-api-key"
                                        onClick={copyKey}>
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                </div>
                                <div className="copyIcon">
                                    <IconButton
                                        data-testid="open-rotate-ds-dialog"
                                        aria-label={t('a11y_rotate_api_key')}
                                        onClick={() => {
                                            openRotateSkillDialog(true);
                                        }}
                                    >
                                        <AutoRenew />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="data" />
                    )}
                </div>
                <div className="row spaceFromTop">
                    <div className="data">
                        <div className="header">
                            {t('common_description_label')}
                        </div>
                        <div
                            className="value"
                            data-testid="description">
                            {dataFormatter(dsDto.description, i18n.language)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
