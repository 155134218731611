import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React from 'react';

interface LabelProps {
    value: string;
    id?: string;
    htmlFor?: string;
    required?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    label: {
        display: 'block',
        marginTop: '24px',
        marginBottom: '5px',
        fontFamily: Tokens.FontFamily.FontNormal,
        color: theme.palette.semantic.colorForeground,
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        fontWeight: 600,
        fontStyle: 'normal',
    },
    required: {
        '&:after': {
            content: '"*"',
            color: 'red',
        },
    },
}));

const Label: React.FC<LabelProps> = (props) => {
    const classes = useStyles();
    const classNames = props.required ? classes.label + ' ' + classes.required : classes.label;
    return <>
        <label
            id={props.id}
            htmlFor={props.htmlFor}
            className={classNames}>
            {props.value}
        </label>
        {props.children ? props.children : null}
    </>;
};

export default Label;
