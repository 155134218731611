import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Button,
    IconButton,
    Tab,
    Tabs,
    TextField,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type {
    DatasetDto,
    ProjectDto,
} from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import {
    Field,
    Formik,
    validateYupSchema,
    yupToFormErrors,
} from 'formik';
import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';
import type { Column } from 'react-table';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import {
    deleteLabelingFile,
    getLabellingFileStats,
} from '../../api/client/appManagerClient';
import { getDatasetById } from '../../api/client/datasetManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import { usePermissions } from '../../api/global/usePermissions';
import type {
    ProjectActions,
    ProjectState,
} from '../../api/global/useProjects';
import { useProjects } from '../../api/global/useProjects';
import AccessibleFormikInput from '../../components/AccessibleFormikInput';
import {
    CustomDialog,
    DataLabellingExportProgressDialog,
    DataLabellingImportProgressDialog,
    SendToLabellersDialog,
} from '../../components/Dialog';
import { FileDropZone } from '../../components/fileDropzone/FileDropZone';
import type FileToUpload from '../../components/fileDropzone/FileToUpload';
import FormButtonGroup from '../../components/FormButtonGroup';
import HeroInfoIcon from '../../components/HeroInfoIcon';
import Label from '../../components/Label';
import Section from '../../components/Section';
import ServerSideTable from '../../components/Table';
import { WithVisibility } from '../../components/WithVisibility';
import URLManager from '../../config/URLManager';
import { AZURE_SAS_TOKEN_SUBS_STRING } from '../../constants/BlobStorageConstants';
import { AppPermissions } from '../../enums/Authorization';
import { getAccountAndTenantFromCannonicalPath } from '../../route/routeHelper';
import { RoutePath } from '../../route/routeMap';
import { extractErrorMessage } from '../../utils/CommonUtils';
import { dateFormatter } from '../../utils/DateFormatter';
import logger from '../../utils/Logging';
import DataLabellingTab from './configure/DataLabellingConfigureTab';

export const dashboardStyle = makeStyles((theme: Theme) =>
    createStyles({
        labelersCTA: {
            marginTop: 24,
            marginRight: theme.spacing(2),
        },
        dlPage: {
            '& .apps-container': {
                display: 'flex',

                '& .app': { marginRight: '2em' },
            },
        },
        configureTab: {
            display: 'flex',
            '& #tabs': { width: '20%' },
            '& #tab-content': { width: '100%' },
            '& .MuiTextField-root': { width: '100%' },
        },
        configureWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '24px',
        },
        templateInput: {
            width: '48%',
            border: 'solid 1px grey',
            borderRadius: '4px',
        },
        templateGeneral: {
            width: '48%',
            border: 'solid 1px grey',
            borderRadius: '4px',
        },
        templatePreview: { width: '48%' },
        templateSubmit: { marginRight: '32px' },
        warningText: {
            borderRadius: '15px',
            maxWidth: '50vw',
            background: 'rgba(235, 197, 0, 0.15)',
            padding: '10px 15px 10px 15px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '25px',
            '& p': {
                fontFamily: Tokens.FontFamily.FontNormal,
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#526069',
            },
            '& label': {
                marginTop: '5px',
                fontFamily: Tokens.FontFamily.FontTitle,
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '24px',
            },
        },
        statsContainer: { display: 'flex' },
        infoImage: {
            alignSelf: 'center',
            marginRight: '16px',
        },
        statsSection: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '24px',
            height: 'auto',
            border: `solid 1px ${theme.palette.semantic.colorBorder}`,
            color: theme.palette.semantic.colorForeground,
            marginBottom: '24px',
            marginTop: '24px',
        },
        statsButton: {
            marginTop: '6px',
            marginBottom: '6px',
            alignSelf: 'flex-start',
        },
        stats: {
            display: 'flex',
            flexDirection: 'column',
            '& p': {
                padding: '0 10px 0 10px',
                marginTop: '16px',
                marginBottom: '16px',
                color: theme.palette.semantic.colorForeground,
            },
        },
        exportButton: {
            marginTop: 24,
            marginRight: theme.spacing(2),
        },
        buttonFooter: {
            borderTopWidth: '1px',
            borderTopColor: theme.palette.semantic.colorBorder,
            borderColor: theme.palette.semantic.colorForegroundLink,
            flexGrow: 0,
            position: 'relative',
            marginBottom: 'auto',

            '& div': {
                height: '70px',
                lineHeight: '70px',
            },

            '& div.MuiLinearProgress-indeterminate': {
                height: '4px',
                position: 'absolute',
                width: '100%',
                top: 0,
            },
        },
        importForm: {
            '& .formTitle': {
                margin: '0px',
                fontFamily: Tokens.FontFamily.FontNormal,
                fontSize: Tokens.FontFamily.FontHeader4Size,
                lineHeight: Tokens.FontFamily.FontHeader4LineHeight,
                fontWeight: 600,
                color: theme.palette.semantic.colorForeground,
            },

            '& .MuiFormHelperText-root.Mui-error': { color: theme.palette.semantic.colorErrorText },

            '& .makeStyles-tsErrorMessage': { color: theme.palette.semantic.colorErrorText },

            '& .tsErrorMessage': { color: theme.palette.semantic.colorErrorText },
        },
        formPadding: { padding: '24px' },
        previewText: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }),
);

enum TabTypes {
    Configure,
    Import,
    Labels,
    Export,
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const LabelingSessionDashboard: React.FC<any> = ({
    isOnStandalone, actionCenterUrl, azureStorageFQDNSuffix,
}) => {
    const locationState: any = useLocation()?.state;
    const datasetId = locationState?.datasetId;
    const appId = locationState?.appId;
    const projectId = locationState?.projectId;
    const isConfigureFirst = locationState?.configure;

    const { t } = useTranslation();
    const cssClasses = dashboardStyle();

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    const [ value, setValue ] = React.useState(isConfigureFirst ? TabTypes.Configure : TabTypes.Labels);
    const [ dataset, setDataset ] = React.useState<DatasetDto>();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(currentProject?.id as string);

    React.useEffect(() => {
        if (datasetId) {
            getDatasetById(datasetId, currentProject?.id)
                .then((datasetResponse: any) => {
                    setDataset(datasetResponse);
                    return true;
                })
                .catch(((error: any) => {
                    logger.error({
                        identifier: 'Dataset',
                        message: 'Error getting dataset by id ' + datasetId,
                        error,
                    });
                }));
        }
    }, [ datasetId ]);

    return (
        <div className={cssClasses.dlPage}>
            <Section>
                <WithVisibility visible={permissions.indexOf(AppPermissions.DataLabeling_View) > -1}>
                    <Tabs
                        color="default"
                        indicatorColor="primary"
                        textColor="primary"
                        value={value}
                        onChange={handleChange}
                        aria-label={t('a11y_data_labeling_tab_label')}>
                        <Tab
                            label={t('configure-tab')}
                            data-testid="configure-tab" />
                        <Tab
                            label={t('import-tab')}
                            data-testid="import-tab" />
                        <Tab
                            label={t('labels-tab')}
                            data-testid="labels-tab" />
                        <Tab
                            label={t('export-tab')}
                            data-testid="export-tab" />
                    </Tabs>
                    <TabPanel
                        value={value}
                        index={TabTypes.Configure}>
                        <DataLabellingTab isConfigureFirst={isConfigureFirst} />
                    </TabPanel>
                    <TabPanel
                        value={value}
                        index={TabTypes.Import}>
                        <ImportSection
                            dataset={dataset}
                            project={currentProject}
                            appId={appId}
                            setTab={setValue}
                            azureStorageFQDNSuffix={azureStorageFQDNSuffix} />
                    </TabPanel>
                    <TabPanel
                        value={value}
                        index={TabTypes.Labels}>
                        <LabelSection
                            datasetId={dataset?.id}
                            projectId={projectId}
                            appId={appId}
                            isOnStandalone={isOnStandalone}
                            actionCenterUrl={actionCenterUrl}
                        />
                    </TabPanel>
                    <TabPanel
                        value={value}
                        index={TabTypes.Export}>
                        <ExportSection
                            projectId={projectId}
                            appId={appId}
                            datasetId={datasetId}
                            setTab={setValue} />
                    </TabPanel>
                </WithVisibility>
            </Section>
        </div>
    );
};

const ShowLabellingStats: React.FC<any> = ({
    projectId, appId,
}) => {

    const classes = dashboardStyle();
    const [ stats, setStats ] = React.useState<any>({});
    const { t } = useTranslation();

    const getStats = async () => {
        const res = await getLabellingFileStats(projectId, appId);
        const totalCount = res?.data ? Object.values(res?.data).reduce((acc, val) => acc + val, 0) : 0;
        setStats({
            'TOTAL_COUNT': totalCount,
            ...res.data,
        });
    };

    React.useEffect(() => {
        getStats().catch((err) => {
            logger.error({
                identifier: 'Labelling Stats',
                message: 'Error while fetching for labelling stats.',
                error: err,
            });
        });
    }, []);

    const handleClick = () => {
        getStats().catch((err) => {
            logger.error({
                identifier: 'Labelling Stats',
                message: 'Error while fetching for labelling stats.',
                error: err,
            });
        });
    };

    return (
        <div className={classes.statsSection}>
            <div className={classes.statsContainer}>
                <div
                    aria-hidden
                    className={classes.infoImage}>
                    <HeroInfoIcon />
                </div>
                <div className={classes.stats}>
                    {
                        Object.keys(stats).map((status: any) => (
                            <p key={`stats_${uuid()}`}>
                                {t(status)}
                                {' '}
:
                                {' '}
                                {stats[status]}
                            </p>
                        ))
                    }
                </div>
            </div>
            <WithVisibility visible={Object.keys(stats).length != 0}>
                <IconButton
                    className={classes.statsButton}
                    name="refresh-button"
                    aria-label={t('refresh')}
                    data-testid="refresh-stats"
                    data-cy="refresh-stats"
                    onClick={handleClick}>
                    <RefreshIcon />
                </IconButton>
            </WithVisibility>
        </div>
    );
};

export const LabelSection: React.FC<any> = ({
    projectId, appId, datasetId, actionCenterUrl, isOnStandalone,
}) => {

    const {
        t, i18n,
    } = useTranslation();
    const classes = dashboardStyle();
    const feedback = useFeedback();
    const {
        account, tenant,
    } = getAccountAndTenantFromCannonicalPath();

    const blobMetadata = { original: { fileName: '' } };
    const [ dialogOpen, setDialogOpen ] = React.useState(false);
    const [ isConfirmationDialogOpen, setConfirmationDialogOpen ] = React.useState(false);
    const [ selectedItems, setSelectedItems ] = React.useState([]);
    const [ itemDeletedFlag, setItemDeletedFlag ] = React.useState(false);
    const [ currentRow, setCurrentRow ] = React.useState(blobMetadata);

    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(projectId as string);

    const url = React.useMemo(() => URLManager.url().apiAppManager + '/app/' + appId + '/files/?sortBy=createdOn&sortOrder=DESC&projectId=' + projectId, [ projectId ]);

    const openSendToLabellersDialog = (): void => {
        setDialogOpen(true);
    };

    const goToActionCenter = (): void => {
        const navigateToUrl = isOnStandalone && actionCenterUrl ? actionCenterUrl + 'tasks?status=Unassigned&creationTime=7&type=DataLabelingTask' : window.location.origin + generatePath(RoutePath.BASE_WITHOUT_AIFABRIC + '/actions_/tasks?status=Unassigned&creationTime=7&type=DataLabelingTask', {
            account,
            tenant,
        });
        window.location.href = navigateToUrl;
    };

    const closeConfirmationDialog = (): void => {
        setConfirmationDialogOpen(false);
    };

    const deleteFile = (): void => {
        const fileName = encodeURIComponent(currentRow?.original?.fileName);
        deleteLabelingFile(projectId, datasetId, appId, fileName)
            .then(() => {
                feedback.enqueueSuccess(t('feedback_delete_success_item'));
                setItemDeletedFlag(true);
                return true;
            })
            .finally(() => {
                closeConfirmationDialog();
                setCurrentRow(blobMetadata);
            })
            .catch((error) => {
                feedback.enqueueError(extractErrorMessage(error, t('feedback_delete_error_item'), {
                    10602: {},
                    72003: {},
                }));
            });
    };

    const rowTooltip = useCallback((rowInfo: any): string => {
        const app = rowInfo.original;
        let tooltip = '';
        if (app.errorCode && app.errorCode > 0) {
            switch (app.errorCode) {
                case '72001': {
                    tooltip = t('file_upload_validation_failed_invalid_json_file', { fileName: app.errorField });
                    break;
                }
                case '72002': {
                    tooltip = t('file_upload_validation_failed_missing_field', { fieldName: app.errorField });
                    break;
                }
                default: {
                    tooltip = t('file_upload_validation_failed_default');
                    break;
                }
            }
            tooltip = t('file_upload_validation_failed') + tooltip;
        }
        return tooltip;
    }, []);

    const dataMapper = React.useMemo(() => [
        {
            Header: `${t('common_name_label')}`,
            accessor: 'fileName',
            sortable: true,
        },
        {
            Header: `${t('common_status_label')}`,
            accessor: 'labellingFileStatus',
            Cell: (data: any): string => t(data.cell.value),
            sortable: true,
        },
        {
            Header: `${t('common_created_time_label')}`,
            accessor: 'createdOn',
            Cell: (data: any): string => dateFormatter(data.cell.value, i18n.language),
            sortable: true,
        },
    ], []);

    const menuItems = [];

    if (permissions.indexOf(AppPermissions.DataLabeling_Edit) > -1) {
        menuItems.push({
            text: 'dataset_delete_label',
            disable: (data: any): boolean => 'PUSH_SUCCESS' === data.labellingFileStatus,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, data: any): void => {
                setConfirmationDialogOpen(true);
                setCurrentRow(data);
            },
        });
    }

    return (
        <>
            <ShowLabellingStats
                projectId={projectId}
                appId={appId} />
            <CustomDialog
                title={t('delete_label_file_dialog_title')}
                open={isConfirmationDialogOpen}
                handleClose={closeConfirmationDialog}
                closeIconButton
                warningText={t('dataset_delete_dialog_confirmation_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={deleteFile}
                secondarybuttonCallback={closeConfirmationDialog}
            />
            <ServerSideTable
                url={url}
                isSelectAble
                onItemSelection={setSelectedItems}
                mapper={dataMapper}
                contextMenuItems={menuItems}
                totalKey="data.totalCount"
                dataKey="data.dataList"
                itemDeletedFlag={itemDeletedFlag}
                keyColumnIndex={0}
                level="datalabelling_pagesize"
                onRowHoverTooltip={rowTooltip}
            />
            <SendToLabellersDialog
                appId={appId}
                projectId={projectId}
                datasetId={datasetId}
                open={dialogOpen}
                handleClose={() => setDialogOpen(false)}
                closeIconButton
                title={t('send_to_labellers_dialog_title')}
                itemsSelected={selectedItems} />
            <Button
                color="primary"
                className={classes.labelersCTA}
                variant="contained"
                data-cy="send-to-labelers"
                data-testid="send-to-labelers"
                aria-label={t('send_to_labellers')}
                onClick={openSendToLabellersDialog}>
                {t('send_to_labellers')}
            </Button>
            <Button
                color="primary"
                className={classes.labelersCTA}
                variant="outlined"
                data-cy="go-to-action-center"
                data-testid="go-to-action-center"
                aria-label={t('go-to-action-center')}
                onClick={goToActionCenter}>
                {t('go-to-action-center')}
            </Button>
        </>
    );
};

export default connect((state: any) => ({
    isOnStandalone: state.config.paths ? state.config.paths.isOnStandalone : false,
    actionCenterUrl: state.config.paths ? state.config.paths.actionCenterUrl : '',
    azureStorageFQDNSuffix: state.config.paths ? state.config.paths.azureStorageFQDNSuffix : AZURE_SAS_TOKEN_SUBS_STRING,
}))(LabelingSessionDashboard);

const ImportSection: React.FC<any> = ({
    dataset, project, appId, setTab, azureStorageFQDNSuffix,
}) => {

    const [ data, setData ] = React.useState<any>('');
    const { t } = useTranslation();
    const classes = dashboardStyle();
    const [ openDataLabellingImportProgressDialog, setOpenDataLabellingImportProgressDialog ] = React.useState(false);

    return (
        <Formik
            initialValues={{
                uploadDropzone: [] as FileToUpload[],
                dataset: dataset?.name,
            }}
            onSubmit={async values => {
                setData(values);
                setOpenDataLabellingImportProgressDialog(true);
            }}
            validate={async (values) => {
                const validationSchema =
          Yup.object().shape({ uploadDropzone: Yup.array().required(t('upload_file_uploadDropzone_req')) });

                try {
                    await validateYupSchema(values, validationSchema, true, values);
                } catch (err) {
                    return yupToFormErrors(err);
                }
                return {};
            }}
        >
            {
                (props) => {
                    const {
                        dirty, handleSubmit, isSubmitting,
                    } = props;

                    return (
                        <div>
                            <form
                                onSubmit={handleSubmit}
                                className={classes.importForm}>
                                <div className={classes.formPadding}>
                                    <DataLabellingImportProgressDialog
                                        open={openDataLabellingImportProgressDialog}
                                        handleClose={(): void => setOpenDataLabellingImportProgressDialog(false)}
                                        project={project}
                                        dataset={dataset}
                                        appId={appId}
                                        data={data}
                                        closeIconButton
                                        primaryButtonText={t('data_labelling_done_button_text')}
                                        primarybuttonCallback={(): void => {
                                            setTab(TabTypes.Labels);
                                        }}
                                        azureStorageFQDNSuffix={azureStorageFQDNSuffix}
                                    />
                                    <Label value={t('dataset_title')} />
                                    <Field
                                        component={AccessibleFormikInput}
                                        className="textFeild min-width-override"
                                        type="text"
                                        requiredField
                                        name="dataset"
                                        variant="outlined"
                                        color="secondary"
                                        placeholder={dataset?.name}
                                        aria-label={dataset?.name}
                                        autoComplete="off"
                                        disabled
                                    />

                                    <Label value={t('labeling_upload_files_label')} />
                                    <Field
                                        name="uploadDropzone"
                                        id="uploadDropzone"
                                        component={FileDropZone}
                                        variant="outlined"
                                        color="secondary"
                                        multiple
                                    />
                                </div>
                                <div className={classes.buttonFooter}>
                                    <FormButtonGroup
                                        dirty={dirty}
                                        isSubmitting={isSubmitting}
                                        submitButtonText={t('upload_folder_upload_button_text')}
                                    />
                                </div>
                            </form>
                        </div>
                    );
                }
            }
        </Formik>
    );
};

export const ExportSection: React.FC<any> = ({
    projectId, appId, datasetId, setTab,
}) => {
    const {
        t, i18n,
    } = useTranslation();
    const [ openDataLabellingExportProgressDialog, setOpenDataLabellingExportProgressDialog ] = React.useState(false);
    const classes = dashboardStyle();

    const url = React.useMemo(() => URLManager.url().apiAppManager + '/app/' + appId + '/files/?labellingStatuses=LABEL_SUCCESS&sortBy=createdOn&sortOrder=DESC&projectId=' + projectId, [ projectId ]);
    const { projectName } = useParams<{ projectName: string }>();
    const history = useHistory();

    const dataMapper: Column[] = React.useMemo(() => [
        {
            Header: `${t('common_name_label')}`,
            accessor: 'fileName',
            sortable: true,
        },
        {
            Header: `${t('common_status_label')}`,
            accessor: 'labellingFileStatus',
            Cell: (data: any): string => t(data.cell.value),
            sortable: true,
        },
        {
            Header: `${t('data_labeling_export_created_by_label')}`,
            accessor: 'createdBy',
            sortable: true,
        },
        {
            Header: `${t('common_created_time_label')}`,
            accessor: 'createdOn',
            Cell: ({ cell: { value } }): string => dateFormatter(value, i18n.language),
            sortable: true,
        },
    ], []);

    const openExportDialogBox = () => {
        setOpenDataLabellingExportProgressDialog(true);
    };

    const goToDataset = async () => {
        const response = await getDatasetById(datasetId, projectId);
        if (projectName && response && response?.id && response?.name) {
            history.push(generatePath(RoutePath.DATASET_FOLDER_VIEW + '%2Fdatalabelling_exportedFiles%2F', {
                projectName,
                datasetId: response?.id,
                datasetFolderName: response?.name,
                currentLocation: response?.name,
            }));
        }
    };

    const handleGoToExportButtonClick = () => {
        goToDataset().catch((error: any) => {
            logger.error({
                identifier: 'ExportSection',
                message: 'Error fetching dataset by id',
                error,
            });
        });
    };

    const [ exportFolderName, setExportFolderName ] = React.useState('');
    const [ selectedItems, setSelectedItems ] = React.useState<string[]>([]);

    return (
        <div>
            <Section>
                <TextField
                    id="export-folder-name"
                    label={t('data_labeling_export_folder_name_label')}
                    variant="outlined"
                    value={exportFolderName}
                    onChange={(event) => {
                        setExportFolderName(event.target.value);
                    }}
                />
            </Section>

            <DataLabellingExportProgressDialog
                open={openDataLabellingExportProgressDialog}
                handleClose={(): void => setOpenDataLabellingExportProgressDialog(false)}
                projectId={projectId}
                datasetId={datasetId}
                appId={appId}
                folderName={exportFolderName}
                primaryButtonText={t('data_labelling_done_button_text')}
                itemsSelected={selectedItems}
                primarybuttonCallback={(): void => {
                    setTab(TabTypes.Labels);
                }}
            />

            <Section>
                <ServerSideTable
                    isSelectAble
                    onItemSelection={setSelectedItems}
                    url={url}
                    totalKey="data.totalCount"
                    dataKey="data.dataList"
                    mapper={dataMapper}
                    level="datalabelling_pagesize"
                />

                <Button
                    className={classes.exportButton}
                    data-testid="export-dataset"
                    aria-label="export-dataset"
                    variant="contained"
                    onClick={openExportDialogBox}>
                    {t('data_labeling_export_dataset_button_text')}
                </Button>
                <Button
                    className={classes.exportButton}
                    data-testid="go-to-dataset"
                    aria-label="go-to-dataset"
                    variant="outlined"
                    onClick={handleGoToExportButtonClick}>
                    {t('data_labeling_go_to_dataset_button_text')}
                </Button>
            </Section>
        </div>
    );

};

interface TabPanelProps {
    children: ReactNode;
    value: number;
    index: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({
    children, value, index,
}) => (
    <div>
        {
            value === index && <div>
                {children}
            </div>
        }
    </div>
);
