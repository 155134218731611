import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type {
    AuditLogDto,
    ProjectDto,
} from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    connect,
    useSelector,
} from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getPipelineRunLogs } from '../../api/client/pipelineManagerClient';
import type {
    ProjectActions,
    ProjectState,
} from '../../api/global/useProjects';
import { useProjects } from '../../api/global/useProjects';
import type { BreadcrumbProps } from '../../components/Breadcrumbs';
import HeroInfoIcon from '../../components/HeroInfoIcon';
import Section from '../../components/Section';
import URLManager from '../../config/URLManager';
import { AZURE_SAS_TOKEN_SUBS_STRING } from '../../constants/BlobStorageConstants';
import { EntityTypeNameMapping } from '../../constants/EntityFieldMapping';
import { BaseTableIcons } from '../../enums/BaseTableIcons';
import {
    dataFormatter,
    downloadContentWithSignUrl,
    entityDataMapper,
    returnValueIfNotUUID,
} from '../../utils/CommonUtils';
import { dateFormatter } from '../../utils/DateFormatter';
import logger from '../../utils/Logging';

const mlLogDetailStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: Tokens.FontFamily.FontMSize,
        width: '100%',
        fontWeight: 600,
        fontFamily: Tokens.FontFamily.FontNormal,
        overflowY: 'auto',

        '& .mlLogsdetails': {
            marginBottom: '20px',
            width: '100%',
            height: 'auto',
            border: `solid 1px ${theme.palette.semantic.colorBorder}`,
            color: theme.palette.semantic.colorForeground,
            padding: '24px',
            boxSizing: 'border-box',
            borderRadius: '2px',
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'row',

            '& .image': {
                display: 'flex',
                justifyContent: 'center',

                '& .MuiSvgIcon-fontSizeLarge': { fontSize: Tokens.FontFamily.FontHeader1Size },
            },

            '& .content': {
                marginLeft: '50px',
                width: '100%',

                '& .row': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    lineHeight: Tokens.FontFamily.FontHeader2LineHeight,

                    '& .data': {
                        display: 'flex',
                        flexDirection: 'row',
                        width: '50%',
                        alignItems: 'center',
                    },

                    '& .header': {
                        display: 'inline-block',
                        fontSize: Tokens.FontFamily.FontMSize,
                        lineHeight: Tokens.FontFamily.FontMLineHeight,
                        fontStyle: 'normal',
                        color: theme.palette.semantic.colorForeground,
                        width: '20%',
                    },

                    '& .value': {
                        marginLeft: '10px',
                        fontWeight: 300,
                        width: '75%',
                    },

                    '& .lastRowHeader': {
                        fontSize: Tokens.FontFamily.FontMSize,
                        lineHeight: Tokens.FontFamily.FontMLineHeight,
                        fontStyle: 'normal',
                        color: theme.palette.semantic.colorForeground,
                        display: 'inline-block',
                        width: '10%',
                    },

                    '& .lastrow': { width: '100%' },
                },
            },
        },

        '& .logsInformation': {
            marginBottom: '40px',
            border: `solid 1px ${theme.palette.semantic.colorBorder}`,
            boxSizing: 'border-box',
            borderRadius: '2px',
            whiteSpace: 'pre-line',
            boxShadow: 'none',
            height: 'auto',
            maxHeight: '400px',
            width: '100%',
            overflowY: 'scroll',

            '& .data': {
                fontSize: Tokens.FontFamily.FontMSize,
                lineHeight: Tokens.FontFamily.FontMLineHeight,
                padding: '12px',
                marginBottom: '10px',
                color: theme.palette.semantic.colorForeground,
                fontWeight: 'normal',
                alignItems: 'center',
            },
        },
    },
}));

interface MlLogsDetailsPageProps {
    isInstanceProfileEnabled: boolean;
    azureStorageFQDNSuffix: string;
}

const MlLogsDetailsPage: React.FC<MlLogsDetailsPageProps> = ({
    isInstanceProfileEnabled, azureStorageFQDNSuffix,
}) => {
    const authToken = useSelector((state: any) => state.auth.authToken);
    const classes = mlLogDetailStyles();
    const {
        t, i18n,
    } = useTranslation();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const locationState: any = useLocation()?.state;
    const mlLogs: AuditLogDto = locationState.data;
    const breadCrumbPath: string = locationState.breadCrumbPath;
    const breadCrumbData: any = locationState.breadCrumbData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const metadata: any = mlLogs.metadata;

    /* Backend is sending entityId is upper case, need to convert it to lower case */
    const pipelineRunId = mlLogs.entityId?.toLowerCase();
    const [ partialLogsExistsToDownload, setPartialLogaExistsToDownload ] = React.useState(false);

    /* Download logs in case of pipeline run only. Error log only exist of PIPELINE STATUS in [FAILED, KILLED, COMPLETED] */
    const downloadButton = (mlLogs.errorLog && mlLogs?.entityTypeName?.toString() === 'PIPELINE');

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    React.useEffect(() => {
        if (downloadButton === true && partialLogsExistsToDownload === false) {
            getPipelineRunLogs(pipelineRunId, currentProject?.id)
                .then((response) => {
                    if (response?.runLogs) {
                        setPartialLogaExistsToDownload(true);
                    }
                    return true;
                })
                .catch((error) => {
                    logger.error({
                        identifier: 'Pipeline logs',
                        message: 'Error getting pipeline run logs',
                        error,
                    });
                    /* Do not add feedback snack bar here */
                });
        }
    }, []);

    const url = React.useMemo(() => URLManager.url().apiTrainer + '/signedURL?blobName=logs/' + pipelineRunId + '/' + pipelineRunId + '.log&signingMethod=GET&projectId=' + currentProject?.id, [ currentProject ]);

    const iconsButtons = [
        {
            type: BaseTableIcons.DOWNLOAD,
            click: (): void => downloadContentWithSignUrl(url, isInstanceProfileEnabled, authToken, azureStorageFQDNSuffix),
        },
    ];

    /* Generate breadcrum for ML Logs detail page */
    const mlLogsDetailsbreadcrumProps: BreadcrumbProps =
  {
      pageTitle: dateFormatter(mlLogs.createdOn, i18n.language),
      crumbs: [
          {
              title: t('ml_logs_title'),
              link: breadCrumbPath,
              data: !breadCrumbData ? null : breadCrumbData,
          },
      ],
  };

    const UserFragment: React.FC = () => (<div className="data">
        <span className="header">
            {t('ml_logs_details_user_label')}
        </span>
        <span className="value">
            {returnValueIfNotUUID(mlLogs.createdBy)}
        </span>
    </div>);

    return (
        <div className={classes.root}>
            <Section
                breadcrumProps={mlLogsDetailsbreadcrumProps}
                iconsButtons={(downloadButton === true && partialLogsExistsToDownload === true) ? iconsButtons : undefined}>

                <div className="mlLogsdetails">
                    <div
                        aria-hidden
                        className="image">
                        <HeroInfoIcon />
                    </div>

                    <div className="content">
                        <div className="row">
                            <div className="data">
                                <div className="header">
                                    {t('ml_logs_details_severity_label')}
                                </div>
                                <div className="value">
                                    {dataFormatter(mlLogs.severity)}
                                </div>
                            </div>

                            <div className="data">
                                <div className="header">
                                    {t('ml_logs_details_about_label')}
                                </div>
                                <div className="value">
                                    {t(entityDataMapper(mlLogs.entityTypeName?.toString(), EntityTypeNameMapping))}
                                </div>
                            </div>
                        </div>
                        {
                            mlLogs?.entityTypeName?.toString() !== 'DATASET' ?
                                <>
                                    <div className="row spaceFromTop">
                                        <div className="data">
                                            <div className="header">
                                                {t('ml_logs_details_ml_package_label')}
                                            </div>
                                            <div className="value">
                                                {metadata?.MLPackageName}
                                            </div>
                                        </div>

                                        <div className="data">
                                            <div className="header">
                                                {t('ml_logs_details_version_label')}
                                            </div>
                                            <div className="value">
                                                {metadata?.MLPackageVersion}
                                            </div>
                                        </div>
                                    </div>
                                </> : null
                        }

                        <div className="row spaceFromTop">
                            {mlLogs.entityTypeName === 'ML_SKILL' ?
                                <>
                                    <div className="data">
                                        <div className="header">
                                            {t('ml_logs_details_ml_skill_label')}
                                        </div>
                                        <div className="value">
                                            {metadata?.MLSkillName}
                                        </div>
                                    </div>
                                </>
                                :
                                mlLogs?.entityTypeName?.toString() === 'DATASET' ?
                                    <>
                                        <div className="data">
                                            <div className="header">
                                                {t('ml_logs_details_dataset_label')}
                                            </div>
                                            <div className="value">
                                                {metadata?.DatasetName}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    mlLogs?.entityTypeName?.toString() === 'PIPELINE' ?
                                        <>
                                            <div className="data">
                                                <div className="header">
                                                    {t('ml_logs_details_pipeline_type_label')}
                                                </div>
                                                <div className="value">
Train Pipeline
                                                </div>
                                            </div>
                                        </> : null}

                            <div className="data">
                                <div className="header">
                                    {t('ml_logs_details_created_time_label')}
                                </div>
                                <div className="value">
                                    {dateFormatter(mlLogs.createdOn, i18n.language)}
                                </div>
                            </div>

                            {mlLogs.entityTypeName !== 'ML_SKILL' && mlLogs.entityTypeName !== 'PIPELINE' ?
                                <UserFragment /> : null}
                        </div>

                        {mlLogs.entityTypeName === 'ML_SKILL' || mlLogs.entityTypeName === 'PIPELINE' ?
                            <div className="row spaceFromTop">
                                <UserFragment />
                            </div> : null}

                        <div className="row spaceFromTop">
                            <div className="data lastrow">
                                <div className="lastRowHeader">
                                    {t('ml_logs_details_description_label')}
                                </div>
                                <div className="value">
                                    {mlLogs.message}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {mlLogs.errorLog ?
                    <div className="logsInformation">
                        <div className="data">
                            {mlLogs.errorLog}
                        </div>
                    </div>
                    : null}
            </Section>
        </div >
    );
};

export default connect((state: any) => ({
    isInstanceProfileEnabled: state.config.paths ? state.config.paths.isInstanceProfileEnabled : undefined,
    azureStorageFQDNSuffix: state.config.paths ? state.config.paths.azureStorageFQDNSuffix : AZURE_SAS_TOKEN_SUBS_STRING,
}))(MlLogsDetailsPage);
