import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';

import {
    createTenant,
    deleteTenant,
} from '../../api/client/deployerManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import { CustomDialog } from '../../components/Dialog';
import Section from '../../components/Section';
import ServerSideTable from '../../components/Table/ServerSideTable/ServerSideTable';
import URLManager from '../../config/URLManager';
import { extractErrorMessage } from '../../utils/CommonUtils';
import logger from '../../utils/Logging';

const styles = makeStyles(() => createStyles({
    tenantManagementPage: {
        width: '100%',
        fontSize: Tokens.FontFamily.FontMSize,
        fontFamily: Tokens.FontFamily.FontNormal,
        overflowY: 'auto',
        height: 'calc(100vh - 76px)',
    },
}),
);

export const TenantManagement: React.FC = () => {
    const classes = styles();
    const { t } = useTranslation();

    return (
        <div className={classes.tenantManagementPage}>
            <Section title={t('tenant_management')}>
                <TenantList />
            </Section>
        </div >
    );
};

const TenantList: React.FC = () => {
    const { t } = useTranslation();
    const [ open, setOpen ] = React.useState(false);
    const feedback = useFeedback();
    const [ autoRefreshFlag, setAutoRefreshFlag ] = React.useState(false);

    const _currentTenantName = React.useRef('');
    const _currentTenantId = React.useRef('');
    const tenantUrl = URLManager.url().apiDeployer + '/tenant/merged';

    const dataMapper: Column[] = [
        {
            Header: `${t('tenant_name')}`,
            accessor: 'tenantName',
        },
        {
            Header: `${t('aifabric_provisioned')}`,
            accessor: 'aiFabricProvisioned',
            Cell: ({ cell: { value } }): string => value ? t('provisioned') : t('not_provisioned'),
        },
        {
            Header: `${t('orchestrator_provisioned')}`,
            accessor: 'orchestratorProvisioned',
            Cell: ({ cell: { value } }): string => value ? t('provisioned') : t('not_provisioned'),
        },
    ];

    const closeDialog = (): void => {
        setOpen(false);
    };

    const deleteSelectedTenant = (): void => {
        deleteTenant(_currentTenantId.current)
            .then(() => {
                closeDialog();
                feedback.enqueueSuccess(t('feedback_delete_tenant_success'));

                // toogle the flag to reload table data
                setAutoRefreshFlag(prev => !prev);
                return true;
            })
            .catch((error) => {
                closeDialog();
                logger.error({
                    identifier: 'Tenant Management',
                    message: 'Error deleting tenant',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('feedback_delete_tenant_failed'),
                    { 40011: {} },
                ));
            });
    };

    const menuItems = [
        {
            text: 'create_tenant',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, row: any): void => {
                const feedbackId = feedback.enqueueInProgress(t('feedback_create_tenant_inprogress'));
                createTenant(row.original.tenantId, row.original.tenantName)
                    .then(() => {

                        // toogle the flag to reload table data
                        setAutoRefreshFlag(prev => !prev);

                        feedback.closeSnackbar(feedbackId);
                        feedback.enqueueSuccess(t('feedback_create_tenant_success'));
                        return true;
                    })
                    .catch((error) => {
                        logger.error({
                            identifier: 'Tenant Management',
                            message: 'Error creating tenant',
                            error,
                        });
                        feedback.closeSnackbar(feedbackId);
                        feedback.enqueueError(extractErrorMessage(
                            error,
                            t('feedback_create_tenant_failed'),
                            { 40011: {} },
                        ));
                    });
            },
        },
        {
            text: 'disable_tenant',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, row: any): void => {
                _currentTenantId.current = row.original.tenantId;
                _currentTenantName.current = row.original.tenantName;
                setOpen(true);
            },
        },
    ];

    return (
        <>
            <CustomDialog
                title={t('tenant_delete_dialog_title')}
                open={open}
                handleClose={closeDialog}
                closeIconButton
                infoText={t('tenant_delete_dialog_info_text')}
                warningText={t('tenant_delete_dialog_warning_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={deleteSelectedTenant}
                secondarybuttonCallback={closeDialog}
            />

            <ServerSideTable
                url={tenantUrl}
                totalKey="data.totalCount"
                dataKey="data.dataList"
                contextMenuItems={menuItems}
                mapper={dataMapper}
                itemDeletedFlag={autoRefreshFlag}
                level="settings_pagesize"
            />
        </>
    );
};
