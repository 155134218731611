import * as React from 'react';

import TelemetryProvider from '../../main/telemetry/TelemetryProvider';
import { ConfigurationHost } from '../configHost/ConfigHost';

export const TelemetryHost: React.FC = () => (
    <TelemetryProvider>
        <ConfigurationHost />
    </TelemetryProvider>
);
