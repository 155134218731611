import {useEffect, useState} from "react";

export const useExternalStyle = (url: string) => {
  let [state, setState] = useState<string>(url ? "loading" : "idle");

  useEffect(() => {
    if (!url) {
      setState("idle");
      return;
    }
    let link : HTMLLinkElement | null = document.querySelector(`link[href="${url}"]`);

    if (!link) {
      link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = url;
      document.body.appendChild(link);
    }
  }, [url]);

  return state;
};
