import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { WithVisibility } from '../../../components/WithVisibility';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        headerBox: {
            margin: theme.spacing(2),
            marginTop: theme.spacing(0.5),
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 'bold',
        },
        taskText: {
            marginTop: theme.spacing(1),
            fontWeight: 'bold',
        },
        taskList: { margin: theme.spacing(1) },
    }),
);

interface TaskListItemProps {
    label: any;
    option: string;
    handleOpen?: () => void;
    handleDelete?: () => void;
    isTabEditable: boolean;
}

const TaskListItems: React.FC<TaskListItemProps> = ({
    label, option, handleOpen, handleDelete, isTabEditable,
}) => {
    const cssClasses = styles();
    const { t } = useTranslation();

    return (
        <>
            <List
                className={cssClasses.taskList}
                data-testid='task-list-items'>
                <Box
                    key={uuid()}
                    className={cssClasses.headerBox}>
                    <Typography className={cssClasses.taskText} >
                        {`${label['@_name']}`}
                    </Typography>
                    <WithVisibility visible={isTabEditable}>
                        <Box>
                            <IconButton
                                data-testid='delete-task-btn'
                                color="primary"
                                aria-label={t('a11y_delete_task')}
                                component="span"
                                onClick={handleDelete}>
                                <DeleteIcon color='primary' />
                            </IconButton>
                            <IconButton
                                data-testid='edit-task-btn'
                                color="primary"
                                aria-label={t('a11y_edit_task')}
                                component="span"
                                onClick={handleOpen}>
                                <EditIcon color='primary' />
                            </IconButton>
                        </Box>
                    </WithVisibility>
                </Box>

                {label[`${option}`]?.map((singleLabel: any) => (
                    <ListItem
                        key={uuid()}
                        data-testId='attr-list-item'>
                        <ListItemText >
                            <Typography>
                                {singleLabel['@_value']}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </>
    );
};

export default TaskListItems;
