import React from 'react';
import { connect } from 'react-redux';

import { useProjects } from '../../../api/global/useProjects';
import type { AuthenticationConfig } from '../../../state-management/states';
import RoleAssignmentComponent from './Base';

const ProjectLevelAssignment: React.FC<{
    token: string;
    authConfig: AuthenticationConfig;
}> = ({
    token,
    authConfig,
}) => {
    const project = useProjects();

    return (
        <RoleAssignmentComponent
            token={token}
            level="PROJECT"
            projectId={project[0].currentProjectId}
            identityUrl={authConfig.identityAPIUrl} />
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({
    token: state.auth.authToken,
    authConfig: state.config.paths ? state.config.paths.authConfig : {},
}))(ProjectLevelAssignment);
