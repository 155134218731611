import { DeploymentType } from '@uipath/telemetry-client-web';
import jwt from 'jsonwebtoken';

import { LOCALSTORAGE_SHARED_KEY_TENANT_ID } from '../../constants/AiappConstants';
import { IDP_COMM_ISSUE_MSG } from '../../constants/TelemetryConstants';
import {
    Origin,
    Scope,
    Service,
} from '../../enums/ClientErrorStrings';
import getInstance from '../../utils/AppInsights';
import { getDisplayErrorCode } from '../../utils/CommonUtils';
import logger from '../../utils/Logging';
import {
    AppMetaActions,
    UserActions,
} from '../Actions';
import { store } from '../store';

export const markIDPCommFailure = (error: Error) => {
    const backendCode = getDisplayErrorCode(Scope.Core, Service.IDENTITY, Origin.AUTHENTICATION, null);
    logger.error({
        identifier: 'Auth Provider Error',
        message: IDP_COMM_ISSUE_MSG,
        error,
        backendCode,
    });
    store.dispatch({
        type: AppMetaActions.FAIL,
        payload: { backendCode },
    });
};

export const logoutFromStore = () => {
    store.dispatch({
        type: UserActions.LOGGEDOUT,
        payload: null,
    });
};

export const updateStoreWithAuthCreds = (token?: string, idToken?: string, deploymentType?: DeploymentType) => {
    if (token) {
        const decodedToken: any = jwt.decode(token);
        const firstName = decodedToken && decodedToken.first_name;
        const lastName = decodedToken && decodedToken.last_name;
        const email = decodedToken && decodedToken.email;
        const tenantId = localStorage.getItem(LOCALSTORAGE_SHARED_KEY_TENANT_ID);
        const identifier = decodedToken?.sub;
        const sessionId = decodedToken?.sid;
        const organizationId: string = decodedToken?.prt_id;

        if (tenantId) {
            const tracker = getInstance();
            if (deploymentType !== DeploymentType.Standalone) {
                tracker?.setPlatformInfo<DeploymentType.AutomationCloud | DeploymentType.AutomationSuite>({
                    cloudOrganizationId: organizationId,
                    cloudTenantId: tenantId,
                    cloudUserId: identifier,
                    sessionId,
                });
            }
            tracker?.setLanguage(localStorage.getItem('language') || 'en');
            tracker?.trackLogin({ authenticationMethod: 'Auto' });
            tracker?.trackApplicationStart();
        }

        store.dispatch({
            type: UserActions.AUTHTOKEN,
            payload: {
                isHostAdmin: decodedToken && decodedToken['host'] === 'True',
                token,
                idToken,
                firstName,
                lastName,
                email,
            },
        });
    }
};
