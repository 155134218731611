import {
    AppInsightDriverBuilder,
    DeploymentType,
    Tracker,
} from '@uipath/telemetry-client-web';

let tracker: Tracker<Record<string, any>> | null = null;

export const getDeploymentType = (isOnPrem: boolean, portalNavEnabled: boolean): DeploymentType => {
    if (isOnPrem) {
        return portalNavEnabled ? DeploymentType.AutomationSuite : DeploymentType.Standalone;
    }
    return DeploymentType.AutomationCloud;
};

export const setup = (insightsKey: string, clusterId: string, isOnPrem: boolean, portalNavEnabled: boolean): void => {
    const deploymentType = getDeploymentType(isOnPrem, portalNavEnabled);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tracker = new Tracker<Record<string, any>>(
        deploymentType,
        'UiPath.AICenter',
        AppInsightDriverBuilder.withConfig({ instrumentationKey: insightsKey }).build(),
    );
    tracker.initialize({
        isDevelopment: process.env.NODE_ENV === 'development',
        language: localStorage.getItem('PORTAL_LOC_CONTEXT') || localStorage.getItem('AIF_LOCALE') || 'en',
    });
    tracker.setPlatformInfo<DeploymentType.AutomationCloud | DeploymentType.AutomationSuite | DeploymentType.Standalone>({ productVersion: process.env.APP_VERSION });
    if (clusterId && clusterId !== 'dev') {
        if (deploymentType === DeploymentType.AutomationSuite) {
            tracker.setPlatformInfo<DeploymentType.AutomationSuite>({ clusterId });
        } else if (deploymentType === DeploymentType.Standalone) {
            tracker.setPlatformInfo<DeploymentType.Standalone>({ installationId: clusterId });
        }
    }

    window.addEventListener('popstate', () => trackNavigation(location.pathname));
};

export const trackNavigation = (targetRoute: string) => {
    tracker?.trackNavigation({
        targetRoute,
        invocationSource: 'history',
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (): Tracker<Record<string, any>> | null => tracker;
