import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React, {
    useCallback,
    useRef,
} from 'react';

import { bindKeyTo } from '../utils/a11y';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        fontWeight: 'normal',
        width: '340px',
        border: `solid 1px ${theme.palette.semantic.colorIconDefault}`,
        lineHeight: '40px',
        padding: '0 8px',
        boxSizing: 'border-box',
        borderRadius: '6px',
        color: theme.palette.semantic.colorForegroundDeEmp,
        fontSize: theme.spacing(1.75),

        '&.hasError': { border: `solid 1px ${theme.palette.semantic.colorErrorText}` },

        '&:focus': {
            borderColor: theme.palette.semantic.colorForegroundLink,
            borderWidth: 2,
        },
    },
    errorContent: {
        color: theme.palette.semantic.colorErrorText,
        fontSize: Tokens.FontFamily.FontSSize,
        fontWeight: Tokens.FontFamily.FontWeightDefault,
        margin: '8px 0',
    },
}));
const InputMimick: React.FC<{
    label: string;
    onClick: () => void;
    requiredField?: boolean;
    error?: string;
    labelledBy?: string;
    ariaLabel?: string;
}> = ({
    label, onClick, requiredField = false, error, labelledBy, ariaLabel,
}) => {
    const classes = useStyles();
    const cellRef = useRef<HTMLDivElement | null>(null);
    const cb = useCallback(bindKeyTo({
        ref: cellRef,
        cycle: true,
    }), [ cellRef ]);
    return (
        <>
            <div
                ref={cellRef}
                onClick={onClick}
                aria-labelledby={labelledBy ? labelledBy : undefined}
                tabIndex={0}
                onKeyDown={cb}
                data-testid="open-dataset-chooser-dialog"
                className={`${classes.root} ${error ? 'hasError' : ''}`}
                aria-label={ariaLabel ? ariaLabel : label}
                aria-required={requiredField}
                role='textbox'
            >
                {label}
            </div>
            {
                error &&
        <p className={classes.errorContent}>
            {error}
        </p>
            }
        </>
    );
};

export default InputMimick;
