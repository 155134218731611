import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

interface InfiniteScrollProps {
    items: any;
    next: any;
    hasMore: boolean;
    scrollableTarget: string;
    hasScrolled: boolean;
    loader?: React.ReactNode;
    pullDownToRefresh?: boolean;
    refreshFunction?: any;
    pullDownToRefreshThreshold?: number;
    releaseToRefreshContent?: React.ReactNode;
    pullDownToRefreshContent?: React.ReactNode;
    endMessage?: React.ReactNode;
}

/**
 *  This component is used to support on demaand data
 *  fetch from server while scrolling
 *
 */
export const InfiniteScrollComponent: React.FC<InfiniteScrollProps> = props => {

    const { t } = useTranslation();

    /* Call next function from wrapper */
    const fetchMoreData = () => {
        if (props.next != null) {
            props.next();
        }
    };

    return (
        <InfiniteScroll
            dataLength={props.items.length}
            next={fetchMoreData}
            hasMore={props.hasMore}
            scrollableTarget={props.scrollableTarget}
            loader={props?.loader ? props?.loader : <h4>
                {t('auto_complete_loading_text')}
            </h4>}
            pullDownToRefresh
            scrollThreshold="80%"
            refreshFunction={fetchMoreData}
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={<div
                className="MuiTypography-root MuiTypography-h3 MuiTypography-gutterBottom"
                aria-hidden="true"
                style={{ textAlign: 'center' }}>
&#8595;
                {t('pull_down_to_refresh')}
            </div>}
            releaseToRefreshContent={<div
                className="MuiTypography-root MuiTypography-h3 MuiTypography-gutterBottom"
                style={{ textAlign: 'center' }}>
&#8593;
                {t('release_to_refresh')}
                {' '}
            </div>}
            endMessage={props.hasScrolled ? (<p style={{ textAlign: 'center' }}>
                {' '}
                <b>
                    {t('no_more_results_found')}
                </b>
                {' '}
            </p>) : ''}
        >
            {props.children}
        </InfiniteScroll>
    );
};
