import type { AxiosResponse } from 'axios';
import axios from 'axios';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';

import { useFeedback } from '../../../api/global/useFeedback';
import type { Icon } from '../../../utils/IconsInterface';
import logger from '../../../utils/Logging';
import type {
    PaginationArgs,
    SelectionItem,
} from '../BaseTable/BaseTable';
import Table from '../BaseTable/BaseTable';
import { http } from './../../../http';

interface ServerSideTableProps {
    url: string;
    totalKey: string;
    mapper: Column[];
    dataKey: string;
    urlBuilder?: Function;
    contextMenuItems?: ContextMenuItem[];
    isSelectAble?: boolean;
    showRefresh?: boolean;
    selectionItems?: SelectionItem;
    tooltip?: string;
    // todo: Sahil to fix this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icons?: Icon[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onTableCellClicked?(row: any, event: any): any;
    itemDeletedFlag?: boolean;
    level?: string;
}

interface ContextMenuItem {
    text: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    click?: (event: React.MouseEvent<HTMLLIElement>, data: any) => void;
}

const ServerSideTableGCS: React.FC<ServerSideTableProps> = (
    {
        url,
        mapper,
        dataKey,
        contextMenuItems,
        isSelectAble,
        icons,
        onTableCellClicked,
        totalKey,
        selectionItems,
        itemDeletedFlag,
        showRefresh = true,
        level = '',
    }) => {

    const feedback = useFeedback();
    const { t } = useTranslation();
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ refresh, setRefresh ] = useState(false);
    const [ pageNum, setPageNum ] = useState(1);
    const [ pageSize, setPageSize ] = useState(20);
    const [ tokens, setTokens ] = useState<{ [k: string]: number }>({});
    const [ empty, setEmpty ] = useState(false);

    const refreshButtonClicked = (): void => {
    // Toggle refresh state
        setRefresh(prev => !prev);
    };

    useEffect(() => {
        setLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const params: any = { pageSize: pageNum === 1 ? (parseInt('' + pageSize, 10) + 1) : pageSize };
        if (tokens[pageNum]) {
            params.pageToken = tokens[pageNum];
        }
        http.get(url, { params }).then((response: AxiosResponse) => {
            let responseData = response.data;
            let totalDataSet = response.data;

            dataKey.split('.').forEach(key => (responseData = responseData[key]));
            setData(responseData);
            totalKey.split('.').forEach(key => (totalDataSet = totalDataSet[key]));

            if (!responseData || !responseData.length) {
                setEmpty(true);
                setData([]);
            } else {
                setEmpty(false);
                setData(responseData);
            }
            setLoading(false);
            setTokens({
                ...tokens,
                [pageNum + 1]: response.data.data.nextPageToken,
            });
            return true;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        })
            .catch((error: any) => {
            /** Don't do anything if request is cancelled by debounce action */
                if (!axios.isCancel(error)) {
                    logger.error({
                        identifier: 'Server side table GCS',
                        message: 'Error making API call',
                        error,
                        payload: params,
                    });
                    setEmpty(true);
                    setData([]);
                    setLoading(false);
                    feedback.enqueueError(t('feedback_table_loading_error'));
                }
            });
    }, [ url, dataKey, totalKey, refresh, pageSize, pageNum, itemDeletedFlag ]);

    const paginate = useCallback((params: PaginationArgs) => {
        setPageSize(params.pageSize);
        setPageNum(params.pageIndex + 1);
    }, []);

    return (
        <div>
            <Table
                data={data}
                columns={mapper}
                isLoading={loading}
                empty={empty}
                contextMenuItems={contextMenuItems}
                isSelectAble={isSelectAble}
                total={Infinity}
                icons={icons}
                showRefresh={showRefresh}
                onTableCellClicked={onTableCellClicked}
                refreshButtonClicked={refreshButtonClicked}
                selectionItems={selectionItems}
                onPagination={paginate}
                canNextPageProp={!!tokens[pageNum + 1]}
                level={level}
            />
        </div>
    );
};

export default ServerSideTableGCS;
