import {
    CircularProgress,
    Grid,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import type { ReactElement } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '300px',
        height: '250px',
        textAlign: 'center',
    },
    item: {
        width: '150px',
        height: '175px',
    },
    circle: { transform: 'translateX(0)' },
    top: {
        color: theme.palette.secondary.main,
        opacity: 0.1,
        position: 'fixed',
        left: 0,
    },
    bottom: {
        position: 'fixed',
        left: 0,
    },
    circleText: {
        position: 'fixed',
        width: '150px',
        height: '150px',
    },
    innerNumber: {
        color: theme.palette.secondary.main,
        fontSize: Tokens.FontFamily.FontHeader3Size,
    },
    innerText: { color: theme.palette.grey[500] },
}));

interface GaugeProps {
    used?: number;
    allowed?: number;
    descriptionKey: string;
}

export default function Gauge(props: GaugeProps): ReactElement {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid
            className={classes.root}
            container
            direction="column"
            justifyContent='center'
            alignItems='center'
        >
            <Grid
                item
                className={classes.item}>
                <div className={classes.circle}>
                    <CircularProgress
                        className={classes.top}
                        variant="determinate"
                        value={100}
                        size="150px"
                    />
                    <CircularProgress
                        className={classes.bottom}
                        variant="determinate"
                        value={(props.used && props.allowed) ? props.used * 100 / props.allowed : 0}
                        size="150px"
                        color="secondary"
                    />
                    <Grid
                        className={classes.circleText}
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Grid item>
                            <span className={classes.innerNumber}>
                                {props.used ? props.used : 0}
                            </span>
                        </Grid>
                        <Grid item>
                            <span className={classes.innerText}>
                                {`${t('licenses_label_of')} ${props.allowed ? props.allowed : 0}`}
                            </span>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item>
                {t(props.descriptionKey, {
                    a: props.used ? props.used : 0,
                    b: props.allowed ? props.allowed : 0,
                })}
            </Grid>
        </Grid>
    );
}
