import { makeStyles } from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    columnTitle: {
        fontSize: Tokens.FontFamily.FontMSize,
        lineHeight: Tokens.FontFamily.FontMLineHeight,
        paddingRight: '8px',
        color: theme.palette.semantic.colorForeground,
    },
}));
export const ColumnTitle: React.FC = ({ children }) => {
    const classes = useStyles();
    return <span className={classes.columnTitle}>
        {children}
    </span>;
};
