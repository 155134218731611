import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import type { ProjectDto } from '@uipath/aifabric';
import React, {
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useParams,
} from 'react-router-dom';

import { getLabelingTemplates } from '../../api/client/dataLabelling';
import { usePermissions } from '../../api/global/usePermissions';
import type {
    ProjectActions,
    ProjectState,
} from '../../api/global/useProjects';
import { useProjects } from '../../api/global/useProjects';
import extractionPkgImg from '../../assets/extract-pkg.svg';
import { ActionCard } from '../../components/ActionCard';
import Section from '../../components/Section';
import { WithVisibility } from '../../components/WithVisibility';
import { AppPermissions } from '../../enums/Authorization';
import { RoutePath } from '../../route/routeMap';

const OOBTemplates = makeStyles(() =>
    createStyles({
        dlPage: {
            '& h4': { marginLeft: '24px' },

            '& .apps-container': {
                display: 'flex',

                '& .app': { marginRight: '2em' },
            },
        },
    }),
);

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const OOBLabelingTemplates: React.FC = () => {
    const { projectName } = useParams<{ projectName: string }>();
    const cssClasses = OOBTemplates();
    const [ data, setData ] = useState<any>([]);
    const { t } = useTranslation();

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    const [ , permissionActions ] = usePermissions();
    const permissions = permissionActions.getProjectPermissions(currentProject?.id as string);

    useEffect(() => {
        if (currentProject?.id) {
            getLabelingTemplates(currentProject.id).then((response: any[]) => {
                setData(response);
                return true;
            })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [ currentProject?.id ]);

    return (
        <div className={cssClasses.dlPage}>
            <WithVisibility visible={permissions.indexOf(AppPermissions.DataLabeling_Create) > -1}>
                <Section title={t('configure-data-labeling')}>
                    {
                        data?.map((template: any) => (
                            <div className="apps-container">
                                <div
                                    className="app"
                                    data-testid="app-datamanager"
                                    data-cy={template.name}>
                                    <ActionCard
                                        title={template.name}
                                        cardContent={template.description}
                                        imgUrl={extractionPkgImg}
                                        route={generatePath(RoutePath.CREATE_DATALABELING, { projectName })}
                                        data={{
                                            projectName,
                                            appType: 'LABEL_BOX',
                                            oobTemplateId: template.id,
                                        }}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </Section>
            </WithVisibility>
        </div>
    );
};
