import type { AutocompleteInputChangeReason } from '@mui/material';
import {
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import type {
    BasePipelineDto,
    MLPackageDto,
    MLPackageVersionDto,
    ProjectDto,
    RunDto,
} from '@uipath/aifabric';
import type { CancelTokenSource } from 'axios';
import type { FormikProps } from 'formik';
import {
    ErrorMessage,
    Field,
    Formik,
    validateYupSchema,
    yupToFormErrors,
} from 'formik';
import {
    RadioGroup,
    Switch,
    TextField,
} from 'formik-mui';
import {
    DatePicker,
    TimePicker,
} from 'formik-mui-x-date-pickers';
import { debounce } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';
import * as Yup from 'yup';

import type FeatureFlagManager from '../../../../src/feature-flag/FeatureFlagManager';
import { getDatasetById } from '../../../api/client/datasetManagerClient';
import { getIsAIUnitEnabled } from '../../../api/client/deployerManagerClient';
import {
    createPipeline,
    getPipelineById,
    updatePipeline,
} from '../../../api/client/pipelineManagerClient';
import {
    getMlPackages,
    getMlVersionsByPackageId,
} from '../../../api/client/pkgManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import type {
    ProjectActions,
    ProjectState,
} from '../../../api/global/useProjects';
import { useProjects } from '../../../api/global/useProjects';
import {
    PipelineType,
    ScheduleType,
} from '../../../api/types/common';
import AccessibleFormikInput from '../../../components/AccessibleFormikInput';
import TooltipContainer from '../../../components/AccessibleTooltip/TooltipContainer';
import CronInput from '../../../components/CronInput/';
import type {
    EnvironmentVariable,
    ParametersFileEnvironmentVariable,
} from '../../../components/EnvironmentVariablesTable';
import {
    EnvironmentVariablesTable,
    ParametersFileKeys,
    ParametersFileParamsKeyEntries,
    ParametersFileParamsKeyPossibleTypes,
} from '../../../components/EnvironmentVariablesTable';
import {
    FormAutoCompleteClientSide,
    FormAutoCompleteServerSide,
} from '../../../components/FormAutoComplete';
import FormButtonGroup from '../../../components/FormButtonGroup';
import FormikErrorLabels from '../../../components/FormikErrorLabels';
import FormLayout from '../../../components/FormLayout';
import Label from '../../../components/Label';
import {
    CPU_AIUNITS,
    GPU_AIUNITS,
} from '../../../constants/AiappConstants';
import { Validation as val } from '../../../constants/validation';
import type { DataSetInput } from '../../../containers/DatasetChooser';
import FormikCompliantDataSetChooser from '../../../containers/DatasetChooser';
import { http } from '../../../http/';
import { RoutePath } from '../../../route/routeMap';
import {
    extractErrorMessage,
    extractKeyValuePairs,
    getCronStr,
    labelFunc,
    localeMap,
} from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';
import logger from '../../../utils/Logging';

interface PipelineTypeOption {
    name: string;
    type: PipelineType;
}

interface WithHookStateUpdateProps {
    payload: any; // To update it with
    flag: number; // Should Update it
    setter: any; // How to update
}

const WithHookStateUpdate: React.FC<WithHookStateUpdateProps> = (
    {
        payload,
        flag,
        setter,
    },
) => {
    React.useEffect(() => {
        if (flag == 1) {
            setter(payload);
        }
    }, [ flag ]);
    return (<div />);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transform = (from: any, projectId: string | undefined, isPipelineInfraSettingsEnabled: boolean, sourceCustomVersion: string | undefined): any => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    const useCustomValues: boolean = (isPipelineInfraSettingsEnabled && from.pipelineInfraSettings || from.packageMinorVersionId != '');

    const requestBody: any = {
        environmentVars: from.envVars.map((n: EnvironmentVariable) => ({
            key: n.name,
            value: n.value,
        })),
        pipelineType: from.pipelineType,
        name: 'Pipeline_' + (+ new Date()),
        displayName: from.displayName,
        processor: from.enableGpu ? 'GPU' : 'CPU',
        projectId,
        scheduleType: (from.schedule !== ScheduleType.REPEAT_TRIGGER) ? from.schedule : null,
        sourceId: from.packageId,
        sourceType: 'ML_PACKAGE',
        sourceVersionId: from.packageMinorVersionId,
        requestMemory: useCustomValues ? from.requestMemory : 4,
        requestCPU: useCustomValues ? from.requestCPU : 2,
        limitMemory: useCustomValues ? from.limitMemory : 12,
        limitCPU: useCustomValues ? from.limitCPU : 2,
        pvcSize: useCustomValues ? from.pvcSize : 50,
        sourceCustomVersion,
    };

    if (from.schedule === ScheduleType.REPEAT_TRIGGER) {
        requestBody.repeat = true;
        requestBody.schedule = from.cronInput.cronExp;
        requestBody.scheduleType = ScheduleType.TIME_TRIGGER;
        if (from.cronInput.recurrence > 0 && from.cronInput.time != null) {
            requestBody.recurrence = from.cronInput.recurrence;
            requestBody.triggerTime = +(new Date(from.cronInput.time));
        }
    } else {
        requestBody.scheduleType = from.schedule;

        if (from.date) {
            const dateStr = new Date(from.date).toDateString();
            const timeStr = new Date(from.time).toTimeString();
            const dateTime = new Date(dateStr + ' ' + timeStr);
            requestBody.triggerTime = +dateTime;
        } else {
            requestBody.triggerTime = +from.time;
        }
    }

    /* Empty tree node is passed when not selected */
    if (from?.evalDataset?.datasetId) {
        requestBody.evaluationDatasetId = from.evalDataset.datasetId;
        requestBody.evaluationDataDirectory = from.evalDataset.dataDirectory || '/';
    }

    /* Empty tree node is passed when not selected */
    if (from?.inputDataset?.datasetId) {
        requestBody.trainingDatasetId = from.inputDataset.datasetId;
        requestBody.dataDirectory = from.inputDataset.dataDirectory || '/';
    }
    return requestBody;
};

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
            '& .disabledDisplay': { '& .MuiInputBase-root.Mui-disabled': { color: theme.palette.primary.dark } },
            '& .crono': {
                position: 'relative',

                '& .Trigger': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                },
            },
        },

        infraSettingsContainer: { marginBottom: '25px' },

        pvcSizeContainer: { paddingLeft: '16px' },

        infraSettings: {
            width: '100%',

            '& .resources': { '& .table': { width: '40%' } },
        },
        accessibleMenuItem: { opacity: '1 !important' },
        label: {
            display: 'block',
            marginTop: '15px',
            marginBottom: '5px',
        },

        tsErrorMessage: {
            color: theme.palette.semantic.colorErrorText,
            margin: '8px 14px 0',
            fontWeight: 400,
        },
        error: {
            borderColor: theme.palette.semantic.colorErrorText,
            borderWidth: '2px',
        },
        header: { color: theme.palette.semantic.colorForeground },
        tooltipHeader: { marginTop: theme.spacing(1) },
        customTooltipClass: { marginTop: '20px' },
        customTooltipClassTimeAndRecurring: {
            marginLeft: '-10px',
            marginRight: '20px',
        },
    }),
);

interface PipelineCreateProps {
    featureManager: FeatureFlagManager;
    accountId: string;
    isOnPrem: boolean;
}

const PipelineCreate: React.FC<PipelineCreateProps> = ({
    featureManager, accountId, isOnPrem,
}) => {
    const {
        t, i18n,
    } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const {
        pipelineId, mlpackageName,
    } = useParams<{ pipelineId: string; mlpackageName: string }>();
    const feedback = useFeedback();

    const [ inputChanged, setInputChanged ] = React.useState(false);
    const [ loadingState, setLoadingState ] = React.useState(true);

    const [ pipeline, setPipeline ] = React.useState<BasePipelineDto | undefined>(undefined);
    const [ pipelineTypes, setPipelineTypes ] = React.useState<PipelineTypeOption[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ packageList, setPackageList ] = React.useState<any[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ packageMajorVersionList, setPackageMajorVersionList ] = React.useState<any[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ packageMinorVersionList, setPackageMinorVersionList ] = React.useState<any[]>([]);

    const [ inputDataset, setInputDataset ] = React.useState<DataSetInput>({
        label: '',
        datasetId: '',
        dataDirectory: '',
    });
    const [ evalDataset, setEvalDataset ] = React.useState<DataSetInput>({
        label: '',
        datasetId: '',
        dataDirectory: '',
    });

    const [ selectedPackage, setSelectedPackage ] = React.useState('');
    const [ selectedPackageMajorVersion, setSelectedPackageMajorVersion ] = React.useState('');

    const [ searchTerm, setSearchTerm ] = React.useState('');
    const [ cancelToken, setCancelToken ] = React.useState<CancelTokenSource | null>(null);
    const [ debouncedSearchTerm, setDebouncedSearchTerm ] = React.useState('');

    let selection = '';
    const loading = (packageList.length === 0 ? true : false) && (loadingState === true ? true : false);
    const majorVersionDisabled = (loading ? true : false) || (packageMajorVersionList.length === 0 ? true : false);
    const minorVersionDisabled = (majorVersionDisabled) || (packageMinorVersionList.length === 0 ? true : false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ mlPackageMajaorVersionValue, setMlPackageMajaorVersionValue ]: any = React.useState('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ mlPackageMinorVersionValue, setMlPackageMinorVersionValue ]: any = React.useState('');
    const [ mlPackageCustomVersion, setMlPackageCustomVersion ]: any = React.useState('');

    const [ parametersFileJSON, setParametersFileJSON ]: any = React.useState([]);
    const [ isParametersFileDirty, setIsParametersFileDirty ]: any = React.useState(false);

    const [ isAiUnitEnabled, setisAiUnitEnabled ] = React.useState<any>(false);
    const [ isPipelineInfraSettingsEnabled, setIsPipelineInfraSettingsEnabled ] = React.useState<any>(featureManager.isEnabled('ml-skill-infra-settings-enabled'));

    const [ currentProject ] = useProjects<ProjectDto | undefined, (value: string) => void>(
        (state: ProjectState) => state.currentProject,
    (actions: ProjectActions) => actions.setCurrent,
    );

    const debounceAction = debounce(() => {
        setDebouncedSearchTerm(searchTerm);
    }, 200);

    const getRequestMemory = (isTmPackage: boolean) => getDefault(isTmPackage, 45, 12);
    const getLimitMemory = (isTmPackage: boolean) => getDefault(isTmPackage, 55, 23);
    const getRequestCPU = (isTmPackage: boolean) => getDefault(isTmPackage, 14, 1);
    const getLimitCPU = (isTmPackage: boolean) => getDefault(isTmPackage, 15, 3);
    const getPvcSize = (isTmPackage: boolean) => getDefault(isTmPackage, 200, 50);

    const getDefault = (isTmPackage: boolean, tmDefault: number, pipelineDefault: number) => isTmPackage ? tmDefault : pipelineDefault;
    React.useEffect(() => {
        if (inputChanged === true) {
            debounceAction();
            return function cleanup(): void {
                debounceAction.cancel();
            };
        }
    }, [ searchTerm ]);

    React.useEffect(() => {
        if (!isOnPrem) {
            getIsAIUnitEnabled(accountId)
                .then((res) => {
                    setisAiUnitEnabled(res?.data);
                    setIsPipelineInfraSettingsEnabled(res?.data);
                    return res?.data;
                })
                .catch((error) => {
                    logger.error({
                        identifier: 'Core',
                        message: t('ml_aiunitenabled_fetch_error_message'),
                        error,
                    });
                    return false;
                });
        }
    }, [ isAiUnitEnabled ]);

    React.useEffect(() => {
        if (inputChanged === true || loading === true) {
            setPackageList([]);
            setLoadingState(true);
            loadData();
            return function cleanup(): void {
                loadData.cancel();
                if (cancelToken) {
                    cancelToken.cancel();
                }
            };
        }
    }, [ debouncedSearchTerm ]);

    const loadData = debounce(() => {

        const CancelToken = http.CancelToken;
        const source = CancelToken.source();
        setCancelToken(source);
        setLoadingState(true);

        getMlPackages(source, currentProject?.id, selection).then((packages: MLPackageDto[] | undefined) => {
            if (packages && packages.length) {
                setPackageList(packages
                    .filter((pkg: MLPackageDto) => pkg.retrainable)
                    .map((pkg: MLPackageDto) => ({
                        id: pkg.id,
                        name: pkg.name,
                        tmModel: pkg.tmModel,
                    })));
            }
            return true;
        })
            .finally(() => {
                setLoadingState(false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            })
            .catch((error: any) => {
                logger.error({
                    identifier: 'Pipeline create',
                    message: 'Error getting ML Packages',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('ml_package_loading_error'),
                    {
                        20003: {},
                        20004: {},
                    },
                ));
            });
    });

    React.useEffect(() => {
        setPipelineTypes([
            {
                name: t('PIPELINE_FULL_TRAINING_OPTION'),
                type: PipelineType.FullTraining,
            },
            {
                name: t('PIPELINE_TRAIN_ONLY_OPTION'),
                type: PipelineType.TrainOnly,
            },
            {
                name: t('PIPELINE_EVALUATE_ONLY_OPTION'),
                type: PipelineType.EvaluateOnly,
            },
        ]);

        if (pipelineId) {
            getPipelineById(pipelineId, currentProject?.id)
                .then((pipeline: RunDto | undefined) => {
                    setPipeline(pipeline);
                    if (pipeline?.trainingDatasetId) {
                        getDatasetById(pipeline?.trainingDatasetId, currentProject?.id).then((dataset) => {
                            const dataDirectory = pipeline.dataDirectory || '';
                            const label = (dataset?.name || '') + dataDirectory;
                            const datasetId = dataset?.id || '';
                            setInputDataset({
                                label,
                                datasetId,
                                dataDirectory,
                            });
                            return true;
                        })
                            .catch(() => false);
                    }

                    if (pipeline?.evaluationDatasetId) {
                        getDatasetById(pipeline?.evaluationDatasetId, currentProject?.id).then((dataset) => {
                            const dataDirectory = pipeline.evaluationDataDirectory || '';
                            const label = (dataset?.name || '') + dataDirectory;
                            const datasetId = dataset?.id || '';
                            setEvalDataset({
                                label,
                                datasetId,
                                dataDirectory,
                            });
                            return true;
                        })
                            .catch(() => false);
                    }

                    return true;
                })
                .catch(error => {
                    logger.error({
                        identifier: 'Pipeline create',
                        message: 'Error getting pipeline by ID',
                        error,
                    });
                    feedback.enqueueError(extractErrorMessage(
                        error,
                        t('pipelines_loading_error'),
                        {
                            10602: {
                                1: 'Pipeline',
                                0: pipelineId,
                            },
                        },
                    ));
                });
        }
    }, []);

    React.useEffect(() => {
        if (mlPackageMinorVersionValue && packageMinorVersionList.length != 0) {
            const mlPackageVersionSelected: MLPackageVersionDto = packageMinorVersionList.filter((pkg) => pkg.trainingVersion === mlPackageMinorVersionValue.trainingVersion)[0];

            if (mlPackageVersionSelected.customVersion) {
                setMlPackageCustomVersion(mlPackageVersionSelected.customVersion + '.' + mlPackageVersionSelected.trainingVersion);
            }

            // Convert the parameters file json string to json object.
            if (mlPackageVersionSelected.parametersFileJSON) {
                const parametersFileJSONString: any = mlPackageVersionSelected.parametersFileJSON;
                const parametersJSON = JSON.parse(parametersFileJSONString);

                // convert the json object into ParametersFileEnvironmentVariable.
                if (ParametersFileKeys.PARAMS in parametersJSON && Array.isArray(parametersJSON[ParametersFileKeys.PARAMS])) {
                    const settingsFileEntries: any[] = parametersJSON[ParametersFileKeys.PARAMS]
                        .filter((obj: any) => {
                            if (ParametersFileParamsKeyEntries.NAME in obj && typeof (obj.name) === 'string' && ParametersFileParamsKeyEntries.TYPE in obj && Array.isArray(obj.type) && obj.type.includes(ParametersFileParamsKeyPossibleTypes.PIPELINE)) {
                                return true;
                            }
                            return false;

                        })
                        .map((obj: any) => ({
                            name: obj.name,
                            type: obj.type,
                            value: (ParametersFileParamsKeyEntries.VALUE in obj) ? obj.value : '',
                            help: (ParametersFileParamsKeyEntries.HELP in obj) ? typeof (obj.help) === 'string' ? obj.help : '' : '',
                            options: (ParametersFileParamsKeyEntries.OPTIONS in obj) ? Array.isArray(obj.options) ? obj.options : [] : [],
                        } as ParametersFileEnvironmentVariable));
                    setIsParametersFileDirty(true);
                    setParametersFileJSON(settingsFileEntries);
                }
            }
        }
    }, [ mlPackageMinorVersionValue, packageMinorVersionList ]);

    React.useEffect(() => {
        if (selectedPackage && inputChanged === false) {
            getMlVersionsByPackageId(selectedPackage, currentProject?.id).then((versions: MLPackageVersionDto[] | undefined) => {
                if (versions && versions.length) {
                    const list = versions.filter((pkgVersion: MLPackageVersionDto) => pkgVersion.retrainable)
                        .map((pkgVersion: MLPackageVersionDto) =>
                            ({
                                id: pkgVersion.id,
                                version: pkgVersion.customVersion ? pkgVersion.customVersion : pkgVersion.version?.toString(),
                                trainingVersion: pkgVersion.trainingVersion?.toString(),
                                customVersion: pkgVersion?.customVersion,
                                parametersFileJSON: pkgVersion?.parametersFileJSON,
                            }));
                    setPackageMajorVersionList(list);
                }
                return true;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            })
                .catch((error: any) => {
                    logger.error({
                        identifier: 'Pipeline create',
                        message: 'Error getting ML Versions',
                        error,
                    });
                    feedback.enqueueError(extractErrorMessage(
                        error,
                        t('ml_package_version_loading_error'),
                        {
                            10602: {
                                1: 'ML Package Versions',
                                0: selectedPackage,
                            },
                            20101: {},
                        },
                    ));
                });
        }
    }, [ selectedPackage ]);

    React.useEffect(() => {
        setMlPackageMinorVersionValue('');
        /* Filter the ML package list for selected Major version */
        if (selectedPackageMajorVersion) {
            setPackageMinorVersionList(packageMajorVersionList.filter(pkg => pkg.version === selectedPackageMajorVersion));
        }
    }, [ selectedPackageMajorVersion ]);

    if (pipelineId && !pipeline) {
        return <h2 className={classes.header}>
            {t('pipeline_edit_loading')}
        </h2>;
    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    pipelineType: pipeline ? pipeline.pipelineType : PipelineType.FullTraining,
                    packageId: pipeline ? pipeline.sourceId : selectedPackage,
                    packageVersion: pipeline && pipeline.sourceVersion ? (pipeline.sourceVersion.split('.')[0]) : '',
                    packageMinorVersionId: pipeline ? pipeline.sourceVersionId : '',
                    /* Yup works unexpectedly with undefined values */
                    inputDataset: inputDataset.datasetId ? inputDataset : null,
                    evalDataset: evalDataset.datasetId ? evalDataset : null,
                    envVars: pipeline ?
                        (pipeline.environmentVars ? pipeline.environmentVars.map(ev => ({
                            name: ev.key,
                            value: ev.value,
                            type: [ ParametersFileParamsKeyPossibleTypes.PIPELINE ],
                        } as ParametersFileEnvironmentVariable)) : [] as ParametersFileEnvironmentVariable[]) :
                        [] as ParametersFileEnvironmentVariable[],
                    enableGpu: pipeline ? pipeline.processor === 'GPU' : false,
                    schedule: pipeline ? (pipeline.scheduleType === ScheduleType.TIME_TRIGGER && pipeline.repeat ? ScheduleType.REPEAT_TRIGGER : pipeline.scheduleType) : ScheduleType.ONE_SHOT,
                    triggerTimeStr: pipeline && pipeline.scheduleType === ScheduleType.TIME_TRIGGER && pipeline.repeat && pipeline.schedule ? getCronStr(pipeline.schedule) : '',
                    cronInput: {
                        time: pipeline && pipeline.triggerTime ? new Date(+(pipeline.triggerTime)) : null,
                        cronExp: pipeline?.schedule,
                        recurrence: pipeline?.recurrence,
                    },
                    triggerTime: pipeline && pipeline.scheduleType === ScheduleType.TIME_TRIGGER && pipeline.repeat && pipeline.triggerTime ? dateFormatter(+(pipeline.triggerTime), 'en') : '',
                    date: pipeline && pipeline.scheduleType === ScheduleType.TIME_TRIGGER && !pipeline.repeat && pipeline.triggerTime ? dateFormatter(+(pipeline.triggerTime), 'en') : new Date(),
                    time: pipeline && pipeline.scheduleType === ScheduleType.TIME_TRIGGER && !pipeline.repeat && pipeline.triggerTime ? dateFormatter(+(pipeline.triggerTime), 'en') : (Date.now() + (5 * 60 * 1000)),
                    recurrence: pipeline && pipeline.scheduleType === ScheduleType.TIME_TRIGGER && pipeline.repeat && pipeline.recurrence ? pipeline.recurrence : 0,
                    pipelineInfraSettings: pipeline ? true : false,
                    requestMemory: pipeline ? pipeline.requestMemory : 12,
                    limitMemory: pipeline ? pipeline.limitMemory : 23,
                    requestCPU: pipeline ? pipeline.requestCPU : 1,
                    limitCPU: pipeline ? pipeline.limitCPU : 3,
                    pvcSize: pipeline ? pipeline.pvcSize : 50,
                }}
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onSubmit={async values => {
                    try {
                        if (pipelineId) {
                            await updatePipeline(transform(values, currentProject?.id, isPipelineInfraSettingsEnabled, mlPackageCustomVersion), pipelineId, currentProject?.id);
                            feedback.enqueueSuccess(t('feedback_edit_success_pipeline'));
                        } else {
                            await createPipeline(transform(values, currentProject?.id, isPipelineInfraSettingsEnabled, mlPackageCustomVersion));
                            feedback.enqueueSuccess(t('feedback_create_success_pipeline'));
                        }
                        history.push(generatePath(RoutePath.PIPELINES, { projectName: currentProject?.name }));
                    } catch (error) {
                        feedback.enqueueError(extractErrorMessage(
                            error,
                            pipelineId ?
                                t('pipeline_edit_default_error') :
                                t('pipeline_create_default_error'),
                            {
                                10602: {
                                    1: 'ML Package',
                                    0: values.packageId ?? '',
                                },
                                60209: {
                                    1: 'ML Package',
                                    0: values.packageId ?? '',
                                },
                                60710: { 0: extractKeyValuePairs(error?.response?.data?.respMsg) },
                            },
                        ));
                    }
                }}
                validate={(values) => {
                    const limitMemoryToCompare = values?.limitMemory ? values.limitMemory : Number.MAX_SAFE_INTEGER - 1;
                    const limitCPUToCompare = values?.limitCPU ? values.limitCPU : Number.MAX_SAFE_INTEGER - 1;
                    if (values.pipelineType === PipelineType.TrainOnly) {
                        values.evalDataset = null;
                    }
                    const validationSchema =
            Yup.object().shape({

                pipelineType: Yup.string().required(t('pipeline_create_pipelineType_req')),
                packageId: Yup.string().required(t('ml_package_required')),
                packageVersion: Yup.string().required(t('ml_package_major_version_required_error_label')),
                packageMinorVersionId: Yup.string().required(t('ml_package_minor_version_required_error_label')),
                displayName: Yup.string()
                    .matches(val.pipelineNameValidation, t('pipeline_name_validation_error'))
                    .max(val.pipelineNameLengthMax,
                        t('pipeline_create_pipelineName_max', { max: val.pipelineNameLengthMax })),

                inputDataset: Yup.mixed().when('pipelineType', {
                    is: PipelineType.TrainOnly,
                    then: Yup.mixed()
                        .required(t('pipeline_create_inputDataset_req'))
                        .test(t('pipeline_create_inputDataset_req'), t('pipeline_create_inputDataset_req'), value => value?.datasetId),
                    otherwise: Yup.mixed().notRequired(),
                })
                    .when(
                        'pipelineType', {
                            is: PipelineType.FullTraining,
                            then: Yup.mixed()
                                .required(t('pipeline_create_inputDataset_req'))
                                .test(t('pipeline_create_inputDataset_req'), t('pipeline_create_inputDataset_req'), value => value?.datasetId),
                            otherwise: Yup.mixed().notRequired(),
                        },
                    ),

                evalDataset: Yup.mixed().when('pipelineType', {
                    is: PipelineType.EvaluateOnly,
                    then: Yup.mixed()
                        .required(t('pipeline_create_evalDataset_req'))
                        .test(t('pipeline_create_evalDataset_req'), t('pipeline_create_evalDataset_req'), value => value?.datasetId),
                    otherwise: Yup.mixed().notRequired(),
                }),

                triggerTime: Yup.mixed().when('schedule', {
                    is: ScheduleType.REPEAT_TRIGGER,
                    then: Yup.date().min(new Date(), 'Trigger time cannot be less than now'),
                }),

                requestMemory: Yup.number().integer(t('ml_skill_infra_settings_value_should_be_integer_only'))
                    .positive(t('ml_skill_infra_settings_value_greater_than_one_message'))
                    .required(t('evtable_field_req'))
                    .lessThan(limitMemoryToCompare + 1, t('ml_skill_infra_settings_request_memory_less_than_limit_memory_message')),
                limitMemory: Yup.number().integer(t('ml_skill_infra_settings_value_should_be_integer_only'))
                    .positive(t('ml_skill_infra_settings_value_greater_than_one_message'))
                    .required('evtable_field_req'),
                requestCPU: Yup.number().positive(t('ml_skill_infra_settings_value_greater_than_half_message'))
                    .required(t('evtable_field_req'))
                    .lessThan(limitCPUToCompare + 0.1, t('ml_skill_infra_settings_request_cpu_less_than_limit_cpu_message')),
                limitCPU: Yup.number().positive(t('ml_skill_infra_settings_value_greater_than_half_message'))
                    .required(t('evtable_field_req')),
                pvcSize: Yup.number().integer(t('ml_skill_infra_settings_value_should_be_integer_only'))
                    .positive(t('ml_skill_infra_settings_value_greater_than_one_message'))
                    .required(t('evtable_field_req'))
                    .min(10, t('ml_skill_infra_settings_min_storage')),
            });

                    try {
                        validateYupSchema(values, validationSchema, true, values);
                    } catch (err) {
                        return yupToFormErrors(err);
                    }
                    return {};
                }}
            >
                {// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
                    (props: FormikProps<any>) => {
                        const {
                            dirty,
                            isSubmitting,
                            handleSubmit,
                            setFieldValue,
                            submitForm,
                            values,
                            errors,
                        } = props;

                        const handleEnvVarDataChange = (newEnvVars: ParametersFileEnvironmentVariable[]): void => {
                            setFieldValue('envVars', newEnvVars);
                        };

                        /* Input changed info is true for input change and false for selection */
                        const handleSelection = (inputChangedInfo: boolean, fieldName: string, fieldValue: string): void => {

                            /* Since ML package selection, input chnaged is being controlled
             we need to avoid any change for pipeline edit case where setting initial value called callbak */
                            if (pipelineId) {
                                return;
                            }

                            setFieldValue(fieldName, fieldValue);
                            setFieldValue('packageVersion', '');
                            setFieldValue('packageMinorVersionId', '');
                            setMlPackageMajaorVersionValue('');
                            setMlPackageMinorVersionValue('');
                            setInputChanged(inputChangedInfo);
                            setPackageMajorVersionList([]);
                            setPackageMinorVersionList([]);
                            const mlPackage = packageList.find((pkg) => pkg.id === fieldValue);
                            setFieldValue('limitCPU', getLimitCPU(mlPackage?.tmModel));
                            setFieldValue('requestCPU', getRequestCPU(mlPackage?.tmModel));
                            setFieldValue('requestMemory', getRequestMemory(mlPackage?.tmModel));
                            setFieldValue('limitMemory', getLimitMemory(mlPackage?.tmModel));
                            setFieldValue('pvcSize', getPvcSize(mlPackage?.tmModel));
                        };

                        /* Handle Ml package Majpr version selection change */
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const handleMlPackageMajorVersionSelection = (item: any, fieldName: string, fieldValue: string, reason?: AutocompleteInputChangeReason): void => {

                            /* Since Major version input chnaged is being controlled
              we need to avoid any change for pipeline edit case where setting initial value called callbak */
                            if (pipelineId) {
                                return;
                            }

                            setFieldValue(fieldName, fieldValue);
                            setMlPackageMajaorVersionValue(item);
                            if (reason && (reason === 'reset' || reason === 'clear')) {
                                setFieldValue('packageMinorVersionId', '');
                                setMlPackageMinorVersionValue('');
                                setPackageMinorVersionList([]);
                            }
                        };

                        /* Handle Ml package Minor version selection change */
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const handleMlPackageMinorVersionSelection = (item: any, fieldName: string, fieldValue: string): void => {

                            /* Since Minor version input chnaged is being controlled
              we need to avoid any change for pipeline edit case where setting initial value called callbak */
                            if (pipelineId) {
                                return;
                            }

                            setFieldValue(fieldName, fieldValue);
                            setMlPackageMinorVersionValue(item);
                        };

                        selection = inputChanged === true ? values.packageId : '';

                        /* Update the inputDataset formik value for pipeline edit */
                        if (pipelineId && !values.inputDataset && inputDataset?.label?.length > 0) {
                            setFieldValue('inputDataset', inputDataset);
                        }

                        /* Update the evalDataset formik value for pipeline edit */
                        if (pipelineId && !values.evalDataset && evalDataset?.label?.length > 0) {
                            setFieldValue('evalDataset', evalDataset);
                        }

                        if (inputChanged === false && !pipelineId) {
                            setSelectedPackage(values.packageId);

                            setSearchTerm('');
                            setDebouncedSearchTerm('');
                        }

                        if (inputChanged === true && !pipelineId) {
                            setSelectedPackage('');
                            setSearchTerm(values.packageId || '*UNDEFINED_OR_NULL_VALUE*');
                        }

                        setSelectedPackageMajorVersion(values.packageVersion);

                        return (
                            <FormLayout
                                onSubmit={handleSubmit}
                                submitForm={submitForm}
                                onKeyDown={onKeyDown}
                                footer={
                                    <FormButtonGroup
                                        dirty={dirty}
                                        isSubmitting={isSubmitting}
                                        submitButtonText={pipelineId ? t('form_edit_button_text') : undefined}
                                    />
                                }
                            >

                                <WithHookStateUpdate
                                    payload={parametersFileJSON}
                                    flag={pipeline ? false : isParametersFileDirty}
                                    setter={(payload: any) => {
                                        setFieldValue('envVars', payload);
                                        setIsParametersFileDirty(false);
                                    }} />

                                <FormikErrorLabels errors={errors} />
                                <h2
                                    className='formTitle'
                                    aria-label={`${pipeline ? t('pipeline_edit_title') : t('pipeline_create_title')} title`}
                                    id='page-title'
                                    tabIndex={0}
                                >
                                    {pipeline ? t('pipeline_edit_title') : t('pipeline_create_title')}
                                </h2>
                                <Typography className={classes.tooltipHeader}>
                                    {t('tooltip_pipelines_desc')}
                                </Typography>

                                <Label
                                    id="pipeline-type-label"
                                    value={t('pipeline_create_pipelineType_label')}
                                />
                                <Field
                                    component={AccessibleFormikInput}
                                    className="textFeild min-width-override"
                                    getContentAnchorEl={null}
                                    anchorReference="anchorEl"
                                    type="text"
                                    select
                                    variant="outlined"
                                    color="secondary"
                                    name="pipelineType"
                                    aria-label={t('pipeline_create_pipelineType_label')}
                                    disabled={pipelineId ? true : false || (isSubmitting)}
                                >

                                    <MenuItem
                                        value=""
                                        disabled
                                        className={classes.accessibleMenuItem}
                                        aria-label={`${t('pipeline_create_pipelineType_ph')} menu item`}>
                                        {t('pipeline_create_pipelineType_ph')}
                                    </MenuItem>
                                    {pipelineTypes.map((pipelineType, index) => (
                                        <MenuItem
                                            key={index}
                                            aria-label={`${pipelineType.name} menu item`}
                                            value={pipelineType.type}>
                                            {pipelineType.name}
                                        </MenuItem>
                                    ))}
                                </Field>

                                {pipelineId === undefined || pipelineId === null ? <>
                                    <Label
                                        id="pipeline-display-name-label"
                                        value={t('pipeline_displayName_label')}
                                    />
                                    <Field
                                        component={AccessibleFormikInput}
                                        className="textFeild min-width-override"
                                        type="text"
                                        requiredField
                                        name="displayName"
                                        variant="outlined"
                                        color="secondary"
                                        aria-labelledby="pipeline-display-name-label"
                                        placeholder={t('pipeline_displayName_ph')}
                                    />
                                </> : null }

                                <Label
                                    id="pipeline-package-id-label"
                                    value={t('pipeline_create_packageName_label')}
                                    required
                                />
                                <Field
                                    name="packageId"
                                    options={packageList}
                                    loading={loading}
                                    className="min-width-override"
                                    component={FormAutoCompleteServerSide}
                                    requiredField
                                    inputvalue={pipelineId ? mlpackageName : ''}
                                    type="select"
                                    labelKey="name"
                                    labelledBy="pipeline-package-id-label"
                                    valueField="id"
                                    loadingText={t('auto_complete_loading_text')}
                                    noOptionsText={t('pipeline_create_no_trainable_package_found')}
                                    handleSelection={handleSelection}
                                    placeholder={t('auto_complete_select_ml_package_place_holder')}
                                    disabled={(pipelineId ? true : false) || (isSubmitting)}
                                />

                                <Label
                                    id="pipeline-package-major-version-label"
                                    value={t('ml_package_choose_package_major_version_label')}
                                    required
                                />
                                <Field
                                    name="packageVersion"
                                    options={packageMajorVersionList.filter((pkg, i, a) => a.findIndex(tmp => (tmp.version === pkg.version)) === i)}
                                    component={FormAutoCompleteClientSide}
                                    requiredField
                                    inputvalue={pipeline && pipeline.sourceVersion ? (pipeline.sourceVersion.split('.')[0]) : ''}
                                    type="select"
                                    className="min-width-override"
                                    labelKey="version"
                                    labelledBy="pipeline-package-major-version-label"
                                    valueField="version"
                                    value={mlPackageMajaorVersionValue}
                                    handleSelection={handleMlPackageMajorVersionSelection}
                                    loadingText={t('auto_complete_loading_text')}
                                    noOptionsText={t('auto_complete_no_content_found')}
                                    placeholder={t('ml_package_choose_package_major_version_label')}
                                    disabled={majorVersionDisabled || isSubmitting || ((pipelineId ? true : false))}
                                />

                                <Label
                                    id="pipeline-package-minor-version-label"
                                    value={t('ml_package_choose_package_minor_version_label')}
                                    required
                                />
                                <Field
                                    name="packageMinorVersionId"
                                    options={packageMinorVersionList}
                                    component={FormAutoCompleteClientSide}
                                    requiredField
                                    className="min-width-override"
                                    inputvalue={pipeline && pipeline.sourceVersion ? (pipeline.sourceVersion.split('.')[1]) : ''}
                                    type="select"
                                    labelKey="trainingVersion"
                                    labelledBy="pipeline-package-minor-version-label"
                                    valueField="id"
                                    value={mlPackageMinorVersionValue}
                                    loadingText={t('auto_complete_loading_text')}
                                    noOptionsText={t('auto_complete_no_content_found')}
                                    handleSelection={handleMlPackageMinorVersionSelection}
                                    placeholder={t('ml_package_choose_package_minor_version_label')}
                                    disabled={minorVersionDisabled || isSubmitting || (pipelineId ? true : false)}
                                />

                                {(props.values.pipelineType === PipelineType.FullTraining ||
                  props.values.pipelineType === PipelineType.TrainOnly) &&
                  (
                      <>
                          <Label
                              id="pipeline-input-dataset-label"
                              value={t('pipeline_create_inputDataset_label')}
                              required
                          />
                          <Field
                              name="inputDataset"
                              type="text"
                              options={packageMinorVersionList}
                              projectId={currentProject?.id}
                              component={FormikCompliantDataSetChooser}
                              className="textFeild min-width-override"
                              labelKey="inputDataset"
                              labelledBy="pipeline-input-dataset-label"
                              color="secondary"
                              placeholder={t('pipeline_create_inputDataset_label')}
                              requiredField
                              InputProps={{ id: 'pipeline-input-dataset-field' }}
                          />
                      </>
                  )}

                                {(props.values.pipelineType === PipelineType.FullTraining ||
                    props.values.pipelineType === PipelineType.EvaluateOnly) &&
                    (<>
                        <Label
                            id="pipeline-eval-dataset-label"
                            value={t('pipeline_create_evalDataset_label')}
                            required={props.values.pipelineType === PipelineType.EvaluateOnly}
                        />
                        <Field
                            projectId={currentProject?.id}
                            name="evalDataset"
                            options={packageMinorVersionList}
                            component={FormikCompliantDataSetChooser}
                            requiredField={props.values.pipelineType === PipelineType.EvaluateOnly}
                            className="min-width-override"
                            labelKey="evalDataset"
                            labelledBy="pipeline-eval-dataset-label"
                            color="secondary"
                            placeholder={t('pipeline_create_evalDataset_label')}
                        />
                    </>)}

                                <EnvironmentVariablesTable
                                    id="envVars"
                                    title={t('pipeline_env_parameters_label')}
                                    tableData={props.values.envVars}
                                    setTableData={handleEnvVarDataChange}
                                    aria-label={`${t('pipeline_env_parameters_label')} table`}
                                />
                                <TooltipContainer
                                    title={t('tooltip_pipelines_env_vars')}
                                    customClass={classes.customTooltipClass}>
                                    <Label
                                        value={t('pipeline_create_enableGpu_label')}
                                        aria-label={`${t('pipeline_create_enableGpu_label')} label`} />
                                </TooltipContainer>
                                <div aria-label={`${t('pipeline_create_enableGpu_label')} switch ${values.enableGpu ? `checked` : `unchecked`}`}>
                                    <Field
                                        name="enableGpu"
                                        type="checkbox"
                                        component={Switch}
                                        inputProps={{ 'aria-label': t('pipeline_create_enableGpu_label') }}
                                        color="secondary"
                                        disabled={isSubmitting}
                                    />
                                </div>

                                {isAiUnitEnabled === true ?
                                    <div
                                        aria-label={`${t('infra_total_ai_units_per_hour_label')} ${values.enableGpu ? GPU_AIUNITS : CPU_AIUNITS}`}
                                        tabIndex={0}>
                                        <Label
                                            value={`${t('infra_total_ai_units_per_hour_label')} ${values.enableGpu ? GPU_AIUNITS : CPU_AIUNITS}`}
                                        />
                                    </div> : null}
                                {isPipelineInfraSettingsEnabled === true && isAiUnitEnabled !== true ?
                                    <div className={classes.infraSettingsContainer}>
                                        <Label value={t('dialog_enable_mlskill_infra_settings')} />
                                        <div aria-label={`${t('ml_skill_infra_settings_label')} switch ${values.skillInfraSettings ? `checked` : `unchecked`}`}>
                                            <Field
                                                name="pipelineInfraSettings"
                                                component={Switch}
                                                color="secondary"
                                                type="checkbox"
                                                disabled={isSubmitting}
                                                aria-label={t('ally_enable_ml_skill_infra_settings')}
                                            />
                                        </div>

                                        {values.pipelineInfraSettings ?
                                            <div className={classes.infraSettings}>
                                                <div className="resources">
                                                    <Table className="table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    {t('ml_skill_infra_settings_request_label')}
                                                                </TableCell>
                                                                <TableCell />
                                                                <TableCell>
                                                                    {t('ml_skill_infra_settings_limit_label')}
                                                                </TableCell>
                                                                <TableCell />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>
                                                                    {t('ml_skill_infra_settings_ram_gb_label')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Field
                                                                        style={{ width: '8em' }}
                                                                        component={TextField}
                                                                        variant="outlined"
                                                                        name="requestMemory"
                                                                        type="number"
                                                                        inputProps={{
                                                                            step: '1',
                                                                            min: '1',
                                                                        }}
                                                                        color="secondary"
                                                                        aria-hidden
                                                                        aria-label={t('ally_enter_request_memory')}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {t('ml_skill_infra_settings_ram_gb_label')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Field
                                                                        style={{ width: '8em' }}
                                                                        component={TextField}
                                                                        variant="outlined"
                                                                        name="limitMemory"
                                                                        type="number"
                                                                        inputProps={{
                                                                            step: '1',
                                                                            min: '1',
                                                                        }}
                                                                        color="secondary"
                                                                        aria-hidden
                                                                        aria-label={t('ally_enter_limit_memory')}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    {t('ml_skill_infra_settings_cpu_label')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Field
                                                                        style={{ width: '8em' }}
                                                                        component={TextField}
                                                                        variant="outlined"
                                                                        name="requestCPU"
                                                                        type="number"
                                                                        inputProps={{
                                                                            step: '0.1',
                                                                            min: '0.5',
                                                                        }}
                                                                        color="secondary"
                                                                        aria-hidden
                                                                        aria-label={t('ally_enter_request_cpu')}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {t('ml_skill_infra_settings_cpu_label')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Field
                                                                        style={{ width: '8em' }}
                                                                        component={TextField}
                                                                        variant="outlined"
                                                                        name="limitCPU"
                                                                        type="number"
                                                                        inputProps={{
                                                                            step: '0.1',
                                                                            min: '0.5',
                                                                        }}
                                                                        color="secondary"
                                                                        aria-hidden
                                                                        aria-label={t('ally_enter_limit_cpu')}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <div className={classes.pvcSizeContainer}>
                                                        <Label value={t('ml_skill_infra_settings_pvc_label')} />
                                                        <Field
                                                            component={TextField}
                                                            variant="outlined"
                                                            name="pvcSize"
                                                            type="number"
                                                            inputProps={{
                                                                step: '5',
                                                                min: '10',
                                                            }}
                                                            color="secondary"
                                                            aria-hidden
                                                            aria-label={t('ally_enter_size_pvc')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </div> : null}

                                <Field
                                    name="schedule"
                                    row
                                    aria-label={`${t('pipeline_create_schedule_label')} radio group`}
                                    component={RadioGroup}
                                >
                                    <FormControlLabel
                                        value={ScheduleType.ONE_SHOT}
                                        control={<Radio
                                            id="schedule"
                                            disabled={isSubmitting}
                                            aria-label={`${t('pipeline_create_schedule_runNow_label')} radio button`} />}
                                        label={t('pipeline_create_schedule_runNow_label')}
                                        aria-label={`${t('pipeline_create_schedule_runNow_label')} radio button label`}
                                        disabled={isSubmitting}
                                    />
                                    <TooltipContainer
                                        title={t('tooltip_pipelines_time_based')}
                                        customClass={classes.customTooltipClassTimeAndRecurring}>
                                        <FormControlLabel
                                            value={ScheduleType.TIME_TRIGGER}
                                            control={<Radio
                                                id="schedule"
                                                disabled={isSubmitting}
                                                aria-label={`${t('pipeline_create_schedule_timeBased_label')} radio button`} />}
                                            label={t('pipeline_create_schedule_timeBased_label')}
                                            aria-label={`${t('pipeline_create_schedule_timeBased_label')} radio button label`}
                                            disabled={isSubmitting}
                                        />
                                    </TooltipContainer>
                                    <TooltipContainer
                                        title={t('tooltip_pipelines_recurring')}
                                        customClass={classes.customTooltipClassTimeAndRecurring}>
                                        <FormControlLabel
                                            value={ScheduleType.REPEAT_TRIGGER}
                                            control={<Radio
                                                id="schedule"
                                                disabled={isSubmitting}
                                                aria-label={`${t('pipeline_create_schedule_repeatBased_label')} radio button`} />}
                                            label={t('pipeline_create_schedule_repeatBased_label')}
                                            aria-label={`${t('pipeline_create_schedule_repeatBased_label')} radio button label`}
                                            disabled={isSubmitting}
                                        />
                                    </TooltipContainer>
                                </Field>

                                {props.values.schedule === ScheduleType.TIME_TRIGGER && (
                                    <LocalizationProvider
                                        adapterLocale={localeMap[i18n.language]}
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <Grid
                                            container
                                            direction='row'
                                            justifyContent="flex-start"
                                            alignItems='flex-end'
                                            spacing={5}>
                                            <Grid item>
                                                <Label
                                                    value={t('pipeline_create_date_label')}
                                                    aria-label={`${t('pipeline_create_date_label')} label`} />
                                                <Field
                                                    component={DatePicker}
                                                    InputProps={{
                                                        'inputProps': {
                                                            'aria-label': t('pipeline_create_date_label'),
                                                            'readOnly': false,
                                                            'role': 'textbox',
                                                        },
                                                    }}
                                                    className="textFeild"
                                                    name="date"
                                                    disablePast
                                                    inputVariant='outlined'
                                                    cancelLabel={t('form_cancel_button_text')}
                                                    okLabel={t('form_edit_button_text')}
                                                    role='textbox'
                                                    disabled={isSubmitting} />
                                            </Grid>
                                            <Grid item>
                                                <Label
                                                    value={t('pipeline_create_time_label')}
                                                    aria-label={`${t('pipeline_create_time_label')} label`} />
                                                <Field
                                                    component={TimePicker}
                                                    InputProps={{
                                                        'inputProps': {
                                                            'aria-label': t('pipeline_create_time_label'),
                                                            'role': 'textbox',
                                                            'readOnly': false,
                                                        },
                                                    }}
                                                    cancelLabel={t('form_cancel_button_text')}
                                                    okLabel={t('form_edit_button_text')}
                                                    className="textFeild"
                                                    name="time"
                                                    labelFunc={labelFunc}
                                                    inputVariant='outlined'
                                                    role='textbox'
                                                    disabled={isSubmitting} />
                                            </Grid>
                                        </Grid>
                                    </LocalizationProvider>
                                )}

                                {props.values.schedule === ScheduleType.REPEAT_TRIGGER && (
                                    <div className="crono">

                                        <Field
                                            name="cronInput"
                                            style={{ marginTop: '20px' }}
                                            type="text"
                                            component={CronInput}
                                            className="disabledDisplay textFeild"
                                            disabled
                                            variant="outlined"
                                            color="secondary"
                                            placeholder={t('pipeline_cron_choose_label')}
                                            aria-label={`${t('pipeline_cron_choose_label')} text field`}
                                            autoComplete="off"
                                            role='textbox'
                                        />
                                        <ErrorMessage name="triggerTime" />
                                    </div>
                                )}
                            </FormLayout>
                        );
                    }
                }
            </Formik>
        </div>
    );
};

export default connect((state: any) => ({
    featureManager: state.featureManagement.featureManager,
    accountId: state.authZ.accountId,
    isOnPrem: state.config.paths ? state.config.paths.isOnPrem : undefined,
}))(PipelineCreate);
