import {
    Breadcrumbs,
    Link,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface Crumb {
    title: string;
    link: string;
    data?: any;
}

const useBreadCumbStyles = makeStyles(() => createStyles({ root: { marginTop: '8px' } }));

export interface BreadcrumbProps {
    pageTitle: string | undefined;
    crumbs: Crumb[];
}

export const PageBreadCrumbs: React.FC<BreadcrumbProps> = ({
    pageTitle, crumbs,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useBreadCumbStyles();

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label={t('a11y_breadcrumb_text')}>
                {
                    crumbs.map((crumb: Crumb) => (
                        <Link
                            className="cursor-pointer"
                            key={crumb.link}
                            underline="hover"
                            data-testid="uipath-breadcrumb-link"
                            onClick={(): void => {
                                if (crumb.data) {
                                    history.push({
                                        pathname: crumb.link,
                                        state: { data: crumb.data },
                                    });
                                } else {
                                    history.push(crumb.link);
                                }

                            }} >
                            {crumb.title}
                        </Link>
                    ))
                }
                <Typography color="textPrimary">
                    {pageTitle}
                </Typography>
            </Breadcrumbs>
        </div>
    );
};
